<h5 style="margin-top: 0.5rem; margin-left:1rem">Vehicles Details</h5>    
<p>&nbsp;</p>

<div class="all-vehicle-details custom-scrollbar">
    <div class="w-100 result-found-blocks">
        <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
                <div class="ptable-css ptable-scrollable">
                    <div class="row w-100 justify-content-between m-0">
                        <div class="col-md-6 col-6 vehicle-fillter mb-3 pr-3 pl-0">
                          <ul class="nav">
                              <li class="d-flex align-items-center nav-item w-auto p-0">                           
                                    <div class="range-calendar position-relative">
                                        <div class="p-field p-col-12 ">
                                        <p-calendar [(ngModel)]="schdlDate" dateFormat="dd-mm-yy" [showIcon]="true" placeholder="Select Date" (ngModelChange)="parseDate($event)" [showIcon]="true" inputId="icon"></p-calendar>
                                        </div>
                                    </div>
                              </li>
                          </ul>
                        </div>
                        <div class="col-md-6 col-6 vehicle-fillter mb-3 p-0 text-right">
                            <ul class="nav">
                                <li class="nav-item p-0" style="width: 100%;">
                                    <div class="range-calendar">
                                        <span class="p-input-icon-right">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                    <div #dtMain>
                        <p-table #dt [value]="apiVehiclesData"  [paginator]="true" [rows]="10" [showCurrentPageReport]="true"  [globalFilterFields]="['vehicleRegNo','contact','speed','model','lastupdate2','deviceTime','address']"  
                        [(selection)]="selectedvihelcelTableDetails"  [rowHover]="true" dataKey="Id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                        responsiveLayout="scroll" [scrollable]="true" scrollHeight="70vh"
                         [tableStyle]="{'max-width': '100vw'}"
                        
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" >
                            <ng-template pTemplate="caption">
                                <div class="flex align-items-center justify-content-between">
                                    <div class="flex align-items-center justify-content-between summary-message">
                                        {{apiVehiclesData ? apiVehiclesData.length : 0 }} result(s) found.
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="vehicleRegNo">Details<p-sortIcon field="vehicleRegNo"></p-sortIcon></th> 
                                    <th pSortableColumn="contact">Company <p-sortIcon field="contact"></p-sortIcon></th>
                                    <th pSortableColumn="model">Vehicle Type <p-sortIcon field="model"></p-sortIcon></th>                       
                                    <th pSortableColumn="deviceTime" >Last Updated <p-sortIcon field="deviceTime"></p-sortIcon></th>
                                    <th pSortableColumn="lastupdate2">Timespan Status<p-sortIcon field="lastupdate2"></p-sortIcon></th> 
                                    <th>GPS</th>
                                    <th pSortableColumn="speed" >Speed <p-sortIcon field="speed"></p-sortIcon></th>
                                    <th style="width:256px;max-width:256px;" pSortableColumn="address">Location <p-sortIcon field="address"></p-sortIcon></th>           
                                </tr>
                                
                            </ng-template>
                            
                            <ng-template pTemplate="body" let-data>
                                <tr class="p-selectable-row">
                                    <td>
                                        <div class="w-100">
                                            <div class="w-100 d-flex flex-wrap">
                                                <div>
                                                    <div class="">
                                                      <div class="vehicleId d-flex  w-100">
                                                        <div class="vehicleId fs-16 font-weight-bold">{{data.vehicleRegNo}} <i *ngIf="data.backup_vehicle_no" style="color: #ffc107;" class="fa fa-truck" aria-hidden="true"></i></div>
                                                        <div class="vehicle-status-info pl-2 align-items-sm-end">
                                                          <div class="vehicle-circles rounded-circle">
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="w-100 txt-gry vehicle-route fs-12 text-capitalize my-1">{{data.subRoute}}</div>
                                                      <div class="w-100 txt-gry vehicle-route fs-12 text-capitalize my-1">{{data.company}}</div>
                                                    </div>
                                                    <div class="vehicle-route fs-12"><Span style="color: #19A3F1;">{{data.vehicle_reg_no}} ({{data.vehicle_type}})</Span></div>
                                                    <div class="vehicle-route fs-12" style="padding-top: 0.5rem;">
                                                        <span style="font-style: italic;"><b>Backup Vehicle</b> : 
                                                        {{data.backup_vehicle_no}}</span>
                                                    </div>
                                                  </div>
                                                  <div class="ml-auto">
                                                    <div class="vehicle-route fs-12">   
                                                        
                                                    </div>
                                                  

                                                  </div>
                                                  <div class="ml-auto">
                                                
                                                    <div class="vehicle-route fs-12"> 
                                                        <!-- <button *ngIf="isValidCurrentDate" pButton  label="Edit" icon="pi pi-pencil" class="p-button-info" type="button" (click)="editData(data)"></button>                                                             -->
                                                    </div> 
                                                  </div>
                                            </div>
                                            
                                        </div>
                                    </td>
                                    <td>{{data.contact}}</td>
                                    <td>{{data.model}}</td>
                                    <td>{{data.lastUpdate | date: 'dd/MM/yyyy hh:mm a'}}</td>
                                    <td>{{data.lastUpdate2}}</td> 
                                    <td><img src="{{checkStatus(data.status)}}" height="30" width= "30" alt={{data.status}} /></td>                                               
                                    <td>{{((data.speed)*1.852).toFixed(2)}} km/h</td>
                                    <td style="width:256px;max-width:256px;">{{data.address}}</td>
                                </tr>
                                
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="6" class="empty-message">No data found.</td>
                                </tr>
                              
                            </ng-template>
                            <ng-template pTemplate="summary">
                               
                                <div class="flex align-items-center justify-content-between summary-message">
                                    In total there are {{apiVehiclesData ? apiVehiclesData.length : 0 }} record(s).
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

