//import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
//import {FileUploadModule} from 'primeng/fileupload';
//import {HttpClientModule} from '@angular/common/http';
import {MenuItem} from 'primeng/api';
//import { User } from 'src/app/app.models';
//import * as XLSX from 'xlsx';
import { ConfigRoute } from 'src/app/app.models';
import { FormBuilder } from '@angular/forms';
import { GlobalComponent } from 'src/app/global-component';

@Component({
  selector: 'app-config-route',
  templateUrl: './config-route.component.html',
  styleUrls: ['./config-route.component.scss']
})
export class ConfigRouteComponent implements OnInit {
  @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
  @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigRoute[]= [];
    data: ConfigRoute = { ModuleConfigServiceArea: {}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    configRouteParam =  {id: null, isactiveonly: true};
    currentUser: any = JSON.parse(localStorage.getItem('currentData') || '{}');
    shift : any[] = [];
    shift_status = null;
    isLoading = false;

    routeShift = this.formBuilder.group({
        // id: this.data.shift_id,
        // shiftStatus: this.data.status,
        // modifiedBy: this.currentUser,
    });

   

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }


    ngOnInit(): void {
        this.getDatas();
        this.menuitems = GlobalComponent.submenus;

        this.shift = [
          {name: 'Day', value: "0"},
          {name: 'Day & Night', value: "2"},
          {name: 'Night', value: "1"}
        ]
    }

    getDatas(){
        this.commonService.getAllConfigRoute(this.configRouteParam).subscribe((resp: any) => {

            if (resp.length != 0) {
                this.datas = resp.Result;
                this.loading = false;
              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
      
    }

    editData(data: any) {

      this.data = {...data};
      this.dataDialog = true;
      // this.isCreateNew = false;
      // this.Id = data.Id;
    }

    onChangeVehicleFilter(event: any) {
        this.shift_status = event.value.value;
    }

    onSubmit(data: any): void {
      if(this.shift_status == null){
          this.messageService.add({key: 'alertMsg', severity:'error', summary: 'Failed', detail: 'Please Select Shift!', life: 3000});
      } else {
          this.isLoading = true;

          var dataConfigRouteShift = {
              id: this.data.Id,
              shiftStatus: Number(this.shift_status),
              modifiedBy: this.currentUser.Username,
          }

          this.commonService.updateConfigRoute(dataConfigRouteShift).subscribe((resp: any) => {

              if (resp.Success == true) {
                  this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                  this.isLoading = false;
                  this.dataDialog = false;
                  this.getDatas();
                
              } else {
                  this._appSharedService.showError('Error', "Something went wrong!");
                  this.isLoading = false;
              }
          }), (error: any) => {
    
              this._appSharedService.showError('Error', "Something went wrong!");
              this.isLoading = false;
          }
      }

  }


}
