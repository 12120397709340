import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppSharedService } from '../../../app.SharedService';
declare var $: any;
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import * as XLSX from 'xlsx';
import { AdminService } from '../../../admin/admin.service';
import { CommonService } from '../../../services/common.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'route-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  @ViewChild('vihecleTable', { static: true }) tabl!: ElementRef;
  @ViewChild('TABLE', { static: true }) table!: ElementRef;
  //@ViewChild('dt') dtTable!: ElementRef;


  vihelcelTableDetails: any[] = [];
  selectedvihelcelTableDetails: any[] = [];
  scitiesFilters: any[] = [];
  //rangeDates!: Date[];
  rangeDates: any;
  rangeDatesSchdl: any = '';
  startTime: any;
  endTime: any;
  value: any;
  selectedfillerVehicle: any;
  selectedfillerRoute: any;
  selectedfillerVehicleNo: any = null;
  selectedfillerVehicleType: any;
  selectedfillerScheme: any;
  selectedfillerStatus: any;
  selectedfillerCompany: any;
  fillerVihelcelDetails: any;
  filterOption: boolean = false;
  routeShow: boolean = true;
  showTime: boolean = false;
  hourFormat: string = "12";
  calnedarDisp: boolean = false;
  Lsk: boolean = false;
  calendarClose: boolean = true;
  //fromDate: any = null;
  fromDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  toDate: any = null;
  fromDateSchdl: any = null;
  toDateSchdl: any = null;
  startDate: any = '';
  endDate: any = '';
  dataAllVehicles: any[] =[];
  dataVehicles: any[] =[];
  encrypted: any;
  dataPresentR: boolean = false;
  dataPresentS: boolean = false;
  schedules: any[] = [];
  data: any[] = [];
  routes: any[] = [];
  routeRangeDates: any = '';
  schdlRangeDates: any = '';
  schdlDate = new Date();
  selectedDate: any = null;
  scheduleDate = new Date(formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US'));
  pageNo: number = 1;
  hide_arrow: boolean = false;
  hide_arrow2: boolean = false;
  showLoader: boolean = false;
  searchBy: any[] = [

    { search: 'Vehicle No.', key: 'Vehicle' }, 
    { search: 'Route No.', key: 'Route' }
 
  ];
  selectedSearchBy: any;
  isVehicleFilterShow: boolean = false;
  isRouteFilterShow: boolean = false;
  isVehicleFilterShowlsk: boolean = false;
  isRouteFilterShowlsk: boolean = false;
  selectedVehicleSchedule: any;
  selectedRouteSchedule: any;
  routeSchedules: any[] =[];
  allRouteSchedules: any[] =[];
  summaryVehicleRoutes: any[] = [];
  vehicleSchedules: any[] =[];
  allVehicleSchedules: any[] =[];

  minDate: Date;
  maxDate: Date;
  currentUser: any;
  showRouteLoader: boolean = false;
  showVehicleLoader: boolean = false;
  isRouteReady: boolean = false;
  isRouteDisabled: boolean = false;
  progressBar: boolean = false;

  routeCords: any[] = [];
  contractorVehicles : any[] =[];
  archiveList: any[] = [];
  selectedarchive: any;

  constructor(
    private _appSharedService: AppSharedService,
    private router: Router,
    private adminService: AdminService,
    private commonService: CommonService,
    public datepipe: DatePipe) { 
      const currentDate = new Date();
      this.minDate = new Date(currentDate.getFullYear() - 100, 0, 1);
      this.maxDate = new Date();
    }

  ngOnInit() {
    
    this._appSharedService.allVehicleLogs$.subscribe(value => {this.routeCords = value; });
    this.getAllVehicles();
    this.scitiesFilters = [
      { name: 'Route', code: 'route' },
      { name: 'Schedule & LSK', code: 'scheduleLSK' }
    ];
    this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');

    var startYear = parseInt('2023');
    var currentYear =  parseInt(formatDate(new Date(), 'yyyy', 'en-US'));
     
    for (let i = startYear; i <= currentYear; i++) {
      this.archiveList.push({ year: i.toString()});
    }
 

    this.getRouteVehicleSch();
    //this._appSharedService.liveTrackDetails().subscribe(
    //  data => {
    //    this.vihelcelTableDetails = data.liveTrack;
    //  },
    //  err => console.error(err),
    //);
  }

  selectedType = this.scitiesFilters;

  calendarModal() {
    this.calnedarDisp = !this.calnedarDisp;
  }

  parseDate(e: Date) {
    
    //this.selectedDate = formatDate(e, 'yyyy-MM-dd', 'en-US');
    this.fromDate = formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US');
    //this.selectedDate = formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US');
 
    

    this.getRouteVehicleSch();
  }

  getRouteVehicleSch(){

    this.progressBar= true;

    this.showRouteLoader = true;

    this.commonService.getVehidleRouteSummary(this.fromDate,this.currentUser.Id).subscribe((resp: any) => {
      //console.log(resp.Result)

      //console.log(resp.Total)

      this.routeSchedules = [];
      this.vehicleSchedules = [];
      var distictCompany:any = [];
      if (resp.Total != 0) {
        
        this.summaryVehicleRoutes= resp.Result;
        //console.log(this.summaryVehicleRoutes);
       

        var uniqueRoute:any = [];
        var uniqueVehicle:any = [];
        var arrayUniqueByKey = resp.Result.forEach((element: any) => {

            if(uniqueRoute[uniqueRoute.indexOf(element.main_route)] == undefined)
            {
            
              let route_obj: any = {};
              route_obj["main_route"] = element.main_route;
                      
              this.routeSchedules.push(route_obj);
              uniqueRoute.push(element.main_route);
            }

            if(uniqueVehicle[uniqueVehicle.indexOf(element.vehicle_no)] == undefined)
            {
            
              let vehicle_obj: any = {};
              vehicle_obj["vehicle_no"] = element.vehicle_no;         
              
              this.vehicleSchedules.push(vehicle_obj);
              uniqueVehicle.push(element.vehicle_no);
            }
          
          });

          this.allRouteSchedules = this.routeSchedules;
          this.allVehicleSchedules = this.vehicleSchedules;
          //console.log(this.allRouteSchedules);
          //console.log(this.allVehicleSchedules);
          this.showRouteLoader = false;
          this.progressBar = false;
      }
     

    });
  }

  schdlDateValues() {
    this.fromDateSchdl = this.datepipe.transform(this.rangeDatesSchdl[0], 'dd-MM-yyyy');
    this.toDateSchdl = this.datepipe.transform(this.rangeDatesSchdl[1], 'dd-MM-yyyy');
    if (this.toDateSchdl == null)
      this.schdlRangeDates = this.fromDateSchdl + ' - ';
    else
      this.schdlRangeDates = this.fromDateSchdl + ' - ' + this.toDateSchdl;
  }

  onChangesec(event: any) {
    //console.log(event.value.name)
    if (event.value.name == 'Route') {
      this.routeShow = true;
      this.Lsk = false;
      this.pageNo = 1;
      this.clearAllFilters();
      this.clearSchdlAllFilters();
      this.routes = [];
    } else if (event.value.name == 'Schedule & LSK') {
      this.Lsk = true;
      this.routeShow = false;
      this.pageNo = 1;
      this.clearAllFilters();
      this.clearSchdlAllFilters();
      this.schedules = [];
    } else if (event.value.name == null) {
      this.routeShow = false;
      this.Lsk = false;
    }
  }

  getAllVehicles() {
    this.showVehicleLoader = true;
    this.commonService.vehicleDetails().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          const result = resp.filter((obj: any) => {
            if(obj.disabled === false || obj.vehicleRegNo.includes('_MB_'))
            {
              if(obj.vehicleRegNo.includes('_MB_'))
              {
        
                let vehicle = obj.vehicleRegNo;
                obj.vehicleRegNo = vehicle.substring(0,obj.vehicleRegNo.indexOf('_MB_'));
                return obj;
              }
              else
              {
                return obj;
              }
            }
            //return obj.disabled === false;
          });

          if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
            this.dataAllVehicles = result;
            this.dataVehicles = this.dataAllVehicles;
            this.showVehicleLoader = false;
          }
          else
          {
            
            this.dataAllVehicles = result;
            this.getAllUserVehicles();
            
            //this.showVehicleLoader = false;
          }
          
          
        }
      }));
  }

  getAllUserVehicles() {
 
    this.commonService.getUserVehicle().subscribe(
      ((resp: any) => {

        var uniqueVehicle:any = [];
       
        if (resp.Result.length != 0) {

          var userVehicles : any[] =[];

          var arrayUniqueByKey = resp.Result.forEach((element: any) => {

            var objIndex = this.dataAllVehicles.findIndex((obj => obj.vehicleRegNo.replace(/\s/g, '') == element.VehicleRegNo.replace(/\s/g, '')));
      
            var objVehicle = this.dataAllVehicles[objIndex];

            if(objIndex >= 0)
            {
              userVehicles.push(objVehicle);
            }

          });


          this.contractorVehicles = userVehicles;
          this.dataVehicles = userVehicles;          
          this.showVehicleLoader = false;
        }
        else {
          this.dataVehicles = [];
          this.showLoader = false;
        }
      }),
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
        this.showLoader = false;
      }
    );
  }

  getSNextPageData() {
    if (this.pageNo >= 1) {
      this.pageNo++;
      this.getAllSchedules();
    }
  }

  getSPrevPageData() {
    if (this.pageNo >= 2) {
      this.pageNo--;
      this.getAllSchedules();
    }
  }

  getRNextPageData() {
    if (this.pageNo >= 1) {
      this.hide_arrow2 = !this.hide_arrow2;
      this.pageNo++;
      this.getAllRoutes();
    }
  }

  getRPrevPageData() {
    if (this.pageNo >= 2) {
      this.hide_arrow = !this.hide_arrow;
      this.pageNo--;
      this.getAllRoutes();
    }
  }

  getAllSchedules() {


    
    if(this.selectedRouteSchedule != null)
    {
      this.showLoader = true;

      var dataRoute = { 
        vehicleRegNo: null,     
        date: this.fromDate,
        route: this.selectedRouteSchedule.main_route,
        archive_year : (this.selectedarchive != null)? this.selectedarchive.year: ""
      }
      

      this._appSharedService.updateBinData(dataRoute);
      this._appSharedService.showBinsSchedules(dataRoute);

      this.commonService.getBinScheduleSummaryByRouteDate(dataRoute.date,dataRoute.route,dataRoute.archive_year).subscribe(
        ((resp: any) => {
          if (resp.Total != 0) {
            //console.log( resp.Result);
            this.dataPresentS = true;
            this.schedules =  resp.Result;
            this.showLoader = false;
          }
          else {
            this.schedules = [];
            this.dataPresentS = false;
            this.showLoader = false;
          }
        }),
      );
    }
    if(this.selectedfillerVehicleNo != null)
    {
      this.showLoader = true;
      var dataVehicle = { 
        vehicleRegNo: this.selectedfillerVehicleNo.vehicleRegNo,     
        date: this.fromDate,
        route: null,
        archive_year : (this.selectedarchive != null)? this.selectedarchive.year: ""
      }

      //movement log history
      var dataVehicleMove = {
      vehicleId: this.selectedfillerVehicleNo.vehicleId,
      date: this.fromDate
      }
      this._appSharedService.showVehicleHistoryLog(dataVehicleMove);

      this._appSharedService.updateBinData(dataVehicle);
      this._appSharedService.showBinsSchedules(dataVehicle);

      this.commonService.getBinScheduleSummaryByVehicleDate(dataVehicle.date,dataVehicle.vehicleRegNo,dataVehicle.archive_year).subscribe(
        ((resp: any) => {
          if (resp.Total != 0) {
            //console.log( resp.Result);
            this.dataPresentS = true;
            this.schedules =  resp.Result;
            this.showLoader = false;
          }
          else {
            this.schedules = [];
            this.dataPresentS = false;
            this.showLoader = false;
          }
        }),
      );
    }
   

    //Added 05/09/2022
   // var dataVehicle = {
    //      vehicleId: this.selectedfillerVehicleNo.vehicleId,
   //       date: this.fromDate,
   // }
   // this._appSharedService.showVehicleHistoryLog(dataVehicle);
   

   // var data = {
   //   "vehicleRegNo": this.selectedfillerVehicleNo.vehicleRegNo,
   //   "selectedDay": formatDate(this.fromDate, 'yyyy-MM-dd', 'en-US'),
   //   "page": this.pageNo
   // }
    //this.commonService.getScheduleReports(data).subscribe(
    //  ((resp: any) => {
     //   if (resp.length > 0) {
     //     this.dataPresentS = true;
     //     this.schedules = resp;
     //     this.showLoader = false;
     //   }
     //   else {
     //     this.schedules = [];
      //    this.dataPresentS = false;
      //    this.showLoader = false;
     //   }
     // }),
  //  );
  }

  getAllRoutes() {

    this.showLoader = true;
   
    if(this.selectedRouteSchedule != null)
    {
  
      //total summary collection
      var dataRouteSummary = {      
        date: this.fromDate,
        route: this.selectedRouteSchedule.main_route,
        archive_year : (this.selectedarchive != null)? this.selectedarchive.year: ""
      }
      console.log("dataRouteSummary")
      console.log(dataRouteSummary);
      this._appSharedService.updateBinData(dataRouteSummary);

      //bin schedule 
     var dataBin = {
      vehicleRegNo: (this.selectedfillerVehicleNo != null)? this.selectedfillerVehicleNo.vehicleRegNo: undefined,
      date: this.fromDate,
      route: this.selectedRouteSchedule.main_route,
      archive_year : (this.selectedarchive != null)? this.selectedarchive.year: ""
    }
    console.log("dataBin")
    console.log(dataBin);
    this._appSharedService.showBinsSchedules(dataBin);
    }
    
    
    //movement log history
    if(this.selectedfillerVehicleNo != null)
    {
      var dataVehicle = {
        vehicleId: (this.selectedfillerVehicleNo != null)? this.selectedfillerVehicleNo.vehicleId: undefined,
        date: this.fromDate,
      }
      this._appSharedService.showVehicleHistoryLog(dataVehicle);
    }
    
    

    this.showLoader = false;

 

   // var data = {
   //   "vehicleRegNo": this.selectedfillerVehicleNo.vehicleRegNo,
   //   "selectedDay": formatDate(this.fromDate, 'yyyy-MM-dd', 'en-US'),
   ///   "page": this.pageNo
   // }
   // this.commonService.getRouteReports(data).subscribe(
   //   ((resp: any) => {
   //     if (resp.length > 0) {
    //      this.dataPresentR = true;
    //      this.routes = resp;
    //      this.showLoader = false;
    //    }
    //    else {
    //      this.routes = [];
    //      this.dataPresentR = false;
    //      this.showLoader = false;

   //     }
    //  }),
    //);
  }

  clearOptions() {
    this.selectedfillerVehicleNo = '';
    this.selectedfillerRoute = [];
  }

  clearSchdlOptns() {
    this.selectedfillerVehicleNo = null;
    this.selectedfillerRoute = [];
    this.selectedfillerVehicleType = [];
    this.selectedfillerScheme = [];
    this.selectedfillerStatus = [];
    this.selectedfillerCompany = [];
    this.selectedDate = null;
    this.fromDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  }

  clearAllFilters() {
    this.selectedfillerVehicleNo = null;
    this.selectedfillerRoute = [];
    this.selectedfillerVehicle = '';
    this.selectedDate = null;
    this.selectedarchive = null;
    this.schdlDate = new Date();
    this.fromDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.canclCalendar();
    this.selectedRouteSchedule = null;
    this.routeSchedules = this.allRouteSchedules;
    if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
      this.dataVehicles = this.dataAllVehicles;
    }
    else
    {
      this.dataVehicles = this.contractorVehicles;
    }
    this.routeCords = [];

  }

  clearSchdlAllFilters() {
    this.selectedfillerVehicleNo = null;
    this.selectedfillerRoute = [];
    this.selectedfillerVehicleType = [];
    this.selectedfillerScheme = [];
    this.selectedfillerStatus = [];
    this.selectedfillerCompany = [];
    this.selectedfillerVehicle = '';
    this.selectedDate = null;
    this.selectedRouteSchedule = [];
    this.selectedarchive = null;
    this.schdlDate = new Date();
    this.fromDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.canclSchdlCalendar();
    if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
      this.dataVehicles = this.dataAllVehicles;
    }
    else
    {
      this.dataVehicles = this.contractorVehicles;
    }
  }

  filterOptionBtn() {
    this.filterOption = !this.filterOption;
  }

  canclCalendar() {
    this.calnedarDisp = false;
  }

  canclSchdlCalendar() {
    this.calnedarDisp = false;
    this.fromDateSchdl = null;
    this.toDateSchdl = null;
    this.schdlRangeDates = '';
  }

  binDetails(collectionId: any) {
    this.encrypted = btoa(collectionId);
    this.router.navigate(['/bin-details', this.encrypted]);
  }

  searchVehicles(searchValue: any) {
    
    var value = searchValue.target.value;
    if (value)
      this.dataAllVehicles = this.fillerVihelcelDetails.filter((x: { vehicleRegNo: string; }) => x.vehicleRegNo.toLowerCase().includes(value.toLowerCase()));
    else
      this.dataAllVehicles = this.fillerVihelcelDetails;
  }

  exportExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tabl.nativeElement);
    ws['!cols'] = [];
    ws['!cols'][0] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Route.xlsx');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    ws['!cols'] = [];
    ws['!cols'][0] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Schedule.xlsx');
  }

  selectByFilter(event: any) {
    

    this.isVehicleFilterShowlsk = false;
    this.isRouteFilterShowlsk = false;

    this.selectedVehicleSchedule = null;
    this.selectedRouteSchedule = null;


    if(event.value != null)
    {
      if (event.value.key == 'Vehicle') {
        this.isVehicleFilterShowlsk = true;
      }
      else if (event.value.key == 'Route') {
        this.isRouteFilterShowlsk = true;
      }

    }
 
  }

  onChangeVehicleFilters(event: any) {
  
    this.selectedRouteSchedule =null;
    this.routeSchedules = this.allRouteSchedules;
    this.selectedfillerVehicleNo = null;

    if(event.value != null)
    {

        this.selectedfillerVehicleNo = event.value;
          
        let selectRoute = this.summaryVehicleRoutes.filter(x => (x.vehicle_no == event.value.vehicleRegNo))[0];

        if(selectRoute != undefined)
        {
          //this.isRouteDisabled = true;
          this.routeSchedules = this.routeSchedules.filter(x=> x.main_route == selectRoute.main_route);
          this.selectedRouteSchedule = this.routeSchedules.filter(x=> x.main_route == selectRoute.main_route)[0];
        }               
    }
    
  }

 

  onChangeRoutesFilters(event: any) {

    this.selectedfillerVehicleNo =null;
    this.dataVehicles = this.dataAllVehicles;
    this.selectedRouteSchedule = null;

    if(event.value != null)
    {
        //console.log(event.value.main_route);

        this.selectedRouteSchedule = event.value;
          
        let selectVehicle = this.summaryVehicleRoutes.filter(x => (x.main_route == event.value.main_route))[0];
        //console.log(selectVehicle);

        //console.log(selectVehicle);
     

        if(selectVehicle != undefined)
        {
          //console.log(selectVehicle.vehicle_no);
          //this.isRouteDisabled = true;
          //console.log(this.dataVehicles);
          this.dataVehicles = this.dataVehicles.filter(x=> x.vehicleRegNo.replace(/\s/g, "").toUpperCase() == selectVehicle.vehicle_no.replace(/\s/g, "").toUpperCase());
     
          
          this.selectedfillerVehicleNo = this.dataVehicles.filter(x=> x.vehicleRegNo == selectVehicle.vehicle_no)[0];
        }
   
           
    }
    
  }

  onChangeVehicleFiltersLsk(event: any) {
  
    this.selectedfillerVehicleNo = null;
    if(event.value != null)
    {
        this.selectedfillerVehicleNo = event.value;        
    }
  }

  onChangeRoutesFiltersLsk(event: any) {

    this.selectedRouteSchedule = null;
    if(event.value != null)
    {
        this.selectedRouteSchedule = event.value;  
    }
    
  }

  printDate(e: Date) {
    //this.selectedDate = formatDate(e, 'yyyy-MM-dd', 'en-US');
  }

  onChangeArchiveFilters(event: any) {

    this.selectedarchive =null;
  

    if(event.value != null)
    {
        //console.log(event.value.main_route);

        this.selectedarchive = event.value;

    }
    
  }

 

}
