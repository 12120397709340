import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;
  pwdEye1: boolean = false;
  pwdEye2: boolean = false;
  pwdEye3: boolean = false;
  currentUser: any;
  successMsgBox: boolean = false;
  formError: string = "";
  errorMsgBox: boolean = false;

  constructor(private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');
    this.changePasswordForm = this.fb.group({
      email: [""],
      oldPwd: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      newPwd: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      retypePwd: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
    });
  }

  changePassword() {

    const chngPwd = {
      email: this.currentUser.Email,
      currentPassword: this.changePasswordForm.value.oldPwd,
      newPassword: this.changePasswordForm.value.newPwd,
      retypePassword: this.changePasswordForm.value.retypePwd,
    };
  }

  alertBoxClose() {
    this.errorMsgBox = false;
    this.successMsgBox = false;
  }

}
