import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import { AppSharedService } from '../../app.SharedService';
import { ConfirmationService, Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
  providers: [ConfirmationService]
})
export class VehicleComponent implements OnInit {
  vehicleForm!: FormGroup;
  showLoader: boolean = false;
  dataPresent: boolean = false;
  vehicles: any[] = [];
  devices: any[] = [];
  companies: any[] = [];
  deviceTypeIds: any[] = [];
  formActionType: string = '';
  formSubmitting: boolean = false;
  vehicle: any = null;
  addDisplay: boolean = false;
  editDisplay: boolean = false;
  msgs: Message[] = [];
  data: any;

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private appCom: AppSharedService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig) { }

  first = 0;
  display: boolean = false;
  rows = 10;
  
  ngOnInit(): void {
    this.vehicleForm = this.fb.group({
      vehicleId: ["", Validators.compose([Validators.required])],
      companyId: ["", Validators.compose([Validators.required])],
      deviceId: ["", Validators.compose([Validators.required,])],
      vehicleRegNo: ["", Validators.compose([Validators.required])],
      vehicleTypeId: ["", Validators.compose([Validators.required])],
      description: ["", Validators.compose([Validators.required,])],
      status: ["", Validators.compose([Validators.required])],
    });
    this.getAllVehicles();
    this.getAllDevices();
    this.getAllCompanies();
  }

  getAllDevices() {
    this.showLoader = true;
    this.adminService.getAllDevices().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.devices = [];
          this.data = resp;
          for (var i = 0; i < resp.length; i++) {
            if (this.data[i].isActive == true) {
              this.devices.push(this.data[i].deviceId);
            }
          }
        }
        else {
          this.devices = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
    );
  }

  getAllCompanies() {
    this.showLoader = true;
    this.adminService.getAllCompanies().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.data = [];
          this.companies = [];
          this.data = resp;
          for (var i = 0; i < resp.length; i++) {
            if (this.data[i].active == true) {
              this.companies.push(this.data[i].companyId);
            }
          }
        }
        else {
          this.data = [];
          this.companies = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
    );
  }

  addShowDialog() {
    this.vehicleForm.reset();
    this.addDisplay = true;
  }

  addCancLBtn() {
    this.addDisplay = false;
  }

  editShowDialog(id: any) {
    this.editDisplay = true;
    this.formActionType = 'Edit';
    this.vehicle = null;
    this.vehicle = this.vehicles.filter(x => x.vehicle_id == id)[0];
    if (this.vehicle != null) {
      this.vehicleForm.controls['companyId'].setValue(this.vehicle.companyId);
      this.vehicleForm.controls['deviceId'].setValue(this.vehicle.deviceId);
      this.vehicleForm.controls['vehicleRegNo'].setValue(this.vehicle.vehicleRegNo);
      this.vehicleForm.controls['vehicleTypeId'].setValue(this.vehicle.vehicleTypeId);
      this.vehicleForm.controls['description'].setValue(this.vehicle.description);
      this.vehicleForm.controls['status'].setValue(this.vehicle.active);
    }
    else
      this.appCom.showError("Something went wrong!", 'wrong');
  }

  editCancLBtn() {
    this.editDisplay = false;
  }

  getAllVehicles() {
    this.showLoader = true;
    this.adminService.getAllVehicles().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.vehicles = [];
          this.vehicles = resp;
          this.data = resp;
          for (var i = 0; i < resp.length; i++) {
            if (this.data[i].active == true) {
              this.deviceTypeIds.push(this.data[i].vehicleTypeId);
            }
          }
        }
        else {
          this.vehicles = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
    /*  error => {
        this.appCom.showError("Something went wrong!", "wrong");
        this.showLoader = false;
      }*/
    );
  }

  addVehicle() {
    this.formActionType = 'Add';
    this.showLoader = true;
    this.formSubmitting = false;
    const vehicle = {
      companyId: this.vehicleForm.controls['companyId'].value,
      deviceId: this.vehicleForm.controls['deviceId'].value,
      vehicleRegNo: this.vehicleForm.controls['vehicleRegNo'].value,
      vehicleTypeId: this.vehicleForm.controls['vehicleTypeId'].value,
      description: this.vehicleForm.controls['description'].value,
      active: true
    };
    this.addDisplay = false;
    this.adminService.addVehicle(vehicle).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess(this.formActionType == "Add" ? "Successfully added!" : "Successfully updated!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  editVehicle() {
    const vehicle = {
      vehicleId: this.vehicle.vehicleId,
      companyId: this.vehicleForm.controls['companyId'].value,
      deviceId: this.vehicleForm.controls['deviceId'].value,
      vehicleRegNo: this.vehicleForm.controls['vehicleRegNo'].value,
      vehicleTypeId: this.vehicleForm.controls['vehicleTypeId'].value,
      description: this.vehicleForm.controls['description'].value,
      active: this.vehicleForm.controls['status'].value
    };
    this.editDisplay = false;
    this.adminService.editVehicle(vehicle).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess(this.formActionType == "Add" ? "Successfully added!" : "Successfully updated!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  deleteVehicle(id: any) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.adminService.deleteVehicle(id).subscribe(
          resp => {
            if (resp.statusCode == 200) {
              this.appCom.showSuccess(`Successfully deleted!`, 'success');
              this.ngOnInit();
            }
            else {
              this.appCom.showError("Something went wrong!", 'wrong');
            }
            this.showLoader = false;
          }, error => {
            this.appCom.showError("Something went wrong!", 'wrong');
            this.showLoader = false;
          });
      },
      reject: () => {
        this.appCom.showWarn("Rejected", "You have rejected");
      },
      key: "myDialog"
    });
  }

  validatedName(event: { charCode: number; }) {
    return (
      (event.charCode > 96 && event.charCode < 123) || //alphabets
      (event.charCode > 64 && event.charCode < 91) || //capital Alphabets
      event.charCode == 8 || //backspace
      event.charCode == 32 //Space
    );
  }

  validatedExtension(event: { charCode: number; }) {
    return (
      (event.charCode > 47 && event.charCode < 58) || // numericals
      event.charCode == 8 || //backspace
      event.charCode == 43
    );
  }
}
