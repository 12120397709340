import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

const API_BASE_URL = `${environment.apiBaseURL}`;
const API_BASE_INT_URL = `${environment.apiBaseTelematicUrl}`;

export class TokenInterceptor implements HttpInterceptor {
  result: any;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentToken = AuthService.getCurrentToken();


    if (currentToken != null && currentToken != '' && currentToken != undefined) {
      if (request.method == "GET" || "POST") {
        if (request.url.startsWith(API_BASE_URL)) {
        //if (request.url.startsWith('http://localhost:64106')) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${currentToken}`
            }
          });
        }
        else if (request.url.match(API_BASE_INT_URL)) {
         
          request = request.clone({
            setHeaders: {              
              //Authorization: 'Basic ' + btoa('admin3@alamflora.com.my:@dmin123*')
              Authorization: 'Basic ' + localStorage.getItem("TraccarAccess")
            }
          });

         
        }
        else {
          return next.handle(request);
        }
      }
    }
    return next.handle(request);
  }
}
