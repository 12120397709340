<div class="bg-layout layout-block">
<app-header></app-header>
  <div class="w-100 d-flex layout-bodyinfo">
    <div class="left-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="right-sidebar scrollbar">
      <div class="body-admin-block">
        <div class="page-content-info py-3 pe-3 mx-3">
          <div>
            <router-outlet></router-outlet>
          </div>
        </div>
        <div class="w-100">
       
        </div>
      </div>
    </div>
  </div>
</div>

