import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AdminService {
  private adminDomainURL: string = 'http://202.153.46.220:6601/';

  constructor(private http: HttpClient) { }

  getAllDevices(): Observable<any> {
    return this.http.get(this.adminDomainURL + 'devices');
  }

  addDevices(device: object): Observable<any> {
    return this.http.post(this.adminDomainURL + 'device/add', device);
  }

  editDevices(device: object): Observable<any> {
    return this.http.put(this.adminDomainURL + 'device/update', device);
  }

  deleteDevices(id: string): Observable<any> {
    return this.http.delete(this.adminDomainURL + 'delete?id=' + id);
  }

  getAllCompanies(): Observable<any> {
    return this.http.get(this.adminDomainURL + 'companies');
  }

  addCompany(company: object): Observable<any> {
    return this.http.post(this.adminDomainURL + 'company/add', company);
  }

  editCompany(company: object): Observable<any> {
    return this.http.put(this.adminDomainURL + 'company/update', company);
  }

  deleteCompany(id: any): Observable<any> {
    return this.http.delete(this.adminDomainURL + 'company/delete?id=', id);
  }

  getAllRoutes(): Observable<any> {
    return this.http.get(this.adminDomainURL + 'routes');
  }

  addRoute(route: object): Observable<any> {
    return this.http.post(this.adminDomainURL + 'route/add', route);
  }

  editRoute(route: object): Observable<any> {
    return this.http.put(this.adminDomainURL + 'route/update', route);
  }

  deleteRoute(id: any): Observable<any> {
    return this.http.delete(this.adminDomainURL + 'route/delete?id', id);
  }

  getAllSchedules(): Observable<any> {
    return this.http.get(this.adminDomainURL + 'schedules');
  }

  addSchedule(schedule: object): Observable<any> {
    return this.http.post(this.adminDomainURL + 'schedule/add', schedule);
  }

  editSchedule(schedule: object): Observable<any> {
    return this.http.put(this.adminDomainURL + 'schedule/update', schedule);
  }

  deleteSchedule(id: any): Observable<any> {
    return this.http.delete(this.adminDomainURL + 'schedule/delete?id', id);
  }

  getAllVehicles(): Observable<any> {
    return this.http.get(this.adminDomainURL + 'vehicles');
  }

  addVehicle(vehicle: object): Observable<any> {
    return this.http.post(this.adminDomainURL + 'vehicle/add', vehicle);
  }

  editVehicle(vehicle: object): Observable<any> {
    return this.http.put(this.adminDomainURL + 'vehicle/update', vehicle);
  }

  deleteVehicle(id: any): Observable<any> {
    return this.http.delete(this.adminDomainURL + 'vehicle/delete?id', id);
  }
}
