import { Component, OnInit, HostListener } from '@angular/core';
import { AppSharedService } from '../../../app.SharedService';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { SocketService } from '../../../services/socket.service';
import { AuthService } from '../../../_helpers/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})

export class LeftSidebarComponent implements OnInit {
  changePassForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    newPassword: new FormControl(),
    retypeNewPassword: new FormControl(),
  });
  submitted: boolean = false;
  publicKey: string = '';
  pwdEye: boolean = false;
  dataDialog: boolean = false;
  leftSideBoxHidden: boolean = false;
  successMsgBox: boolean = false;
  windowSize!: number;
  currentAdmin: any;
  menuData: any;
  formError: string = "";
  tempData: any[] = [];
  display: boolean = false;
  selectedIndex: number = 1;
  getBoolean!: boolean;


  constructor(private _appSharedService: AppSharedService,
    private router: Router,
    private webSocketSerice: SocketService,
    private authSerice: AuthService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.currentAdmin = JSON.parse(localStorage.getItem('currentData') || '{}');
    this.menuItems();

    this.changePassForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      currentPassword: ["", [Validators.required, Validators.minLength(4)]],
      newPassword: ["", [Validators.required, Validators.minLength(4)]],
      // currentPassword: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      // newPassword: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      retypePassword: ["", [Validators.required]],
    }, {
      validator: this.ConfirmedValidator('newPassword', 'retypePassword'),
    });
  }

  menuItems() {
    this.commonService.menuItems(this.currentAdmin.Id).subscribe(
      (res: any) => {
        this.tempData = [];
        if (res.Success == true) {
          this.successMsgBox = true;
          this.menuData = res.Result;
          
          for (var i = 0; i < this.menuData.length; i++) {
            this.tempData.push(this.menuData[i].ConfigMenu);
          }
          
        }
        else
          this.formError = res.Message;
      }), (error: any) => {
        this.formError = "Something went wrong!";
      }
  }

  menuBarRedirection(value: any, index: number) {
    this.selectedIndex = index;
    if (value == 'Back')
      this.navShowHiden();
    else if (value == 'Fleet Live Tracking') {
      this.router.navigate(["home/live-tracking"]);
      this._appSharedService.setdata(this.getBoolean = true);
    }
    else if (value == 'Log Out')
      this.showDialog();
    else if (value == 'Routing and Reports')
      this.router.navigate(["home/route"]);
    else if (value == 'Backup Vehicles')
      this.router.navigate(["home/backup-vehicles"]);
    else if (value == 'Vehicle Shift')
      this.router.navigate(["home/vehicle-shift"]);
    else if (value == 'Report')
      this.router.navigate(["home/report-route"]);
    else if (value == 'LSK On-going')
      this.router.navigate(["home/vehicle-pending-task"]);
    // else if (value == 'Geofence')
    //   this.router.navigate(["home/geofence"]);
    // else if (value == 'Geofence')
    //   // this.router.navigate(["home/geo-fence"]);
    else if (value == 'BHK')
      this.router.navigate(["home/module-bhk"]);
    else if (value == 'Trips')
      this.router.navigate(["home/trips"]);
    else if (value == 'Events')
      this.router.navigate(["home/events"]);
    else if (value == 'Dashboard')
      this.router.navigate(["home/analytics"]);
    else if (value == 'Settings')
      this.router.navigate(["home/settings/users"]);
    else if (value == 'Change Password')
      this.dataDialog = true;
    else
      console.log('no action for now!');
  }

  showDialog() {
    this.display = true;
  }

  displayFalse() {
    this.display = false;
  }

  navShowHiden() {
    this.leftSideBoxHidden = !this.leftSideBoxHidden;
    this._appSharedService.toggleSidebarView(this.leftSideBoxHidden);
  }

  logout() {
    this.authSerice.logout('/login');
    this.display = false;
  }

  alertBoxClose() {
    this.successMsgBox = false;
  }

  @HostListener('window:resize')
  dropdownShowLeft() {
    this.windowSize = window.innerHeight;
    if (this.windowSize <= 443) {
      this.leftSideBoxHidden = true;
      this._appSharedService.toggleSidebarView(this.leftSideBoxHidden);
    }
    else { }
  }

  chagePassFormSubmit(log: any) {

    this.commonService.changePassword(log).subscribe((resp: any) => {
      if (resp.Success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Data Created', life: 3000 });
        this.dataDialog = false;
        setTimeout(() => {
          this.authSerice.logout('/login');
        }, 2000); //2seconds
      } else {
        this._appSharedService.showError('Error', "Something went wrong! Please check your password");
      }
    }), (error: any) => {

      this._appSharedService.showError('Error', "Something went wrong!");
    }
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  hideDialog() {
    this.dataDialog = false;
    this.submitted = false;
  }
}
