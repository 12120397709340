
<div style="padding:5px 20px">
    <p-menubar [model]="menuitems">
    </p-menubar>
</div><h5 style="margin-top: 0.5rem; margin-left:1rem">Manage Vehicle Shift</h5>    
<p>&nbsp;</p>

<div class="all-vehicle-details custom-scrollbar">
    <div class="w-100 result-found-blocks">
        <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
                <div class="ptable-css ptable-scrollable">
                    <div class="row w-100 justify-content-between m-0">
                        <div class="col-md-6 col-6 vehicle-fillter mb-3 pr-3 pl-0">
                            <ul class="nav">
                                <li class="d-flex align-items-center nav-item w-auto p-0">
                                    <div class="range-calendar position-relative">
                                        <div class="p-field p-col-12 ">
                                            <p-calendar [(ngModel)]="schdlDate" dateFormat="dd-mm-yy" [showIcon]="true" placeholder="Select Date" (ngModelChange)="parseDate($event)" [showIcon]="true" inputId="icon"></p-calendar>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-6 vehicle-fillter mb-3 p-0 text-right">
                            <ul class="nav">
                                <li class="nav-item p-0" style="width: 100%;">
                                    <div class="range-calendar">
                                        <span class="p-input-icon-right">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                                                placeholder="Search..." />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div #dtMain>
                        <p-table #dt [value]="datas" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"  [globalFilterFields]="['route','subRoute','vehicle_reg_no','company','vehicle_type','shift_backup_vehicle_no']" 
                        [(selection)]="selectedDatas" [rowHover]="true" dataKey="Id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                        responsiveLayout="scroll" [scrollable]="true" scrollHeight="70vh"
                            [tableStyle]="{'max-width': '100vw'}"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [rowsPerPageOptions]="[10,25,50]">
                            <ng-template pTemplate="caption">
                                <div class="flex align-items-center justify-content-between">
                                    <div class="flex align-items-center justify-content-between summary-message">
                                        {{datas ? datas.length : 0 }} result(s) found.
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="route" style="width:90%;">Details<p-sortIcon
                                            field="route"></p-sortIcon></th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-data>
                                <tr class="p-selectable-row">
                                    <td style="width:90%;">
                                        <div class="w-100">
                                            <div class="w-100 d-flex flex-wrap">
                                                <div>
                                                    <div class="">
                                                        <div class="vehicleId d-flex  w-100">
                                                            <div class="vehicleId fs-16 font-weight-bold">{{data.route}}
                                                            </div>
                                                            <div class="vehicle-status-info pl-2 align-items-sm-end">
                                                                <div class="vehicle-circles rounded-circle">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="w-100 txt-gry vehicle-route fs-12 text-capitalize my-1">
                                                            {{data.subRoute}}</div>
                                                        <div
                                                            class="w-100 txt-gry vehicle-route fs-12 text-capitalize my-1">
                                                            {{data.company}}</div>
                                                    </div>
                                                    <div class="vehicle-route fs-12"><Span style="color: #19A3F1;">{{data.vehicle_reg_no}} ({{data.vehicle_type}})</Span></div>
                                                    <div><i>
                                                        <span class="badge badge-pill badge-warning" *ngIf="data.shift_status == '0' || data.shift_status == ''">Day</span>
                                                        <span class="badge badge-pill badge-secondary" *ngIf="data.shift_status == '1'">Night</span>
                                                        <span class="badge badge-pill badge-success" *ngIf="data.shift_status == '2'">Day & Night</span>
                                                    </i></div>
                                                    
                                                    <div class="vehicle-route fs-12" style="padding-top: 0.5rem;">
                                                        <span style="font-style: italic;"><b>Vehicle Replacement</b> : 
                                                           {{data.shift_backup_vehicle_no}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="ml-auto" style="margin-block: auto">
                                                
                                                    <div class="vehicle-route fs-12"> 
                                                        <button *ngIf="isValidCurrentDate" pButton label="Edit" icon="pi pi-pencil" class="p-button-info" type="button" (click)="editData(data)"></button>                                                            
                                                    </div> 
                                                </div>
                                            </div>

                                        </div>
                                    </td>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="6" class="empty-message">No data found.</td>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="summary">

                                <div class="flex align-items-center justify-content-between summary-message">
                                    In total there are {{datas ? datas.length : 0 }} record(s).
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- MODAL -->
<form [formGroup]="assignShiftVehicle" (ngSubmit)="onSubmit(data)" id="formSubmit">
    <p-dialog [(visible)]="dataDialog" [maximizable]="true" [breakpoints]="{'960px': '70vw'}" [style]="{width: '50vw'}"
        [modal]="true" styleClass="p-fluid dialog-overflow-visible">
        <ng-template pTemplate="header">
            <div class="w-100 d-flex justify-content-between align-items-center h-100">
                <div class="title-fiters">Manage Shift - {{data.vehicle_reg_no}}</div>
            </div>
        </ng-template>
        <div class="row">
            <div class="col-sm-6 col-md-12">
                <div class="w-100 fillet-boxs-css mb-4 position-relative">
                </div>
            </div>
        </div>
        <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">

            <table class="mb-3">
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Date</b></td>
                    <td style="width:10vw;font-size: 90%;">:</td>
                    <td style="width:60vw;"><span class=" fs-12 text-capitalize">{{data.schedule_date}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>Route</b></td>
                    <td style="width:10vw;font-size: 90%">:</td>
                    <td style="width:60vw;"><span class=" fs-12 text-capitalize">{{data.route}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>Sub Route</b></td>
                    <td style="width:10vw;font-size: 90%">:</td>
                    <td><span class=" fs-12 text-capitalize">{{data.subRoute}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>Vehicle</b></td>
                    <td style="width:10vw;font-size: 90%">:</td>
                    <td><span class=" fs-12 text-capitalize">{{data.vehicle_reg_no}} ({{data.vehicle_type}})</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>Status</b></td>
                    <td style="width:10vw;font-size: 90%">:</td>
                    <td>
                        <!-- <span class="fs-12 text-capitalize">{{data.vehicle_reg_no}} ({{data.vehicle_type}})</span> -->
                        <span class="fs-12 text-capitalize badge badge-pill badge-warning" *ngIf="data.shift_status == '0' || data.shift_status == ''">Day</span>
                        <span class="fs-12 text-capitalize badge badge-pill badge-secondary" *ngIf="data.shift_status == '1'">Night</span>
                        <span class="fs-12 text-capitalize badge badge-pill badge-success" *ngIf="data.shift_status == '2'">Day & Night</span>
                    </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b> <div class="w-100 fillet-boxs-css position-relative">
                        Assign Vehicle
                    </div></b></td>
                    <td><div class="w-100 fillet-boxs-css position-relative">
                        :
                    </div></td>
                    <td><span><div class="w-100 fillet-boxs-css position-relative">
                        <p-dropdown [options]="unassignVehicle" [filter]="true" [ngModelOptions]="{standalone: true}"
                                      [showClear]="true" placeholder="Select Vehicle" (onChange)="onChangeVehicleBackup($event)"
                                      optionLabel="Description">
                        </p-dropdown>
                    </div></span> </td>
                </tr>
            </table>
            <div *ngIf="!showVehicleLoader" class="w-100 fillet-boxs-css mb-4 position-relative">                
                <button pButton label="Assign" class="p-button-info" type="submit"></button>
            </div>
            <div *ngIf="showVehicleLoader" class="align-items-center justify-content-center"
                style="text-align: center; display: flex;">
                <div class="loader"></div>
            </div>
            <div class="scrollable-table">

                <table class="table mt-3 outside-border" *ngIf="!showVehicleLoader">
                    <thead class="thead-light" style="background-color: #e9ecef;">
                        <tr>
                            <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Replacement Vehicle Shift</td>
                        </tr>
                    </thead>
                    <tbody> 
                        <ng-template [ngIf]="assignedBackupVehicleShiftList.length!=0" [ngIfElse]="noData">
                            <tr *ngFor="let backup of assignedBackupVehicleShiftList">
                                <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">
                                    <b>{{backup.shift_backup_vehicle_no}}</b> 
                                    ({{backup.vehicle_type}})-{{backup.company}}
                                    <span class="fs-12 text-capitalize badge badge-pill badge-warning" *ngIf="backup.status == '0' || data.shift_status == ''">Day</span>
                                    <span class="fs-12 text-capitalize badge badge-pill badge-secondary" *ngIf="backup.status == '1'">Night</span>
                                </td>
                                <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash" style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right" (click)="unassignShift(backup)"></i></td>
                            </tr>
                        </ng-template>
                        <ng-template #noData>
                            <tr>
                                <td style="padding: 0.3rem;" class="font-italic empty-message">No Backup Vehicle</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </ng-template>
        <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
            <button pButton label="Close" icon="pi pi-times"
                class="p-button-outlined p-button-info p-button p-component" (click)="hideDialog()"
                type="button"></button>
        </ng-template>
        <ng-template #loaderProgress>
            <div class="Loading"></div>
        </ng-template>
    </p-dialog>
</form>
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>