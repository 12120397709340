<ng-template #loading>
  <div class="d-flex mb-3 justify-content-center w-100">
    <div class="loader-small"></div>
  </div>
  

</ng-template>
<ng-template #loaderProgress>
  <div class="Loading"></div>
  </ng-template>
<div class="all-vehicle-details custom-scrollbar">
  <div class="w-100 result-found-blocks">
    <div class="w-100 row justify-content-start border-bottom align-items-center mx-0">
      <div class="fs-12 col-md-2 text-left ">Routing and Reports</div>
      <p-divider layout="vertical"></p-divider>
      <div class="fs-12 col-md-2 text-left">
        <div class="w-100 fillet-boxs-css1">
          <p-dropdown [options]="scitiesFilters" (onChange)="onChangesec($event)" optionLabel="name"
            [showClear]="false"></p-dropdown>
        </div>
      </div>
    </div>
  
    <div class="result-found-list found-fonts fs-10 lh-12">
      <div class="w-100 tableFixHead">
        <!--Route schedule block-->
        <div class="ptable-css ptable-scrollable" [ngClass]="routeShow? 'd-block':'d-none'">
          <div>
            <div class="w-100 d-flex justify-content-between my-4">
              <div class="vehicle-fillter">

               
                <ng-template #loaderProgress>
                  <div class="Loading"></div>
                  </ng-template>
                <ul class="nav">
                  <li class="nav-item">
                    <div class="range-calendar position-relative">
                      <div class="p-field p-col-12 ">
                        <p-calendar [(ngModel)]="schdlDate" dateFormat="dd-mm-yy" [showIcon]="true"
                          placeholder="Select Date" (ngModelChange)="parseDate($event)" [showIcon]="true"
                          inputId="icon"></p-calendar>
                      </div>
                      <div class="modal_calendar range-calendar border rounded position-absolute bg-white"
                        style="z-index:111;font-weight:normal;" *ngIf="calnedarDisp">
                        <div class="p-1 d-flex justify-content-between">
                          <div style="width:300px;" class="p-3">
                          </div>
                        </div>
                      </div>
                    </div>
                    <small style="color: red;" *ngIf="selectedDate == 'undefined'">*Select Date</small>
                  </li>
                  <!--li class="nav-item">
                    <div class="fillet-boxs-css mb-3 position-relative mx-3">
                      <p-dropdown  [options]="searchBy" [(ngModel)]="selectedSearchBy" [ngModelOptions]="{standalone: true}"
                      [showClear]="true" placeholder="Select Filter" (onChange)="selectByFilter($event)"
                      optionLabel="search" >
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="svehicleRegNoelectedSearchBy">
                            <div>{{selectedSearchBy.search}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li-->
                  <li class="nav-item">
                    <div class="fillet-boxs-css" *ngIf="!showVehicleLoader; else loading">
                      <p-dropdown [options]="dataVehicles" [(ngModel)]="selectedfillerVehicleNo"
                        optionLabel="vehicleRegNo" [filter]="true" filterBy="vehicleRegNo" [showClear]="true"
                        placeholder="Select a Vehicle" (onChange)="onChangeVehicleFilters($event)">
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="selectedfillerVehicleNo">
                            <div>{{selectedfillerVehicleNo.vehicleRegNo}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li>

                  <li class="nav-item">

                    <div class="fillet-boxs-css" *ngIf="!showRouteLoader; else loading">
                      <p-dropdown [options]="routeSchedules" [(ngModel)]="selectedRouteSchedule" [filter]="true"
                        [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Route"
                        (onChange)="onChangeRoutesFilters($event)" optionLabel="main_route">
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="selectedRouteSchedule">
                            <div>{{selectedRouteSchedule.main_route}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li>

                  <li class="nav-item" *ngIf="(currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">

                    <div class="fillet-boxs-css" *ngIf="!showRouteLoader; else loading">
                      <p-dropdown [options]="archiveList" [(ngModel)]="selectedarchive" [filter]="true"
                        [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Archive Data"
                        (onChange)="onChangeArchiveFilters($event)" optionLabel="year">
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="selectedarchive">
                            <div>{{selectedarchive.year}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li>

                  <li class="d-flex align-items-center nav-item w-auto">

                    <button class=" clear-search  all-bg text-white p-2 border-0 rounded cursor-pointer"
                      [disabled]="selectedDate == 'undefined'" (click)="getAllRoutes()"
                      style="width:100px !important;">Search</button>
                  </li>
                  <li class="d-flex align-items-center nav-item w-auto">
                    <button class=" mx-2 border-0 p-0 bg-transparent clear-search txt-default-color"
                      (click)="clearAllFilters()">Clear Search</button>
                  </li>
                </ul>
              </div>
              <div class="vehicle-fillter">
                <ul class="nav">
                  <li class="nav-item">
                    <div class="range-calendar">
                      <span class="p-input-icon-right">
                        <i class="pi pi-search"></i>
                        <input type="text" [(ngModel)]="selectedfillerVehicle" pInputText placeholder="Search"
                          (input)="searchVehicles($event)">
                        <!--input pInputText type="text" (input)="dt1.ElementRef.filterGlobal($event.target.value, 'contains')" placeholder="Search..." /-->
                      </span>
                    </div>
                  </li>
                  <li class="d-flex align-items-center nav-item w-auto pr-0 dropdown down-drop-btn-bk">
                    <button
                      class="border px-3 py-2 filter-btn-btm bg-transparent d-flex align-items-center h-100  d-flex dropdown-toggle"
                      type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <div>Export</div>
                      <div class="pl-3">
                        <img src="./assets/images/web-112021/icons/export.png" />
                      </div>
                    </button>
                    <ul class="dropdown-menu border-0 p-0 mt-0 w-100 bg-white" aria-labelledby="navbarDropdown">
                      <li>
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportExcel()">Download
                          all </a>
                      </li>
                      <!-- <li>
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportExcel()">Download
                          selected schedules</a>
                      </li>
                      <li>
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportExcel()">Download
                          all LSK</a>
                      </li>
                      <li>
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportExcel()">Download
                          selected LSK</a>
                      </li> -->
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-2">
              <div class="w-100 text-center" style="margin:0 auto;">
                <div class="loader" *ngIf="showLoader"></div>
              </div>
            </div>
          </div>


          <div class="" #vihecleTable>
            <p-table #dt [value]="routeCords" [rows]="10" [paginator]="true"
              [globalFilterFields]="['id','speed','deviceTime','address']" [(selection)]="selectedvihelcelTableDetails"
              *ngIf="!showLoader" [rowHover]="true" dataKey="id" styleClass="p-datatable-striped p-datatable-sm"
              [scrollable]="true" scrollHeight="250px"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [showCurrentPageReport]="true">
              <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                  <div class="flex align-items-center justify-content-between summary-message">
                    {{routeCords ? routeCords.length : 0 }} result(s) found.
                  </div>
                </div>
              </ng-template>

              <ng-template pTemplate="header">
                <tr>
                  <th style="width:56px;max-width:56px;" class="bg-white text-center fillet-boxs-css">
                    <p-tableHeaderCheckbox class="mx-auto"></p-tableHeaderCheckbox>
                  </th>
                  <!--th style="width: 50px;" pSortableColumn="id" >Id <Small><p-sortIcon field="id"></p-sortIcon></Small></th-->
                  <th pSortableColumn="vehicle">Vehicle <p-sortIcon field="vehicle"></p-sortIcon></th>
                  <th pSortableColumn="contact">Company <p-sortIcon field="contact"></p-sortIcon></th>
                  <th pSortableColumn="model">Vehicle Type <p-sortIcon field="model"></p-sortIcon></th>
                  <th pSortableColumn="latitude">Latitude <p-sortIcon field="latitude"></p-sortIcon></th>
                  <th pSortableColumn="longitude">Longitude <p-sortIcon field="longitude"></p-sortIcon></th>

                  <th pSortableColumn="deviceTime">DeviceTime <p-sortIcon field="deviceTime"></p-sortIcon></th>
                  <th pSortableColumn="speed">Speed <p-sortIcon field="speed"></p-sortIcon></th>
                  <th style="width:256px;max-width:256px;" pSortableColumn="address">Address <p-sortIcon
                      field="address"></p-sortIcon></th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-route>
                <tr class="p-selectable-row">
                  <td style="width:56px;max-width:56px;" class="fillet-boxs-css">
                    <p-tableCheckbox [value]="route" class="mx-auto"></p-tableCheckbox>
                  </td>
                  <!--td style="width: 50px;">{{route.id}}</td-->
                  <td>{{selectedfillerVehicleNo.vehicleRegNo}}</td>
                  <td>{{selectedfillerVehicleNo.contact}}</td>
                  <td>{{selectedfillerVehicleNo.model}}</td>
                  <td>{{route.latitude}}</td>
                  <td>{{route.longitude}}</td>

                  <td>{{route.deviceTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
                  <td>{{((route.speed)*1.852).toFixed(2)}} km/h</td>
                  <td style="width:256px;max-width:256px;">{{route.address}}</td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="18" class="empty-message">Please select Date and Vehicle.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>


        </div>
        <!-- LSK schedule block-->
        <div class="ptable-css ptable-scrollable" [ngClass]="Lsk?'d-block':'d-none'">
          <div>
            <div class="w-100 d-flex justify-content-between my-4">
              <div class="vehicle-fillter">
                <ul class="nav">
                  <li class="nav-item">
                    <div class="range-calendar position-relative">
                      <div class="p-field p-col-12 ">
                        <p-calendar [(ngModel)]="schdlDate" dateFormat="dd-mm-yy" [showIcon]="true"
                          placeholder="Select Date" (ngModelChange)="parseDate($event)" [showIcon]="true"
                          inputId="icon"></p-calendar>
                      </div>
                      <div class="modal_calendar range-calendar border rounded position-absolute bg-white"
                        style="z-index:111;font-weight:normal;" *ngIf="calnedarDisp">
                        <div class="p-1 d-flex justify-content-between">
                          <div style="width:300px;" class="p-3">
                          </div>
                        </div>
                      </div>
                    </div>

                  </li>
                  <li class="nav-item">
                    <div class="fillet-boxs-css mb-3 position-relative mx-3">
                      <p-dropdown [options]="searchBy" [(ngModel)]="selectedSearchBy"
                        [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Filter"
                        (onChange)="selectByFilter($event)" optionLabel="search">
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="selectedSearchBy">
                            <div>{{selectedSearchBy.search}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li>
                  <li class="nav-item" *ngIf="isVehicleFilterShowlsk">
                    <div class="fillet-boxs-css" *ngIf="!showVehicleLoader; else loading">
                      <p-dropdown [options]="dataVehicles" [(ngModel)]="selectedfillerVehicleNo"
                        optionLabel="vehicleRegNo" [filter]="true" filterBy="vehicleRegNo" [showClear]="true"
                        placeholder="Select a Vehicle" (onChange)="onChangeVehicleFiltersLsk($event)">
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="selectedfillerVehicleNo">
                            <div>{{selectedfillerVehicleNo.vehicleRegNo}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li>

                  <li class="nav-item" *ngIf="isRouteFilterShowlsk" >

                    <div class="fillet-boxs-css" *ngIf="!showRouteLoader; else loading">
                      <p-dropdown [options]="routeSchedules" [(ngModel)]="selectedRouteSchedule" [filter]="true"
                        [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Route"
                        (onChange)="onChangeRoutesFiltersLsk($event)" optionLabel="main_route">
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="selectedRouteSchedule">
                            <div>{{selectedRouteSchedule.main_route}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li>
                  <li class="nav-item" *ngIf="(currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">

                    <div class="fillet-boxs-css" >
                      <p-dropdown [options]="archiveList" [(ngModel)]="selectedarchive" [filter]="true"
                        [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Archive Data"
                        (onChange)="onChangeArchiveFilters($event)" optionLabel="year">
                        <ng-template pTemplate="selectedItem">
                          <div class="filler-h-item" *ngIf="selectedarchive">
                            <div>{{selectedarchive.year}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </li>
                  <li class="d-flex align-items-center nav-item w-auto">
                    <button class=" clear-search  all-bg text-white p-2 border-0 rounded"
                      [disabled]="selectedDate == 'undefined'" (click)="getAllSchedules()"
                      style="width:100px !important;">Search</button>
                  </li>

                  <li class="d-flex align-items-center nav-item w-auto">
                    <button class=" mx-2 border-0 p-0 bg-transparent clear-search txt-default-color"
                      (click)="clearSchdlAllFilters()">Clear Search</button>
                  </li>

                </ul>
              </div>
              <div class="vehicle-fillter">
                <ul class="nav">
                  <li class="d-flex align-items-center nav-item w-auto pr-0 dropdown down-drop-btn-bk">
                    <button
                      class="border px-3 py-2 filter-btn-btm bg-transparent d-flex align-items-center h-100  d-flex dropdown-toggle"
                      type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <div>Export</div>
                      <div class="pl-3">
                        <img src="./assets/images/web-112021/icons/export.png" />
                      </div>
                    </button>
                    <ul class="dropdown-menu border-0 p-0 mt-0 w-100 bg-white" aria-labelledby="navbarDropdown">
                      <li>
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportToExcel()">Download all</a>
                      </li>
                      <!-- <li>
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportToExcel()">Download selected schedules</a>
                      </li> -->
                      <!-- <li *ngIf="this.Lsk">
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportToExcel()">Download all LSK</a>
                      </li> -->
                      <!-- <li>
                        <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportToExcel()">Download selected LSK</a>
                      </li> -->
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-2">
              <div class="w-100 text-center" style="margin:0 auto;">
                <div class="loader" *ngIf="showLoader"></div>
              </div>
            </div>
          </div>
          <div #TABLE>


            <p-table id="lskTable" [value]="schedules" *ngIf="!showLoader" [rows]="10" [paginator]="true"
              [globalFilterFields]="['schedule_no','route','scheme','vehicle_no']"
              [(selection)]="selectedvihelcelTableDetails" [rowHover]="true" dataKey="schedule_no"
              styleClass="p-datatable-striped p-datatable-sm" [scrollable]="true" scrollHeight="250px"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [showCurrentPageReport]="true">
              <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                  <div class="flex align-items-center justify-content-between summary-message">
                    {{schedules ? schedules.length : 0 }} result(s) found.
                  </div>
                </div>
              </ng-template>

              <ng-template pTemplate="header">
                <tr>
                  <th style="width:56px;max-width:56px;" class="bg-white text-center fillet-boxs-css">
                    <p-tableHeaderCheckbox class="mx-auto"></p-tableHeaderCheckbox>
                  </th>
                  <!--th style="width: 50px;" pSortableColumn="id" >Id <Small><p-sortIcon field="id"></p-sortIcon></Small></th-->
                  <th pSortableColumn="schedule_no">Sch. No <p-sortIcon field="schedule_no"></p-sortIcon></th>
                  <th pSortableColumn="scheduledate">Sch. Date <p-sortIcon field="scheduledate"></p-sortIcon></th>
                  <th pSortableColumn="vehicle_no">Vehicle <p-sortIcon field="vehicle_no"></p-sortIcon></th>
                  <!--th pSortableColumn="vehicle_type">Vehicle Type <p-sortIcon field="vehicle_type"></p-sortIcon></th-->
                  <th pSortableColumn="company_name">Company <p-sortIcon field="company_name"></p-sortIcon></th>
                  <th pSortableColumn="scheme">Scheme <p-sortIcon field="scheme"></p-sortIcon></th>

                  <th pSortableColumn="route">Route <p-sortIcon field="route"></p-sortIcon></th>
                  <!--th pSortableColumn="road_id" >road_id <p-sortIcon field="road_id"></p-sortIcon></th-->
                  <th pSortableColumn="road_name">Road <p-sortIcon field="road_name"></p-sortIcon></th>
                  <th pSortableColumn="park_name">Park <p-sortIcon field="park_name"></p-sortIcon></th>
                  <th pSortableColumn="location">Location <p-sortIcon field="location"></p-sortIcon></th>
                  <th pSortableColumn="frequency">Frequency <p-sortIcon field="frequency"></p-sortIcon></th>
                  <th pSortableColumn="activity_code">Activity <p-sortIcon field="activity_code"></p-sortIcon></th>
                  <th pSortableColumn="percentage">percentage <p-sortIcon field="percentage"></p-sortIcon></th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-schedule>
                <tr class="p-selectable-row td-font-size">
                  <td style="width:56px;max-width:56px;" class="fillet-boxs-css">
                    <p-tableCheckbox [value]="schedule" class="mx-auto"></p-tableCheckbox>
                  </td>

                  <td>{{schedule.schedule_no}}</td>
                  <td>{{schedule.scheduledate | date: 'dd/MM/yyyy'}}</td>
                  <td>{{schedule.vehicle_no}}</td>
                  <!--td>{{schedule.vehicle_type}}</td-->
                  <td>{{schedule.company_name}}</td>
                  <td>{{schedule.scheme}}</td>
                  <td>{{schedule.route}}</td>
                  <!--td>{{schedule.road_id}}</td-->
                  <td class="td-font-size">{{schedule.road_name}}</td>
                  <td class="td-font-size">{{schedule.park_name}}</td>
                  <td class="td-font-size">{{schedule.location}}</td>
                  <td class="td-font-size">{{schedule.frequency}}</td>
                  <td class="td-font-size">{{schedule.activity_code}}</td>
                  <td class="td-font-size">
                    <div><small>{{schedule.collected}}/{{schedule.sch_qty_bin}}</small></div> <br> &nbsp;

                    <div class="w-100 bin-progressbar position-relative" style="display: inline-block;">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [ngStyle]="{width: schedule.percentage + '%'}">
                          <div class="progress-parentage position-absolute lh-14 fs-12">{{schedule.percentage |
                            number}}%</div>
                        </div>
                      </div>
                    </div>


                  </td>


                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="18" class="empty-message">Please select Date and Vehicle.</td>
                </tr>
              </ng-template>
            </p-table>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>