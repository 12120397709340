import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import { MenuItem } from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigGroups } from 'src/app/app.models';
import { ModuleBHK } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalComponent } from 'src/app/global-component';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { ConfigActivityTypeComponent } from '../settings/config-activity-type/config-activity-type.component';
import { EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';
import { Calendar } from 'primeng/calendar';


@Component({
    selector: 'app-module-bhk',
    templateUrl: './module-bhk.component.html',
    styleUrls: ['./module-bhk.component.scss']
})



export class ModuleBhkComponent implements OnInit {

    @ViewChild('fileUpload') fileUpload!: FileUpload
    @ViewChild('calendar1') calendar1!: Calendar;
    @ViewChild('calendar2') calendar2!: Calendar;
    dataDialog: boolean = false;
    dataEditDialog: boolean = false;
    datas: ModuleBHK[] = [];
    data: ModuleBHK = { Activity: {}, Scheme: { ModuleConfigServiceArea: {} }, Route: { ModuleConfigServiceArea: {} }, SubRoute: { ModuleConfigSubRoute: {} }, Park: { ModuleCfgScheme: {} }, Vehicle: { ConfigCompany: {}, ConfigVehicleType: {} }, Bhktype: {} };
    isLoading: boolean = false;
    selectedDatas: any[] = [];
    moduleBHKParam = { id: null, isactiveonly: true };
    isCreateNew = true;
    submitted: boolean = false;
    items: MenuItem[] = [];
    itemcustom: MenuItem[] = [];
    datefilter: any[] = [];
    filterscheme: any[] = [];
    filterroute: any[] = [];
    filtersubroute: any[] = [];
    filterroad: any[] = [];
    filterpark: any[] = [];
    selectedOption: any;
    vehicleno: any[] = [];
    showDatepicker: boolean = false;
    showMonthYear: boolean = false;
    schdlDate = new Date();
    datePipe = new DatePipe('en-US');
    currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    isValidCurrentDate: boolean = false;
    servicearea_dropdown: any[] = [];
    //scheme_dropdown: any[] = [];
    route_dropdown: any[] = [];
    subroute_dropdown: any[] = [];
    road_dropdown: any[] = [];
    park_dropdown: any[] = [];
    vehicle_dropdown: any[] = [];
    activity_dropdown: any[] = [];
    bhktype_dropdown: any[] = [];
    configServiceAreaParam = { id: null, isactiveonly: true };
    configModuleBHKParam = { datefrom: null, dateto: null, servicereaid: null };
    min_date = new Date();
    range_date = new Date();
    range_date2 = new Date();
    startdate: any;
    range_time = new Date();
    starttime: any;
    max_date = new Date();
    configModuleBHKtest: any;
    area_filter = "";
    scheme_filter = "";
    showBHKLoader: boolean = false;
    schedulelist: any[] = [];
    loading = false;
    selectedDate: Date = new Date();
    dropdownDisabled: boolean = false;
    disableschemedropdown: boolean = true;
    schemeBG = 'inactive';
    disableroutedropdown: boolean = true;
    routeBG = 'inactive';
    disablesubroutedropdown: boolean = true;
    subrouteBG = 'inactive';
    disableparkdropdown: boolean = true;
    roadBG = 'inactive';
    disableroaddropdown: boolean = true;
    parkBG = 'inactive';
    bhktypeBG = 'inactive';
    disablebhktypedropdown: boolean = true;
    vehicleBG = 'inactive';
    disablevehicledropdown: boolean = true;
    selectedSchemeValue: any;
    scheme_dropdown: any[] = [];
    selectedName: string = '';
    distinctSchemeNames: any[] = [];
    schemeid: any;
    distinctRouteNames: any[] = [];
    routeid: any;
    distinctSubRouteNames: any[] = [];
    subrouteid: any;
    distinctVehicleNames: any[] = [];
    vehicleid: any;
    distinctParkNames: any[] = [];
    parkid: any;
    distinctRoadNames: any[] = [];
    roadid: any;
    selectedActivity: any;
    selectedScheme: any;
    selectedRoute: any;
    selectedSubRoute: any;
    selectedRoad: any;
    selectedPark: any;
    selectedBHKType: any;
    selectObstacle: any;
    selectObstacleID: any;
    selectedVehicle: any;
    scheduleno: any[] = [];
    currentUser: any = JSON.parse(localStorage.getItem('currentData') || '{}');
    selectedItem: any[] = [];
    SchemeIdValue: any[] = [];
    RouteIdValue: any[] = [];
    SubRouteIdValue: any[] = [];
    RoadIdValue: any[] = [];
    ParkIdValue: any[] = [];
    Id: any[] = [];
    dataEdit: any;
    BHKScheduleList: any[] = [];
    showLoader: boolean = false;
    isBtnApproveRejectDisabled = true;
    searchfilter: any;
    storage: any;
    uploadedFiles: any[] = [];
    tempUploadFile: any[] = [];
    upload_file: any[] = [];
    temp_bhkid: any;
    fileNames: string[] = [];
    images: string[] = [];
    selectedrangedate: any;
    selectActivity: any;
    today = new Date();
    calendarOptions = {
        minDate: this.today,
        maxDate: this.today,
    };
    cbInternal: boolean = false;
    isBtnNewDisabled = false;
    listrole: string[] = [];
    imagePath = '../../assets/images/logo.png';
    checkboxKB: boolean = false;
    // checkboxKB: boolean = true;
    checkboxKI: boolean = false;
    isInternalChecked: boolean = false;
    formattedStartDate: string = '';

    submitBHK = this.formBuilder.group({

        bhk_id: this.data.bhk_id,
        schedule_no: this.data.schedule_no,
        start_date: this.data.start_date,
        end_date: this.data.end_date,
        start_time: this.data.start_time,
        end_time: this.data.end_time,
        activity_type_id: this.data.Activity?.Id,
        scheme_id: null,
        route_id: this.data.Route?.Id,
        park_id: this.data.Park?.Id,
        //road_id: this.data.Road?.Id,
        bhk_type_id: this.data.bhk_id,
        verified: this.data.verified_by,
        status: this.data.status,
        created: this.data.created,
        created_by: this.data.created_by,
        modified: this.data.modified,
        modified_by: this.data.modified_by,
        DetailObstacle: new FormControl(''),
        file: new FormControl(null, Validators.required),
        internaldata: this.cbInternal
        //date5: ''
        //created: null,
    });

    DetailBHK = this.formBuilder.group({

        bhk_id: this.data.bhk_id,
        schedule_no: this.data.schedule_no,
        start_date: this.data.start_date,
        end_date: this.data.end_date,
        start_time: this.data.start_time,
        end_time: this.data.end_time,
        activity_type_id: this.data.Activity?.Id,
        activity_type_name: this.data.Activity?.Name,
        scheme_id: this.data.Scheme?.Id,
        route_id: this.data.Route?.Id,
        park_id: this.data.Park?.Id,
        //road_id: this.data.Road?.Id,
        bhk_type_id: this.data.Bhktype?.Id,
        bhk_type_name: this.data.Bhktype?.Name,
        verified: this.data.verified_by,
        status: this.data.status,
        created: this.data.created,
        created_by: this.data.created_by,
        modified: this.data.modified,
        modified_by: this.data.modified_by,
        date5: ''

        //created: null,
    });

    selectedFile!: string | Blob;

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder, public datepipe: DatePipe, private http: HttpClient) { }

    ngOnInit(): void {

        //this.getDatas(currentDate);

        // this.datefilter = [
        //     { name: 'Daily', value: "0" },
        //     { name: 'Monthly', value: "1" }
        // ]npm install util
        this.listrole = this.currentUser.Roles.Name;
        const matchingRole = this.currentUser.Roles.find((obj: { Name: string; }) => {
            return obj.Name === "OE";
        });

        if (matchingRole) {
            this.isBtnNewDisabled = true;
        }


        this.itemcustom = [
            {
                label: 'Details', icon: 'pi pi-pencil', command: () => {
                    //console.log(this.data.Bhktype?.Name);
                    // console.log(this.data.Activity?.Name);
                    this.dataEdit = this.data;
                    this.editData(this.data);
                }
            }
        ]
        //console.log(this.currentUser);

        this.items = [
            {
                label: 'Details', icon: 'pi pi-pencil', command: () => {
                    //console.log(this.data.Bhktype?.Name);
                    // console.log(this.data.Activity?.Name);
                    this.dataEdit = this.data;
                    this.editData(this.data);
                }
            },
            {
                label: 'Delete', icon: 'pi pi-trash', command: () => {
                    this.deleteData(this.data)
                },
            }
        ];
        // this.items = [
        //     {
        //         label: 'Details', icon: 'pi pi-pencil', command: () => {
        //             //console.log(this.data.Bhktype?.Name);
        //             // console.log(this.data.Activity?.Name);
        //             this.dataEdit = this.data;
        //             this.editData(this.data);
        //         }
        //     },
        //     {
        //         label: 'Delete', icon: 'pi pi-trash', command: () => {
        //            if(this.data.status ==='Approve' || this.data.status ==='Reject')
        //            {}
        //            else{this.deleteData(this.data);}

        //         },
        //         visible: this.data.status === 'Approve' || this.data.status === 'Reject'
        //     }
        // ];
        //console.log(this.items);

        //this.configServiceAreaParam.id = this.currentUser.Id;
        //console.log(this.configServiceAreaParam);


        const matchingAdminRole = this.currentUser.Roles.find((obj: { Name: string; }) => {
            return obj.Name === "Administrator";
        });

        if (matchingAdminRole) {
            this.commonService.getAllConfigServiceArea(this.configServiceAreaParam).subscribe((resp: any) => {
                this.isLoading = true;
                if (resp.length != 0) {
                    //console.log(resp);
                    this.servicearea_dropdown = resp.Result;
                    this.isLoading = false;
                } else {

                }
            }), (error: any) => {
                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }


        }
        else {
            //this.configServiceAreaParam.id = this.currentUser.Id;
            const configUserId= { id: this.currentUser.Id, isactiveonly: true };

            this.commonService.getUserConfigServiceArea(configUserId).subscribe((resp: any) => {
                this.isLoading = true;
                if (resp.length != 0) {
                    //console.log(resp);
                    this.servicearea_dropdown = resp.Result;
                    this.isLoading = false;
                } else {

                }
            }), (error: any) => {
                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }







        // this.commonService.getAllConfigScheme(this.configServiceAreaParam).subscribe((resp: any) => {
        //     this.isLoading = true;
        //     if (resp.length != 0) {
        //         //console.log(resp);
        //         this.scheme_dropdown = resp.Result;
        //         this.isLoading = false;
        //     } else {

        //     }
        // }), (error: any) => {
        //     this._appSharedService.showError('Error', "Something went wrong!");
        //     this.isLoading = false;
        // }


    }

    menu() {
        this.items = [
            {
                label: 'Details', icon: 'pi pi-pencil', command: () => {
                    //console.log(this.data.Bhktype?.Name);
                    // console.log(this.data.Activity?.Name);
                    this.dataEdit = this.data;
                    this.editData(this.data);
                }
            },
            {
                label: 'Delete', icon: 'pi pi-trash', command: () => {
                    if (this.data.status === 'Approve' || this.data.status === 'Reject') { }
                    else { this.deleteData(this.data); }

                },
                visible: this.data.status === 'Pending' || this.data.status === 'Reject'
            }
            //{label: 'Assign Vehicle', icon: 'pi pi-pencil', command: () => {
            //this.assignVehicle(this.data);
            //}}
        ];
    }

    getDatas(range_date_filter: any) {

        this.selectedrangedate = range_date_filter;


        this.isLoading = true;

        this.commonService.getModuleBHK(range_date_filter).subscribe((resp: any) => {

            if (resp.length != 0) {
                console.log(resp);
                this.datas = resp.Result;
                // if(resp.status==='Approved')
                // {
                //  this.   
                // }
                this.isLoading = false;

            } else {

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;

        }

    }

    ReloadData(range_date_filter: any) {

        this.selectedrangedate = range_date_filter;


        this.isLoading = true;

        this.commonService.getModuleBHK(range_date_filter).subscribe((resp: any) => {

            if (resp.length != 0) {
                console.log(resp);
                this.datas = resp.Result;
                //this.isLoading = false;

            } else {

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;

        }

    }

    getSubRoute(routeid: any) {

        var data = {
            id: routeid
        }

        this.commonService.getSubRouteByRoute(data).subscribe((resp: any) => {

            this.isLoading = true;
            if (resp.length != 0) {
                this.subroute_dropdown = resp.Result;
                this.isLoading = false;

            } else {

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;

        }

    }

    getPark(schemeid: any) {

        var data = {
            id: schemeid
        }

        this.commonService.getParkByScheme(data).subscribe((resp: any) => {

            this.isLoading = true;
            if (resp.length != 0) {
                this.park_dropdown = resp.Result;
                this.isLoading = false;

            } else {

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;

        }

    }

    parseDate(e: any) {


        // console.log(e.value);
        if (e[1] != null) {
            this.datefilter = e;
        }

        if (e.value != null) {
            this.area_filter = e.value.Id;
        }

        if (this.area_filter.length > 0 && this.datefilter.length > 0) {
            var range_date_filter = {
                start_date: formatDate(new Date(this.datefilter[0]), 'yyyy-MM-dd', 'en-US'),
                end_date: formatDate(new Date(this.datefilter[1]), 'yyyy-MM-dd', 'en-US'),
                servicearea_id: this.area_filter
            }
            this.searchfilter = range_date_filter;
            this.getDatas(range_date_filter);
        }
        else if (this.area_filter.length > 0) {
            var range_date_filter = {
                start_date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
                end_date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
                servicearea_id: this.area_filter
            }
            this.searchfilter = range_date_filter;
            this.getDatas(range_date_filter);
        }
        else if (this.datefilter.length > 0) {
            var range_date_filter = {
                start_date: formatDate(new Date(this.datefilter[0]), 'yyyy-MM-dd', 'en-US'),
                end_date: formatDate(new Date(this.datefilter[1]), 'yyyy-MM-dd', 'en-US'),
                servicearea_id: ""
            }
            this.searchfilter = range_date_filter;
            this.getDatas(range_date_filter);
        }


        //this.getDatas(range_date_filter);
    }


    onChangeActivity(event: any) {
        //console.log(event);
        this.selectedActivity = event.value;
        //console.log(this.selectedActivity);

    }


    parseDateOnly(e: any) {



        this.clearForm();


        this.startdate = {
            Date: formatDate(e, 'yyyy-MM-dd', 'en-US')
        }

        this.scheme_dropdown == null;
        this.schedulelist = [];
        this.isLoading = true;

        this.commonService.getBHKScheduleDetailByDate(this.startdate).subscribe((resp: any) => {

            if (resp.length != 0) {

                this.schedulelist = resp.Result;

                this.schemeBG = 'active';
                this.disableschemedropdown = false;
                this.bhktypeBG = 'active';
                this.disablebhktypedropdown = false;

                // console.log(this.schedulelist);

                // this.schedulelist.forEach((item, key) => {
                //     if (item && item.ConfigScheme.name != null && item.ConfigScheme.id == this.selectedScheme) {
                //         //console.log("Item found at location : "+key, item)
                //         this.filterscheme[key] = item;
                //     }
                // });

                // this.distinctSchemeNames = this.schedulelist.filter((value, index, self) => {
                //     return self.findIndex(item => item.ConfigScheme.id === value.ConfigScheme.id && item.ConfigScheme.name === value.ConfigScheme.name) === index;
                // }).map(item => ({ id: item.ConfigScheme.id, name: item.ConfigScheme.name, routeid: item.ConfigRoute.Id, routename: item.ConfigRoute.Name }));

                // this.distinctSchemeNames = this.schedulelist.map(item => ({ id: item.ConfigScheme.id, name: item.ConfigScheme.name }))
                //     .filter((value, index, self) => self.indexOf(value) === index).sort();

                const distinctSchemeNames = this.schedulelist.map(item => item.ConfigScheme.name)
                    .filter((value, index, self) => self.indexOf(value) === index).sort();

                // console.log('Distinct: ' + distinctSchemeNames);

                this.scheme_dropdown = distinctSchemeNames;

                this.getBHKType();
                this.isLoading = false;

            } else {

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");

        }

    }

    parseTimeOnly(e: any) {

        //this.clearForm();


        this.starttime = {
            Date: formatDate(e, 'hh:mm a', 'en-US')
        }

        // let hour = new Date(e).getHours();
        // let min = new Date(e).getMinutes();
        // if(min < 10) { 
        //    this.starttime= `${hour}:0${min}`;
        // } else {
        //    this.starttime= `${hour}:${min}`;
        // } 
    }

    schemeChangeEvent(event: any) {
        this.selectedScheme = event.value;
        // console.log(event);

        this.selectedRoute = [];
        this.selectedSubRoute = [];
        this.selectedRoad = [];
        this.selectedPark = [];
        this.selectedBHKType = [];
        this.selectedVehicle = [];

        this.distinctRouteNames == null;
        this.filterscheme = [];
        this.filterroute = [];
        this.filtersubroute = [];
        this.filterroad = [];
        this.route_dropdown = [];
        this.subroute_dropdown = [];
        this.road_dropdown = [];
        this.park_dropdown = [];


        this.routeBG = 'inactive';
        this.disableroutedropdown = true;
        this.subrouteBG = 'inactive';
        this.disablesubroutedropdown = true;
        this.roadBG = 'inactive';
        this.disableroaddropdown = true;
        this.parkBG = 'inactive';
        this.disableparkdropdown = true;


        this.schedulelist.forEach((item, key) => {
            if (item && item.ConfigScheme.name != null && item.ConfigScheme.name == this.selectedScheme) {
                //console.log("Item found at location : "+key, item)
                this.filterscheme[key] = item;
            }
        });

        this.routeBG = 'active';
        this.disableroutedropdown = false;

        const distinctRouteNames = this.filterscheme.map(item => item.ConfigRoute.Name)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        this.route_dropdown = distinctRouteNames;

        console.log(this.filterscheme);

        // this.distinctRouteNames = this.distinctSchemeNames.filter((value, index, self) => {
        //     return self.findIndex(item => item.routeid === value.routeid && item.routename === value.routename) === index;
        // }).map(item => ({ routeid: item.routeid, routename: item.routename }));

        // console.log(this.distinctRouteNames);

        // this.route_dropdown = this.distinctRouteNames;


    }

    routeChangeEvent(event: any) {

        this.selectedRoute = event.value;

        this.selectedSubRoute = [];
        this.selectedRoad = [];
        this.selectedPark = [];
        this.selectedBHKType = [];
        this.selectedVehicle = [];

        this.subrouteBG = 'inactive';
        this.disablesubroutedropdown = true;
        this.roadBG = 'inactive';
        this.disableroaddropdown = true;
        this.parkBG = 'inactive';
        this.disableparkdropdown = true;

        this.distinctSubRouteNames == null;
        this.filterroute = [];
        this.filtersubroute = [];
        this.subroute_dropdown = [];
        this.road_dropdown = [];
        this.park_dropdown = [];


        this.filterscheme.forEach((item, key) => {
            if (item && item.ConfigRoute.Name != null && item.ConfigRoute.Name == this.selectedRoute) {
                //console.log("Item found at location : "+key, item)
                this.filterroute[key] = item;

            }
        });



        this.subrouteBG = 'active';
        this.disablesubroutedropdown = false;

        const distinctSubRouteNames = this.filterroute.map(item => item.ConfigSubroute.Name)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        console.log(this.filterroute);

        // const distinctSubRouteNames = this.filterroute.filter((value, index, self) => {
        //     return self.findIndex(item => item.ConfigSubroute.Id === value.ConfigSubroute.Id && item.ConfigSubroute.Name === value.ConfigSubroute.Name) === index;
        // }).map(item => ({ id: item.ConfigSubroute.Id, name: item.ConfigSubroute.Name }));

        this.subroute_dropdown = distinctSubRouteNames;


        // console.log(this.filterroute);
    }

    subRouteChangeEvent(event: any) {

        this.selectedSubRoute = event.value;

        this.selectedRoad = [];
        this.selectedPark = [];
        this.selectedBHKType = [];
        this.selectedVehicle = [];

        this.roadBG = 'inactive';
        this.disableroaddropdown = true;
        this.parkBG = 'inactive';
        this.disableparkdropdown = true;

        this.distinctRoadNames == null;
        this.distinctParkNames == null;
        this.road_dropdown == null;
        this.park_dropdown = [];
        this.filtersubroute = [];
        this.filterroad = [];


        this.filterroute.forEach((item, key) => {
            if (item && item.ConfigSubroute.Name != null && item.ConfigSubroute.Name == this.selectedSubRoute) {
                //console.log("Item found at location : "+key, item)
                this.filtersubroute[key] = item;
            }
        });


        //update by wan
        // this.roadBG = 'active';
        // this.disableroaddropdown = false;
        this.parkBG = 'active';
        this.disableparkdropdown = false;

        //update by wan
        // const distinctRoadNames = this.filtersubroute.map(item => item.ConfigRoad.Name)
        //     .filter((value, index, self) => self.indexOf(value) === index).sort();
        const distinctParkNames = this.filtersubroute.map(item => item.ConfigPark.Name)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        // const distinctRoadNames = this.filtersubroute.filter((value, index, self) => {
        //     return self.findIndex(item => item.ConfigRoad.Id === value.ConfigRoad.Id && item.ConfigRoad.Name === value.ConfigRoad.Name) === index;
        // }).map(item => ({ id: item.ConfigRoad.Id, name: item.ConfigRoad.Name }));

        //update by wan
        // this.road_dropdown = distinctRoadNames;
        this.park_dropdown = distinctParkNames;
    }

    parkChangeEvent(event: any) {

        this.selectedPark = event.value;
        console.log(this.selectedPark);

        this.selectedRoad = [];
        this.selectedBHKType = [];
        this.selectedVehicle = [];

        this.roadBG = 'inactive';
        this.disableroaddropdown = true;

        this.distinctRoadNames == null;
        this.road_dropdown = [];
        this.filterpark = [];


        this.filtersubroute.forEach((item, key) => {
            if (item && item.ConfigPark.Name != null && item.ConfigPark.Name == this.selectedPark) {
                //console.log("Item found at location : "+key, item)
                this.filterpark[key] = item;
            }
        });

        console.log(this.filterpark);

        this.roadBG = 'active';
        this.disableroaddropdown = false;

        const distinctRoadNames = this.filterpark.map(item => item.ConfigRoad.Name)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        // const distinctParkNames = this.filterroad.filter((value, index, self) => {
        //     return self.findIndex(item => item.ConfigPark.Id === value.ConfigPark.Id && item.ConfigPark.Name === value.ConfigPark.Name) === index;
        // }).map(item => ({ id: item.ConfigPark.Id, name: item.ConfigPark.Name }));


        this.road_dropdown = distinctRoadNames;
    }

    roadChangeEvent(event: any) {

        this.selectedRoad = event.value;

        console.log(event);

        this.selectedBHKType = [];
        this.selectedVehicle = [];

        this.filterroad = [];


        this.filterpark.forEach((item, key) => {
            if (item && item.ConfigRoad.Name != null && item.ConfigRoad.Name == this.selectedRoad) {
                //console.log("Item found at location : "+key, item)
                this.filterroad[key] = item;
            }
        });

        this.filterroad = this.filterpark.filter(item => this.selectedRoad.includes(item.ConfigRoad.Name))


        console.log(this.filterroad);
    }

    bhkTypeChangeEvent(event: any) {

        this.selectObstacle = event.originalEvent.srcElement.innerText;

        this.selectObstacleID = event.value;

        this.selectedVehicle = [];

        if (this.selectObstacle == 'Kenderaan Gantian') {

            this.vehicleBG = 'inactive';
            this.disablevehicledropdown = true;

            this.distinctVehicleNames == null;
            this.vehicle_dropdown = [];

            this.vehicleBG = 'active';
            this.disablevehicledropdown = false;

            const distinctVehicleNames = this.schedulelist.map(item => item.ModuleVehicle.VehicleRegNo)
                .filter((value, index, self) => self.indexOf(value) === index).sort();

            this.vehicle_dropdown = distinctVehicleNames;

        }
        else {
            this.vehicleBG = 'inactive';
            this.disablevehicledropdown = true;
        }

    }
    vehicleChangeEvent(event: any) {

        this.selectedVehicle = event.value;
    }

    getActivityType() {

        this.commonService.getAllConfigActivityType(this.configServiceAreaParam).subscribe((resp: any) => {

            this.isLoading = true;
            if (resp.length != 0) {
                this.activity_dropdown = resp.Result;
                this.isLoading = false;

            } else {

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;

        }
    }

    getBHKType() {

        this.commonService.getAllBHKType(this.configServiceAreaParam).subscribe((resp: any) => {

            this.isLoading = true;
            if (resp.length != 0) {
                this.bhktype_dropdown = resp.Result;
                this.isLoading = false;

            } else {

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;

        }
    }

    submitNewBHK(data: any) {
        this.isLoading = true;
        // console.log(data);
        var defaultdata = {
            start_date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
            end_date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
            servicearea_id: null
        }

        this.commonService.createBHK(data).subscribe((resp: any) => {
            this.isLoading = true;
            if (resp.Success == true) {
                //console.log(resp);
                //this.servicearea_dropdown = resp.Result;
                this.messageService.add({ key: 'alertMsg', severity: 'success', summary: 'Successful', detail: 'Data Created', life: 3000 });
                this.ReloadData(defaultdata);
                this.isLoading = false;
            } else {
                this.isLoading = false;
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
        this.hideDialog();
    }



    openNew() {
        this.showLoader = false;
        this.data = { Activity: {}, Scheme: { ModuleConfigServiceArea: {} }, Route: { ModuleConfigServiceArea: {} }, Park: { ModuleCfgScheme: {} } }
        this.isCreateNew = true;
        this.submitted = false;
        this.dataDialog = true;

        this.getActivityType();

    }

    selectData(data: any) {
        this.data = data;
        // this.isInternalChecked != this.data.internaldata;
        // console.log(this.dataEdit.internaldata);
        // console.log(this.isInternalChecked);

    }

    editData(data: any) {

        this.data = { ...data };
        this.dataEditDialog = true;
        this.isCreateNew = false;
        this.Id = data.Id;
        this.showBHKLoader = true;
        this.getBHKScheduleList(this.data.schedule_no);
        this.getBHKImage(this.dataEdit.bhk_id);
    }

    deleteData(data: any) {
        this.isLoading = true;
        data.ModifiedBy = localStorage.getItem('username');

        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.bhk_id + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.isLoading = true;
                this.commonService.deleteBHK(data).subscribe((resp: any) => {
                    this.isLoading = true;
                    if (resp.Success == true) {
                        this.messageService.add({ key: 'alertMsg', severity: 'success', summary: 'Successful', detail: 'Data Deleted', life: 3000 });

                        this.dataDialog = false;
                        this.ReloadData(this.selectedrangedate);
                        this.isLoading = false;

                    } else {

                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.isLoading = false;
                    }
                }), (error: any) => {

                    this._appSharedService.showError('Error', "Something went wrong!");
                    this.isLoading = false;
                }

            }

        });
        //this.isLoading = false;
    }

    hideDialog() {
        this.clearActivityDropdown();
        this.clearForm();
        this.dataDialog = false;
        this.submitted = false;
    }

    hideEditDialog() {
        this.dataEditDialog = false;
        this.submitted = false;
    }

    onSubmit(data: any): void {



        if (!this.selectedActivity) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Activity is required!', life: 3000 });
        }
        else if (!this.startdate) {
            this.calendar1.inputfieldViewChild.nativeElement.focus();
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'StartDate is required!', life: 3000 });
        }
        else if (!this.starttime) {
            this.calendar2.inputfieldViewChild.nativeElement.focus();
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'StartTime is required!', life: 3000 });
        }
        else if (this.disableschemedropdown == true) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Scheme is required!', life: 3000 });
        }
        else if (this.selectedScheme.length == 0) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Scheme is required!', life: 3000 });
        }
        else if (this.disableroutedropdown == true) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Route is required!', life: 3000 });
        }
        else if (this.selectedRoute.length == 0) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Route is required!', life: 3000 });
        }
        else if (this.disablebhktypedropdown == true) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Obstacle Type is required!', life: 3000 });
        }
        else if (this.selectObstacle == undefined) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Obstacle Type is required!', life: 3000 });
        }
        else if (this.disablevehicledropdown == false && this.selectedVehicle.length == 0) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Vehicle No. is required!', life: 3000 });
        }
        else if (this.fileUpload._files.length == 0) {
            this.messageService.add({ key: 'alertError', severity: 'error', summary: 'Error', detail: 'Attachment is required!', life: 3000 });
        }
        else {
            //console.log('activity:' + this.selectedActivity);
            var dataBhkList;

            var currentdatetime = formatDate(new Date(), 'yyyyMMddHHmmss', 'en');
            var createddatetime = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss', 'en');
            // console.log(this.selectedActivity);

            let userid = this.currentUser.Id;
            userid = userid.replace(/[^\w\s]/gi, "")
            let username = this.currentUser.Username;

            let userrole = this.currentUser.Roles;
            //console.log(userrole);

            this.submitted = true;

            if (this.filterroute.length != 0 && this.filtersubroute.length == 0) {

                dataBhkList = this.filterroute;

                this.SchemeIdValue = dataBhkList.map(item => item.scheme_id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.RouteIdValue = dataBhkList.map(item => item.ConfigRoute.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                let listofscheduleno = dataBhkList.map(a => a.scheduleno);

                let filteredData = listofscheduleno.filter((item) => {
                    return Object.values(item).every((val) => val !== null && val !== '');
                });

                const stringscheduleno = filteredData.join(', ');

                // console.log('routeData');
                 console.log(dataBhkList);
                // console.log(this.SchemeIdValue);
                // console.log(stringscheduleno);
                // console.log(this.startdate);

                var bhkroutedata = {

                    bhk_id: 'BHK/Collection/' + this.selectedRoute + '/' + currentdatetime + '/' + userid,
                    schedule_no: stringscheduleno,
                    start_date: this.startdate.Date,
                    end_date: this.startdate.Date,
                    start_time: this.starttime.Date,
                    activity_type_id: this.selectedActivity,
                    scheme_id: this.SchemeIdValue[0],
                    scheme_name: this.selectedScheme,
                    route_id: this.RouteIdValue[0],
                    route_name: this.selectedRoute,
                    obstacle_id: this.selectObstacleID,
                    obstacle_name: this.selectObstacle,
                    obstacle_detail: this.submitBHK.get('DetailObstacle')?.value,
                    vehicle_name: this.selectedVehicle.toString(),
                    verified_by: '',
                    status: 'Pending',
                    created: createddatetime.toString(),
                    created_by: username,
                    active: true,
                    internaldata: this.cbInternal
                }
                //console.log('----');
                console.log(bhkroutedata);
                //console.log('----');

                this.temp_bhkid = bhkroutedata.bhk_id;
                this.submitNewBHK(bhkroutedata);
                this.triggerUpload();
            }
            else if (this.filtersubroute.length != 0 && this.filterpark.length == 0) {

                dataBhkList = this.filtersubroute;

                this.SchemeIdValue = dataBhkList.map(item => item.scheme_id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.RouteIdValue = dataBhkList.map(item => item.ConfigRoute.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.SubRouteIdValue = dataBhkList.map(item => item.ConfigSubroute.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                let listofscheduleno = dataBhkList.map(a => a.scheduleno);

                let filteredData = listofscheduleno.filter((item) => {
                    return Object.values(item).every((val) => val !== null && val !== '');
                });

                const stringscheduleno = filteredData.join(', ');

                // console.log('subrouteData');
                 console.log(dataBhkList);
                // console.log(stringscheduleno);

                var bhksubroutedata = {

                    bhk_id: 'BHK/Collection/' + this.selectedRoute + '/' + currentdatetime + '/' + userid,
                    schedule_no: stringscheduleno,
                    start_date: this.startdate.Date,
                    end_date: this.startdate.Date,
                    start_time: this.starttime.Date,
                    activity_type_id: this.selectedActivity,
                    scheme_id: this.SchemeIdValue[0],
                    scheme_name: this.selectedScheme,
                    route_id: this.RouteIdValue[0],
                    route_name: this.selectedRoute,
                    sub_route_id: this.SubRouteIdValue[0],
                    sub_route_name: this.selectedSubRoute,
                    obstacle_id: this.selectObstacleID,
                    obstacle_name: this.selectObstacle,
                    obstacle_detail: this.submitBHK.get('DetailObstacle')?.value,
                    vehicle_name: this.selectedVehicle.toString(),
                    verified_by: '',
                    status: 'Pending',
                    created: createddatetime.toString(),
                    created_by: username,
                    active: true,
                    internaldata: this.cbInternal
                }
                //console.log(bhksubroutedata);

                this.temp_bhkid = bhksubroutedata.bhk_id;
                this.submitNewBHK(bhksubroutedata);
                this.triggerUpload();
            }
            else if (this.filterpark.length != 0 && this.filterroad.length == 0) {

                dataBhkList = this.filterpark;

                this.SchemeIdValue = dataBhkList.map(item => item.scheme_id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.RouteIdValue = dataBhkList.map(item => item.ConfigRoute.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.SubRouteIdValue = dataBhkList.map(item => item.ConfigSubroute.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.ParkIdValue = dataBhkList.map(item => item.ConfigPark.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                let listofscheduleno = dataBhkList.map(a => a.scheduleno);

                let filteredData = listofscheduleno.filter((item) => {
                    return Object.values(item).every((val) => val !== null && val !== '');
                });

                const stringscheduleno = filteredData.join(', ');

                // console.log('roadData');
                // console.log(dataBhkList);
                // console.log(stringscheduleno);

                var bhkparkdata = {

                    bhk_id: 'BHK/Collection/' + this.selectedRoute + '/' + currentdatetime + '/' + userid,
                    schedule_no: stringscheduleno,
                    start_date: this.startdate.Date,
                    end_date: this.startdate.Date,
                    start_time: this.starttime.Date,
                    activity_type_id: this.selectedActivity,
                    scheme_id: this.SchemeIdValue[0],
                    scheme_name: this.selectedScheme,
                    route_id: this.RouteIdValue[0],
                    route_name: this.selectedRoute,
                    sub_route_id: this.SubRouteIdValue[0],
                    sub_route_name: this.selectedSubRoute,
                    park_id: this.ParkIdValue[0],
                    park_name: this.selectedPark,
                    obstacle_id: this.selectObstacleID,
                    obstacle_name: this.selectObstacle,
                    obstacle_detail: this.submitBHK.get('DetailObstacle')?.value,
                    vehicle_name: this.selectedVehicle.toString(),
                    verified_by: '',
                    status: 'Pending',
                    created: createddatetime.toString(),
                    created_by: username,
                    active: true,
                    internaldata: this.cbInternal
                }
                //console.log(bhkparkdata);

                this.temp_bhkid = bhkparkdata.bhk_id;
                this.submitNewBHK(bhkparkdata);
                this.triggerUpload();
            }
            else if (this.filterroad.length != 0) {

                dataBhkList = this.filterroad;
                //console.log(dataBhkList);

                this.SchemeIdValue = dataBhkList.map(item => item.scheme_id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.RouteIdValue = dataBhkList.map(item => item.ConfigRoute.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.SubRouteIdValue = dataBhkList.map(item => item.ConfigSubroute.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.ParkIdValue = dataBhkList.map(item => item.ConfigPark.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                this.RoadIdValue = dataBhkList.map(item => item.ConfigRoad.Id)
                    .filter((value, index, self) => self.indexOf(value) === index)

                let listofscheduleno = dataBhkList.map(a => a.scheduleno);

                let filteredData = listofscheduleno.filter((item) => {
                    return Object.values(item).every((val) => val !== null && val !== '');
                });

                const stringscheduleno = filteredData.join(', ');

                let listofroad = dataBhkList.map(a => a.ConfigRoad.Name)
                    .filter((value, index, self) => self.indexOf(value) === index).sort();

                let filteredRoadData = listofroad.filter((item) => {
                    return Object.values(item).every((val) => val !== null && val !== '');
                });

                const stringroadname = filteredRoadData.join(', ');

                // console.log('parkData');
                //console.log(dataBhkList);
                //console.log(stringscheduleno);
                //console.log(stringroadname);

                var bhkroaddata = {

                    bhk_id: 'BHK/Collection/' + this.selectedRoute + '/' + currentdatetime + '/' + userid,
                    schedule_no: stringscheduleno,
                    start_date: this.startdate.Date,
                    end_date: this.startdate.Date,
                    start_time: this.starttime.Date,
                    activity_type_id: this.selectedActivity,
                    scheme_id: this.SchemeIdValue[0],
                    scheme_name: this.selectedScheme,
                    route_id: this.RouteIdValue[0],
                    route_name: this.selectedRoute,
                    sub_route_id: this.SubRouteIdValue[0],
                    sub_route_name: this.selectedSubRoute,
                    //road_id: this.RoadIdValue,
                    road_name: stringroadname,//this.selectedRoad,
                    park_id: this.ParkIdValue[0],
                    park_name: this.selectedPark,
                    obstacle_id: this.selectObstacleID,
                    obstacle_name: this.selectObstacle,
                    obstacle_detail: this.submitBHK.get('DetailObstacle')?.value,
                    vehicle_name: this.selectedVehicle.toString(),
                    verified_by: '',
                    status: 'Pending',
                    created: createddatetime.toString(),
                    created_by: username,
                    active: true,
                    internaldata: this.cbInternal
                }
                console.log(bhkroaddata);

                this.temp_bhkid = bhkroaddata.bhk_id;
                this.submitNewBHK(bhkroaddata);
                this.triggerUpload();
            }
            else {
                this.triggerUpload();
                // dataBhkList = this.schedulelist;

                // let listofscheduleno = dataBhkList.map(a => a.scheduleno);
                // //const listofscheme = dataBhkList.find(({ ConfigScheme }) => ConfigScheme === this.selectedScheme);
                // const listofscheme = dataBhkList.map(item => item.scheme_id)
                //     .filter((value, index, self) => self.indexOf(value) === index).sort();

                // console.log('allData');
                // console.log(listofscheduleno);
                // console.log(listofscheme);

                // var bhkdata = {

                //     bhk_id: 'BHK/Collection/' + this.selectedRoute + '/' + currentdatetime + '/' + userid,
                //     schedule_no: listofscheduleno,
                //     start_date: this.startdate,
                //     end_date: this.startdate,
                //     start_time: this.starttime,
                //     activity_type_id: this.selectedActivity,
                //     scheme_name: this.selectedScheme
                // }

                // console.log(bhkdata);

                // this.submitNewBHK(dataBhkList);
            }
            dataBhkList = [];
            this.submitted = false;
        }
    }

    disableDropdown() {
        this.dropdownDisabled = true;
    }


    enableDropdown() {
        this.dropdownDisabled = false;
    }

    clearActivityDropdown() {
        this.activity_dropdown = [];
        this.selectedActivity = null;
    }

    clearForm() {

        this.distinctVehicleNames == null;
        this.distinctSchemeNames == null;
        this.distinctRouteNames == null;
        this.distinctSubRouteNames == null;
        this.distinctRoadNames == null;
        this.distinctParkNames == null;

        this.schedulelist = [];
        this.filterscheme = [];
        this.filterroute = [];
        this.filtersubroute = [];
        this.filterroad = [];
        this.filterpark = [];

        this.scheme_dropdown = [];
        this.route_dropdown = [];
        this.subroute_dropdown = [];
        this.road_dropdown = [];
        this.park_dropdown = [];
        this.bhktype_dropdown = [];
        this.vehicle_dropdown = [];
        this.schemeBG = 'inactive';
        this.disableschemedropdown = true;
        this.routeBG = 'inactive';
        this.disableroutedropdown = true;
        this.subrouteBG = 'inactive';
        this.disablesubroutedropdown = true;
        this.roadBG = 'inactive';
        this.disableroaddropdown = true;
        this.parkBG = 'inactive';
        this.disableparkdropdown = true;
        this.bhktypeBG = 'inactive';
        this.disablebhktypedropdown = true;
        this.vehicleBG = 'inactive';
        this.disablevehicledropdown = true;

        this.selectedScheme = [];
        this.selectedRoute = [];
        this.selectedSubRoute = [];
        this.selectedRoad = [];
        this.selectedPark = [];
        this.selectedBHKType = [];
        this.selectedVehicle = [];
        this.uploadedFiles = [];

        this.startdate = null;
        this.starttime = null;

        this.selectObstacle = undefined;
        this.cbInternal = false;


    }

    getBHKScheduleList(data: any) {

        this.isBtnApproveRejectDisabled = true;
        this.BHKScheduleList = [];
        console.log(data);
        var scheduleNo = data;
        this.showBHKLoader = true;
        this.commonService.getBHKScheduleList(scheduleNo).subscribe((resp: any) => {
            // console.log('responing:' + resp);
            if (resp.Total != 0) {
                this.BHKScheduleList = resp.Result;

                this.listrole = this.currentUser.Roles.Name;
                const matchingRole = this.currentUser.Roles.find((obj: { Name: string; }) => {
                    return obj.Name === "Supervisor" || obj.Name === "Contractor";
                });

                const matchingRoleAdmin = this.currentUser.Roles.find((obj: { Name: string; }) => {
                    return obj.Name === "Administrator" || obj.Name === "OE" || obj.Name === "Monitoring";
                });

                if (matchingRoleAdmin) {
                    this.isBtnApproveRejectDisabled = false;
                }
                else if (matchingRole) { this.isBtnApproveRejectDisabled = true; }
                else { this.isBtnApproveRejectDisabled = true; }
                // console.log(this.BHKScheduleList);
            } else {
                this.BHKScheduleList = [];
            }
            this.showBHKLoader = false;
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.showBHKLoader = false;
        }
    }

    getBHKImage(data: any) {

        // this.isBtnApproveRejectDisabled = true;
        // console.log('bhkid:' + this.dataEdit.bhk_id);

        const modbhkid = this.dataEdit.bhk_id.replaceAll('/', '_');
        // console.log('modbhkid:' + modbhkid);
        // var bhkid = 'BHK_Collection_PHKT03-C24_20230414125112_5daa1233f2dc4da999d82c96d7cef4f5';

        // this.commonService.BHKgetImages(bhkid).subscribe((resp: any) => {
        //     console.log('responing:' + resp);
        //     if (resp.Total != 0) {

        //         // this.images = resp.Result;
        //         // this.isBtnApproveRejectDisabled = false;
        //         // console.log(this.images);
        //     } else {
        //         //this.images = [];
        //     }
        //     this.showLoader = false;
        // }), (error: any) => {
        //     this._appSharedService.showError('Error', "Something went wrong!");
        //     this.showLoader = false;
        // }

        const request = { prefixName: 'BHKCollection/' + modbhkid + '_' };
        this.http.post<string[]>('/api/CoreModules/ModuleBHK/GetImages', request).subscribe((urls: any) => {
            this.images = urls.Result;
        });
    }

    onFileSelect(event: any) {
        // console.log(this.selectedFile);
        this.selectedFile = event.target.files[0];
        console.log(this.selectedFile);
    }

    updateBHKStatus(data: any, updatedstatus: any) {
        this.isLoading = true;
        // console.log('2 : ' + this.currentUser);
        this.submitted = false;
        var updatedata = {
            "bhk_id": data,
            "status": updatedstatus,
            "user": this.currentUser.Username
        };
        console.log(updatedata);

        this.commonService.updateBHKStatus(updatedata).subscribe((resp: any) => {
            this.isLoading = true;
            if (resp.Success == true) {
                //console.log(resp);
                //this.servicearea_dropdown = resp.Result;
                this.messageService.add({ key: 'alertMsg', severity: 'success', summary: 'Successful', detail: 'Data Updated', life: 3000 });
                this.ReloadData(this.searchfilter);

                this.isLoading = false;
            } else {
                this.ReloadData(this.searchfilter);
                this.isLoading = false;
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            //this.showLoader = false;
            this.isLoading = false;
        }
        this.hideEditDialog();
        // console.log(this.searchfilter);

    }

    upload(event: any): void {
        // console.log(event);
        // console.log('bhk_id:' + this.temp_bhkid);
        // for (let file of event.files) {

        //      console.log("3 - " +file);
        //     this.uploadedFiles.push(file);

        // }
        this.submitBHK.patchValue({
            file: event.files[0],
        });
        var count = 0;
        const formData = new FormData();

        for (let file of event.files) {

            //this.upload_file.push({
            // objectURL: file.objectURL,
            // lastModified: file.lastModified,
            // lastModifiedDate: file.lastModifiedDate,
            //name: file.name.toString(),
            // size: file.size,
            // type: file.type,
            // webkitRelativePath: file.webkitRelativePath,
            //bhk_id: this.temp_bhkid.toString()
            //})

            formData.append('file', event.files[count], this.temp_bhkid);
            count++;
            // console.log(count);
            //this.uploadedFiles.push(file);
        }
        var testdata = "adam"

        this.selectedFile = event.files;



        // console.log(event.files[0]);
        // console.log(formData);
        // this.http.post('http://localhost:64106/api/CoreModules/ModuleBHK/UploadImage/upload', formData).subscribe(response => {
        //         // console.log(response);
        //     });
        this.commonService.uploadFile(formData).subscribe(response => {
                // console.log(response);
            });


    }

    triggerUpload() {
        // console.log("upload is trigger" + this.fileUpload);
        this.fileUpload.upload();
        // var defaultdata = {
        //     start_date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
        //     end_date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
        //     servicearea_id: null
        // }
        // console.log(defaultdata);
        // this.getDatas(defaultdata)

    }

    openPrintableView() {
        this.formattedStartDate = this.formatDate(this.dataEdit.start_date);

        var status = '';
        if (this.dataEdit.status == 'Approve') { status = 'Diterima' }
        else if (this.dataEdit.status == 'Reject') { status = 'Ditolak' }
        else { status = 'Dalam semakan' }
        console.log(this.dataEdit.verified_by);
        //this.checkboxKB = this.dataEdit.Activity?.Name === 'Kutipan Bulky'
        // console.log(this.checkboxKB);
        // this.checkboxKI = this.dataEdit.Activity?.Name === 'Kutipan Isi Rumah'

        const printWindow = window.open();
        if (printWindow) {

            printWindow.document.open();
            printWindow.document.write(`
      <html>
        <!--<head>
          <title style="align:right;">Makluman Halangan Kerja</title>
          
        </head>-->
        <body>
        <table class="table mt-3 outside-border" style="width:100%">
            <tbody>
                <tr>
                    <td style="width:20vw;font-size: 90%;"><img src="${this.imagePath}" alt="Description of the image"></td>
                    <td style="font-size:12; font-size: 90%; text-align:right;"><strong>No Rujukan : </strong>${this.dataEdit.bhk_id}</td>
                </tr>
            </tbody>
        </table>
        <!--<img src="${this.imagePath}" alt="Description of the image">-->
          <!--<p style="font-size:12; font-size: 90%;"><strong>No Rujukan : </strong>${this.dataEdit.bhk_id}</p>-->
          <p style="text-align:center;font-size: 90%;"><u><strong>MAKLUMAN HALANGAN KERJA</strong></u></p>
          <!--BAHAGIAN A>-->
          <table class="table mt-3 outside-border" style="width:100%">
          <thead class="thead-light" style="background-color: #88bef5;">
              <tr>
                  <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;text-align:center;" colspan="11"><strong>BAHAGIAN A: BUTIRAN PELAPOR</strong></td>
                  </td>
              </tr>
          </thead>
          <tbody>
            <tr>
                <td style="width:20vw;font-size: 90%;"><b>Nama</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.currentUser.Person.FullName}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Jawatan</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.getNamesAsString()}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Skim</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.dataEdit.Scheme?.Name}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Laluan</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.dataEdit.Route?.Name}</span></td>
            </tr>
          </tbody>
          </table>
          <!--BAHAGIAN B>-->
          <table class="table mt-3 outside-border" style="width:100%">
          <thead class="thead-light" style="background-color: #88bef5;">
              <tr>
                  <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;text-align:center;" colspan="11"><strong>BAHAGIAN B: BUTIRAN HALANGAN DI LAPANGAN</strong></td>
                  </td>
              </tr>
          </thead>
          <tbody>
          <tr>
                <td style="width:10vw;font-size: 90%;"><b>Aktiviti</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.dataEdit.Activity?.Name}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Tarikh Halangan Dikenalpasti</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.formattedStartDate}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Masa Halangan Dikenalpasti</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.dataEdit.start_time}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Lokasi Kejadian</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.getLocation()}</span></td>
            </tr>
            <tr>
                <td style="width:20vw;font-size: 90%;"><b>Deskripsi Halangan Di Lapangan</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.dataEdit.Bhktype?.Name}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Perincian Halangan Di Lokasi</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.dataEdit.bhk_detail}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Status Pengesahan</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${status}</span></td>
            </tr>
            <tr>
                <td style="width:10vw;font-size: 90%;"><b>Disahkan Oleh</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize">${this.dataEdit.verified_by}</span></td>
            </tr>
          </tbody>
          </table>
          <!--BAHAGIAN C>-->
          <table class="table mt-3 outside-border" style="width:100%">
          <thead class="thead-light" style="background-color: #88bef5;">
              <tr>
                  <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;text-align:center;" colspan="11"><strong>BAHAGIAN C: PENERIMAAN MAKLUMAN HALANGAN DI LAPANGAN</strong></td>
                  </td>
              </tr>
          </thead>
          <tbody>
          <tr>
                <td style="width:40vw;font-size: 90%;"><b>Penerimaan Makluman Halangan Di Lapangan <br>(Sila tanda pada petak berkenaan)</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize"><input type="checkbox" [checked]="false"/>Terima<input type="checkbox" [checked]="false"/>Tidak Terima</span></td>
            </tr>
            <tr>
                <td style="width:30vw;font-size: 90%;"><b>Nyatakan sebab jika tidak terima</b></td>
                <td style="width:2vw;font-size: 90%;">:</td>
                <td style="width:40vw;font-size: 90%;"><span class=" fs-12 text-capitalize"></span></td>
            </tr>
          </tbody>
          </table>
          <br></br>
          <!--SIGNATURE>-->
          <table class="table mt-3 outside-border" style="width:100%">
            <tbody>
                <tr>
                    <td style="width:40vw;font-size: 90%;">Diterima oleh:</td>
                    <td style="width:20vw;font-size: 90%;"></td>
                    <td style="width:40vw;font-size: 90%;">Diterima oleh:</td>
                </tr>
                <tr>
                    <td style="width:40vw;font-size: 90%;"><br></br>
                    <br></br>(______________________)</td>
                    <td style="width:20vw;font-size: 90%;"></td>
                    <td style="width:40vw;font-size: 90%;"><br></br>
                    <br></br>(______________________)</td>
                </tr>
                <tr>
                    <td style="width:40vw;font-size: 90%;">Nama :</td>
                    <td style="width:20vw;font-size: 90%;"></td>
                    <td style="width:40vw;font-size: 90%;">Nama :</td>
                </tr>
                <tr>
                    <td style="width:40vw;font-size: 90%;">Jawatan :</td>
                    <td style="width:20vw;font-size: 90%;"></td>
                    <td style="width:40vw;font-size: 90%;">Jawatan :</td>
                </tr>
                <tr>
                    <td style="width:40vw;font-size: 90%;">Tarikh :</td>
                    <td style="width:20vw;font-size: 90%;"></td>
                    <td style="width:40vw;font-size: 90%;">Tarikh :</td>
                </tr>
                <tr>
                    <td style="width:40vw;font-size: 90%;">Cop :</td>
                    <td style="width:20vw;font-size: 90%;"></td>
                    <td style="width:40vw;font-size: 90%;">Cop :</td>
                </tr>
            </tbody>
            <table class="table mt-3 outside-border" style="width:100%">
                <tr>
                    <td style="width:80vw;font-size: 80%;"><br><br><b>Nota:<b></td>
                </tr>
                <tr>
                    <td style="width:80vw;font-size: 80%;">Pihak Operasi perlu sertakan bukti halangan di lapangan (contoh:gambar)</td>
                </tr>
                <tr>
                    <td style="width:80vw;font-size: 80%;">Bahagian C diisi oleh SWCorp sahaja</td>
                </tr>
            </table>
          </table>
          <!--LAMPIRAN>-->
          <div class="page-break" style="page-break-before: always;"></div>
          <table class="table mt-3 outside-border" style="width:100%">
          <thead class="thead-light" style="background-color: #88bef5;">
              <tr>
                  <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;text-align:center;" colspan="11"><strong>LAMPIRAN</strong></td>
                  </td>
              </tr>
          </thead>
          </table>
          <br></br>
          <div style="text-align:center;">
          ${this.images.map(imageUrl => `<img src="${imageUrl}" alt="Image" width="545" height="441"/>`).join('')}
          </div>
          <!-- Display more form fields as needed -->
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() {
                window.close();
              };
            };
          </script>
        </body>
      </html>
    `);
            printWindow.document.close();
        } else {
            console.log('Failed to open the printable view in a new window.');
        }


    }

    getNamesAsString(): string {
        const names = this.currentUser.Roles.map((obj: { Name: any; }) => obj.Name);
        return names.join(', ');
    }

    getLocation(): string {
        // const route = this.dataEdit.Route?.Name;
        // const subroute = this.dataEdit.SubRoute?.Name;
        // const park = this.dataEdit.Park?.Name;
        //const road = this.dataEdit.Road?.Name;
        let result = '';
        //console.log(this.BHKScheduleList);


        const distinctSubRouteNames = this.BHKScheduleList.map(item => item.sub_route_name)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        let filteredSubRouteData = distinctSubRouteNames.filter((item) => {
            return Object.values(item).every((val) => val !== null && val !== '');
        });

        const stringsubroutename = filteredSubRouteData.join(', ');
        console.log(this.dataEdit.SubRoute?.Name.length);

        const distinctParkNames = this.BHKScheduleList.map(item => item.park_name)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        let filteredParkData = distinctParkNames.filter((item) => {
            return Object.values(item).every((val) => val !== null && val !== '');
        });

        const stringparkname = filteredParkData.join(', ');
        console.log(this.dataEdit.Park?.Name.length);

        const distinctRoadNames = this.BHKScheduleList.map(item => item.road_name)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        let filteredRoadData = distinctRoadNames.filter((item) => {
            return Object.values(item).every((val) => val !== null && val !== '');
        });

        const stringroadname = filteredRoadData.join(', ');
        console.log(this.dataEdit.Road?.Name.length);

        if (this.dataEdit.SubRoute?.Name.length > 0 && this.dataEdit.Park?.Name.length == 0 && (this.dataEdit.Road?.Name.length == 0 || this.dataEdit.Road?.Name.length == undefined)) {
            result = stringparkname;
            console.log('subroute data');
        }
        else if (this.dataEdit.Park?.Name.length > 0 && (this.dataEdit.Road?.Name.length == 0 || this.dataEdit.Road?.Name.length == undefined)) {
            result = stringroadname;
            console.log('park data');
        }
        else if (this.dataEdit.Road?.Name.length > 0) {
            result = stringroadname;
            console.log('road data');
        }
        console.log(result);
        return result;
    }
    formatDate(date: Date): string {
        const formattedDate = new Date(date);
        return formattedDate.toLocaleDateString('en-GB');
    }
}


