import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})

export class CommonService {

  constructor(private http: HttpClient) { }

  changePassword(chngPwd: any): Observable<any> {
    return this.http.post('/api/Authorizations/Authentications/ChangePassword', chngPwd);
  }

  getTraccarAccess(key: any): Observable<any> {
    return this.http.get('/api/Configurations/ConfigPreferenceSettings/GetByKey?request='+key);
  }

  menuItems(userId: any): Observable<any> {
    return this.http.get('/api/Configurations/ConfigPermissionMenu/GetUserMenu?UserId=' + userId);
  }

  contractorGroups(id: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserGroups/GetByUser', id);
  }

  getContractorVehicle(userId: any): Observable<any> {
    return this.http.get('/api/Configurations/ConfigGroupVehicles/GetUserVehicle?UserId=' + userId);
  }

  getUserVehicle(): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleVehicleBQ/GetVehicleByUser');
  }

  vehicleData(): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/devices?');
  }

  //Added 05/09/2022
  vehicleHistoryLog(deviceId: any, start: any, to: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/positions?deviceId=' + deviceId + '&from=' + start + '&to=' + to);
  }

  vehiclePosition(positionId: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/positions?id=' + positionId);
  }

  vehicleDetails(): Observable<any> {
    return this.http.get('http://34.143.214.182/cache/live/vehicles');
  }

  vehicleDeviceList(): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/devices');
  }

  vehicleScheduleDetails(id: any, date: any): Observable<any> {
    return this.http.get('http://34.143.214.182/vehicleHistory?id=' + id + '&selectedDay=' + date);
  }

  vehicleBinData(vehicleRegNo: any, date: any): Observable<any> {
    return this.http.get('http://34.87.47.36:6602/binsByVehicleRegNo?vehicleRegNo=' + vehicleRegNo + '&selectedDay=' + date);
  }

//Added 05/09/2022
  vehicleBinScheduleData(vehicleRegNo: any, date: any,archive_year:any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinDetailsByVehicleNo?date=' + date + '&vehicle_no=' + vehicleRegNo+ '&archive_year='+archive_year);
  }

  //Added 05/09/2022
  vehicleBinScheduleDataByRoute(route: any, date: any,archive_year:any): Observable<any> {
    
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinDetailsByRouteName?date=' + date + '&route=' + route+ '&archive_year='+archive_year);
  }

  //Added 05/09/2022
  vehicleBinScheduleDataDetails(lskid: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinDetails?lskid=' + lskid);
  }

  getRouteReports(data: any): Observable<any> {
    return this.http.post('http://34.87.47.36:6602/report/routes', data);
  }

  getScheduleReports(data: any): Observable<any> {
    return this.http.post('http://34.87.47.36:6602/report/lskschedule', data);
  }

  forgotPassword(data:any) {
    return this.http.post('/api/Authorizations/Authentications/ForgotPassword', data);
  }

  //collect bins from api get
  collectBin(): Observable<any> {
    return this.http.get('http://34.87.47.36:6602/bins/status');
  }

  //vehicle summary data get
  getVehicleSummary(vehicleRegNo: any): Observable<any> {
    return this.http.get('http://34.87.47.36:6602/vehicle/summary/' + vehicleRegNo);
  }

  //vehicle bin status data get
  getBinStatus(vehicleRegNo: any): Observable<any> {
    return this.http.get('http://34.87.47.36:6602/bins/status/' + vehicleRegNo);
  }
  //Added 05/09/2022
  //GetBinSummaryByDate from api get
  getBinTotalSummaryByDate(date: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinTotalSummaryByDate?date=' + date);
  }

  getBinTotalSummaryCompanyByDate(date: any,companyid: any): Observable<any> {

    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinTotalSummaryByCompany?date=' + date +'&companyid=' +companyid);
  }

  getBinTotalSummaryVehicleByDate(date: any,vehicle_no: any,archive_year:any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinTotalSummaryByVehicleNo?date=' + date +'&vehicle_no=' +vehicle_no+ '&archive_year='+archive_year);
  }

  getBinTotalSummaryRouteByDate(date: any,route: any,archive_year:any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinTotalSummaryByRoute?date=' + date +'&route=' +route+ '&archive_year='+archive_year);
  }

  getBinTotalSummarySubRouteByDate(date: any,subroute: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinTotalSummaryBySubRoute?date=' + date +'&sub_route=' +subroute);
  }

  getCompanyByDate(date: any): Observable<any> {
    return this.http.get('/api/Configurations/ModuleCompany/GetBySchedule?date=' + date);
  }

  getScheduleSummary(date: any,userid: any): Observable<any> {
    return this.http.get('/api/CoreModules/ModuleSchedule/GetSummaryByUserId?date=' + date + '&userid='+userid);
  }

  getVehidleRouteSummary(date: any,userid: any): Observable<any> {
    return this.http.get('/api/CoreModules/ModuleSchedule/GetSummaryVehicleRouteByUserId?date=' + date + '&userid='+userid);
  }

  getBinScheduleSummaryByRouteDate(date: any,route: any,archive_year:any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinScheduleSummaryByRoute?date=' + date +'&route=' +route+'&archive_year='+archive_year);
  }

  getBinScheduleSummaryByVehicleDate(date: any,vehicle: any,archive_year:any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBinScheduleLSKs/GetBinScheduleSummaryByVehicle?date=' + date +'&vehicle=' +vehicle+'&archive_year='+archive_year);
  }

  getAllUSers(): Observable<any> {
    return this.http.get('/api/Configurations/AspNetUsers/GetAllUser');
  }

  getAllUSersBasic(): Observable<any> {
    return this.http.get('/api/Configurations/AspNetUsers/GetAllUserBasic');
  }

  createUser(data: any): Observable<any> {
    return this.http.post('/api/Configurations/AspNetUsers/Create', data);
  }

  updateUser(data: any): Observable<any> {
    return this.http.post('/api/Configurations/AspNetUsers/Edit', data);
  }

  deleteUsers(data: any): Observable<any> {
    return this.http.post('/api/Configurations/AspNetUsers/Delete', data);
  }

  getAllConfigRoles(): Observable<any> {
    return this.http.get('/api/Configurations/AspNetRoles/Get');
  }

  createConfigRoles(data: any): Observable<any> {
    return this.http.post('/api/Configurations/AspNetRoles/Create', data);
  }

  updateConfigRoles(data: any): Observable<any> {
    return this.http.post('/api/Configurations/AspNetRoles/Update', data);
  }

  deleteConfigRoles(data: any): Observable<any> {
    return this.http.post('/api/Configurations/AspNetRoles/Delete', data);
  }

  getAllConfigScheme(data: any): Observable<any> {
    return this.http.get('/api/Configurations/ConfigScheme/GetAll?IsActiveOnly=true');
  }

  getAllConfigActivityType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigActivityType/GetAll',data);
  }

  getAllConfigCategoryType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigCategoryType/GetAll',data);
  }

  getAllConfigPark(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPark/GetAll',data);
  }

  getAllConfigPBT(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPBT/GetAll',data);
  }

  getAllConfigPremiseType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPremiseType/GetAll',data);
  }

  getAllConfigRoad(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigRoad/GetAll',data);
  }

  getAllConfigRoute(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigRoute/GetAll',data);
  }

  updateConfigRoute(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigRoute/Update',data);
  }

  getAllConfigServiceArea(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigServiceArea/GetAll',data);
  }

  getUserConfigServiceArea(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigServiceArea/GetByUser',data);
  }

  getAllConfigState(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigState/GetAll',data);
  }

  getAllConfigSubRoute(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigSubRoute/GetAll',data);
  }

  getAllConfigCity(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigCity/GetAll',data);
  }

  CreateConfigCity(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigCity/Create',data);
  }

  UpdateConfigCity(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigCity/Update',data);
  }

  deleteConfigCity(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigCity/Delete',data);
  }
  
  getAllConfigModuleVehicle(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleVehicle/GetAll',data);
  }

  getAllConfigCompany(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleCompany/GetAll',data);
  }

  getAllConfigVehicleType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigVehicleType/GetAll',data);
  }

  createConfigModuleVehicle(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleVehicle/Create',data);
  }

  updateConfigModuleVehicle(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleVehicle/Update',data);
  }

  deleteConfigModuleVehicle(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleVehicle/Delete',data);
  }

  getAllConfigGroups(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigGroups/GetAll',data);
  }

  createConfigGroups(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigGroups/Create',data);
  }

  updateConfigGroups(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigGroups/Update',data);
  }

  deleteConfigGroups(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigGroups/Delete',data);
  }

  createConfigVehicleType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigVehicleType/Create',data);
  }

  updateConfigVehicleType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigVehicleType/Update',data);
  }

  deleteConfigVehicleType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigVehicleType/Delete',data);
  }

  getAllConfigBinType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigBinType/GetAll',data);
  }

  getAllConfigMenuType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigMenuType/GetAll',data);
  }

  createConfigMenuType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigMenuType/Create',data);
  }

  updateConfigMenuType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigMenuType/Update',data);
  }

  deleteConfigMenuType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigMenuType/Delete',data);
  }

  getAllConfigModuleCompany(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleCompany/GetAll',data);
  }

  createConfigModuleCompany(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleCompany/Create',data);
  }

  updateConfigModuleCompany(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleCompany/Update',data);
  }

  deleteConfigModuleCompany(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleCompany/Delete',data);
  }

  getAllConfigStatus(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigStatus/GetAll',data);
  }

  createConfigStatus(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigStatus/Create',data);
  }

  updateConfigStatus(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigStatus/Update',data);
  }

  deleteConfigStatus(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigStatus/Delete',data);
  }

  getAllBackupVehicleByDate(date: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBackupVehicle/GetBackupVehicle?backup_date=' + date);
  }

  getUnassignVehicle(data: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBackupVehicle/GetUnassignVehicle?backup_date=' + data.backup_date +'&sub_route='+data.subRoute+'&schedule_vehicle_no='+data.vehicle_reg_no);
  }

  assignBackupVehicle(data: any): Observable<any> {
    return this.http.post('/api/CoreModulesBQ/ModuleBackupVehicle/CreateBackupVehicle', data);
  }
  
  getBackupVehicleList(data: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleBackupVehicle/GetBackupVehicleList?backup_date=' + data.backup_date +'&schedule_vehicle_no='+data.vehicle_reg_no+'&sub_route='+data.subRoute);
  }

  deleteBackupVehicle(data: any): Observable<any> {
    return this.http.post('/api/CoreModulesBQ/ModuleBackupVehicle/DeleteBackupVehicle', data);
  }
  userAssignCompany(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserCompany/Assign', data);
  }

  deleteAssignedCompany(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserCompany/Unassign', data);
  }

  getAssignedCompanyByUser(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserCompany/GetUserId', data);
  }

  userAssignScheme(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserScheme/Assign', data);
  }

  deleteAssignedScheme(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserScheme/Unassign', data);
  }

  getAssignedSchemeByUser(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserScheme/GetUserId', data);
  }

  AssigUserSchemeBQ(data: any): Observable<any> {
    return this.http.post('/api/ConfigurationsBQ/ConfigUserSchemeBQ/AssignUserScheme', data);
  }

  UnassigUserSchemeBQ(data: any): Observable<any> {
    return this.http.post('/api/ConfigurationsBQ/ConfigUserSchemeBQ/UnassignUserScheme', data);
  }

  GetUserSchemeByUserIdBQ(data: any): Observable<any> {
    return this.http.get('/api/ConfigurationsBQ/ConfigUserSchemeBQ/GetSchemeByUserId?user_id='+ data);
  }

  AssigUserCompanyBQ(data: any): Observable<any> {
    return this.http.post('/api/ConfigurationsBQ/ConfigUserCompanyBQ/AssignUserCompany', data);
  }

  UnassigUserCompanyBQ(data: any): Observable<any> {
    return this.http.post('/api/ConfigurationsBQ/ConfigUserCompanyBQ/UnassignUserCompany', data);
  }

  GetUserCompanyByUserIdBQ(data: any): Observable<any> {

    return this.http.get('/api/ConfigurationsBQ/ConfigUserCompanyBQ/GetCompanyByUserId?user_id=' + data);
  }

  AssignSchemeVehicleBQ(data: any): Observable<any> {
    return this.http.post('/api/ConfigUserCompanyBQ/ConfigVehicleSchemeBQ/AssignVehicleScheme', data);
  }

  UnassignSchemeVehicleBQ(data: any): Observable<any> {
    return this.http.post('/api/ConfigUserCompanyBQ/ConfigVehicleSchemeBQ/UnassignVehicleScheme', data);
  }

  GetVehicleOrSchemeBQ(data: any): Observable<any> {
    return this.http.post('/api/ConfigUserCompanyBQ/ConfigVehicleSchemeBQ/GetVehicleOrScheme', data);
  }

  getConfigPreferenceSettings(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPreferenceSettings/GetAll', data);
  }

  getConfigPreferenceSettingsByKey(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPreferenceSettings/GetByKey', data);
  }

  createConfigPreferenceSettings(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPreferenceSettings/Create', data);
  }

  updateConfigPreferenceSettings(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPreferenceSettings/Update', data);
  }

  deleteConfigPreferenceSettings(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPreferenceSettings/Delete', data);
  }

  getConfigPermission(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPermission/GetAll', data);
  }

  createConfigPermission(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPermission/Create', data);
  }

  updateConfigPermission(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPermission/Update', data);
  }

  deleteConfigPermission(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPermission/Delete', data);
  }

  assignPermissionRole(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigRolePermission/Assign', data);
  }

  getPermissionRoleByPermissionId(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigRolePermission/GetRolePermissionByPermissionId', data);
  }

  unassignPermissionRole(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigRolePermission/Unassign', data);
  }

  getConfigMenu(data: any): Observable<any> {
    return this.http.get('/api/Configurations/ConfigMenu/GetAll');
  }

  createConfigMenu(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigMenu/Create', data);
  }

  updateConfigMenu(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigMenu/Update', data);
  }

  deleteConfigMenu(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigMenu/Delete', data);
  }

  assignMenuPermission(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPermissionMenu/Assign', data);
  }

  getPermissionPermissionByMenuId(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPermissionMenu/GetMenu', data);
  }

  unassignMenuPermission(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigPermissionMenu/Unassign', data);
  }
  
  AssignUserGroups(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserGroups/Assign', data);
  }

  GetUserGroupsByUserId(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserGroups/GetByUser', data);
  }
  
  UnassignUserGroup(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigUserGroups/Unassign', data);
  }

  GetGroupVehicleByGroupID(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigGroupVehicles/GetByGroup', data);
  }

  AssignGroupVehicle(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigGroupVehicles/Assign', data);
  }

  UnassignVehicle(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigGroupVehicles/Unassign', data);
  }

  getAllVehicleShiftByDate(date: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleVehicleShift/GetVehicleShift?schedule_date=' + date);
  }

  assignVehicleShift(data: any): Observable<any> {
    return this.http.post('/api/CoreModulesBQ/ModuleVehicleShift/AssignVehicleShift', data);
  }

  getModuleConfigActionLog(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleActionLog/GetAll', data);
  }

  GetAssignedBackupVehicleShift(data: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleVehicleShift/GetBackupVehicleShiftList?shift_date=' + data.shift_date + '&schedule_vehicle_no=' + data.schedule_vehicle_no + '&sub_route=' + data.sub_route);
  }
  getModuleBHK(data: any): Observable<any> {
    return this.http.post('/api/CoreModules/ModuleBHK/GetByFilter', data);
  }

  getRouteByScheme(data: any): Observable<any> {
    //return this.http.post('/api​/Configurations​/ConfigRoute​/GetByScheme', data);
    return this.http.post('/api/Configurations/ConfigRoute/GetByScheme', data);
  }

  getSubRouteByRoute(data: any): Observable<any> {
    //return this.http.post('/api​/Configurations​/ConfigRoute​/GetByScheme', data);
    return this.http.post('/api/Configurations/ConfigSubRoute/GetByRoute', data);
  }

  getParkByScheme(data: any): Observable<any> {
    //return this.http.post('/api​/Configurations​/ConfigRoute​/GetByScheme', data);
    return this.http.post('/api/Configurations/ConfigPark/GetByScheme', data);
  }

  getBHKScheduleDetailByDate(data: any): Observable<any> {
    //return this.http.post('/api​/Configurations​/ConfigRoute​/GetByScheme', data);
    return this.http.post('/api/CoreModules/ModuleSchedule/GetAllBHKByDate', data);
  }

  getAllBHKType(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigBHKType/GetAll',data);
  }

  createBHK(data: any): Observable<any> {
    return this.http.post('/api/CoreModules/ModuleBHK/Create', data);
  }

  getBHKScheduleList(data: any): Observable<any> {
    return this.http.get('/api/CoreModules/ModuleBHK/GetBHKScheduleList?schedule_no=' + data);
  }

  updateBHKStatus(data: any): Observable<any> {
    return this.http.post('/api/CoreModules/ModuleBHK/UpdateBHKStatus', data);
  }

  uploadFile(formData: FormData): Observable<any> {
    return this.http.post('/api/CoreModules/ModuleBHK/UploadImage/upload', formData);
  }

   BHKgetImages(imageNames: string): Observable<any> {
    return this.http.post('/api/CoreModules/ModuleBHK/GetImages', imageNames);
  }

  deleteBHK(data: any): Observable<any> {
    return this.http.post('/api/CoreModules/ModuleBHK/Delete',data);
  }
  
  getAllBHKConfigActivityType(data: any): Observable<any> {
    return this.http.post('/api/CoreModules/ModuleBHK/GetAllBHKActivityType',data);
  }


  unAssignVehicleShift(data: any): Observable<any> {
    return this.http.post('/api/CoreModulesBQ/ModuleVehicleShift/UnassignVehicleShift', data);
  }

  getBinPendingTask(data: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleVehiclePendingTask/GetBinPendingTask?date=' + data.date + '&vehicle_reg_no=' + data.vehicle_reg_no + '&filter_type=' + data.filter_type + '&sub_route=' + data.sub_route) ;
  }

  getSchedulePendingTask(data: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleVehiclePendingTask/GetSchedulePendingTask?date=' + data.date + '&vehicle_reg_no=' + data.vehicle_reg_no + '&filter_type=' + data.filter_type + '&sub_route=' + data.sub_route);
  }

  getVehicleTotalTask(data: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleVehiclePendingTask/GetVehicleTotalTask?date=' + data.date + '&vehicle_reg_no=' + data.vehicle_reg_no + '&filter_type=' + data.filter_type + '&sub_route=' + data.sub_route);
  }
  testReport(data: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/reports/stops?_dc=1684459015068&deviceId=1037&type=allEvents&from=2023-05-14T16%3A00%3A00.000Z&to=2023-05-21T16%3A00%3A00.000Z&daily=false&page=1&start=0&limit=25');
  }

  getVehicleByDateAndSubRoute(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ModuleVehicle/GetVehicleByDate', data);
  }

  getRouteBySchemeAndDate(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigRoute/GetBySchemeAndDate', data);
  }

  getSubRouteByRouteAndDate(data: any): Observable<any> {
    return this.http.post('/api/Configurations/ConfigSubRoute/getByRouteAndDate', data);
  }
  
  
  // getReportStop(data: any): Observable<any> {
  //   return this.http.get('device.telematic.mflora.com.my/api/reports/stops?_dc=1684459015068&deviceId=2051&type=allEvents&from=2023-05-14T16%3A00%3A00.000Z&to=2023-05-15T16%3A00%3A00.000Z&daily=false&page=1&start=0&limit=25');
  // }

  // getReportStop(data: any): Observable<any> {
  //   return this.http.get('device.telematic.mflora.com.my/api/reports/stops?_dc=1684459015068&deviceId=2051&type=allEvents&from=' + data.start_date + '&to=' + data.end_date + '&daily=false&page=1&start=0&limit=25');
  // }

  getReportStop(data: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/reports/stops?_dc=1684459015068&deviceId='+ data.deviceId + '&from=' + data.start_date + '&to=' + data.end_date + '&daily=false&page=1&start=0&limit=25');
  }

  getReportRoute(data: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/reports/route?_dc=1685414006724&deviceId='+ data.deviceId + '&from=' + data.start_date + '&to=' + data.end_date + '&daily=false&page=1&start=0&limit=25');
  }

  getReportTrips(data: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/reports/trips?_dc=1685414100835&deviceId='+ data.deviceId + '&from=' + data.start_date + '&to=' + data.end_date + '&daily=false&page=1&start=0&limit=25');
  }

  getReportSummary(data: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/reports/summary?_dc=1685414134557&deviceId='+ data.deviceId + '&from=' + data.start_date + '&to=' + data.end_date + '&daily=false&page=1&start=0&limit=25');
  }

  getReportDailysummary(data: any): Observable<any> {
    return this.http.get('device.telematic.mflora.com.my/api/reports/summary?_dc=1685414181171&deviceId='+ data.deviceId + '&from=' + data.start_date + '&to=' + data.end_date + '&daily=true&page=1&start=0&limit=25');
  }

  // getVehicleByUser(): Observable<any> {
  //   return this.http.get('/api/CoreModulesBQ/ModuleVehicleBQ/GetVehicleByUser');
  // }
  
  getVehicleByUser(current_date: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ModuleVehicleBQ/GetVehicleByUser2?current_date=' + current_date);
  }

  // geofence(data: any): Observable<any> {
  //   return this.http.post('http://device.telematic.mflora.com.my/api/geofences', data);
  // }

  AssignUserServiceAreaBQ(data: any): Observable<any> {
    return this.http.post('/api/CoreModulesBQ/ConfigUserServiceAreaBQ/AssignUserServiceArea', data);
  }

  UnassigUserAreaBQ(data: any): Observable<any> {
    return this.http.post('/api/CoreModulesBQ/ConfigUserServiceAreaBQ/UnassignUserServiceArea', data);
  }

  GetUserAreaByUserIdBQ(data: any): Observable<any> {
    return this.http.get('/api/CoreModulesBQ/ConfigUserServiceAreaBQ/GetServiceAreaByUserId?user_id='+ data);
  }
  
  

}
