import { Injectable, Inject, Optional, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})

export class AppSharedService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }

  //send data from map to vehiclestatus
  private _senddataFromMap = new Subject<any>();
  sendDataFromMap$ = this._senddataFromMap.asObservable();
  sendDataFromMap(value: any) {
    this._senddataFromMap.next(value);
  }

  // Search text box on change in header section
  private _allVehicleDetails = new Subject<any[]>();
  allVehicleDetails$ = this._allVehicleDetails.asObservable();
  allVehicleDetails(value: any[]) {
    this._allVehicleDetails.next(value);
  }

  public change: EventEmitter<any> = new EventEmitter();
  public setdata(value: any) {
    this.change.emit(value);
  }

  // Layout box width changes section
  private _toggleSidebarView = new Subject<boolean>();
  navSidebarView$ = this._toggleSidebarView.asObservable();
  toggleSidebarView(value: boolean) {
    this._toggleSidebarView.next(value);
  }

  // nav page close
  private _pageLeftClose = new Subject<number>();
  pageSidebarClose$ = this._pageLeftClose.asObservable();
  closeSidebarView(value: number) {
    this._pageLeftClose.next(value);
  }

  // Bottom box width changes section
  private _toggleSideMenu = new Subject<number>();
  navSidebarMenuView$ = this._toggleSideMenu.asObservable();
  toggleSideMenu(value: number) {
    this._toggleSideMenu.next(value);
  }

  // Layout box width changes section
  private _toggleBottomView = new Subject<boolean>();
  toggleBottomView$ = this._toggleBottomView.asObservable();
  toggleBottomViewBtn(value: boolean) {
    this._toggleBottomView.next(value);
  }

  private _moveMapLocation = new Subject<any>();
  moveMapLocation$ = this._moveMapLocation.asObservable();
  moveMapLocation(value: any) {
    this._moveMapLocation.next(value);
  }

  // send vehicleDetails data from vehicle Status to map comp
  private _apiVehicleDetails = new Subject<any>();
  apiVehicleDetails$ = this._apiVehicleDetails.asObservable();
  apiVehicleDetails(value: any) {
    this._apiVehicleDetails.next(value);
  }

  // Layout box width changes section
  private _toggleVehiclesView = new Subject<boolean>();
  toggleVehiclesView$ = this._toggleVehiclesView.asObservable();
  toggleVehiclesView(value: boolean) {
    this._toggleVehiclesView.next(value);
  }

  // update bin collection status
  private _updateBinValue = new Subject<any>();
  updateBinValue$ = this._updateBinValue.asObservable();
  updateBinData(value: any) {
    this._updateBinValue.next(value);
  }

  // send scheduleId from schedule to bin-details
  private _vehicleCollectionId = new Subject<any>();
  vehicleCollectionId$ = this._vehicleCollectionId.asObservable();
  vehicleDetailsByCollectionId(value: any) {
    this._vehicleCollectionId.next(value);
  }

  private _showAllVehicles = new Subject<any>();
  showAllVehicles$ = this._showAllVehicles.asObservable();
  showAllVehicles(value: any) {
    this._showAllVehicles.next(value);
  }

  // show bins and schedules on map
  private _showBinsSchedules = new Subject<any>();
  showBinsSchedules$ = this._showBinsSchedules.asObservable();
  showBinsSchedules(value: any) {
    
    this._showBinsSchedules.next(value);
  }

  private _resetBinsSchedules = new Subject<any>();
  resetBinsSchedules$ = this._resetBinsSchedules.asObservable();
  resetBinsSchedules(value: any) {
    this._resetBinsSchedules.next(value);
  }

  //Added 05/09/2022
   // show vehicle route on map
   private _showVehicleHistoryLog = new Subject<any>();
   showVehicleHistoryLog$ = this._showVehicleHistoryLog.asObservable();
   showVehicleHistoryLog(value: any) {
     this._showVehicleHistoryLog.next(value); 
   }

  private _updateVehicle = new Subject<any>();
  updateVehicle$ = this._updateVehicle.asObservable();
  updateVehicle(value: any) {
    this._updateVehicle.next(value);
  }

  private _updateZoomDefault = new Subject<any>();
  updateZoomDefault$ = this._updateZoomDefault.asObservable();
  updateZoomDefault(value: any) {
    this._updateZoomDefault.next(value);
  }

  // user Logout
  private _logout = new Subject<boolean>();
  logout$ = this._logout.asObservable();
  logout(value: boolean) {
    this._logout.next(value);
  }

  //show Success Toast
  showSuccess(summary: any, detail: any) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  //show error Toast
  showError(summary2: any, detail2: any) {
    this.messageService.add({
      severity: 'error',
      summary: summary2,
      detail: detail2,
    });
  }

  showWarn(summary3: any, detail3: any) {
    this.messageService.add({
      severity: 'warn',
      summary: summary3,
      detail: detail3
    });
  }

   //show error Toast
   showStickyError(summary2: any, detail2: any) {
    this.messageService.add({
     severity: 'error',
      summary: summary2,
      detail: detail2,
      //sticky: true,
      life: 10000,
      key: 'myKey1'
    });
    //this.messageService.add({key: 'myKey1', severity:'error', summary: summary2, detail: detail2});
  }

  //showConfirm(msg: any) {
  //  this.messageService.add({
  //    severity: 'warn',
  //    summary: msg,
  //  });
  //  this.getConfirm(msg);
  //}

  liveTrackDetails(): Observable<any> {
    return this.http.get('./assets/files/data/dataAll.json');
  }

  // Search text box on change in header section
  private _allVehicleLogs = new Subject<any>();
  allVehicleLogs$ = this._allVehicleLogs.asObservable();
  allVehicleLogs(value: any) {
    this._allVehicleLogs.next(value);
    
  }

  // update bin summary collection status
  private _updateBinCollectionSummary = new Subject<any>();
  updateBinCollectionSummary$ = this._updateBinCollectionSummary.asObservable();
  updateBinCollectionSummary(value: any) {
    this._updateBinCollectionSummary.next(value);
  }


}
