import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import {MenuItem} from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/app.models';
import * as XLSX from 'xlsx';
import{ GlobalComponent } from 'src/app/global-component';
import { ConfigUserCompany,ConfigUserScheme,ConfigUserArea } from 'src/app/app.models';
import {PasswordModule} from 'primeng/password';
//import * as FileSaver from 'file-saver';
// import { IDropdownSettings, } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {


    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;

    pwdEye: boolean = false;
    dataDialog: boolean = false;
    dataEditDialog: boolean = false;
    datas: User[]= [];
    //data: User= { ModulePerson: {}};
    data: User = { ModulePerson: {}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    submittedEdit: boolean = false;
    statuses: any[] = [];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    role: any[] = [];
    company: any[] = [];
    groups:any[] = [];
    scheme: any[] = [];
    serviceAreaList: any[] = [];
    // dropdownSettings:IDropdownSettings={};
    defaultParameter =  { id: null, isactiveonly: true };
    modalAssignCompany: boolean = false;
    modalAssignGroup: boolean = false;
    modalAssignScheme: boolean = false;
    modalAssignArea: boolean = false;
    selectedDropdown: any;
    showLoader: boolean = false;
    // dataAssignCompany: any[] = [];
    assignedCompanyList: ConfigUserCompany[] = [];
    assignedGroupList: any[] = [];
    assignedSchemeList: ConfigUserScheme[] = [];
    assignedUserAreaList: ConfigUserArea[] = [];
    isLoading: boolean = true;

    userNameFormControl = new FormControl('', [
        Validators.required,
    ]);
    fullNameFormControl = new FormControl('', [
        Validators.required,
    ]);
    roleIdFormControl = new FormControl([], [
        Validators.required,
    ]);
    CompanyIdFormControl = new FormControl([], [
        Validators.required,
    ]);
    IdentityNoFormControl = new FormControl('', [
        Validators.required,
    ]);
    NRICNoNewFormControl = new FormControl('', [
        Validators.required,
    ]);
    phoneNumberFormControl = new FormControl('', [
        Validators.required,
    ]);
    emailFormControl = new FormControl('', [
        Validators.required,
    ]);
    emailConfirmFormControl = new FormControl('', [
        Validators.required,
    ]);
    passwordFormControl = new FormControl('', [
        Validators.required,
    ]);
    passwordConfirmFormControl = new FormControl('', [
        Validators.required,
    ]);
    UserRoleIdFormControl = new FormControl('', [
        Validators.required,
    ]);

    userForm = this.formBuilder.group({
        UserName: this.userNameFormControl,
        FullName: this.fullNameFormControl,
        RoleId: this.roleIdFormControl,
        IdentityNo: '',
        PhoneNumber: this.phoneNumberFormControl,
        Email: this.emailFormControl,
        EmailConfirm: this.emailConfirmFormControl,
        Password: this.passwordFormControl,
        PasswordConfirm: this.passwordConfirmFormControl,
        createdBy: localStorage.getItem('username'),
        // created: null,
    });

    userEditForm = this.formBuilder.group({
        //UserName: this.userNameFormControl,
        FullName: this.fullNameFormControl,
        UserRoleId: this.UserRoleIdFormControl,
        NRICNoNew: this.NRICNoNewFormControl,
        NRICNoOld:'',
        PhoneNumber: '',
        PassportNo: '',
        OtherIDNo:'',
        DateOfBirth:null,
        // Email: this.emailFormControl,
        // EmailConfirm: this.emailConfirmFormControl,
        createdBy: localStorage.getItem('username'),
        Active:true,
        
        // created: null,
    });

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.getDatas();
        this.getAllConfigCompany();
        this.getAllConfigScheme();
        this.getAllConfigServiceArea();
        this.getAllConfigRole();
        this.getAllConfigGroups();

        

        this.items = [
            {label: 'Assign Company', icon: 'pi pi-pencil', command: () => {
                this.assignCompany(this.data);
            }},
            {label: 'Assign Group', icon: 'pi pi-pencil', command: () => {
                this.assignGroup(this.data);
            }},
            {label: 'Assign Scheme', icon: 'pi pi-pencil', command: () => {
                this.assignScheme(this.data);
            }},
            {label: 'Assign Service Area', icon: 'pi pi-pencil', command: () => {
                this.assignServiceArea(this.data);
            }},
            {label: 'Edit', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Delete', icon: 'pi pi-trash', command: () => {
                this.deleteData(this.data);
            }}
        ];

        this.menuitems = GlobalComponent.submenus;

        // this.dropdownSettings = {
        //     idField: 'Id',
        //     textField: 'Name',
        // };
    }

    getDatas(){
        this.isLoading = true;
        this.commonService.getAllUSers().subscribe((resp: any) => {
        if (resp.length != 0) {
            console.log(resp.Result);
            this.datas = resp.Result;
            this.isLoading = false;
        } else {
        
        }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    getAllConfigCompany(){
        this.commonService.getAllConfigCompany(this.defaultParameter).subscribe((resp: any) => {
            if (resp.length != 0) {
                console.log(resp)
                this.company = resp.Result;
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getAllConfigScheme(){
        this.commonService.getAllConfigScheme(this.defaultParameter).subscribe((resp: any) => {
            if (resp.length != 0) {
          
                this.scheme = resp.Result;
                console.log(this.scheme);
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getAllConfigServiceArea(){
        this.commonService.getAllConfigServiceArea(this.defaultParameter).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.serviceAreaList = resp.Result;
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getAllConfigRole(){
        this.commonService.getAllConfigRoles().subscribe((resp: any) => {
            if (resp.length != 0) {
                this.role = resp.Result;
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getAllConfigGroups(){
        this.commonService.getAllConfigGroups(this.defaultParameter).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.groups = resp.Result;
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    onSubmit(): void {
        
        this.submitted = true;

        if (this.userForm.valid) {
            this.createUser();
            
        }
        this.showLoader=false;
    }
    onSubmitEdit(): void {
        this.submittedEdit = true;
        if (this.userEditForm.valid) {
                this.updateUser();            
        }
    }

    selectData(data: any){
        this.data = data;
    }
    clearselectData(){
        this.selectedDatas = [];
    }

    openNew() {

        this.data = { ModulePerson: {}};

        this.submitted = false;
        this.dataDialog = true;
        this.isCreateNew = true;
    }

    editData(data: any) {
        this.data = {...data};
        this.submittedEdit = false;
        this.dataEditDialog = true;
        this.isCreateNew = false;
        this.Id = data.Id;
    }

    hideDialog() {
        this.modalAssignGroup = false;
        this.modalAssignCompany = false;
        this.modalAssignScheme = false;
        this.modalAssignArea = false;
        this.dataDialog=false;
        this.dataEditDialog=false;
        this.submittedEdit = false;
        this.submitted = false;
        this.isCreateNew = false;
    }

    hideEditDialog() {
        // this.modalAssignCompany = false;
        // this.modalAssignScheme = false;
        // this.dataDialog=false;
        this.dataEditDialog=false;
        this.submittedEdit = false;
        //this.submitted = false;
    }

    createUser(){
        this.isLoading = true;
        this.commonService.createUser(this.userForm.value).subscribe((resp: any) => {
            if (resp.Success == true) {
                this.messageService.add({severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {

                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
        
    }

    updateUser(){
        this.isLoading = true;
        this.userEditForm.value.id = this.Id;
        this.commonService.updateUser(this.userEditForm.value).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                this.isLoading = false;
                this.dataEditDialog = false;
                this.getDatas();
              
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    deleteData(data: any) {
        this.isLoading = true;
        data.ModifiedBy = localStorage.getItem('username');
        
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.UserName + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.commonService.deleteUsers(data).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        this.isLoading = false;
                        this.dataDialog = false;
                        this.getDatas();
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.isLoading = false;
                    }
                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                    this.isLoading = false;
                }
            }
        });
    }

    findIndexById(Id: string): number {
        let index = -1;
        for (let i = 0; i < this.datas.length; i++) {
            if (this.datas[i].Id === Id) {
                index = i;
                break;
            }
        }

        return index;
    }

    createId(): string {
        let Id = '';
        var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for ( var i = 0; i < 5; i++ ) {
            Id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return Id;
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.datas);
            worksheet['!cols'] = [];
            worksheet['!cols'][0] = { hidden: true };
            worksheet['!cols'][6] = { hidden: true };
            
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            /* save to file */
            XLSX.writeFile(workbook, 'Users.xlsx');
        });
    }

    onChangeFilter(event: any) {
        this.selectedDropdown = event.value;
        console.log(event.value)
    }

    // COMPANY
    assignCompanyForm(data: any){
        this.showLoader = true;
        var dataAssignCompany = {
            "user_id": this.data.Id,
            "company_id": this.selectedDropdown.id
        };
        this.commonService.AssigUserCompanyBQ(dataAssignCompany).subscribe((resp: any) => {
            if (resp.Success == true) {
                var userId = {"userId": data.id};
                this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                // this.dataDialog = false;
                this.getAssignedCompanyList(data);
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
            }

            this.showLoader = false;

        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
        }

    }

    getAssignedCompanyList(data: any){
      
        var userId = {"user_id": this.data.Id};

        this.showLoader = true;

        this.commonService.GetUserCompanyByUserIdBQ(this.data.Id).subscribe((resp: any) => {
        // this.commonService.getAssignedCompanyByUser(userId).subscribe((resp: any) => {
            if (resp.Total != 0) {
              this.assignedCompanyList = resp.Result;
              console.log(this.assignedCompanyList)
            } else {
              this.assignedCompanyList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    assignCompany(data: any){
        this.modalAssignCompany = true;
        this.getAssignedCompanyList(data);
    }

    unassignCompany(data: any){

        var dataUnassignCompany = {
                "user_id": data.user_id,
                "company_id": data.company_id
            };
        this.confirmationService.confirm({
            message: 'Are you sure you want to unassign company ' + data.ModuleCompanies.name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                // this.commonService.deleteAssignedCompany(data).subscribe((resp: any) => {
                this.commonService.UnassigUserCompanyBQ(dataUnassignCompany).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedCompanyList(this.data);
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

    

    // SCHEME
    assignSchemeForm(data: any){
        this.showLoader = true;

        var dataSchemeCompany = {
            "user_id": data.Id,
            "scheme_id": this.selectedDropdown.id
        };
        this.commonService.AssigUserSchemeBQ(dataSchemeCompany).subscribe((resp: any) => {
            console.log(resp)
            if (resp.Success == true) {
                console.log(resp)
                this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.getAssignedSchemeList(data);
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
            }
            this.showLoader = true;

        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    assignScheme(data: any){
        this.modalAssignScheme = true;
        this.getAssignedSchemeList(data);
    }

    getAssignedSchemeList(data: any){
        var userId = {"user_id": data.Id};
        this.showLoader = true;

        this.commonService.GetUserSchemeByUserIdBQ(data.Id).subscribe((resp: any) => {
            if (resp.Total != 0) {
              this.assignedSchemeList = resp.Result;
            } else {
                this.assignedSchemeList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
        }
    }


    unassignScheme(data: any){
        this.confirmationService.confirm({
            message: 'Are you sure you want to unassign scheme ' + data.ConfigSchemes.name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                this.commonService.UnassigUserSchemeBQ(data).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedSchemeList(this.data);
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

    // GROUPS
    assignGroupForm(data: any){
        this.showLoader = true;
        var dataAssignGroup = {
            "userId": data.Id,
            "groupId": this.selectedDropdown.Id
        };
        // var dataAssignCompany = {
        //     "userId": data.Id,
        //     "companyId": this.selectedDropdown.Id
        // };
        this.commonService.AssignUserGroups(dataAssignGroup).subscribe((resp: any) => {
        // this.commonService.userAssignCompany(dataAssignCompany).subscribe((resp: any) => {
            if (resp.Success == true) {
                var userId = {"userId": data.Id};
                this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                // this.dataDialog = false;
                this.getAssignedGroupList(data);
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
            }

            this.showLoader = false;

        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
        }

    }

    getAssignedGroupList(data: any){
        var id = {"id": data.Id};
        // var userId = {"userId": data.Id};
        this.showLoader = true;

        this.commonService.GetUserGroupsByUserId(id).subscribe((resp: any) => {
        // this.commonService.getAssignedCompanyByUser(userId).subscribe((resp: any) => {
            if (resp.Total != 0) {
              this.assignedGroupList = resp.Result;
            } else {
              this.assignedGroupList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    assignGroup(data: any){
        this.modalAssignGroup = true;
        this.getAssignedGroupList(data);
    }

    unassignUserGroups(data: any){
        var dataUnassignGroup = {
                "userId": data.UserId,
                "groupId": data.Group.Id
            };
        this.confirmationService.confirm({
            
            message: 'Are you sure you want to unassign ' + data.Group.Name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                this.commonService.UnassignUserGroup(dataUnassignGroup).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedGroupList(this.data);
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

    // Service Area
    assignServiceAreaForm(data: any){
        this.showLoader = true;

        var dataUserArea = {
            "user_id": data.Id,
            "service_area_id": this.selectedDropdown.Id
        };

        console.log(dataUserArea)

        this.commonService.AssignUserServiceAreaBQ(dataUserArea).subscribe((resp: any) => {
            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.getAssignedServiceAreaList(data);
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
            }
            this.showLoader = true;

        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    assignServiceArea(data: any){
        this.modalAssignArea = true;
        this.getAssignedServiceAreaList(data);
    }

    getAssignedServiceAreaList(data: any){
        var userId = {"user_id": data.Id};
        this.showLoader = true;

        this.commonService.GetUserAreaByUserIdBQ(data.Id).subscribe((resp: any) => {
            if (resp.Total != 0) {
              this.assignedUserAreaList = resp.Result;
            } else {
                this.assignedUserAreaList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    unassignServiceArea(data: any){
        this.confirmationService.confirm({
            message: 'Are you sure you want to unassign Service area' + data.ConfigServiceArea.Name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                this.commonService.UnassigUserAreaBQ(data).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedServiceAreaList(this.data);
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

}
