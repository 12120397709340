import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigSetting } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';

@Component({
    selector: 'app-config-preference-setting',
    templateUrl: './config-preference-setting.component.html',
    styleUrls: ['./config-preference-setting.component.scss']
})
export class ConfigPreferenceSettingComponent implements OnInit {

    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigSetting[]= [];
    data: ConfigSetting = {ModuleConfigSetting:{}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    configsettingParam =  { id: null, isactiveonly: true };
    isLoading: boolean = true;

    descriptionFormControl = new FormControl('', [
        Validators.required,
    ]);
    keyFormControl = new FormControl('', [
        Validators.required,
    ]);
    valueFormControl = new FormControl('', [
        Validators.required,
    ]);

    configSettingForm = this.formBuilder.group({
        key: this.keyFormControl,
        description: this.descriptionFormControl,
        value: this.valueFormControl,
        active: true,
        createdBy: localStorage.getItem('username'),
    });

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.getDatas();

        this.items = [
            {label: 'Edit', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Delete', icon: 'pi pi-trash', command: () => {
                this.deleteData(this.data);
            }}
        ];

        this.menuitems = GlobalComponent.submenus;
    }

    selectData(data: any){
        this.data = data;
    }

    openNew(){
        this.data= { ModuleConfigSetting: {}};
        this.submitted = false;
        this.dataDialog = true;
    }

    editData(data: any) {
        this.data = {...data};
        this.dataDialog = true;
        this.isCreateNew = false;
        this.Id = data.Id;
    }

    hideDialog() {
        this.dataDialog = false;
        this.submitted = false;
    }

    onSubmit(): void {
        this.submitted = true;
        if (this.configSettingForm.valid) {
            if (this.isCreateNew == true) {
                this.createConfigRole();
            } else {
                this.updateConfigRole();
            }
        }
    }

    getDatas(){
        this.isLoading = true;
        this.commonService.getConfigPreferenceSettings(this.configsettingParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.datas = resp.Result;
                this.isLoading = false;
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    createConfigRole(){
        this.isLoading = true;
        this.commonService.createConfigPreferenceSettings(this.configSettingForm.value).subscribe((resp: any) => {
            if (resp.Success == true) {
                this.messageService.add({severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {

                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    updateConfigRole(){
        this.isLoading = true;
        this.configSettingForm.value.id = this.Id;
        this.commonService.updateConfigPreferenceSettings(this.configSettingForm.value).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    deleteData(data: any) {
        this.isLoading = true;
        data.CreatedBy = localStorage.getItem('username');
        data.Active = false;
        
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.Description + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                this.commonService.deleteConfigPreferenceSettings(data).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        this.isLoading = false;
                        this.dataDialog = false;
                        this.getDatas();
                      
                    } else {
              
                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.isLoading = false;
                    }
                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                    this.isLoading = false;
                }
            }
        });
    }


}
