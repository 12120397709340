import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { APIInterceptor } from './_helpers/api.interceptor';
import { CacheInterceptor } from './_helpers/cache.interceptor';
import { TokenInterceptor } from './_helpers/token.interceptor';
import { AppSharedModule } from './app-shared.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { LeftSidebarComponent } from './pages/shared/left-sidebar/left-sidebar.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { PocComponent } from './pages/poc/poc.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { VehicleStatusComponent } from './pages/vehicle-status/vehicle-status.component';
import { RouteComponent } from './pages/route/route.component';
import { MapComponent } from './pages/map/map.component';
import { ScheduleComponent } from './pages/route/schedule/schedule.component';
import { BinDetailsComponent } from './pages/route/bin-details/bin-details.component';
import { TripsComponent } from './pages/trips/trips.component';
import { EventsComponent } from './pages/events/events.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { UsersComponent } from './pages/settings/users/users.component';
import { VehicleStatusDetailsComponent } from './pages/vehicle-status-details/vehicle-status-details.component';
import { ReportStopComponent } from './pages/report-stop/report-stop.component';
import { DurationPipe } from './pages/report-stop/report-stop.component';
import { ReportRouteComponent } from './pages/report-route/report-route/report-route.component';
import { ReportTripsComponent } from './pages/report-trips/report-trips/report-trips.component';
import { ReportSummaryComponent } from './pages/report-summary/report-summary/report-summary.component';
import { ReportDailysummaryComponent } from './pages/report-dailysummary/report-dailysummary/report-dailysummary.component';

//config
import { ConfigRoleComponent } from './pages/settings/config-role/config-role.component';
import { ConfigSchemeComponent } from './pages/settings/config-scheme/config-scheme.component';
import { ConfigActivityTypeComponent } from './pages/settings/config-activity-type/config-activity-type.component';
import { ConfigCategoryTypeComponent } from './pages/settings/config-category-type/config-category-type.component';
import { ConfigParkComponent } from './pages/settings/config-park/config-park.component';
import { ConfigPBTComponent } from './pages/settings/config-pbt/config-pbt.component';
import { ConfigPremiseTypeComponent } from './pages/settings/config-premise-type/config-premise-type.component';
import { ConfigRoadComponent } from './pages/settings/config-road/config-road.component';
import { ConfigRouteComponent } from './pages/settings/config-route/config-route.component';
import { ConfigServiceAreaComponent } from './pages/settings/config-service-area/config-service-area.component';
import { ConfigStateComponent } from './pages/settings/config-state/config-state.component';
import { ConfigSubRouteComponent } from './pages/settings/config-sub-route/config-sub-route.component';
import { ConfigCityComponent } from './pages/settings/config-city/config-city.component';
import { ConfigModulevehicleComponent } from './pages/settings/config-modulevehicle/config-modulevehicle.component';
import { ConfigGroupsComponent } from './pages/settings/config-groups/config-groups.component';
import { ConfigVehicletypeComponent } from './pages/settings/config-vehicletype/config-vehicletype.component';
import { ConfigBintypeComponent } from './pages/settings/config-bintype/config-bintype.component';
import { ConfigMenutypeComponent } from './pages/settings/config-menutype/config-menutype.component';
import { ConfigModulecompanyComponent } from './pages/settings/config-modulecompany/config-modulecompany.component';
import { ConfigStatusComponent } from './pages/settings/config-status/config-status.component';
import { ConfigPreferenceSettingComponent } from './pages/settings/config-preference-setting/config-preference-setting.component';
import { ConfigPermissionComponent } from './pages/settings/config-permission/config-permission.component';
import { ConfigMenuComponent } from './pages/settings/config-menu/config-menu.component';
import { ConfigModuleActionLogComponent } from './pages/settings/config-module-action-log/config-module-action-log.component';
import { ModuleBhkComponent } from './pages/module-bhk/module-bhk.component';

//
import { PdibComponent } from './pages/settings/pdib/pdib.component';
import { PointOfCollectionComponent } from './pages/settings/point-of-collection/point-of-collection.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ChangePasswordComponent } from '../app/pages/shared/change-password/change-password.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'primeng/calendar';
import { AngularDraggableModule } from 'angular2-draggable';
import { MultiSelectModule } from 'primeng/multiselect';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule, Dropdown } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { BackupVehiclesComponent } from './pages/backup-vehicles/backup-vehicles.component';
//import { GeofenceComponent } from './pages/geofence/geofence.component';
import { VehiclePendingTaskComponent } from './pages/vehicle-pending-task/vehicle-pending-task.component';
import { VehicleShiftComponent } from './pages/vehicle-shift/vehicle-shift.component';
import { SlideMenuModule } from 'primeng/slidemenu';
import { MenuItem } from 'primeng/api';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ConfirmationService } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import { MenubarModule } from 'primeng/menubar';
import { BadgeModule } from 'primeng/badge';
//import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import {PanelModule} from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { GMapModule } from 'primeng/gmap';
import { InputTextareaModule } from 'primeng/inputtextarea';



@NgModule({
  declarations: [
    AppComponent, LoginComponent, LeftSidebarComponent, LayoutComponent, PocComponent, VehicleStatusComponent, RouteComponent, ChangePasswordComponent,BackupVehiclesComponent,
    MapComponent,ScheduleComponent,BinDetailsComponent, TripsComponent, EventsComponent, AnalyticsComponent, UsersComponent, ConfigRoleComponent, ConfigSchemeComponent, ConfigActivityTypeComponent,  ConfigCategoryTypeComponent, ConfigParkComponent, ConfigPBTComponent, ConfigPremiseTypeComponent, ConfigRoadComponent, ConfigRouteComponent, ConfigServiceAreaComponent, ConfigStateComponent, ConfigSubRouteComponent, ConfigCityComponent, ConfigModulevehicleComponent, ConfigGroupsComponent, ConfigVehicletypeComponent, ConfigBintypeComponent, ConfigMenutypeComponent, ConfigModulecompanyComponent,PdibComponent, PointOfCollectionComponent, ComingSoonComponent, ResetPasswordComponent, ConfigStatusComponent,
    ConfigPreferenceSettingComponent, ConfigPermissionComponent, ConfigMenuComponent, VehicleShiftComponent, VehicleStatusDetailsComponent,ConfigModuleActionLogComponent, VehiclePendingTaskComponent,
    ReportStopComponent, DurationPipe, ReportRouteComponent, ReportTripsComponent, ReportSummaryComponent, ReportDailysummaryComponent, ModuleBhkComponent
  ],
  imports: [DividerModule, CalendarModule, AngularDraggableModule,
    BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule, AppSharedModule, ToastModule, DialogModule,SlideMenuModule,TieredMenuModule,ToolbarModule,FileUploadModule,SplitButtonModule,CardModule,MenubarModule,BadgeModule,
    RadioButtonModule, MultiSelectModule, FormsModule, ReactiveFormsModule, ButtonModule, ConfirmDialogModule, MessagesModule, DropdownModule, ProgressBarModule, TabViewModule, AccordionModule, PanelModule, TooltipModule,
    TagModule, ImageModule, GMapModule, InputTextareaModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB128TkwXjDzDALWFkEpTUL3_xc7ijKgPM',
      libraries: ['places', 'drawing', 'geometry'],
      language: 'en'
    })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
