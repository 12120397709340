<p-card styleClass="p-card-content-0">

    <p-menubar [model]="menuitems">
    </p-menubar>
  
    <h5 style="margin-top: 1.0rem; margin-left:1.1rem; padding-bottom: 15px;">Report Route</h5><br>
    <div class="all-vehicle-details custom-scrollbar">
      <div class="w-100 result-found-blocks">

        <div class="result-found-list found-fonts fs-10 lh-12 mb-3">
          <div class="vehicle-register-number" *ngIf="selectedVehicleRegNo">
            <span style="color:black; font-size: 1.0rem; margin-left:-0.2rem; " >Vehicle Register Number:  </span>
            <span style="color:black; font-weight: bold; font-size: 1.0rem;" > {{ selectedVehicleRegNo }} </span>
          </div>
          <div class="w-100 tableFixHead">
            <div class="ptable-css ptable-scrollable">

              <div class="row w-100 justify-content-between mt-4" style="margin-left: -0.1rem">
                <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0">
                      <div class="range-calendar position-relative" style="width: 100%; height: 49px;">
                        <p-calendar selectionMode="range" [(ngModel)]="range_date" dateFormat="dd/mm/yy"
                          [maxDate]="dateTime" [showIcon]="true" placeholder="Select Range Date" class="custom-report"
                          (ngModelChange)="parseDate($event); selectedVehicleRegNo = selectedVehicle?.vehicleRegNo;" [showIcon]="true" inputId="icon"></p-calendar>
                      </div>
                    </li>
                </div>
                <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0">
                      <div class="fillet-boxs-css range-calendar position-relative" style="width: 100%; ">
                        <p-dropdown [options]="vehicleList" [(ngModel)]="selectedVehicle" optionLabel="vehicleRegNo" style="width: 100%"
                          [filter]="true" filterBy="vehicleRegNo" [showClear]="true" placeholder="Select a Vehicle"
                          (onChange)="parseDate($event); selectedVehicleRegNo = selectedVehicle?.vehicleRegNo;" class="custom-bhk-dropdown">
                        </p-dropdown>
                      </div>
                    </li>
                </div>
                <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                  <li class="d-flex align-items-center nav-item w-auto pr-0">
                    <div class="range-calendar" style="width: 100%">
                      <span class="p-input-icon-right" style="width: 100%">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" style="width: 100%; height: 40px" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                          placeholder="Search..." />
                      </span>
                    </div>
                  </li>
                </div>
                <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                  <li class="d-flex align-items-center nav-item w-auto pr-0">
                    <div class="export-button" style="width: 100%; height:48px">
                      <button type="button" class="border px-3 py-2 filter-bt-btm bg-transparent" style="width: 100%; height: 40px" (click)="exportToExcel(selectedVehicleRegNo)"><i class="	fa fa-download"></i> Export</button>
                    </div>
                  </li>
                </div>
              </div>
                
              <div #dtMain>
                <p-table #dt [value]="datas" [rows]="10" [paginator]="true"
                  [globalFilterFields]="['valid','deviceTime', 'latitude','longitude','altitude','speed', 'address']"
                  [(selection)]="selectedDatas" [rowHover]="true" dataKey="id"
                  styleClass="p-datatable-striped p-datatable-sm" [loading]="loading" responsiveLayout="scroll"
                  [rowsPerPageOptions]="[10,25,50]" [scrollable]="true" scrollHeight="60vh"
                  [tableStyle]="{'max-width': '100vw'}"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  [showCurrentPageReport]="true">
                  <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                      <div class="flex align-items-center justify-content-between summary-message"
                      style="margin-left: -0.7rem; margin-top: -1rem;">
                        {{ datas ? datas.length : 0 }} result(s) found.
                        <span style="color: #19A3F1; border-bottom: 1px solid; cursor: pointer;"
                          (click)="clearFilterData()">Clear Filter </span>
                        &nbsp;
                      </div>
                    </div>
                  </ng-template>
  
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="vehicleRegNo" style= >Vehicle<p-sortIcon
                          field="vehicleRegNo"></p-sortIcon></th>
                      <th pSortableColumn="deviceTime" style="width:150px">Time<p-sortIcon
                          field="deviceTime"></p-sortIcon></th>
                      <th pSortableColumn="latitude" style="width:150px">Latitude<p-sortIcon
                          field="latitude"></p-sortIcon></th>
                      <th pSortableColumn="longitude" style="width:150px">Longitude<p-sortIcon
                          field="longitude"></p-sortIcon></th>
                      <th pSortableColumn="altitude" style="width:150px">Altitude<p-sortIcon
                          field="altitude"></p-sortIcon></th>
                      <th pSortableColumn="speed" style="width:150px">Speed<p-sortIcon
                          field="speed"></p-sortIcon></th>
                      <th pSortableColumn="address" style="width:150px">Address<p-sortIcon
                          field="address"></p-sortIcon></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-datas>
                    <tr class="p-selectable-row">
                      <td style="width:150px">{{selectedVehicle.vehicleRegNo}}</td>
                      <td style="width:150px">{{datas.deviceTime | date:'dd-MM-yyyy hh:mm:ss a'}}</td>
                      <td style="width:150px">{{datas.latitude}}</td>
                      <td style="width:150px">{{datas.longitude}}</td>
                      <td style="width:150px">{{datas.altitude}}</td>
                      <td style="width:150px">{{datas.speed.toFixed(1)}} kn</td>
                      <td style="width:150px">{{datas.address}} </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6" class="empty-message" style="width:1500px;" >No data found.</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between summary-message">
                      In total there are {{datas ? datas.length : 0 }} record(s).
                    </div>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </p-card>