<div class="w-100 position-relative">
  <div #map style="width: 100%; height: 100vh; background: grey"></div>
  <div class="position-absolute count-bin-block">
    <div class="w-100 d-flex justify-content-between align-items-center bin-cout-infos">
      <div class="text-center">
          <div class="count-name">Total</div>
          <div class="bin-box bg-blue-box">1</div>
      </div>
      <div class="text-center">
        <div class="count-name">Collected</div>
          <div class="bin-box bg-green-box">1</div>
      </div>
      <div class="text-center">
        <div class="count-name">Missed</div>
          <div class="bin-box bg-red-box">1</div>
      </div>
      <div class="text-center">
        <div class="count-name">On Going</div>
          <div class="bin-box bg-yellow-box">1</div>
      </div>
    </div>
  </div>
</div>


