import { HttpResponse } from "@angular/common/http";

export class CacheService {
  private cache = new Map<string, [Date, HttpResponse<any>]>();

  get(key: any): HttpResponse<any> {
    const tuple = this.cache.get(key);
    if (!tuple) {
      return null as any;
    }

    const expires = tuple[0],
      httpResponse = tuple[1],
      now = new Date();
    if (expires && expires.getTime() < now.getTime()) {
      this.cache.delete(key);
      return null as any;
    }

    return httpResponse;
  }

  set(key: any, value: HttpResponse<any>, ttlInSeconds = null as any) {
    if (ttlInSeconds) {
      const expires = new Date();
      expires.setSeconds(expires.getSeconds() + ttlInSeconds);
      this.cache.set(key, [expires, value]);
    } else {
      this.cache.set(key, [null as any, value]);
    }
  }

  static CACHE_HEADER = "CACHE_LOCALLY";
  static cacheThis(seconds = "60") {
    return { headers: { [this.CACHE_HEADER]: String(seconds) } };
  }

  clearCache() {
    this.cache.clear();
  }
}
