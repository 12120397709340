import { HostListener, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { AuthService } from './_helpers/auth.service';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  title = 'LiveTracking';
  subscription: Subscription;
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (AuthService.isAuthenticated()) {
      localStorage.setItem('lastActive', new Date().toString());
    }
  }

  constructor(private router: Router, private authService: AuthService) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!router.navigated)
          this.authService.updateUserSession();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
