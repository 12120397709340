import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { AppSharedService } from '../../app.SharedService';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  restPasswordBox: boolean = false;
  resetPassword!: FormGroup;
  resetPasswordConfirm!: FormGroup;
  resetPasswordConfirmForm: boolean = false;
  errorMsgBox: boolean = false;
  successMsgBox: boolean = false;
  userName: any;
  loader: boolean = false;


  constructor(private fb: FormBuilder,
    private commonService: CommonService,
    private appCom: AppSharedService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.resetPassword = this.fb.group({
      userId: ["", [Validators.required, Validators.email]],
      resetUrl: ''
    });

    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {
      const token = params['token'];
      const email = params['Email'];
      console.log(token);
      console.log(email);
      if(token != undefined && email != undefined)
      {
        this.resetPasswordConfirmForm = true;
      }
    });
  }

  resetPasswordSubmit() {
    this.loader = true;

    const resetObj = {
      email: this.resetPassword.value.userId,
      //resetUrl: 'http://localhost:4455/reset-password'
      resetUrl: 'http://collection.mflora.com.my/reset-password'
    }

    this.commonService.forgotPassword(resetObj).subscribe(
      (res: any) => {
       
        if (res.Success == true) {

          this.appCom.showSuccess("Email link sent to ", this.resetPassword.value.userId);
          this.resetPassword.reset();
        }
        else
          this.appCom.showError("Something went wrong", res.Message);
      
        this.loader = false;
      }), (error: any) => {
        this.appCom.showError("Something went wrong", '');
        this.loader = false;
      }
    //if (this.resetPassword.valid) {
    //  this.successMsgBox = true;
    //  let interval = setInterval(() => {
    //    this.successMsgBox = false;
    //    this.restPasswordBox = false;
    //    clearInterval(interval);
    //  }, 3000)
    //}
    //else {
    //  this.errorMsgBox = true;
    //  let interval = setInterval(() => {
    //    this.errorMsgBox = false;
    //    clearInterval(interval);
    //  }, 3000)
    //}
    //this.resetPassword.reset();
  }

  resetPasswordConfirmSubmit() {
    this.loader = true;

    const resetObj = {
      email: this.resetPassword.value.userId,
      //resetUrl: 'http://localhost:4455/reset-password'
      resetUrl: 'http://collection.mflora.com.my/reset-password'
    }

    this.commonService.forgotPassword(resetObj).subscribe(
      (res: any) => {
       
        if (res.Success == true) {

          this.appCom.showSuccess("Email link sent to ", this.resetPassword.value.userId);
          this.resetPassword.reset();
        }
        else
          this.appCom.showError("Something went wrong", res.Message);
      
        this.loader = false;
      }), (error: any) => {
        this.appCom.showError("Something went wrong", '');
        this.loader = false;
      }
    //if (this.resetPassword.valid) {
    //  this.successMsgBox = true;
    //  let interval = setInterval(() => {
    //    this.successMsgBox = false;
    //    this.restPasswordBox = false;
    //    clearInterval(interval);
    //  }, 3000)
    //}
    //else {
    //  this.errorMsgBox = true;
    //  let interval = setInterval(() => {
    //    this.errorMsgBox = false;
    //    clearInterval(interval);
    //  }, 3000)
    //}
    //this.resetPassword.reset();
  }

  forgotPassword() {
    this.restPasswordBox = true;
  }

  alertBoxClose() {
    this.errorMsgBox = false;
    this.successMsgBox = false;
  }

}
