
<div class="w-100 d-flex layout-blocks" [ngClass]="leftSideBoxHidden? 'nav-width-215':'normal-width-80'">
 
  <div class="left-w vh-100"> <left-sidebar></left-sidebar></div>
    <div class="right-w position-relative">
      <div class="w-100">
        <!--div class="w-100 position-absolute map-styles">
          <app-map></app-map>
        </div -->
        <div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
</div>
