
  <div *ngIf="loader" class="w-100 vh-100 background_Loader position-absolute z-index-1 d-flex align-items-center justify-content-center">
    <div class="loader"></div>
  </div>
<div class="vw-100 vh-100 auth-bg-color position-relative" *ngIf="1loader">
  <div class="vw-100 vh-100 auth-bg">
    <div class="d-flex align-items-center vh-100 containet-center-blocks custom-scrollbar">
      <div class="container">
        <div class=" auth-bks-fields">
          <div class="brand-ele">
            <img src="./assets/images/brand-imgs/logo-mfloratel-black.png" />
          </div>
          <div class="auth-titles font-weight-bold txt-black py-3">
            Sign in.
          </div>
          <div class="auth-sub-titles pb-4">
            Please enter your credentials to signIn!
          </div>
          <form [formGroup]="logInForm" (ngSubmit)="loginFormSubmit(logInForm.value)" class="w-100">
            <div>
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="email">Email</label>
                <input formControlName="email" class="rounded inpu-shadows p-2 w-100" id="email" type="text" pInputText />
                <div *ngIf="logInForm.controls['email'].invalid && (logInForm.controls['email'].dirty || logInForm.controls['email'].touched)"
                     class="fs-10 text-danger position-absolute error-msgs error-input err-top-72">
                  <div class="w-100 lh-10" *ngIf="logInForm.controls['email'].errors?.['required']">
                    Email ID is required.
                  </div>
                  <div class="w-100 lh-10" *ngIf="logInForm.controls['email'].errors?.['email']">
                    Email ID must be of type email.
                  </div>
                </div>
              </div>
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="password">Password</label>
                <div class="w-100 position-relative pwd-eye-btn">
                  <input formControlName="password" class="rounded inpu-shadows p-2 w-100" id="password" type="{{pwdEye ? 'text':'password'}}" pInputText />
                  <button type="button" (click)="pwdEye = !pwdEye" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
                    <span class="material-icons">
                      {{pwdEye ? 'visibility':'visibility_off'}}
                    </span>
                  </button>
                </div>
                <div *ngIf="logInForm.controls['password'].invalid && (logInForm.controls['password'].dirty || logInForm.controls['password'].touched)"
                     class="text-danger position-absolute error-msgs error-input err-top-72 fs-10">
                  <div class="w-100 lh-10" *ngIf="logInForm.controls['password'].errors?.['required']">
                    password is required.
                  </div>
                  <div class="w-100 lh-10" *ngIf="logInForm.controls['password'].errors?.['minlength']">
                    password must be at least 4 digits.
                  </div>
                  <!-- <div class="w-100 lh-10" *ngIf="logInForm.controls['password'].errors?.['maxlength']">
                    password must be at most 8 digits.
                  </div> -->
                </div>
              </div>
            </div>
            <div class="d-flex w-100 justify-content-between pb-3">
              <div class="d-flex align-items-center fillet-boxs-css">
                <input type="checkbox" formControlName="rememberMe" />
                <label for="rememberMe" class="txt-default-color fs-12 m-0 pl-2">Remember me</label>
              </div>
              <div>
                <a routerLink="/reset-password" class="cursor-pointer fs-12 txt-default-color">Forgot password</a>
              </div>
            </div>
            <div class="pb-3">
              <button class="btn btn-lg btn-primary btn-block rounded fs-12" [disabled]="!logInForm.valid" type="submit">Sign in</button>
            </div>
            <div class="text-danger text-center" style="clear:both;padding:5px;justify-content:center;display:flex" *ngIf="formError!=''">{{formError}}</div>
          </form>
          <div class="fs-12 pb-3">
            Don’t have an account?<br />
            <span class="txt-default-color">Contact support to join us!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>
<!--<div class="alert alert-danger alert-dismissible fade show position-absolute alertBoxs" role="alert" *ngIf="errorMsgBox">
  <strong class="text-capitalize">Hello User</strong> Please enter user and Password
  <button type="button" class="close" (click)="alertBoxClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress progress-infos bg-transparent" style="height: 5px;">
    <div class="progress-bar bg-danger" role="progressbar" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
</div>
<div class="alert alert-success alert-dismissible fade show position-absolute alertBoxs" role="alert" *ngIf="successMsgBox">
  <strong class="text-capitalize">{{userName}}</strong> Logged In Successfully!
  <button type="button" class="close" (click)="alertBoxClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress progress-infos bg-transparent" style="height: 5px;">
    <div class="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
</div>-->
