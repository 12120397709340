import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigStatus } from 'src/app/app.models';
import { ModuleConfigState } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';

@Component({
  selector: 'app-config-status',
  templateUrl: './config-status.component.html',
  styleUrls: ['./config-status.component.scss']
})
export class ConfigStatusComponent implements OnInit {

    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigStatus[]= [];
    data: ConfigStatus = { ModuleConfigStatus: {}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    configStatusParam =  { id: null, isactiveonly: true };
    selectedState: ModuleConfigState[] = [];
    state: any[] = [];

    ModuleFormControl = new FormControl('', [
        Validators.required,
    ]);
    NameFormControl = new FormControl('', [
        Validators.required,
    ]);
    DescriptionFormControl = new FormControl('', [
        Validators.required,
    ]);

    configStatusForm = this.formBuilder.group({
        Module: this.ModuleFormControl,
        Name: this.NameFormControl,
        Description: this.DescriptionFormControl,
        Active: true,
        CreatedBy: localStorage.getItem('username'),
    });

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.getDatas();

        this.items = [
            {label: 'Edit', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Delete', icon: 'pi pi-trash', command: () => {
                this.deleteData(this.data);
            }}
        ];

        this.menuitems = GlobalComponent.submenus;
    }

    getDatas(){
        this.commonService.getAllConfigStatus(this.configStatusParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.datas = resp.Result;
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    onSubmit(): void {
        this.submitted = true;
        if (this.configStatusForm.valid) {
            if (this.isCreateNew == true) {
                this.CreateConfigStatus();
            } else {
                this.UpdateConfigStatus();
            }
        }
    }

    selectData(data: any){
        this.data = data;
    }

    clearselectData(){
        this.selectedDatas = [];
    }

    openNew() {
        this.data= { ModuleConfigStatus: {}};
        this.isCreateNew = true;
        this.submitted = false;
        this.dataDialog = true;
    }

    hideDialog() {
        this.dataDialog = false;
        this.submitted = false;
    }

    editData(data: any) {
        this.data = {...data};
        this.dataDialog = true;
        this.isCreateNew = false;
        this.Id = data.Id;
    }

    CreateConfigStatus(){
        this.commonService.createConfigStatus(this.configStatusForm.value).subscribe((resp: any) => {
            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.dataDialog = false;
                this.getDatas();
              
            } else {

                this._appSharedService.showError('Error', "Something went wrong!");
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    UpdateConfigStatus(){
        this.configStatusForm.value.id = this.Id;
        this.commonService.updateConfigStatus(this.configStatusForm.value).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                this.dataDialog = false;
                this.getDatas();
              
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    deleteData(data: any) {
        data.ModifiedBy = localStorage.getItem('username');
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.Name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.commonService.deleteConfigStatus(data).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        this.dataDialog = false;
                        this.getDatas();
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }
                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

    // findIndexById(Id: string): number {
    //     let index = -1;
    //     for (let i = 0; i < this.datas.length; i++) {
    //         if (this.datas[i].Id === Id) {
    //             index = i;
    //             break;
    //         }
    //     }

    //     return index;
    // }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.datas);
            worksheet['!cols'] = [];
            worksheet['!cols'][0] = { hidden: true };
            worksheet['!cols'][4] = { hidden: true };
            
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            /* save to file */
            XLSX.writeFile(workbook, 'ConfigRole.xlsx');
        
        });
    }

}
