import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import { AppSharedService } from '../../app.SharedService';
import { ConfirmationService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  providers: [ConfirmationService]
})
export class DeviceComponent implements OnInit {
  addDisplay: boolean = false;
  editDisplay: boolean = false;
  deviceForm!: FormGroup;
  showLoader: boolean = false;
  dataPresent: boolean = false;
  devices: any[] = [];
  formActionType: string = '';
  formSubmitting: boolean = false;
  device: any = null;
  msgs: Message[] = [];

  constructor(private fb: FormBuilder,
    private adminService: AdminService,
    private appCom: AppSharedService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig) { }
  first = 0;
  rows = 10;
  
  ngOnInit(): void {
    this.deviceForm = this.fb.group({
      id: ["", Validators.compose([Validators.required, Validators.maxLength(4)])],
      name: ["", Validators.compose([Validators.required])],
      uniqueId: ["", Validators.compose([Validators.required])],
      positionId: ["", Validators.compose([Validators.required])],
      phone: ["", Validators.compose([Validators.required])],
      lastUpdate: ["", Validators.compose([Validators.required,])],
      isActive: ["", Validators.compose([Validators.required])],
    });
    this.getAllDevices();
    this.primengConfig.ripple = true;
  }

  addShowDialog() {
    this.deviceForm.reset();
    this.addDisplay = true;
  }

  addCancLBtn() {
    this.addDisplay = false;
  }

  editShowDialog(id: string) {
    this.editDisplay = true;
    this.formActionType = 'Edit';
    this.device = null;
    this.device = this.devices.filter(x => x.deviceId == id)[0];
    if (this.device != null) {
      this.deviceForm.controls['id'].setValue(this.device.id);
      this.deviceForm.controls['name'].setValue(this.device.name);
      this.deviceForm.controls['uniqueId'].setValue(this.device.uniqueId);
      this.deviceForm.controls['positionId'].setValue(this.device.positionId);
      this.deviceForm.controls['phone'].setValue(this.device.phone);
      this.deviceForm.controls['lastUpdate'].setValue(this.device.lastUpdate);
      this.deviceForm.controls['isActive'].setValue(this.device.isActive);
    }
    else
      this.appCom.showError("Something went wrong!", 'wrong');
  }

  editCancLBtn() {
    this.editDisplay = false;
  }

  getAllDevices() {
    this.showLoader = true;
    this.adminService.getAllDevices().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.devices = [];
          this.devices = resp;
          //this.appCom.allDevicesData(this.devices);
        }
        else {
          this.devices = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
      /*  error => {
          this.appCom.showError("Something went wrong!", "wrong");
          this.showLoader = false;
        }*/
    );
  }

  addDevice() {
    this.formActionType = 'Add';
    this.showLoader = true;
    this.formSubmitting = false;
    const device = {
      id: this.deviceForm.controls['id'].value,
      name: this.deviceForm.controls['name'].value,
      uniqueId: this.deviceForm.controls['uniqueId'].value,
      positionId: this.deviceForm.controls['positionId'].value,
      phone: this.deviceForm.controls['phone'].value,
      lastUpdate: this.deviceForm.controls['lastUpdate'].value,
      disabled: true
    };
    this.addDisplay = false;

    this.adminService.addDevices(device).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully added!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  editDevice() {
    const device = {
      deviceId: this.device.deviceId,
      id: this.deviceForm.controls['id'].value,
      name: this.deviceForm.controls['name'].value,
      uniqueId: this.deviceForm.controls['uniqueId'].value,
      positionId: this.deviceForm.controls['positionId'].value,
      phone: this.deviceForm.controls['phone'].value,
      lastUpdate: this.deviceForm.controls['lastUpdate'].value,
      disabled: this.deviceForm.controls['isActive'].value
    };
    this.editDisplay = false;
    this.adminService.editDevices(device).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully updated!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  deleteDevice(id: string) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.adminService.deleteDevices(id).subscribe(
          resp => {
            if (resp.statusCode == 200) {
              this.appCom.showSuccess(`Successfully deleted!`,'success');
              this.ngOnInit();
            }
            else {
              this.appCom.showError("Something went wrong!",'wrong');
            }
            this.showLoader = false;
          }, error => {
            this.appCom.showError("Something went wrong!",'wrong');
            this.showLoader = false;
          });
      },
      reject: () => {
        this.appCom.showWarn("Rejected", "You have rejected");
      },
      key: "myDialog"
    });
    //this.confirmationService.confirm({
    //  message: 'Delete this Device',
    //  header: 'Delete',
    //});
  }

  validatedName(event: { charCode: number; }) {
    return (
      (event.charCode > 96 && event.charCode < 123) || //alphabets
      (event.charCode > 64 && event.charCode < 91) || //capital Alphabets
      event.charCode == 8 || //backspace
      event.charCode == 32 //Space
    );
  }

  validatedNumber(event: { charCode: number; }) {
    return (
      (event.charCode > 47 && event.charCode < 58)  // numericals
    );
  }
}
