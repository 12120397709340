import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AppSharedService } from '../../app.SharedService';
import { PrimeNGConfig } from "primeng/api";
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { AuthService } from '../../_helpers/auth.service';
import {DropdownModule} from 'primeng/dropdown';
import { Console } from 'console';
import {SidebarModule} from 'primeng/sidebar';

interface vehicle {
  vehicleNo: string,
  company: string,
  scheme: string,
  vehicleType: string,
  route: string
}

@Component({
  selector: 'app-vehicle-status',
  templateUrl: './vehicle-status.component.html',
  styleUrls: ['./vehicle-status.component.scss']
})

export class VehicleStatusComponent implements OnInit {
  selectedfiller!: vehicle[];
  fillerVihelcelDetails!: vehicle[];
  closeVehicleStatu: boolean = true;
  vehcleStatus: boolean = false;
  vihicleNotOnline: boolean = false;
  vehicleInfos: any;
  minDate: Date;
  maxDate: Date;
  vehiInfos: boolean = false;
  closeStatus: boolean = false;
  vehiFullInfos: boolean = false;
  filterDialogBox: boolean = false;
  showLoader: boolean = false;
  showLoader2: boolean = false;
  allVehicles: any[] = [];
  allVehiclesToFilter: any[] = [];
  vehiclesToDisplay: any[] = [];
  allDevices: any[] = [];
  tabsHide: boolean = false;
  allVehiclesCount: number = 0;
  onlineVehiclesCount: number = 0;
  signalLostVehiclesCount: number = 0;
  offlineVehiclesCount: number = 0;
  idleVehiclesCount: number = 0;
  idleVehiclesCount2: number = 0;
  speedVehiclesCount2: number = 0;
  selectedVehicleId: any[] = [];
  selectedVehicleRegNo: any[] = [];
  selectedDeviceId: any[] = [];
  selectedVehicleType: any[] = [];
  currentSelectedVehicleStatus: string = 'all';
  idleVehicles: any[] = [];
  onlineVehicles: any[] = [];
  offlineVehicles: any[] = [];
  signalLostVehicles: any[] = [];
  results: any[] = [];
  searchValue: any = null;
  scheduleRoute: any[] = [];
  lastUpdated: any;
  lastUpdated2: any;
  date2: any;
  routeDate: any;
  signalLostVeh: any[] = [];
  idleVeh: any[] = [];
  schdlDate = new Date();
  scheduleDate = formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US');

  onlineIcon2 = { url: '../../assets/images/web-112021/icons/online-vehicle.png', scaledSize: { height: 30, width: 30 } };
  offlineIcon2 = { url: '../../assets/images/web-112021/icons/offline-vehicle.png', scaledSize: { height: 30, width: 30 } };
  idleIcon2 = { url: '../../assets/images/web-112021/icons/idle-vehicle.png', scaledSize: { height: 30, width: 30 } };
  signalLostIcon2 = { url: '../../assets/images/web-112021/icons/signal-lost.png', scaledSize: { height: 30, width: 30 } };
  currentUser: any;
  contractorVehicles: any;
  filteredContractorVehicles: any[] = [];
  cntrctVehiclesData: any[] = [];
  CntrctVehResults: any[] = [];
  activeShowIndex!: number;
  vehicleSummary: any = [];
  progressBar: boolean = false;
  progressBarBinStatus: boolean = false;
  binsStatus: any = [];
  vehicleMoving: boolean = true;
  dragaable: boolean = true;
  pinActive: boolean = false;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  binCollectedSummary: any = [];
  outputBins: number = 0;
  collectedBins: number = 0;
  ongoingBins: number = 0;
  totalBins: number = 0;
  outputBinPercentage: number = 0;
  inBounds = true;
  filterVehicleValue = ''; 
  selectedCompanySchedule: any;
  selectedVehicleSchedule: any;
  selectedRouteSchedule: any;
  selectedSubRouteSchedule: any;
  selectedtempCompanySchedule: any;
  selectedtempVehicleSchedule: any;
  selectedtempRouteSchedule: any;
  selectedtempSubRouteSchedule: any;
  filterCompanyScheduleValue = '';
  searchBy: any[] = [
    { search: 'Company', key: 'Company' }, 
    { search: 'Vehicle No.', key: 'Vehicle' }, 
    { search: 'Route No.', key: 'Route' }
    //{ search: 'Sub Route No.', key: 'SubRoute' }
  ];
  isFilterSubmit: boolean = false;
  selectedFilter: any = [];
  selectedtempFilter: any = [];
  selectedSearchBy: any;
  isCompanyFilterShow: boolean = false;
  isVehicleFilterShow: boolean = false;
  isRouteFilterShow: boolean = false;
  isSubRouteFilterShow: boolean = false;
  summarySchedules: any =[];
  routeSchedules: any =[];
  subrouteSchedules: any =[];
  vehicleSchedules: any =[];
  companySchedules: any =[];
  binactiveValue: number = 0;
  btnKIIsActive: boolean = true;
  btnKBIsActive: boolean = true;
  filterValue ='';
  totalSchedule: number = 0;
  isLiveTrackingMode: boolean = true;
  displayVehicleList: boolean = false;
  vehicleTraccarData: any[] = [];;

  constructor(private _appSharedService: AppSharedService,
    private commonService: CommonService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private router: Router,
    private primengConfig: PrimeNGConfig) {
    const currentDate = new Date();
    this.minDate = new Date(currentDate.getFullYear() - 100, 0, 1);
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    //comented because repeated
    //console.log("TraccarAccess",localStorage.getItem('TraccarAccess'));

   
    this._appSharedService.sendDataFromMap$.subscribe(val => {
      if(val != null)
      {
        this.vehicleDetails(val.vehicleId, val.vehicleRegNo, 0);
        this.searchValue = val.vehicleRegNo;
        this.searchVehicles(val.vehicleRegNo);
      }
   
    })
    this.primengConfig.ripple = true;
    this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');

    this.getAllTraccrVehicles();

    let interval = setInterval(() => {

      //this.getAllVehicles();
      this._appSharedService.updateVehicle$.subscribe(value => {
      
        if (this.searchValue == null || this.searchValue == "") {
          this.updateVehicleInfo(value);
        }
      });
      this._appSharedService.change.subscribe(val => {
        this.vehiInfos = val;
      })
      this._appSharedService.change.subscribe(val => {
        this.closeVehicleStatu = val;
      })
      this.commonService.getScheduleSummary(this.scheduleDate,this.currentUser.Id).subscribe((resp: any) => {
        var distictCompany:any = [];
        if (resp.Total != 0) {
          this.summarySchedules = resp.Result;
          //var data = resp.Result.map((t:any)=> {t.campany_id,company});
          //console.log(resp);
          //this.companySchedules = resp.Result.map((item: { campany_id: any; company: any; })=> {item.campany_id,item.company});
          //this.vehicleSchedules = new Set(resp.Result.map((item:any) => item.vehicle_no));
          
          var uniqueCompany:any = [];
          var uniqueVehicle:any = [];
          var uniqueRoute:any = [];
          var uniqueSubRoute:any = [];
          
  
          var arrayUniqueByKey = resp.Result.forEach((element: any) => {
          //var arrayUniqueByKey = resp.Result.map((item:any) => [{'campany_id':item.campany_id,'company' :item.company}]).forEach((element: any) => {
            //console.log(element);
            
            if(uniqueCompany[uniqueCompany.indexOf(element.company_id)] == undefined)
            {
            
              let company_obj: any = {};
              company_obj["company_id"] = element.company_id;
              company_obj["company"] = element.company;
              
              this.companySchedules.push(company_obj);
              uniqueCompany.push(element.company_id);
            }
  
            if(uniqueVehicle[uniqueVehicle.indexOf(element.vehicle_no)] == undefined)
            {
            
              let vehicle_obj: any = {};
              vehicle_obj["vehicle_no"] = element.vehicle_no;         
              
              this.vehicleSchedules.push(vehicle_obj);
              uniqueVehicle.push(element.vehicle_no);
            }
  
            if(uniqueRoute[uniqueRoute.indexOf(element.main_route)] == undefined)
            {
            
              let route_obj: any = {};
              route_obj["main_route"] = element.main_route;
                      
              this.routeSchedules.push(route_obj);
              uniqueRoute.push(element.main_route);
            }
  
            if(uniqueSubRoute[uniqueSubRoute.indexOf(element.sub_route)] == undefined)
            {          
              let subroute_obj: any = {};
              subroute_obj["sub_route"] = element.sub_route;
                       
              this.subrouteSchedules.push(subroute_obj);
              uniqueSubRoute.push(element.sub_route);
            }
  
            
  
           
          
          });
           
        
  
                
        } 
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong with company schedule!");
      }
      this.getcollectedBins();
      
      clearInterval(interval);
    }, 1000)
    


    // this._appSharedService.allVehicleLogs$.subscribe(value => {
    //   console.log('log-pos')     
    //   console.log(value[value.length - 1]);
    //   this.updateVehicleAddress(value[value.length - 1]);
    // });
  }


  getAllLiveTrackDetails() {
    this._appSharedService.liveTrackDetails().subscribe(
      data => {
        this._appSharedService.allVehicleDetails(data.liveTrack);
      },
      err => console.error(err),
    );
  }
  getVehicleDetails() {
    this._appSharedService.sendDataFromMap$.subscribe(val => {
    })
  }

  getAllTraccrVehicles(){
    this.commonService.vehicleData().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          
          const result = resp.filter((obj: any) => {
            return obj.disabled === false;
          });
          this.vehicleTraccarData = result;
          //let _objResultVehicle: any[] = resp;
          console.log(this.vehicleTraccarData);
          this.getAllVehicles();
          
        }
        else {
          this.vehicleTraccarData = [];
        }
      }),
      (error: any) => {
        //this._appSharedService.showError('Error', "Something went wrong!");
      }
    );
  }

  getAllVehicles() {
    this.showLoader = true;
    this.allVehicles = [];
    this.vehiclesToDisplay = [];

    localStorage.removeItem('vehiclesData');
   
    this.commonService.vehicleDetails().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          
          const result = resp.filter((obj: any) => {
            return obj.disabled === false;
          });
          this.allVehicles = result;
          let _objResultVehicle: any[] = resp;

          

              if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
                
                for (var i = 0; i < this.allVehicles.length; i++) {

                  var objIndex = this.vehicleTraccarData.findIndex((obj => obj.id == this.allVehicles[i].deviceId.toString()));
                  //console.log(objIndex);

                  if(objIndex >= 0)
                  {
                    this.allVehicles[i].lastUpdate2 = this.checkTimeDiff(this.vehicleTraccarData[objIndex].lastUpdate);
                    this.allVehicles[i].positionId = this.vehicleTraccarData[objIndex].positionId;
                    this.allVehicles[i].status = this.vehicleTraccarData[objIndex].status;
                 

                    if (this.vehicleTraccarData[objIndex].status == 'online') {
                      this.allVehicles[i].vehicleStatus = this.onlineIcon2;
                      this.onlineVehicles.push(this.allVehicles[i]);
                    }
                    else if (this.vehicleTraccarData[objIndex].status == 'offline') {
                      this.allVehicles[i].vehicleStatus = this.offlineIcon2;
                      this.offlineVehicles.push(this.allVehicles[i]);
                    }
                    else if (this.vehicleTraccarData[objIndex].status == 'unknown') {
                      this.allVehicles[i].vehicleStatus = this.signalLostIcon2;
                      this.signalLostVehicles.push(this.allVehicles[i]);
                    }
                    else {               
                        this.allVehicles[i].vehicleStatus = this.idleIcon2;
                        this.idleVehicles.push(this.allVehicles[i]);
                    }
                  }
                  else
                  {
                    this.allVehicles[i].lastUpdate2 = this.checkTimeDiff(this.allVehicles[i].lastUpdate);               

                    if (this.allVehicles[i].status == 'online') {
                      this.allVehicles[i].vehicleStatus = this.onlineIcon2;
                      this.onlineVehicles.push(this.allVehicles[i]);
                    }
                    else if (this.allVehicles[i].status == 'offline') {
                      this.allVehicles[i].vehicleStatus = this.offlineIcon2;
                      this.offlineVehicles.push(this.allVehicles[i]);
                    }
                    else if (this.allVehicles[i].status == 'unknown') {
                      this.allVehicles[i].vehicleStatus = this.signalLostIcon2;
                      this.signalLostVehicles.push(this.allVehicles[i]);
                    }
                    else {               
                        this.allVehicles[i].vehicleStatus = this.idleIcon2;
                        this.idleVehicles.push(this.allVehicles[i]);
                    }
                  }

                  
                }
                
                this.vehiclesToDisplay = this.allVehicles;
                this.results = [...this.onlineVehicles, ...this.idleVehicles, ...this.offlineVehicles, ...this.signalLostVehicles];
                //localStorage.setItem('vehiclesData', JSON.stringify(this.results));
                this._appSharedService.apiVehicleDetails(this.results);
                this._appSharedService.toggleVehiclesView(false);
                this.filterVehiclesCount(this.results);
                this.showLoader = false;
              }
              else{
                this.getAllUserVehicles();
              }
          
        }
        else {
          this.vehiclesToDisplay = [];
          this._appSharedService.showError('Error', "Something went wrong!");
          this.showLoader = false;
        }
      }),
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
        this.showLoader = false;
      }
    );
  }

  getContractorGroups() {
    var data = {
      id: this.currentUser.Id
    }
    this.commonService.contractorGroups(data).subscribe(
      ((resp: any) => {

      }),
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }
    );
  }
  getAllUserVehicles() {
    this.showLoader = true;
    this.commonService.getUserVehicle().subscribe(
      ((resp: any) => {

        var uniqueVehicle:any = [];
       
        if (resp.Result.length != 0) {

          this.contractorVehicles = resp.Result;

          var arrayUniqueByKey = resp.Result.forEach((element: any) => {

            var objIndex = this.allVehicles.findIndex((obj => obj.vehicleRegNo.replace(/\s/g, '') == element.VehicleRegNo.replace(/\s/g, '')));
      
            var objVehicle = this.allVehicles[objIndex];

            if(objIndex >= 0)
            {
              this.cntrctVehiclesData.push(objVehicle);
              //console.log(objVehicle);
            }

          });

          for (var i = 0; i < this.cntrctVehiclesData.length; i++) {

            

            var objIndex = this.vehicleTraccarData.findIndex((obj => obj.id == this.cntrctVehiclesData[i].deviceId.toString()));

            if(objIndex >= 0)
            {
              //console.log("traccar")
              this.cntrctVehiclesData[i].lastUpdate2 = this.checkTimeDiff(this.vehicleTraccarData[objIndex].lastUpdate);
              this.cntrctVehiclesData[i].positionId = this.vehicleTraccarData[objIndex].positionId;
           

              if (this.vehicleTraccarData[objIndex].status == 'online') {
                this.cntrctVehiclesData[i].vehicleStatus = this.onlineIcon2;
                this.onlineVehicles.push(this.cntrctVehiclesData[i]);
              }
              else if (this.vehicleTraccarData[objIndex].status == 'offline') {
                this.cntrctVehiclesData[i].vehicleStatus = this.offlineIcon2;
                this.offlineVehicles.push(this.cntrctVehiclesData[i]);
              }
              else if (this.vehicleTraccarData[objIndex].status == 'unknown') {
                this.cntrctVehiclesData[i].vehicleStatus = this.signalLostIcon2;
                this.signalLostVehicles.push(this.cntrctVehiclesData[i]);
              }
              else {               
                  this.cntrctVehiclesData[i].vehicleStatus = this.idleIcon2;
                  this.idleVehicles.push(this.cntrctVehiclesData[i]);
              }
            }
            else
            {
              this.cntrctVehiclesData[i].lastUpdate2 = this.checkTimeDiff(this.cntrctVehiclesData[i].lastUpdate);
           

              if ((this.cntrctVehiclesData[i].status == 'online')) {
                this.cntrctVehiclesData[i].vehicleStatus = this.onlineIcon2;
                this.onlineVehicles.push(this.cntrctVehiclesData[i]);
              }
              else if (this.cntrctVehiclesData[i].status == 'offline') {
                this.cntrctVehiclesData[i].vehicleStatus = this.offlineIcon2;
                this.offlineVehicles.push(this.cntrctVehiclesData[i]);
              }
              else if (this.cntrctVehiclesData[i].status == 'unknown') {
                this.cntrctVehiclesData[i].vehicleStatus = this.signalLostIcon2;
                this.signalLostVehicles.push(this.cntrctVehiclesData[i]);
              }
              else {               
                  this.cntrctVehiclesData[i].vehicleStatus = this.idleIcon2;
                  this.idleVehicles.push(this.cntrctVehiclesData[i]);
              }
            }      

           
          }

          this.vehiclesToDisplay = this.cntrctVehiclesData;
          this.results = [...this.onlineVehicles, ...this.idleVehicles, ...this.offlineVehicles, ...this.signalLostVehicles];
          //localStorage.setItem('vehiclesData', JSON.stringify(this.results));
          this._appSharedService.apiVehicleDetails(this.results);
          this._appSharedService.toggleVehiclesView(false);
          this.filterVehiclesCount(this.results);
          this.showLoader = false;
   

          // this.vehiclesToDisplay = this.cntrctVehiclesData;
          // this.CntrctVehResults = [...this.onlineVehicles, ...this.idleVehicles, ...this.offlineVehicles, ...this.signalLostVehicles];
          // localStorage.setItem('vehiclesData', JSON.stringify(this.CntrctVehResults));
          // this._appSharedService.apiVehicleDetails(this.CntrctVehResults);
          // this._appSharedService.toggleVehiclesView(false);
          // this.filterVehiclesCount(this.CntrctVehResults);
          // this.showLoader = false;
        }
        else {
          this.vehiclesToDisplay = [];
          this.showLoader = false;
        }
      }),
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
        this.showLoader = false;
      }
    );
  }

  getAllUserVehiclesOld() {
    this.showLoader = true;
    this.commonService.getUserVehicle().subscribe(
      ((resp: any) => {
        if (resp.Result.length != 0) {
          this.contractorVehicles = resp.Result;
          for (var i = 0; i < this.contractorVehicles.length; i++) {
            var temp = {
              vehicleRegNo: this.contractorVehicles[i].ModuleVehicle.VehicleRegNo,
              vehicleId: this.contractorVehicles[i].ConfigVehicleType.Name,
              VehicleType: this.contractorVehicles[i].ConfigVehicleType.Name,
            }
            this.filteredContractorVehicles.push(temp);
          }
          for (var j = 0; j < this.filteredContractorVehicles.length; j++) {
            for (var k = 0; k < this.allVehicles.length; k++) {
              if (this.allVehicles[k].vehicleRegNo == this.filteredContractorVehicles[j].vehicleRegNo) {
                var temp2 = {
                  vehicleRegNo: this.filteredContractorVehicles[j].vehicleRegNo,
                  vehicleId: this.allVehicles[k].vehicleId,
                  VehicleType: this.filteredContractorVehicles[j].VehicleType,
                  ignition: this.allVehicles[k].ignition,
                  motion: this.allVehicles[k].motion,
                  speed: this.allVehicles[k].speed,
                  deviceId: this.allVehicles[k].deviceId,
                  latitude: this.allVehicles[k].latitude,
                  longitude: this.allVehicles[k].longitude,
                  vehicleStatus: null,
                  address: this.allVehicles[k].address,
                  deviceTime: this.allVehicles[k].deviceTime,
                  contact: this.allVehicles[k].contact,
                  model: this.allVehicles[k].model,
                  category: this.allVehicles[k].category,
                }
                this.cntrctVehiclesData.push(temp2);
              }
            }
          }
          for (var i = 0; i < this.cntrctVehiclesData.length; i++) {

            this.cntrctVehiclesData[i].lastUpdate2 = this.checkTimeDiff(this.allVehicles[i].lastUpdate);

            if ((this.cntrctVehiclesData[i].status == 'online')) {
              this.cntrctVehiclesData[i].vehicleStatus = this.onlineIcon2;
              this.onlineVehicles.push(this.cntrctVehiclesData[i]);
            }
            else if (this.cntrctVehiclesData[i].status == 'offline') {
              this.cntrctVehiclesData[i].vehicleStatus = this.offlineIcon2;
              this.offlineVehicles.push(this.cntrctVehiclesData[i]);
            }
            else if (this.cntrctVehiclesData[i].status == 'unknown') {
              this.cntrctVehiclesData[i].vehicleStatus = this.signalLostIcon2;
              this.signalLostVehicles.push(this.cntrctVehiclesData[i]);
            }
            else {
              //if (this.checkTime(this.allVehicles[i]) > 20) {
                this.cntrctVehiclesData[i].vehicleStatus = this.idleIcon2;
                this.idleVehicles.push(this.cntrctVehiclesData[i]);
            }

         
          }
          this.vehiclesToDisplay = this.cntrctVehiclesData;
          this.CntrctVehResults = [...this.onlineVehicles, ...this.idleVehicles, ...this.offlineVehicles, ...this.signalLostVehicles];
          //localStorage.setItem('vehiclesData', JSON.stringify(this.CntrctVehResults));
          this._appSharedService.apiVehicleDetails(this.CntrctVehResults);
          this._appSharedService.toggleVehiclesView(false);
          this.filterVehiclesCount(this.CntrctVehResults);
          this.showLoader = false;
        }
        else {
          this.vehiclesToDisplay = [];
          this.showLoader = false;
        }
      }),
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
        this.showLoader = false;
      }
    );
  }

  checkAdminStatus(vehicleId: number): string {
    var vehicleDetails = this.allVehicles.filter(x => x.vehicleId == vehicleId)[0];
   
    if ((vehicleDetails.status == 'online')) {
    
      return 'online-bg';
    }
    else if (vehicleDetails.status == 'offline') {
     
      return 'offline-bg';
    }
    else if (vehicleDetails.status == 'unknown') {
      
      return 'signal-lost-bg';

    }
    else {
     
      return 'ldle-bg';
    }

    
  }

  checkCntrctrStatus(vehicleId: number): string {
    var vehicleDetails = this.cntrctVehiclesData.filter(x => x.vehicleId == vehicleId)[0];
    if ((vehicleDetails.status == 'online')) {
    
      return 'online-bg';
    }
    else if (vehicleDetails.status == 'offline') {
     
      return 'offline-bg';
    }
    else if (vehicleDetails.status == 'unknown') {
      
      return 'signal-lost-bg';

    }
    else {
     
      return 'ldle-bg';
    }
    
  }

  printDate(e: Date) {
    this.scheduleDate = formatDate(e, 'yyyy-MM-dd', 'en-US');
  }

  getSchedules(vehicleRegNo: any) {
    this.vehiInfos = false;
    this.vehiFullInfos = false;
    this.routeDate = this.scheduleDate;
    var data = {
      vehicleRegNo: vehicleRegNo,
      date: this.scheduleDate,
    }
    this._appSharedService.showBinsSchedules(data);
  }

  checkTime(data: any) {
    this.date2 = new Date(formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US'));
    this.lastUpdated = new Date((formatDate(data.deviceTime, 'yyyy-MM-dd hh:mm', 'en-US')));
    var diff = Math.abs((this.lastUpdated - this.date2) / (60 * 1000));
    return diff;
  }

  checkTimeDiff2(date: Date) {

    var localDate = new Date(date);

    //let lastUpdated:any = new Date((formatDate(date, 'yyyy-MM-dd hh:mm', 'en-US')));

    //const dateNew = new Date((formatDate(date, 'yyyy-MM-dd hh:mm', 'en-US')));
    //const h = 8
    //dateNew.setTime(dateNew.getTime() + (h * 60 * 60 * 1000))
    
    return localDate;
  }

  checkTimeDiff(vehicle: any) {


    if(vehicle != '' && vehicle != null)
    {
      
      let lastUpdated:any = new Date(vehicle);
      let currentDate:any = new Date();
      let diffMs = (currentDate - lastUpdated); // milliseconds
      let diffDays = Math.floor(diffMs / 86400000) // days
      let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return (diffDays > 1)? (diffDays + " day(s) ago "): ((diffDays == 0 && diffHrs > 0)?(diffHrs + " hour(s) ago, "): ((diffMins + " minute(s) ago")));


    }
    else
    {
      
      return '';
    }

    
    
    //return valueDiff;
  }

  filterVehiclesCount(data: any) {

    this.idleVehiclesCount2 = data.filter((x: { status: string; speed: number; contact: any; idle: any}) => (x.idle != null)).length;
    this.speedVehiclesCount2 = data.filter((x: { status: string; speed: number; contact: any}) => (x.speed*1.852 > 80)).length;
 

    if(this.selectedCompanySchedule != null)
    {
      let company = '';
      if(this.selectedCompanySchedule.company.toString() == 'ALAM FLORA SDN BHD')
          company = 'AFSB';
      else
          company = this.selectedCompanySchedule.company;

      this.allVehiclesCount = data.filter((x: { status: string; speed: number; contact: any}) => (x.contact == company)).length;
      this.onlineVehiclesCount = data.filter((x: { status: string; speed: number; contact: any}) => (x.status == 'online') && (x.contact == company)).length;
      this.offlineVehiclesCount = data.filter((x: { status: string; contact: any }) => x.status == 'offline' && (x.contact == company)).length;
      this.signalLostVehiclesCount = data.filter((x: { status: string; contact: any }) => x.status == 'unknown' && (x.contact == company)).length;
      this.idleVehiclesCount = this.idleVeh.length;  
    }
    else if(this.selectedVehicleSchedule != null)
    {

      this.allVehiclesCount = data.filter((x: { status: string; speed: number; vehicleRegNo: string}) => (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)).length;
      this.onlineVehiclesCount = data.filter((x: { status: string; speed: number; vehicleRegNo: string}) => (x.status == 'online') && (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)).length;
      this.offlineVehiclesCount = data.filter((x: { status: string; vehicleRegNo: string }) => x.status == 'offline' && (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)).length;
      this.signalLostVehiclesCount = data.filter((x: { status: string; vehicleRegNo: string }) => x.status == 'unknown' && (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)).length;
      this.idleVehiclesCount = this.idleVeh.length;  
    

    }
  
    else if(this.selectedRouteSchedule != null)
    {
       
      this.allVehiclesCount = data.filter((x: { status: string; speed: number; routeName: any}) => (x.routeName.toString().match(this.selectedRouteSchedule.main_route))).length;
      this.onlineVehiclesCount = data.filter((x: { status: string; speed: number; routeName: any}) => (x.status == 'online') && (x.routeName.toString().match(this.selectedRouteSchedule.main_route))).length;
      this.offlineVehiclesCount = data.filter((x: { status: string; routeName: any }) => x.status == 'offline' && (x.routeName.toString().match(this.selectedRouteSchedule.main_route))).length;
      this.signalLostVehiclesCount = data.filter((x: { status: string; routeName: any }) => x.status == 'unknown' && (x.routeName.toString().match(this.selectedRouteSchedule.main_route))).length;
      this.idleVehiclesCount = this.idleVeh.length;    
    }
    else
    {

      this.allVehiclesCount = data.length;
      this.onlineVehiclesCount = data.filter((x: { status: string; speed: number; }) => (x.status == 'online')).length;
      this.offlineVehiclesCount = data.filter((x: { status: string }) => x.status == 'offline').length;
      this.signalLostVehiclesCount = data.filter((x: { status: string }) => x.status == 'unknown').length;
      this.idleVehiclesCount = this.idleVeh.length;

    }
          
    //this.idleVeh = data.filter((x: { status: string; speed: number; }) => (x.status != 'online') && (x.status != 'offline') && (x.status != 'unknown'));
    //for (var i = 0; i < data.length; i++) {
    //  if (data[i].ignition == true && data[i].speed == 0) {
    //    if (this.checkTime(data[i]) > 10) {
    //      this.signalLostVeh.push(data[i]);
    //    }
    //    else {
    //      this.idleVeh.push(data[i]);
    //    }
    //  }
    //}
    
  }

  tabsMove() {
    this.tabsHide = !this.tabsHide;
  }

  tabsMove2() {
    this.tabsHide = !this.tabsHide;
  }

  startVehicle(vehicleId: any) {
    this.vehiFullInfos = false;
    this.vehiInfos = false;
    this.closeVehicleStatu = false;
    this.router.navigate(["/route"]);
  }

  closeHelpTopic() {
    this.closeVehicleStatu = false;
    this._appSharedService.closeSidebarView(0);

  }

  showHelpTopic() {
    this._appSharedService.change.subscribe(val => {
      this.closeVehicleStatu = val;
    })
  }

  vehicleFullDetails() {
    this.vehiFullInfos = true;
  }

  closeVehDetails() {
    this.vehiInfos = false;
    this.closeStatus = false;
  }

  closeVehFull() {
    this.vehiFullInfos = false;
  }

  searchVehicles(searchValue: any) {
    this.searchValue = searchValue.target != null ? searchValue.target.value : searchValue;
    if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
      if (this.searchValue) {
        this.vehiclesToDisplay = this.allVehicles.filter(x => x.vehicleRegNo.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          x.contact.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          x.model.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          (x.speed2 != null ? x.speed2.toLowerCase().includes(this.searchValue.toLowerCase()): false) ||
          (x.idle != null ? x.idle.toLowerCase().includes(this.searchValue.toLowerCase()): false) ||
          (x.routeName != null ? x.routeName.toLowerCase().includes(this.searchValue.toLowerCase()) : true)
        );
        this._appSharedService.apiVehicleDetails(this.vehiclesToDisplay);
        this._appSharedService.toggleVehiclesView(true);
      }
      else
        this.vehiclesToDisplay = this.allVehicles;
    }
    else {
      if (this.searchValue) {
        this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.vehicleRegNo.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          x.contact.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          x.model.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          (x.speed2 != null ? x.speed2.toLowerCase().includes(this.searchValue.toLowerCase()): false) ||
          (x.idle != null ? x.idle.toLowerCase().includes(this.searchValue.toLowerCase()): false) ||
          (x.routeName != null ? x.routeName.toLowerCase().includes(this.searchValue.toLowerCase()) : true)
        );
        this._appSharedService.apiVehicleDetails(this.vehiclesToDisplay);
        this._appSharedService.toggleVehiclesView(true);
      }
      else
        this.vehiclesToDisplay = this.cntrctVehiclesData;
    }
  }

  clearFilters() {
    this.selectedVehicleId = [];
    this.selectedVehicleRegNo = [];
    this.selectedDeviceId = [];
    this.selectedVehicleType = [];
    this.filterDialogBox = false;
    this.isCompanyFilterShow = false;
    this.isVehicleFilterShow = false;
    this.isRouteFilterShow = false;
    this.isSubRouteFilterShow = false;
    this.selectedCompanySchedule = null;
    this.selectedVehicleSchedule = null;
    this.selectedRouteSchedule = null;
    this.selectedSubRouteSchedule = null;
    this.selectedtempCompanySchedule = null;
    this.selectedtempVehicleSchedule = null;
    this.selectedtempRouteSchedule = null;
    this.selectedtempSubRouteSchedule = null;
    this.selectedFilter = '';
    this.selectedtempFilter = '';
    this.filterVehiclesToDisplay('all', false);
    this.getcollectedBins();
  }

  @ViewChild('scrollableY') input!: ElementRef;
  onWheel(event: WheelEvent): void {
    this.input.nativeElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }

  filterVehiclesToDisplay(vehicleStatus: string, isBackgroundCall: boolean = false) {
    this.searchValue = [];
    this.currentSelectedVehicleStatus = vehicleStatus;
    if (!isBackgroundCall)
      this._appSharedService.showAllVehicles(true);
    if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {

      let company = "";
      if(this.selectedCompanySchedule != null)
      {
       
        if(this.selectedCompanySchedule.company.toString() == 'ALAM FLORA SDN BHD')
          company = 'AFSB';
        else
          company = this.selectedCompanySchedule.company;
      }

      this.allVehiclesToFilter = this.allVehicles;

      switch (vehicleStatus) {
        case 'all': {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.contact == company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.allVehiclesToFilter;

          break;
        }
        case 'online': {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'online' && x.contact == company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'online' && x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'online' && x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'online'));
          
          break;
        }
        case 'offline': {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'offline' && x.contact == company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'offline' && x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'offline' && x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => x.status == 'offline');
          break;
        }
        /*case 'idle': {
        //  this.vehiclesToDisplay = this.filterVehiclesData(this.allVehicles.filter(x => (x.status == 'online') && (x.speed == 0 || x.speed == '' || x.speed == null)));
        //  break;
        }*/
        case 'signalLost': {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'unknown' && x.contact == company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'unknown' && x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'unknown' && x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.status == 'unknown'));
          break;
        }
        default: {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.contact == company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.allVehiclesToFilter;
        }
      }
      this._appSharedService.apiVehicleDetails(this.vehiclesToDisplay);
      this._appSharedService.toggleVehiclesView(false);

    }
    else {
      switch (vehicleStatus) {
        case 'all': {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.contact == this.selectedCompanySchedule.company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.cntrctVehiclesData;
          
          break;
        }
        case 'online': {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'online' && (x.contact == this.selectedCompanySchedule.company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'online' && (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'online' && (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.status == 'online'));
          
          break;
        }
        case 'offline': {

          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'offline' && (x.contact == this.selectedCompanySchedule.company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'offline' && (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'offline' && (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'offline');
        
          
          break;
        }
        /*case 'idle': {
          this.vehiclesToDisplay = this.idleVeh;
          break;
        }**/
        case 'signalLost': {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'unknown' && (x.contact == this.selectedCompanySchedule.company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'unknown' && (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => x.status == 'unknown' && (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.status == 'unknown'));
          
          break;
        }
        default: {
          if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.contact == this.selectedCompanySchedule.company));
          else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
          else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
          else
            this.vehiclesToDisplay = this.cntrctVehiclesData;
         
        }
      }
      this._appSharedService.apiVehicleDetails(this.vehiclesToDisplay);
      this._appSharedService.toggleVehiclesView(false);
    }
  }

  filterVehiclesToDisplayAdv() {
    
  
    
    this._appSharedService.showAllVehicles(true);
    if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {

      let company = "";
      if(this.selectedCompanySchedule != null)
      {
       
        if(this.selectedCompanySchedule.company.toString() == 'ALAM FLORA SDN BHD')
          company = 'AFSB';
        else
          company = this.selectedCompanySchedule.company;
      }
      

      this.allVehiclesToFilter = this.allVehicles;

      if(this.selectedCompanySchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.contact == company));
      else if(this.selectedVehicleSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
      else if(this.selectedRouteSchedule != null)
            this.vehiclesToDisplay = this.allVehiclesToFilter.filter(x => (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
      else
            this.vehiclesToDisplay = this.allVehiclesToFilter;

      this._appSharedService.apiVehicleDetails(this.vehiclesToDisplay);
      this._appSharedService.toggleVehiclesView(false);

    }
    else{
      if(this.selectedCompanySchedule != null)
        this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.contact == this.selectedCompanySchedule.company));
      else if(this.selectedVehicleSchedule != null)
        this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.vehicleRegNo == this.selectedVehicleSchedule.vehicle_no)); 
      else if(this.selectedRouteSchedule != null)
        this.vehiclesToDisplay = this.cntrctVehiclesData.filter(x => (x.routeName.toString().match(this.selectedRouteSchedule.main_route)));     
      else
        this.vehiclesToDisplay = this.cntrctVehiclesData;

      this._appSharedService.apiVehicleDetails(this.vehiclesToDisplay);
      this._appSharedService.toggleVehiclesView(false);
    }
    this.filterVehiclesCount(this.vehiclesToDisplay);
  }


  vehicleDetails(vehicleId: any, vehicleRegno: any, i: any) {
    
    this.progressBar = true;
    this.progressBarBinStatus = true;
    this.showLoader2 = true;

    this.vehicleSummary = [];
    this.vehicleInfos = this.vehiclesToDisplay.filter(x => x.vehicleId == vehicleId)[0];
    this.loadPosition(this.vehicleInfos);

    //Added 05/09/2022
    var dataVehicle = {
      vehicleId: vehicleId,
      date: this.scheduleDate,
    }
    this._appSharedService.showVehicleHistoryLog(dataVehicle);
 
   
    this.progressBar = false;
    
    //getVehicleSummary
    //this.commonService.getVehicleSummary(vehicleRegno).subscribe((resp: any) => {
    //  if (resp.length != 0) {
    //    this.vehicleSummary = resp[0];
    //    this.progressBar = false;
    //  } else {
    //    this.progressBar = false;
    //  }
    //}), (error: any) => {
    //  this._appSharedService.showError('Error', "Something went wrong!");
    //}
    //getBinStatus
    //var selectedRowVehicled = {
    //  vehicle_no: vehicleRegno
    //} 
    //this.selectedVehicleSchedule = selectedRowVehicled;
    this._appSharedService.resetBinsSchedules(null);
    this._appSharedService.showAllVehicles(false);
    this._appSharedService.moveMapLocation(this.vehicleInfos);
    this.vehiInfos = true;
    this.closeStatus = true;
    this.vehiFullInfos = false;
    this.vehcleStatus = false;
    this.vihicleNotOnline = false;
    this.activeShowIndex = i;

    

    this.commonService.getBinTotalSummaryVehicleByDate(this.scheduleDate,vehicleRegno,null).subscribe((resp: any) => {
      if (resp.length != 0) {
        
         this.binsStatus = resp.Result;
         this.binCollectedSummary = resp.Result;
         this.setCollectionSummary();
         this.progressBarBinStatus = false;
         this.showLoader2 = false;
         
       } else {
        
        this.progressBarBinStatus = false;
        this.showLoader2 = false;
       }
     }), (error: any) => {
       this._appSharedService.showError('Error', "Something went wrong!");
     }
    
  }

  loadPosition(value: any)
  {

    var historyLog = this.commonService.vehiclePosition(value.positionId).subscribe((data) => {
      if(data != undefined)
      {
        this.updateVehicleAddress(data[0])
        
        // if (this.allVehicles.filter(x => x.deviceId == data[0].deviceId.toString())[0] != undefined) {

        //   var objIndex = this.allVehicles.findIndex((obj => obj.deviceId == data[0].deviceId.toString()));
        //   this.allVehicles[objIndex].latitude = data[0].latitude;
        //   this.allVehicles[objIndex].longitude = data[0].longitude;
        //   this.allVehicles[objIndex].address = data[0].address;
          
        // }
      }
    });
    
  }

  vehicleStatusTab() {
    this.vehcleStatus = !this.vehcleStatus;
    this.vehiFullInfos = false;
  }

  showFilterDialog() {
    this.filterDialogBox = true;
  }

  updateVehicleInfo(value: any) {

    if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {

      if(value.positions != undefined)
      {
        

        if (this.allVehicles.filter(x => x.deviceId == value.positions[0].deviceId.toString())[0] != undefined) {

          var objIndex = this.allVehicles.findIndex((obj => obj.deviceId == value.positions[0].deviceId.toString()));

          this.allVehicles[objIndex].latitude = value.positions[0].latitude;
          this.allVehicles[objIndex].longitude = value.positions[0].longitude;
          this.allVehicles[objIndex].ignition = value.positions[0].attributes.ignition;
          this.allVehicles[objIndex].speed = value.positions[0].speed;
          this.allVehicles[objIndex].deviceTime = value.positions[0].deviceTime;
         
          if(((value.positions[0].speed) *(1.852)) > 80){

            //console.log((value.positions[0].speed) *(1.852));
            this.allVehicles[objIndex].speed2 = 'Speeding ' +((value.positions[0].speed) *(1.852)).toFixed(2);

            //this.allVehicles[objIndex].speed2 = this.checkSpeed(value.positions[0].speed);
            //  this._appSharedService.showStickyError('Speeding > 80km/h!', this.allVehicles[objIndex].vehicleRegNo + ": "+ this.allVehicles[objIndex].contact+ " " +((value.positions[0].speed) *(1.852)).toFixed(2) +"km/h");
              //this._appSharedService.showStickyError("Speeding Capture!", this.allVehicles[objIndex].vehicleRegNo + " " +(value.positions[0].speed) *(1.852) +"km/h");
          }
          else
          {
            this.allVehicles[objIndex].speed2 = null;
          }

          if(((value.positions[0].speed) *(1.852)) < 1 && value.positions[0].attributes.distance > 0){
            //console.log(value.positions[0])

            // if(value.positions[0].deviceId == 1097)
            //    console.log(value.positions[0]);
            this.allVehicles[objIndex].idle = 'Idle ' + ((value.positions[0].speed) *(1.852)).toFixed(2);

            //this.allVehicles[objIndex].speed2 = this.checkSpeed(value.positions[0].speed);
            //  this._appSharedService.showStickyError('Speeding > 80km/h!', this.allVehicles[objIndex].vehicleRegNo + ": "+ this.allVehicles[objIndex].contact+ " " +((value.positions[0].speed) *(1.852)).toFixed(2) +"km/h");
              //this._appSharedService.showStickyError("Speeding Capture!", this.allVehicles[objIndex].vehicleRegNo + " " +(value.positions[0].speed) *(1.852) +"km/h");
          }
          else
          {
            this.allVehicles[objIndex].idle = null;
          }

          this.filterVehiclesCount(this.allVehicles);
          this.filterVehiclesToDisplay(this.currentSelectedVehicleStatus, true);

          
          

        }
        
      }

      if(value.devices != undefined)
      {
        //console.log(value.devices[0]);

        if (this.allVehicles.filter(x => x.deviceId == value.devices[0].id.toString())[0] != undefined) {

          var objIndex = this.allVehicles.findIndex((obj => obj.deviceId == value.devices[0].id.toString()));

          this.allVehicles[objIndex].status = value.devices[0].status;
          this.allVehicles[objIndex].vehicleRegNo = value.devices[0].name;
          this.allVehicles[objIndex].model = value.devices[0].model;
          this.allVehicles[objIndex].category = value.devices[0].category;
          this.allVehicles[objIndex].contact = value.devices[0].contact;
          this.allVehicles[objIndex].lastUpdate2 = this.checkTimeDiff(value.devices[0].lastUpdate);
          //console.log(value.devices[0].name,this.checkTimeDiff(value.devices[0].lastUpdate));

          if ((value.devices[0].status == 'online')) {
            this.allVehicles[objIndex].vehicleStatus = this.onlineIcon2;
            // this.onlineVehicles.push(this.allVehicles[i]);
          }
          else if (value.devices[0].status == 'offline') {
            this.allVehicles[objIndex].vehicleStatus = this.offlineIcon2;
            //this.offlineVehicles.push(this.allVehicles[i]);
          }
          else if (value.devices[0].status == 'unknown') {
            this.allVehicles[objIndex].vehicleStatus = this.signalLostIcon2;
            //this.signalLostVehicles.push(this.allVehicles[i]);
          }
          else if(value.devices[0].speed2 != null) {
            this.allVehicles[objIndex].vehicleStatus = this.idleIcon2;
              // this.idleVehicles.push(this.allVehicles[i]);
          }
         
          this.filterVehiclesCount(this.allVehicles);
          this.filterVehiclesToDisplay(this.currentSelectedVehicleStatus, true);

       
        }
        
      }

    }
    else{

      

      if(value.positions != undefined)
      {
       

        if (this.cntrctVehiclesData.filter(x => x.deviceId == value.positions[0].deviceId)[0] != undefined) {

          var objIndex = this.cntrctVehiclesData.findIndex((obj => obj.deviceId == value.positions[0].deviceId.toString()));

          this.cntrctVehiclesData[objIndex].latitude = value.positions[0].latitude;
          this.cntrctVehiclesData[objIndex].longitude = value.positions[0].longitude;
          this.cntrctVehiclesData[objIndex].ignition = value.positions[0].attributes.ignition;
          this.cntrctVehiclesData[objIndex].speed = value.positions[0].speed;
          this.cntrctVehiclesData[objIndex].deviceTime = value.positions[0].deviceTime;

          this.filterVehiclesCount(this.cntrctVehiclesData);
          this.filterVehiclesToDisplay(this.currentSelectedVehicleStatus, true);


          //if((value.positions[0].speed) *(1.852) > 81){
            
            //this._appSharedService.showStickyError('Speeding > 80km/h!', this.cntrctVehiclesData.filter(x => x.deviceId == value.positions[0].deviceId)[0].vehicleRegNo + ": "+ this.cntrctVehiclesData.filter(x => x.deviceId == value.positions[0].deviceId)[0].contact+ " " +((value.positions[0].speed) *(1.852)).toFixed(2) +"km/h");
            //this._appSharedService.showStickyError("Speeding Capture!", this.allVehicles[objIndex].vehicleRegNo + " " +(value.positions[0].speed) *(1.852) +"km/h");
         // }
         
          
        }
        
      }

      if(value.devices != undefined)
      {

        if (this.cntrctVehiclesData.filter(x => x.deviceId == value.devices[0].id)[0] != undefined) {

          var objIndex = this.cntrctVehiclesData.findIndex((obj => obj.deviceId == value.devices[0].id.toString()));

          this.cntrctVehiclesData[objIndex].status = value.devices[0].status;
          this.cntrctVehiclesData[objIndex].vehicleRegNo = value.devices[0].name;
          this.cntrctVehiclesData[objIndex].model = value.devices[0].model;
          this.cntrctVehiclesData[objIndex].category = value.devices[0].category;
          this.cntrctVehiclesData[objIndex].contact = value.devices[0].contact;
          this.cntrctVehiclesData[objIndex].lastUpdate2 = this.checkTimeDiff(value.devices[0].lastUpdate);
        
          this.filterVehiclesCount(this.cntrctVehiclesData);
          this.filterVehiclesToDisplay(this.currentSelectedVehicleStatus, true);
        }
        
      }


    }

  }

  updateVehicleAddress(value: any){

   
    if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {

      if (this.allVehicles.filter(x => x.deviceId == value.deviceId.toString())[0] != undefined) {

        var objIndex = this.allVehicles.findIndex((obj => obj.deviceId == value.deviceId.toString()));
        this.allVehicles[objIndex].latitude = value.latitude;
        this.allVehicles[objIndex].longitude = value.longitude;
        this.allVehicles[objIndex].address = value.address;

        this.vehicleInfos.latitude = value.latitude;
        this.vehicleInfos.longitude = value.longitude;
        this.vehicleInfos.address = value.address;

        if ((this.allVehicles[objIndex].status == 'online')) {
          this.allVehicles[objIndex].vehicleStatus = this.onlineIcon2;
          this.vehicleInfos.vehicleStatus = this.onlineIcon2;
        }
        else if (this.allVehicles[objIndex].status == 'offline') {
          this.allVehicles[objIndex].vehicleStatus = this.offlineIcon2;
          this.vehicleInfos.vehicleStatus = this.offlineIcon2;
        }
        else if (this.allVehicles[objIndex].status == 'unknown') {
          this.allVehicles[objIndex].vehicleStatus = this.signalLostIcon2;
          this.vehicleInfos.vehicleStatus = this.signalLostIcon2;
        }
        else {               
          this.allVehicles[objIndex].vehicleStatus = this.idleIcon2;
          this.vehicleInfos.vehicleStatus = this.idleIcon2;
        }


        

        
        
      }
    }
    else
    {
      
     

        var objIndex = this.cntrctVehiclesData.findIndex((obj => obj.deviceId == value.deviceId.toString()));
      
        this.cntrctVehiclesData[objIndex].latitude = value.latitude;
        this.cntrctVehiclesData[objIndex].longitude = value.longitude;
        this.cntrctVehiclesData[objIndex].address = value.address;

        this.vehicleInfos.latitude = value.latitude;
        this.vehicleInfos.longitude = value.longitude;
        this.vehicleInfos.address = value.address;

        if ((this.cntrctVehiclesData[objIndex].status == 'online')) {
          this.cntrctVehiclesData[objIndex].vehicleStatus = this.onlineIcon2;
          this.vehicleInfos.vehicleStatus = this.onlineIcon2;
        }
        else if (this.cntrctVehiclesData[objIndex].status == 'offline') {
          this.cntrctVehiclesData[objIndex].vehicleStatus = this.offlineIcon2;
          this.vehicleInfos.vehicleStatus = this.offlineIcon2;
        }
        else if (this.cntrctVehiclesData[objIndex].status == 'unknown') {
          this.cntrctVehiclesData[objIndex].vehicleStatus = this.signalLostIcon2;
          this.vehicleInfos.vehicleStatus = this.signalLostIcon2;
        }
        else {               
          this.cntrctVehiclesData[objIndex].vehicleStatus = this.idleIcon2;
          this.vehicleInfos.vehicleStatus = this.idleIcon2;
        }

        
        
      //}
    }
    
  }

  clearSearch() {
    this.searchValue = [];
    this.getAllVehicles();
    this.filterVehiclesToDisplay(this.currentSelectedVehicleStatus);
  }
  vehicleCoutBox() {
    this.vehicleMoving = !this.vehicleMoving;
    this._appSharedService.closeSidebarView(0);
  }
  dragPin() {
    this.dragaable = !this.dragaable;
    this.pinActive = !this.pinActive;
  }

  checkEdge(event: { top: boolean; bottom: boolean; left: boolean; right: boolean; }) {
    this.edge = event;
  }

  applyFilter(){

    this.vehiInfos = false;
    this.selectedFilter = this.selectedtempFilter;
    this.selectedCompanySchedule = this.selectedtempCompanySchedule;
    this.selectedVehicleSchedule = this.selectedtempVehicleSchedule;
    this.selectedRouteSchedule = this.selectedtempRouteSchedule;
    this.selectedSubRouteSchedule = this.selectedtempSubRouteSchedule;
  
    this.filterVehiclesToDisplayAdv();
    this.getcollectedBins();
  }
  getcollectedBins() {
    this.showLoader2 = true;
    
    if(this.selectedCompanySchedule != null)
    {
      this.commonService.getBinTotalSummaryCompanyByDate(this.scheduleDate,this.selectedCompanySchedule.company_id).subscribe((resp: any) => {
        if (resp.Total != 0) {
          this.binCollectedSummary = resp.Result;
          this.setCollectionSummary();
          this.showLoader2 = false;
        } else { this.showLoader2 = false; }
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }

    }
    else if(this.selectedVehicleSchedule != null)
    {
      this.commonService.getBinTotalSummaryVehicleByDate(this.scheduleDate,this.selectedVehicleSchedule.vehicle_no,null).subscribe((resp: any) => {
        if (resp.Total != 0) {
          this.binCollectedSummary = resp.Result;
          this.setCollectionSummary();
          this.showLoader2 = false;
        } else { this.showLoader2 = false; }
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }

    }
    else if(this.selectedRouteSchedule != null)
    {
      
      this.commonService.getBinTotalSummaryRouteByDate(this.scheduleDate,this.selectedRouteSchedule.main_route,"").subscribe((resp: any) => {
        if (resp.Total != 0) {
          this.binCollectedSummary = resp.Result;
          console.log(this.binCollectedSummary);
          this.setCollectionSummary();
          this.showLoader2 = false;
        } else { this.showLoader2 = false; }
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }

    }
    else if(this.selectedSubRouteSchedule != null)
    {
      this.commonService.getBinTotalSummarySubRouteByDate(this.scheduleDate,this.selectedSubRouteSchedule.sub_route).subscribe((resp: any) => {
        if (resp.Total != 0) {
          this.binCollectedSummary = resp.Result;
          this.setCollectionSummary();
          this.showLoader2 = false;
        } else { this.showLoader2 = false; }
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }

    }
    else
    {
      this.commonService.getBinTotalSummaryByDate(this.scheduleDate).subscribe((resp: any) => {
        if (resp.Total != 0) {
          this.binCollectedSummary = resp.Result;
          this.setCollectionSummary();
          this.showLoader2 = false;
        } else { this.showLoader2 = false; }
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }
    }
    
  }

  companyFilterResetFunction(options: any) {
    options.reset();
    this.filterCompanyScheduleValue = '';
  }

  onStatusChangeCompany(event: any)
  {
    console.log(event);
  }
  onChangeCompanyFilter(event: any) {
   
    this.selectedCompanySchedule = null;
    this.selectedtempCompanySchedule = event.value;

    //this.selectedCompany = event2.value;
    //this.ddrVehiclesData = this.allVehicles.filter(x => x.contact === event2.value);
  }
  onChangeVehicleFilter(event: any) {
   
    this.selectedtempVehicleSchedule = event.value;
    console.log(this.selectedtempVehicleSchedule);
    //this.selectedCompany = event2.value;
    //this.ddrVehiclesData = this.allVehicles.filter(x => x.contact === event2.value);
  }
  onChangeRoutesFilters(event: any) {
   
    this.selectedtempRouteSchedule = event.value;
    
  }
  onChangeSubRouteFilter(event: any) {

    this.selectedtempSubRouteSchedule = event.value;
    
  }

  selectByFilter(event: any) {
    
    this.isCompanyFilterShow = false;
    this.isVehicleFilterShow = false;
    this.isRouteFilterShow = false;
    this.isSubRouteFilterShow = false;
    this.selectedCompanySchedule = null;
    this.selectedVehicleSchedule = null;
    this.selectedRouteSchedule = null;
    this.selectedSubRouteSchedule = null;
    this.selectedtempCompanySchedule = null;
    this.selectedtempVehicleSchedule = null;
    this.selectedtempRouteSchedule = null;
    this.selectedtempSubRouteSchedule = null;
    this.selectedFilter = '';
    this.selectedtempFilter = '';
    if(event.value != null)
    {
      if (event.value.key == 'Company') {
        this.isCompanyFilterShow = true;    
      } 
      else if (event.value.key == 'Vehicle') {
        this.isVehicleFilterShow = true;
      }
      else if (event.value.key == 'Route') {
        this.isRouteFilterShow = true;
      }
      else if (event.value.key == 'SubRoute') {
        this.isSubRouteFilterShow = true;
      }
      this.selectedtempFilter = event;
     

    }
 
  }

  

  setCollectionSummary() {


    if (this.btnKIIsActive && this.btnKBIsActive) {

     
      this.collectedBins = this.binCollectedSummary.TotalCollectionBin;
      this.ongoingBins = this.binCollectedSummary.TotalOngoingBin;
      this.totalBins = this.binCollectedSummary.TotalBin;
      this.outputBinPercentage = this.binCollectedSummary.PercentageComplete;
      
    }
    else if (this.btnKIIsActive && !this.btnKBIsActive) {
     this.collectedBins = this.binCollectedSummary.TotalCollectionBinKI;
     this.ongoingBins = this.binCollectedSummary.TotalOngoingBinKI;
     this.totalBins = this.binCollectedSummary.TotalBinKI;
     this.outputBinPercentage = this.binCollectedSummary.PercentageCompleteKI;

    }
    else if (!this.btnKIIsActive && this.btnKBIsActive) {
      this.collectedBins = this.binCollectedSummary.TotalCollectionBinKB;
      this.ongoingBins = this.binCollectedSummary.TotalOngoingBinKB;
      this.totalBins = this.binCollectedSummary.TotalBinKB;
      this.outputBinPercentage = this.binCollectedSummary.PercentageCompleteKB;
 
     }

    // this.filterVehiclesCount(this.allVehicles);
    // this.filterVehiclesToDisplay('all', true);
  }

  checkBinKIButton(){

    if(this.btnKIIsActive)
      this.btnKIIsActive = false;
    else
      this.btnKIIsActive = true;

    this.setCollectionSummary();
  }

  checkBinKBButton(){

    if(this.btnKBIsActive)
      this.btnKBIsActive = false;
    else
      this.btnKBIsActive = true;

    this.setCollectionSummary();
  }

  convertToUpperCase(data:any){
    return data.toString().toUpperCase()+":";
  }

  

}

