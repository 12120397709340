import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SidebarModule} from 'primeng/sidebar';



import { CookieService } from 'ngx-cookie-service';


@NgModule({
  declarations: [
  ],
  imports: [ButtonModule, RippleModule, TableModule, DropdownModule, CalendarModule,
     FormsModule, ReactiveFormsModule, DialogModule, MenuModule, InputTextModule, ScrollPanelModule,
    MultiSelectModule, CheckboxModule,SidebarModule
  ],
  exports: [ButtonModule, RippleModule, TableModule, DropdownModule, CalendarModule,
    FormsModule, ReactiveFormsModule, DialogModule, MenuModule, InputTextModule, ScrollPanelModule,
    MultiSelectModule, CheckboxModule,SidebarModule
  ],
  providers: [
     CookieService, 
  ]
})

export class AppSharedModule { }
