<div class="w-100 vh-100 d-flex">
  <div class="w-100 h-100 z-index-11 bin-details-bg">
    <p-scrollPanel styleClass="h-100">
      <div class="w-100" style="padding: 20px 50px;">
        <!--breadcrumb-->
        <div class="w-100 breadcrumb-bins">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent mb-0 p-0">
              <li class="breadcrumb-item"><a class="text-decoration-none" routerLink="/route">Routing and reports</a></li>
              <li class="breadcrumb-item active" aria-current="page">Schedule bin details</li>
            </ol>
          </nav>
        </div>
        <div class="w-100 sticky-position z-index-11">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent mb-0 px-0 py-3">
              <li class="breadcrumb-item"><a class="d-flex align-items-center fs-12 text-decoration-none" routerLink="/route"><i class="pi pi-arrow-left fs-12"></i><div class="pl-2">Back</div></a></li>
            </ol>
          </nav>
        </div>
        <!----->
        <div class="">
          <div class="w-100 fs-12 schedulIdsTitles txt-gry">Schedule ID</div>
          <div class="w-100 d-flex align-items-center py-2">
            <div class="schedulIds pr-3 txt-black">64442718</div>
            <div class="schedulStatus rounded-pill px-3 py-1">Ongoing</div>
          </div>
          <div class="w-100 d-flex fs-12 schedulDate align-items-center">
            <div class="pr-3">
              <img src="./assets/images/web-112021/icons/icon-calendar.png" />
            </div>
            <div class="">01 Oct 2021</div>
          </div>
        </div>
        <!----->
        <div class="w-100 py-3">
          <div class="bg-white w-100 p-3 rounded">
            <div class="row">
              <div class="col-12 fs-14 txt-black pb-2">Details</div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Scheme</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">WPKL09</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Route</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">WPKL09-C3</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Activity</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">KI</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Frequency</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">1,2,3,4,5,6</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Street ID</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">199115</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Park name</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">Jalan Pasar</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Street name</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">Jalan Inai</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Location</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">Barisan Alamoda</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Premise</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">PK1</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Total Premise</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">3</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Vehicle No.</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">WB8760F</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Vehicle type</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">Compactor</div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 pb-3">
                <div class="w-100 txt-gry fs-12 font-weight-light">Company</div>
                <div class="w-100 txt-dgry fs-12 font-weight-bold">Alam Flora Sdn Bhd</div>
              </div>
            </div>
          </div>
        </div>
        <!----->
        <div class="w-100 py-3">
          <div class="bg-white w-100 p-3 rounded">
            <div class="ptable-css ptable-scrollable">

              <div class="p-2">
                Collection summary
              </div>
              <hr />
              <div class="row justify-content-start p-2">
                <div class="col-md-2">
                  <div class="fs-12 txt-gry">
                    Total bin(s)
                  </div>
                  <div class="d-flex align-items-center ">
                    <div class="badge_color all-bg p-1 rounded-circle" style="width:5px;height:5px;">

                    </div>
                    <div class="mx-1 fs-12 txt-default-color">
                      11
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="fs-12 txt-gry">
                    Collected bin(s)
                  </div>
                  <div class="d-flex align-items-center ">
                    <div class="badge_color online-bg p-1 rounded-circle" style="width:5px;height:5px;">
                    </div>
                    <div class="mx-1 fs-12 green-txt">
                      11
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="fs-12 txt-gry">
                    Missed bin(s)
                  </div>
                  <div class="d-flex align-items-center ">
                    <div class="badge_color offline-bg p-1 rounded-circle" style="width:5px;height:5px;">
                    </div>
                    <div class="mx-1 fs-12 red-txt">
                      11
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="fs-12 txt-gry">
                    Ongoing bin(s)
                  </div>
                  <div class="d-flex align-items-center ">
                    <div class="badge_color ongoling_bg_color p-1 rounded-circle" style="width:5px;height:5px;">
                    </div>
                    <div class="mx-1 fs-12 ongoling_text_color">
                      11
                    </div>
                  </div>
                </div>
              </div>
              <div class="bin_tables">
                <table class="table">
                  <thead>
                    <tr>
                      <td style="min-width: 130px" class="bg-white fs-12 txt-gry">Bin ID</td>
                      <td style="min-width: 130px" class="bg-white fs-12 txt-gry">Bin type</td>
                      <td style="min-width: 130px" class="bg-white fs-12 txt-gry">Coordinate</td>
                      <td style="min-width: 130px" class="bg-white fs-12 txt-gry">Bin status</td>
                      <td style="min-width: 130px" class="bg-white fs-12 txt-gry">Collected time</td>
                      <td style="min-width: 130px" class="bg-white fs-12 txt-gry">Collected by</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let binDetails of binTableDetails">
                      <td class="border-bottom fs-14" style="min-width: 130px">
                        {{binDetails.binID}}
                      </td>
                      <td class="border-bottom fs-14 txt-dgry" style="min-width: 100px;">
                        {{binDetails.binType}}
                      </td>
                      <td class="border-bottom fs-14 txt-dgry" style="min-width: 130px;">
                        {{binDetails.coordinate}}
                      </td>
                      <td class="border-bottom fs-14 font-weight-bold"
                          [ngClass]="{'red-txt':binDetails.binstatus==='missed',
                                  'darkorange-txt':binDetails.binstatus==='ongoing',
                                  'green-txt':binDetails.binstatus==='Collected'}" style="min-width: 200px">
                        <span>{{binDetails.binstatus}}</span>
                      </td>
                      <td class="border-bottom fs-14 txt-dgry" style="min-width: 100px">
                        {{binDetails.collectedTime}}
                      </td>
                      <td class="border-bottom fs-14 txt-dgry" style="min-width: 100px">
                        {{binDetails.collectedBy}}
                      </td>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </p-scrollPanel>
  </div>
</div>


