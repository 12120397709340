<div class="w-100" [ngClass]="leftSideBoxHidden? 'navBarWidthFull':'navBarWidth-50'">
  <div class="w-100 vh-100 custom-scrollbar">
    <div class="mx-auto bg-bluecolor vh-100 d-flex flex-wrap">
      <ul class="w-100 nav py-2 flex-wrap" [ngClass]="leftSideBoxHidden? 'justify-content-start':'justify-content-center text-center'">
        <li class="w-100" *ngFor="let menuData of tempData, let i = index" >
          <div [ngClass]="{'active':selectedIndex == i,'back-show':menuData.Name=='Back','logout-show':menuData.Name=='Log Out'}">
            <a class="w-100 d-flex align-items-center cursor-pointer" 
            (click)="menuBarRedirection(menuData.Name, i)">
           
              <span class="navIcons ">
                <i class="{{menuData.Icon}}"></i>
              </span>
              <span [ngClass]="leftSideBoxHidden? 'd-flex':'d-none'" class="navNames fs-12">{{menuData.Name}}</span>
                
            </a>
           </div>
          
        </li>
      </ul>
      <ul class="w-100 mt-auto nav justify-content-center py-2 flex-wrap text-center back-show">
        <li class="w-100" [ngClass]="leftSideBoxHidden? 'd-none':'d-block'">
          <button (click)="navShowHiden()" class="w-100 d-flex border-0 bg-transparent show-none align-items-start cursor-pointer text-decoration-none user-block">
            <div class="navIcons">
              <img src="./assets/images/web-112021/icons/icon-sharp-account-circle.png" />
            </div>
          </button>
        </li>
        <li class="w-100" [ngClass]="leftSideBoxHidden? 'd-block':'d-none'">
          <button type="button" (click)="showDialog()" class="bg-logout bg-transparent border-0 w-100 d-flex align-items-start cursor-pointer text-decoration-none user-block">
            <div class="navIcons">
              <img src="./assets/images/web-112021/icons/icon-sharp-account-circle.png" />
            </div>
            <div [ngClass]="leftSideBoxHidden? 'd-flex':'d-none'" class="navNames fs-12 flex-wrap">
              <span class="d-flex w-100">{{currentAdmin.Username}}</span>
              <div class="bg-transparent border-0 d-flex w-100 px-0">Log out</div>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
<p-dialog header=" " [(visible)]="display" [modal]="true" [baseZIndex]="10000">
  <p class="text-center modal-title-color">
    Logout
  </p>
  <h6 class="text-center modal-txt-color">Are you sure want to log out? </h6>
  <ng-template pTemplate="footer">
    <div class="text-center">
      <button type="button" class="btn bg-transparent border-0 can-btn" (click)="displayFalse()">Cancel</button>
      <button type="button" class="btn btn-primary m-0" (click)="logout()">Log Out</button>
    </div>
  </ng-template>
</p-dialog>
<p-toast position="top-right"></p-toast>

<form [formGroup]="changePassForm" (ngSubmit)="chagePassFormSubmit(changePassForm.value)" autocomplete="no">
  <p-dialog [(visible)]="dataDialog" [style]="{width: '450px'}" header="Change Password" [modal]="true" styleClass="p-fluid" class="dialog-bottom">
      <ng-template pTemplate="content" class="custom-pad-content">
        <div class="p-inputgroup flex-wrap pb-4 position-relative">
          <label class="w-100" for="email">Email</label>
          <input formControlName="email" class="rounded inpu-shadows p-2 w-100" id="email" type="text" pInputText [(ngModel)]="currentAdmin.Email" [attr.disabled]="currentAdmin.Email"/>
        </div>
        <div class="p-inputgroup flex-wrap pb-4 position-relative">
          <label class="w-100" for="currentPassword">Old Password</label>
          <div class="w-100 position-relative pwd-eye-btn">
            <input formControlName="currentPassword" autocomplete="off" class="rounded inpu-shadows p-2 w-100" id="currentPassword" type="{{pwdEye ? 'text':'password'}}" pInputText />
            <button type="button" (click)="pwdEye = !pwdEye" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
              <span class="material-icons">
                {{pwdEye ? 'visibility':'visibility_off'}}
              </span>
            </button>
          </div>
          <div *ngIf="changePassForm.controls['currentPassword'].invalid && (changePassForm.controls['currentPassword'].dirty || changePassForm.controls['currentPassword'].touched)"
               class="text-danger position-absolute error-msgs error-input err-top-72 fs-10 mt-1">
            <div class="w-100 lh-10" *ngIf="changePassForm.controls['currentPassword'].errors?.['required']">
              password is required.
            </div>
            <div class="w-100 lh-10" *ngIf="changePassForm.controls['currentPassword'].errors?.['minlength']">
              password must be at least 4 digits.
            </div>
            <!-- <div class="w-100 lh-10" *ngIf="changePassForm.controls['currentPassword'].errors?.['maxlength']">
              password must be at most 8 digits.
            </div> -->
          </div>
        </div>
        <div class="p-inputgroup flex-wrap pb-4 position-relative">
          <label class="w-100" for="newPassword">New Password</label>
          <div class="w-100 position-relative pwd-eye-btn">
            <input formControlName="newPassword" class="rounded inpu-shadows p-2 w-100" id="newPassword" type="{{pwdEye ? 'text':'password'}}" pInputText />
            <button type="button" (click)="pwdEye = !pwdEye" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
              <span class="material-icons">
                {{pwdEye ? 'visibility':'visibility_off'}}
              </span>
            </button>
          </div>
          <div *ngIf="changePassForm.controls['newPassword'].invalid && (changePassForm.controls['newPassword'].dirty || changePassForm.controls['newPassword'].touched)"
               class="text-danger position-absolute error-msgs error-input err-top-72 fs-10 mt-1">
            <div class="w-100 lh-10" *ngIf="changePassForm.controls['newPassword'].errors?.['required']">
              new password is required.
            </div>
            <div class="w-100 lh-10" *ngIf="changePassForm.controls['newPassword'].errors?.['minlength']">
              new password must be at least 4 digits.
            </div>
            <!-- <div class="w-100 lh-10" *ngIf="changePassForm.controls['newPassword'].errors?.['maxlength']">
              new password must be at most 8 digits.
            </div> -->
          </div>
        </div>
        <div class="p-inputgroup flex-wrap pb-4 position-relative">
          <label class="w-100" for="retypePassword">Confirm New Password</label>
          <div class="w-100 position-relative pwd-eye-btn">
            <input formControlName="retypePassword" class="rounded inpu-shadows p-2 w-100" id="retypePassword" type="{{pwdEye ? 'text':'password'}}" pInputText />
            <button type="button" (click)="pwdEye = !pwdEye" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
              <span class="material-icons">
                {{pwdEye ? 'visibility':'visibility_off'}}
              </span>
            </button>
          </div>
          <div *ngIf="changePassForm.controls['retypePassword'].invalid && (changePassForm.controls['retypePassword'].dirty || changePassForm.controls['retypePassword'].touched)"
               class="text-danger position-absolute error-msgs error-input err-top-72 fs-10 mt-1">
            <div class="w-100 lh-10" *ngIf="changePassForm.controls['retypePassword'].errors?.['confirmedValidator']">
              confirm new password does not match with new password.
            </div>
          </div>
        </div>
        <div style="line-height: 16px;"><small>To confirm with our Strong Password Policy, use at least one letter, one numeral, one special character, and eight characters.</small></div>
      </ng-template>
      <ng-template pTemplate="footer">
          <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" type="button"></button>
          <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!changePassForm.valid" type="submit"></button>
      </ng-template>
  </p-dialog>
</form>
