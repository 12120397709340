<p-toast></p-toast>

<p-card styleClass="p-card-content-0">
  <p-menubar [model]="menuitems">
  </p-menubar>
  <h5 style="margin-top: 0.5rem; margin-left:1rem">Manage Users</h5>

  <p>&nbsp;</p>

  <div class="all-vehicle-details custom-scrollbar">
    <div class="w-100 result-found-blocks">
      <div class="result-found-list found-fonts fs-10 lh-12">
        <div class="w-100 tableFixHead">
          <!--Route schedule block-->
          <div class="ptable-css ptable-scrollable">
            <div class="w-100 d-flex justify-content-between my-4">

              <div class="row w-100 justify-content-between mt-1" style="margin-left: -0.1rem;">
                <div class="col-md-2 col-12 vehicle-fillter mb-2 pr-3 pl-0">
                  <li class="d-flex align-items-center nav-item w-auto pr-0">
                    <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus"
                      class="p-button-info rounded cursor-pointer" (click)="openNew()"></button>
                  </li>
                </div>
                <div class="col-md-8 col-12 vehicle-fillter mb-2 pr-3 pl-0"></div>
                <div class="col-md-2 col-12 vehicle-fillter mb-2 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0">
                      <div class="range-calendar">
                        <span class="p-input-icon-right">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Search..." />
                        </span>
                      </div>
                    </li>
                </div>
              </div>
            </div>
            <div #dtMain>
              <p-table #dt [value]="datas" [rows]="10" [paginator]="true"
                [globalFilterFields]="['UserName','ModulePerson.FullName','Email','UserRoleName','Active']"
                [(selection)]="selectedDatas" [rowHover]="true" dataKey="Id"
                styleClass="p-datatable-striped p-datatable-sm" [loading]="isLoading" responsiveLayout="scroll"
                [rowsPerPageOptions]="[10,25,50]" [scrollable]="true" scrollHeight="60vh"
                [tableStyle]="{'max-width': '100vw'}"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                  <div class="flex align-items-center justify-content-between " style="margin-left:-0.7rem; margin-top: -1.5rem; ">
                    <div class="flex align-items-center justify-content-between summary-message" style="margin-bottom: -1.1rem;">
                      {{datas ? datas.length : 0 }} result(s) found.
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <!-- <th style="width:3vw;max-width:3vw;" class="bg-white text-center fillet-boxs-css">
                                <p-tableHeaderCheckbox class="mx-auto"></p-tableHeaderCheckbox>
                            </th>                  -->
                    <th pSortableColumn="UserName" style="width:320px">UserName <p-sortIcon
                        field="UserName"></p-sortIcon></th>
                    <th pSortableColumn="ModulePerson.FullName" style="width:320px">FullName <p-sortIcon
                        field="ModulePerson.FullName"></p-sortIcon></th>
                    <th pSortableColumn="Email" style="width:320px">Email <p-sortIcon
                        field="Email"></p-sortIcon></th>
                    <th pSortableColumn="UserRoleName" style="width:320px">Role <p-sortIcon
                        field="UserRoleName"></p-sortIcon></th>
                    <th pSortableColumn="Active" style="width:320px">Status <p-sortIcon
                        field="Active"></p-sortIcon></th>
                    <th style="width:320px"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                  <tr class="p-selectable-row">
                    <!-- <td style="width:3vw;max-width:3vw;" class="fillet-boxs-css">
                                  <p-tableCheckbox [value]="data" class="mx-auto"></p-tableCheckbox>
                              </td> -->
                    <td style="width:320px"><img [src]="'../../assets/images/user.png'"
                        [alt]="data.UserName" width="40" /> <span style="margin-left: 0.5rem;">{{data.UserName}}</span>
                    </td>
                    <td style="width:320px">{{data.ModulePerson.FullName}}</td>
                    <td style="width:320px">{{data.Email}}</td>
                    <td style="width:320px">{{data.UserRoleName}}</td>
                    <td style="width:320px"><span style="display: none;">{{data.Active}}</span>
                      <i *ngIf="data.Active" class="pi pi-check" style="font-size: 1rem; color:green"></i>
                      <i *ngIf="!data.Active" class="pi pi-times" style="font-size: 1rem; color:red"></i>
                    </td>
                    <!--td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td-->
                    <td style="width:320px">

                      <p-splitButton label="Action" [model]="this.items" styleClass="p-button-outlined p-button-info"
                        class="border-0 rounded cursor-pointer" (click)="selectData(data)"></p-splitButton>

                      <!--button pButton pRipple icon="pi pi-pencil" styleclass="p-button-sm" class="p-button-rounded p-button-success mr-4 " (click)="editData(data)"></button>
                                  <button pButton pRipple icon="pi pi-trash" styleclass="p-button-sm" class="p-button-rounded p-button-warning" (click)="deleteData(data)"></button-->

                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="6" class="empty-message">Please select Date and Vehicle.</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-between summary-message">
                    In total there are {{datas ? datas.length : 0 }} record(s).
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-card>
<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <p-dialog [(visible)]="dataDialog" [style]="{width: '450px'}" header="Details" [modal]="true" styleClass="p-fluid"
    [loading]="isLoading">
    <ng-template pTemplate="content" class="custom-pad-content">
      <!-- img [src]="'assets/showcase/images/demo/product/' + data.image" [alt]="data.image" class="product-image" *ngIf="data.image" -->
      
      <div class="field custom-pad-bot">
        <label for="UserName">User Name</label>
        <!-- <input type="text" formControlName="UserName" pInputText id="UserName" [(ngModel)]="data.UserName" /> -->
        <input type="text" formControlName="UserName" pInputText id="UserName" [(ngModel)]="data.UserName" autocomplete="off"/>
        <small class="p-error" *ngIf="submitted && !data.UserName">User Name is required.</small>
      </div>

      <div class="field custom-pad-bot">
        <label for="FullName">Full Name</label>
        <input type="text" formControlName="FullName" pInputText id="FullName"
          [(ngModel)]="data.ModulePerson.FullName" />
        <small class="p-error" *ngIf="submitted && !data.ModulePerson.FullName">Full Name is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="RoleId">Role</label>
        <p-dropdown formControlName="RoleId" [options]="role" placeholder="Select a Role" optionLabel="Name"
          optionValue="Id" [(ngModel)]="data.UserRoleId"></p-dropdown>
        <!-- <ng-multiselect-dropdown formControlName="RoleId" [settings]="dropdownSettings" [data]="role"></ng-multiselect-dropdown> -->
        <small class="p-error" *ngIf="submitted && !data.UserRoleId">Role is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="IdentityNo">IC Number</label>
        <input type="text" formControlName="IdentityNo" pInputText id="IdentityNo"
          [(ngModel)]="data.ModulePerson.NRICNoNew" />
        <small class="p-error" *ngIf="submitted && !data.ModulePerson.NRICNoNew">IC Number is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="PhoneNumber">Phone Number</label>
        <input type="text" formControlName="PhoneNumber" pInputText id="PhoneNumber" [(ngModel)]="data.PhoneNumber" />
        <small class="p-error" *ngIf="submitted && !data.PhoneNumber">Phone Number is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="Email">Email</label>
        <input type="text" formControlName="Email" pInputText id="Email" [(ngModel)]="data.Email" />
        <small class="p-error" *ngIf="submitted && !data.Email">Email is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="EmailConfirm">Confirm Email</label>
        <input type="text" formControlName="EmailConfirm" pInputText id="EmailConfirm" [(ngModel)]="data.Email" />
        <small class="p-error" *ngIf="submitted && !data.Email">Confirm Email is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="Password">Password</label>
        <div class="w-100 position-relative pwd-eye-btn">
          <input formControlName="Password" class="rounded inpu-shadows p-2 w-100" id="Password" type="{{pwdEye ? 'text':'password'}}"  />
          <button type="button" (click)="pwdEye = !pwdEye" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
            <span class="material-icons">
              {{pwdEye ? 'visibility':'visibility_off'}}
            </span>
          </button>
        </div>
        <!-- <small class="p-error" *ngIf="submitted && !data.PasswordHash">Password is required.</small> -->
      </div>
      <div class="field custom-pad-bot">
        <label for="PasswordConfirm">Confirm Password</label>
        <div class="w-100 position-relative pwd-eye-btn">
          <input formControlName="PasswordConfirm" class="rounded inpu-shadows p-2 w-100" id="PasswordConfirm" type="{{pwdEye ? 'text':'password'}}" pInputText />
          <button type="button" (click)="pwdEye = !pwdEye" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
            <span class="material-icons">
              {{pwdEye ? 'visibility':'visibility_off'}}
            </span>
          </button>
        </div>
        <!-- <small class="p-error" *ngIf="submitted && !data.PasswordHash">Confirm password is required.</small> -->
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"
        [disabled]="isLoading"></button>
      <button pButton pRipple class="p-button-text" type="submit" [disabled]="isLoading">
        <span *ngIf="!isLoading" style="font-weight: 700"> <i class="pi pi-check"></i> Save</span>
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
      </button>
    </ng-template>
  </p-dialog>
</form>
<!------------------ EDIT USER ---------------------->
<form [formGroup]="userEditForm" (ngSubmit)="onSubmitEdit()">
  <p-dialog [(visible)]="dataEditDialog" [style]="{width: '450px'}" header="Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content" class="custom-pad-content">
      <!-- img [src]="'assets/showcase/images/demo/product/' + data.image" [alt]="data.image" class="product-image" *ngIf="data.image" -->
      <!-- <div class="field custom-pad-bot">
              <label for="UserName">User Name</label>
              <input type="text" formControlName="UserName" pInputText id="UserName" [(ngModel)]="data.UserName"/>
              <small class="p-error" *ngIf="submittedEdit && !data.UserName">User Name is required.</small>
          </div> -->
      <div class="field custom-pad-bot">
        <label for="FullName">Full Name</label>
        <input type="text" formControlName="FullName" pInputText id="FullName"
          [(ngModel)]="data.ModulePerson.FullName" />
        <small class="p-error" *ngIf="submittedEdit && !data.ModulePerson.FullName">Full Name is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="UserRoleId">Role</label>
        <p-dropdown formControlName="UserRoleId" [options]="role" placeholder="Select a Role" optionLabel="Name"
          optionValue="Id" [(ngModel)]="data.UserRoleId"></p-dropdown>
        <!-- <ng-multiselect-dropdown formControlName="RoleId" [settings]="dropdownSettings" [data]="role"></ng-multiselect-dropdown> -->
      </div>
      <div class="field custom-pad-bot">
        <label for="NRICNoNew">IC Number</label>
        <input type="text" formControlName="NRICNoNew" pInputText id="NRICNoNew"
          [(ngModel)]="data.ModulePerson.NRICNoNew" />
        <small class="p-error" *ngIf="submittedEdit && !data.ModulePerson.NRICNoNew">IC Number is required.</small>
      </div>
      <div class="field custom-pad-bot">
        <label for="PhoneNumber">Phone Number</label>
        <input type="text" formControlName="PhoneNumber" pInputText id="PhoneNumber"
          [(ngModel)]="data.ModulePerson.PhoneNumber" />
        <small class="p-error" *ngIf="submittedEdit && !data.PhoneNumber">Phone Number is required.</small>
      </div>
      <!-- <div class="field custom-pad-bot">
              <label for="Email">Email</label>
              <input type="text" formControlName="Email" pInputText id="Email" [(ngModel)]="data.Email"/>
              <small class="p-error" *ngIf="submittedEdit && !data.Email">Email is required.</small>
          </div>
          <div class="field custom-pad-bot">
              <label for="EmailConfirm">Confirm Email</label>
              <input type="text" formControlName="EmailConfirm" pInputText id="EmailConfirm" [(ngModel)]="data.Email"/>
              <small class="p-error" *ngIf="submittedEdit && !data.Email">Confirm Email is required.</small>
          </div> -->
      <!-- <div class="field custom-pad-bot">
              <label for="Password">Password</label>
              <input type="text" formControlName="Password" pInputText id="Password" [(ngModel)]="data.PasswordHash"/>
              <small class="p-error" *ngIf="submitted && !data.PasswordHash">Password is required.</small>
          </div>
          <div class="field custom-pad-bot">
              <label for="PasswordConfirm">Confirm Password</label>
              <input type="text" formControlName="PasswordConfirm" pInputText id="PasswordConfirm" [(ngModel)]="data.PasswordHash"/>
              <small class="p-error" *ngIf="submitted && !data.PasswordHash">Confirm password is required.</small>
          </div> -->
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideEditDialog()"
        type="button" [disabled]="isLoading"></button>
      <button pButton pRipple class="p-button-text" type="submit" [disabled]="isLoading">
        <span *ngIf="!isLoading" style="font-weight: 700"> <i class="pi pi-check"></i> Save</span>
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
      </button>
    </ng-template>
  </p-dialog>
</form>
<!------------------ MODAL ASSIGN COMPANY ---------------------->
<p-dialog [(visible)]="modalAssignCompany" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"
  [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
  <ng-template pTemplate="header">
    <div class="w-100 d-flex justify-content-between align-items-center h-100">
      <div class="title-fiters">Assign Company</div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-sm-6 col-md-12">
      <div class="w-100 fillet-boxs-css mb-4 position-relative">
      </div>
    </div>
  </div>
  <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
    <table class="mb-3">
      <tr>
        <td style="width:30vw;font-size: 90%;"><b>User Name</b></td>
        <td style="width:10vw;font-size: 90%;">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.UserName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Full Name</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.ModulePerson.FullName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Email</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.Email}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Phone Number</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.PhoneNumber}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              Assign Company
            </div>
          </b></td>
        <td>
          <div class="w-100 fillet-boxs-css mb-4 position-relative">
            :
          </div>
        </td>
        <td>
          <span>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              <p-dropdown [options]="company" [(ngModel)]="selectedDropdown" [filter]="true"
                [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Company"
                (onChange)="onChangeFilter($event)" optionLabel="name">
                <ng-template pTemplate="selectedItem">
                  <div class="filler-h-item">
                    <div>{{selectedDropdown.name}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </span>
        </td>
      </tr>
    </table>
    <div class="w-100 fillet-boxs-css mb-4 position-relative">
      <button pButton label="Assign" class="p-button-info" (click)="assignCompanyForm(data)"
        [disabled]="!selectedDropdown"></button>
    </div>
    <div *ngIf="showLoader" class="align-items-center justify-content-center"
      style="text-align: center; display: flex;">
      <div class="loader"></div>
    </div>
    <!-- <table class="table mt-3 outside-border"> -->
    <div class="scrollable-table">
      <table class="table mt-3 outside-border" *ngIf="!showLoader">
        <thead class="thead-light" style="background-color: #e9ecef;">
          <tr>
            <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Company</td>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="assignedCompanyList.length!=0" [ngIfElse]="noData">
            <tr *ngFor="let assinedCompany of assignedCompanyList">
              <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">{{assinedCompany.ModuleCompanies.name}}</td>
              <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash"
                  style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right"
                  (click)="unassignCompany(assinedCompany)"></i></td>
            </tr>
          </ng-template>
          <ng-template #noData>
            <tr>
              <td style="padding: 0.3rem;" class="font-italic empty-message">No data</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>  
    

  </ng-template>
  <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
    <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component"
      (click)="hideDialog()" type="button"></button>
  </ng-template>
  <ng-template #loaderProgress>
    <div class="Loading"></div>
  </ng-template>
</p-dialog>
<!------------------------------- MODAL ASSIGN SCHEME ---------------------------------->
<p-dialog [(visible)]="modalAssignScheme" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"
  [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
  <ng-template pTemplate="header">
    <div class="w-100 d-flex justify-content-between align-items-center h-100">
      <div class="title-fiters">Assign Scheme</div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-sm-6 col-md-12">
      <div class="w-100 fillet-boxs-css mb-4 position-relative">
      </div>
    </div>
  </div>
  <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
    <table class="mb-3">
      <tr>
        <td style="width:30vw;font-size: 90%;"><b>User Name</b></td>
        <td style="width:10vw;font-size: 90%;">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.UserName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Full Name</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.ModulePerson.FullName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Email</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.Email}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Phone Number</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.PhoneNumber}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              Assign Scheme
            </div>
          </b></td>
        <td>
          <div class="w-100 fillet-boxs-css mb-4 position-relative">
            :
          </div>
        </td>
        <td>
          <span>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              <p-dropdown [options]="scheme" [(ngModel)]="selectedDropdown" [filter]="true"
                [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Scheme"
                (onChange)="onChangeFilter($event)" optionLabel="name">
                <ng-template pTemplate="selectedItem">
                  <div class="filler-h-item">
                    <div>{{selectedDropdown.name}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </span>
        </td>
      </tr>
    </table>
    <div class="w-100 fillet-boxs-css mb-4 position-relative">
      <button pButton label="Assign" class="p-button-info" (click)="assignSchemeForm(data)"
        [disabled]="!selectedDropdown"></button>
    </div>
    <div *ngIf="showLoader" class="align-items-center justify-content-center"
      style="text-align: center; display: flex;">
      <div class="loader"></div>
    </div>
    <!-- <table class="table mt-3 outside-border"> -->
    <div class="scrollable-table">
      <table class="table mt-3 outside-border" *ngIf="!showLoader">
        <thead class="thead-light" style="background-color: #e9ecef;">
          <tr>
            <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Scheme</td>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="assignedSchemeList.length!=0" [ngIfElse]="noData">
            <tr *ngFor="let assinedScheme of assignedSchemeList">
              <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">{{assinedScheme.ConfigSchemes.name}}</td>
              <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash"
                  style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right"
                  (click)="unassignScheme(assinedScheme)"></i></td>
            </tr>
          </ng-template>
          <ng-template #noData>
            <tr>
              <td style="padding: 0.3rem;" class="font-italic empty-message">No data</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      
    </div>
    

  </ng-template>
  <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
    <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component"
      (click)="hideDialog()" type="button"></button>
  </ng-template>
  <ng-template #loaderProgress>
    <div class="Loading"></div>
  </ng-template>
</p-dialog>

<!------------------------------- MODAL ASSIGN GROUPS ---------------------------------->
<p-dialog [(visible)]="modalAssignGroup" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"
  [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
  <ng-template pTemplate="header">
    <div class="w-100 d-flex justify-content-between align-items-center h-100">
      <div class="title-fiters">Assign Group</div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-sm-6 col-md-12">
      <div class="w-100 fillet-boxs-css mb-4 position-relative">
      </div>
    </div>
  </div>
  <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
    <table class="mb-3">
      <tr>
        <td style="width:30vw;font-size: 90%;"><b>User Name</b></td>
        <td style="width:10vw;font-size: 90%;">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.UserName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Full Name</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.ModulePerson.FullName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Email</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.Email}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Phone Number</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.PhoneNumber}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              Assign Group
            </div>
          </b></td>
        <td>
          <div class="w-100 fillet-boxs-css mb-4 position-relative">
            :
          </div>
        </td>
        <td>
          <span>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              <p-dropdown [options]="groups" [(ngModel)]="selectedDropdown" [filter]="true"
                [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Groups"
                (onChange)="onChangeFilter($event)" optionLabel="Name">
                <ng-template pTemplate="selectedItem">
                  <div class="filler-h-item">
                    <div>{{selectedDropdown.Name}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </span>
        </td>
      </tr>
    </table>
    <div class="w-100 fillet-boxs-css mb-4 position-relative">
      <button pButton label="Assign" class="p-button-info" (click)="assignGroupForm(data)"
        [disabled]="!selectedDropdown"></button>
    </div>
    <div *ngIf="showLoader" class="align-items-center justify-content-center"
      style="text-align: center; display: flex;">
      <div class="loader"></div>
    </div>
    <!-- <table class="table mt-3 outside-border"> -->
    <table class="table mt-3 outside-border" *ngIf="!showLoader">
      <thead class="thead-light" style="background-color: #e9ecef;">
        <tr>
          <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Groups</td>
        </tr>
      </thead>
      <tbody>
        <ng-template [ngIf]="assignedGroupList.length!=0" [ngIfElse]="noData">
          <tr *ngFor="let assignedGroups of assignedGroupList">
            <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">{{assignedGroups.Group.Name}}</td>
            <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash"
                style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right"
                (click)="unassignUserGroups(assignedGroups)"></i></td>
          </tr>
        </ng-template>
        <ng-template #noData>
          <tr>
            <td style="padding: 0.3rem;" class="font-italic empty-message">No data</td>
          </tr>
        </ng-template>
      </tbody>
    </table>

  </ng-template>
  <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
    <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component"
      (click)="hideDialog()" type="button"></button>
  </ng-template>
  <ng-template #loaderProgress>
    <div class="Loading"></div>
  </ng-template>
</p-dialog>

<!------------------------------- MODAL ASSIGN SERVICE AREA ---------------------------------->
<p-dialog [(visible)]="modalAssignArea" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"
  [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
  <ng-template pTemplate="header">
    <div class="w-100 d-flex justify-content-between align-items-center h-100">
      <div class="title-fiters">Assign Service Area</div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-sm-6 col-md-12">
      <div class="w-100 fillet-boxs-css mb-4 position-relative">
      </div>
    </div>
  </div>
  <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
    <table class="mb-3">
      <tr>
        <td style="width:30vw;font-size: 90%;"><b>User Name</b></td>
        <td style="width:10vw;font-size: 90%;">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.UserName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Full Name</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.ModulePerson.FullName}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Email</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.Email}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>Phone Number</b></td>
        <td style="width:10vw;font-size: 90%">:</td>
        <td><span class=" fs-12 text-capitalize">{{data.PhoneNumber}}</span></td>
      </tr>
      <tr>
        <td style="width:30vw;font-size: 90%"><b>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              Assign Service Area
            </div>
          </b></td>
        <td>
          <div class="w-100 fillet-boxs-css mb-4 position-relative">
            :
          </div>
        </td>
        <td>
          <span>
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
              <p-dropdown [options]="serviceAreaList" [(ngModel)]="selectedDropdown" [filter]="true"
                [ngModelOptions]="{standalone: true}" [showClear]="true" placeholder="Select Service Area"
                (onChange)="onChangeFilter($event)" optionLabel="Name">
                <ng-template pTemplate="selectedItem">
                  <div class="filler-h-item">
                    <div>{{selectedDropdown.Name}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </span>
        </td>
      </tr>
    </table>
    <div class="w-100 fillet-boxs-css mb-4 position-relative">
      <button pButton label="Assign" class="p-button-info" (click)="assignServiceAreaForm(data)"
        [disabled]="!selectedDropdown"></button>
    </div>
    <div *ngIf="showLoader" class="align-items-center justify-content-center"
      style="text-align: center; display: flex;">
      <div class="loader"></div>
    </div>
    <div class="scrollable-table">
      <table class="table mt-3 outside-border" *ngIf="!showLoader">
        <thead class="thead-light" style="background-color: #e9ecef;">
          <tr>
            <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Service Area</td>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="assignedSchemeList.length!=0" [ngIfElse]="noData">
            <tr *ngFor="let assinedArea of assignedUserAreaList">
              <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">{{assinedArea.ConfigServiceArea.Name}}</td>
              <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash"
                  style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right"
                  (click)="unassignServiceArea(assinedArea)"></i></td>
            </tr>
          </ng-template>
          <ng-template #noData>
            <tr>
              <td style="padding: 0.3rem;" class="font-italic empty-message">No data</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      
    </div>
    

  </ng-template>
  <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
    <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component"
      (click)="hideDialog()" type="button"></button>
  </ng-template>
  <ng-template #loaderProgress>
    <div class="Loading"></div>
  </ng-template>
</p-dialog>
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>