import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

declare var H: any;

@Component({
  selector: 'app-poc',
  templateUrl: './poc.component.html',
  styleUrls: ['./poc.component.scss']
})
export class PocComponent implements OnInit {
  private platform: any;
  @ViewChild("map")
  public mapElement?: ElementRef;

  public constructor() {
    this.platform = new H.service.Platform({
      "apikey": "Lp4LDRE5CwK6tQ-4sh6KhSyYmZeuGM8RwG7XCW_3jJw"
    });
  }

  public ngOnInit() { }

  public ngAfterViewInit() {
    var defaultLayers = this.platform.createDefaultLayers({
      tileSize: devicePixelRatio === 1 ? 256 : 512,
      ppi: devicePixelRatio === 1 ? 72 : 320
    });
    var map = new H.Map(
      this.mapElement?.nativeElement,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 17.4421362, lng: 78.3790385 },
        zoom: 17,
        pixelRatio: Math.min(devicePixelRatio, 2)
      }
    );

    var behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(map)
    );

    var ui = H.ui.UI.createDefault(map, defaultLayers);

    window.addEventListener('resize', function () {
      map.getViewPort().resize();
    });

    this.addMarkersToMap(map);
  }

  binIconRed:any = new H.map.Icon("/assets/images/map/dumpster-red.svg", { size: { w: 24, h: 24 } });
  binMarkers: any = [];
  binPositions:any = [
    [17.440868, 78.378375],
    [17.440319, 78.379307],
    [17.441033, 78.379720],
    [17.441794, 78.380198],
    [17.442645, 78.379873],
    [17.443631, 78.380410]
  ]
  addMarkersToMap(map: any) {

    this.binPositions.forEach((pos:any, index:any) => {
      let marker = new H.map.Marker({ lat: pos[0], lng: pos[1] },  {
        icon: this.binIconRed,
        volatility: true
      });
      marker.setData(index + 1);
      this.binMarkers.push(marker);
    });

    map.addObjects(this.binMarkers);

    this.startDrive(map);
  }

  startDrive(map: any) {

    var truckIcon = new H.map.Icon("/assets/images/map/truck.svg", { size: { w: 36, h: 36 } });
    var imageMarker = new H.map.Marker({ lat: 17.44116, lng: 78.37737 }, { icon: truckIcon });
    imageMarker.$id = "marker";
    map.addObject(imageMarker);

    var lineString = new H.geo.LineString();

    let pathPositions = [78.37737, 17.44116, 78.37749, 17.44109, 78.37752, 17.44107, 78.37755, 17.44106, 78.37761, 17.44105, 78.37781, 17.44103, 78.37794, 17.44102, 78.37806, 17.44099, 78.37821, 17.44093, 78.37836, 17.44087, 78.37839, 17.44085, 78.37865, 17.4407, 78.37871, 17.44066, 78.37896, 17.44051, 78.37911, 17.44041, 78.37932, 17.44025, 78.37934, 17.44025, 78.37941, 17.44045, 78.37967, 17.44084, 78.37971, 17.44092, 78.37976, 17.44101, 78.37996, 17.4413, 78.37996, 17.44131, 78.38016, 17.44159, 78.38025, 17.44179, 78.38024, 17.4418, 78.38024, 17.44181, 78.38023, 17.44181, 78.38023, 17.44182, 78.38023, 17.44183, 78.38022, 17.44184, 78.3801, 17.44193, 78.38006, 17.44199, 78.38004, 17.44202, 78.37998, 17.44214, 78.37994, 17.44229, 78.37993, 17.44233, 78.37993, 17.44235, 78.37992, 17.4425, 78.37992, 17.44251, 78.37992, 17.44269, 78.37992, 17.4427, 78.37994, 17.44291, 78.37998, 17.44303, 78.38005, 17.44318, 78.3801, 17.44325, 78.38011, 17.44327, 78.38018, 17.44336, 78.38025, 17.44343, 78.38037, 17.44353, 78.38041, 17.44356, 78.38058, 17.44374, 78.38073, 17.44386, 78.38079, 17.44391, 78.38091, 17.44399, 78.38097, 17.44402, 78.38106, 17.44405, 78.38113, 17.44406, 78.38123, 17.44406, 78.38133, 17.44406, 78.38179, 17.44402, 78.38182, 17.44409];

    var ln = 0;


    var interval = setInterval(() => {
      //var interval = setInterval(function() { 
      if (ln == pathPositions.length) {
        clearInterval(interval);
      } else {
        let coords: any = { lat: pathPositions[ln + 1], lng: pathPositions[ln] }
        lineString.pushPoint(coords);
        imageMarker.setGeometry(coords);
        map.addObject(new H.map.Polyline(
          lineString, { style: { lineWidth: 4 } }
        ));

        ln = ln + 2;

        this.findNearestMarker(coords, map);
      }
    }, 1000);
  }

  findNearestMarker(coords: any, map: any) {
    var minDist = 10,
      nearest_text = '*None*',
      markerDist,
      i;

    let bin_markers = this.binMarkers;
    
    for (i = 0; i < bin_markers.length; i += 1) {
      markerDist = bin_markers[i].getGeometry().distance(coords);
      if (markerDist < minDist) {
        minDist = markerDist;
        nearest_text = bin_markers[i].getData();
        var binIcon = new H.map.Icon("/assets/images/map/dumpster.svg", { size: { w: 24, h: 24 } });
        bin_markers[i].setIcon(binIcon);
      }
    }
  }

}


