import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigMenu } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';

@Component({
    selector: 'app-config-menu',
    templateUrl: './config-menu.component.html',
    styleUrls: ['./config-menu.component.scss']
})
export class ConfigMenuComponent implements OnInit {

    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigMenu[]= [];
    data: ConfigMenu = {ModuleConfigMenuType:{}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    configMenuParam =  { id: null, isactiveonly: true };
    modalAssignPermission = false;
    selectedPermission: any = [];
    allConfigMenuType: any[] = [];
    allConfigPermission: any[] = [];
    showLoader: boolean = false;
    assignedPermissionList:any = [];
    assignPermissionModal: any = [];
    isLoading: boolean = true;

    nameFormControl = new FormControl('', [
        Validators.required,
    ]);
    descriptionFormControl = new FormControl('', [
        Validators.required,
    ]);
    menuTypeIdFormControl = new FormControl('', [
        Validators.required,
    ]);
    menuIndexFormControl = new FormControl('', [
        Validators.required,
    ]);

    configMenuForm = this.formBuilder.group({
        name: this.nameFormControl,
        description: this.descriptionFormControl,
        menuTypeId: this.menuTypeIdFormControl,
        menuIndex: this.menuIndexFormControl,
        icon : '',
        active: true,
        createdBy: localStorage.getItem('username'),
    });

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.getDatas();
        this.getAllConfigMenuType();
        this.getAllConfigPermission();

        this.items = [
            {label: 'Assign Permission', icon: 'pi pi-pencil', command: () => {
                this.assignPermission(this.data);
            }},
            {label: 'Edit', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Delete', icon: 'pi pi-trash', command: () => {
                this.deleteData(this.data);
            }}
        ];

        this.menuitems = GlobalComponent.submenus;
    }
    selectData(data: any){
        this.data = data;
        this.Id = data.Id;
    }

    openNew(){
        this.data= { ModuleConfigMenuType: {}};
        this.submitted = false;
        this.dataDialog = true;
        this.isCreateNew = true;
    }

    editData(data: any) {
        this.data = {...data};
        this.dataDialog = true;
        this.isCreateNew = false;
        this.Id = data.Id;
    }

    hideDialog() {
        this.dataDialog = false;
        this.submitted = false;
        this.modalAssignPermission = false;
    }

    onSubmit(): void {
        this.submitted = true;
        if (this.configMenuForm.valid) {
            if (this.isCreateNew == true) {
                this.createConfigMenu();
            } else {
                this.updateConfigMenu();
            }
        }
    }

    getAllConfigMenuType(){
        this.commonService.getAllConfigMenuType(this.configMenuParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.allConfigMenuType = resp.Result;
                this.loading = false;              
            } else {
                
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getAllConfigPermission(){
        this.commonService.getConfigPermission(this.configMenuParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.allConfigPermission = resp.Result;
                this.loading = false;              
            } else {
                
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getDatas(){
        this.isLoading = true;
        this.commonService.getConfigMenu(this.configMenuParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.datas = resp.Result;
                this.isLoading = false;
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
            
        }
    }

    createConfigMenu(){
        this.isLoading = true;
        this.commonService.createConfigMenu(this.configMenuForm.value).subscribe((resp: any) => {
            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {

                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    updateConfigMenu(){
        this.isLoading = true;
        this.configMenuForm.value.id = this.Id;
        this.commonService.updateConfigMenu(this.configMenuForm.value).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    deleteData(data: any) {
        this.isLoading = true;
        data.CreatedBy = localStorage.getItem('username');
        data.Active = false;
        
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.Name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.commonService.deleteConfigMenu(data).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        this.isLoading = false;
                        this.dataDialog = false;
                        this.getDatas();
                      
                    } else {
              
                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.isLoading = false;
                    }
                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                    this.isLoading = false;
                }
            }
        });
    }

    onChangeFilter(event: any) {
        this.selectedPermission = event.value;
    }

    assignPermission(data: any){
        this.assignPermissionModal = data;
        this.modalAssignPermission = true;
        this.getAssignedPermissionList();
    }

    assignPermissionForm(data: any){

        if(this.selectedPermission == null){
            this.messageService.add({key: 'alertMsg', severity:'warn', summary: 'Warn', detail: 'Please Select Vehicle!'});
        } else { 
            this.showLoader = true;

            var dataMenuPermission = {
                "PermissionId": this.selectedPermission.Id,
                "MenuId": data.Id
            };

            this.commonService.assignMenuPermission(dataMenuPermission).subscribe((resp: any) => {
                if (resp.Success == true) {
                    this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                    this.getAssignedPermissionList();
                } else {
                    this._appSharedService.showError('Error', "Something went wrongg!");
                }
                this.showLoader = false;
    
            }), (error: any) => {
    
                this._appSharedService.showError('Error', "Something went wrongd!");
            }
        } 
    }

    getAssignedPermissionList(){
        var permission = {
            "id": this.Id
        };
        this.showLoader = true;

        this.commonService.getPermissionPermissionByMenuId(permission).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.assignedPermissionList = resp.Result;
            } else {
                this.assignedPermissionList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    unassignPermission(data: any){
        this.confirmationService.confirm({
            message: 'Are you sure you want to unassign ' + data.ConfigPermission.Name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                this.commonService.unassignMenuPermission(data).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedPermissionList();
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }
}
