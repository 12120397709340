<div *ngIf="showSchedules" class="w-100 vh-100 background_Loader position-absolute z-index-1 d-flex align-items-center justify-content-center">
  <div class="loader"></div>
</div>
<div class="range-calendar position-absolute z-index-1 d-flex align-items-center justify-content-between">
  <div style="width:100%;">
    <div class="position-absolute radioButton_css" >
      <div class="d-flex">
      <!-- <div class="d-flex align-items-center"> -->
        <!-- <div *ngFor="let category of searchBy" class="p-field-checkbox position-relative"> -->
          <div *ngFor="let category of searchBy" class="p-field-checkbox d-flex align-items-center">
          <p-radioButton class="" [inputId]="category.key" name="category" [value]="category.key" (ngModelChange)="addressSelection($event)" [(ngModel)]="selectedAddress"></p-radioButton>
          <label [for]="category.key" class="fs-12 mx-2 txt-gry">{{category.search}}</label>
        </div>
     
      </div>
    </div>
    <input id="pac-input" [hidden]="selectedAddress == 'C'" class="controls position-relative" pInputText type="text" (keydown.enter)="addressSlected($event)" [(ngModel)]="enteredAddress" placeholder="Search address..." autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="off" #search>
    <input id="pac-input" [hidden]="selectedAddress == 'A'" class="controls position-relative" pInputText type="text" (keydown.enter)="addressSlected($event)" [(ngModel)]="enteredAddress" placeholder="Search coordinate..." autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="off" #search>
  </div>
</div>
<div class=" map_filters  position-absolute ">
  <div class=" bg-white text-center map_filters_css cursor-pointer" (click)="mapFilters()">
    <svg width="19" height="4" viewBox="0 0 19 4" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 13px auto;" [ngClass]="MapFilters? 'd-none':'d-block'">
      <circle cx="2" cy="2" r="2" fill="#666666" />
      <circle cx="10" cy="2" r="2" fill="#666666" />
      <circle cx="17" cy="2" r="2" fill="#666666" />
    </svg>
    <svg width="19" height="4" viewBox="0 0 19 4" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 13px auto;" [ngClass]="MapFilters? 'd-block':'d-none'">
      <circle cx="2" cy="2" r="2" fill="#19A3F1" />
      <circle cx="10" cy="2" r="2" fill="#19A3F1" />
      <circle cx="17" cy="2" r="2" fill="#19A3F1" />
    </svg>

  </div>
  <div class="bg-white p-3 position-absolute rounded" [ngClass]="MapFilters? 'd-block':'d-none'" style="right:calc(100% - -21%);width:200px;top:-1px;">
    <p>Bin Map filters</p>
    <!-- <button pButton type="button" (click)="clickButton()">clear</button> -->
    
<!-- <div class="field-checkbox d-flex align-items-center mb-1" *ngFor="let tdata of tableData">

  <p-checkbox 
    #checkboxes2
    inputId="point"
    name="group2"
    [value]="tdata"
    [(ngModel)]="isChecked"
    (onChange)="changeCheckmark($event, tdata.id, tdata.name)"
  >
</p-checkbox>
  <label for="point" class="mb-0 mx-2 txt-gry">Point of interest</label>
</div> -->
   

    <div class="field-checkbox d-flex align-items-center" *ngIf="(currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">
      <p-checkbox name="group1" value="Point of interest" [(ngModel)]="selectedMapFiltersValues"  inputId="point"></p-checkbox>
      <label for="point" class="mx-2 txt-gry">Point of interest</label>
    </div>
    <div class="field-checkbox d-flex align-items-center ">
      <p-checkbox name="group1" value="KI Bin" [(ngModel)]="selectedMapFiltersValues" (click)="toggleBins('KIBin')" inputId="Kib" (onChange)="checkBinKIShowValue(chkElmkibin.inputId,$event)" #chkElmkibin></p-checkbox>
      <label for="Kib" class="mx-2 txt-gry">KI Bin</label>
    </div>
    <div class="field-checkbox d-flex align-items-center ">
      <p-checkbox name="group1" value="KB Bin" [(ngModel)]="selectedMapFiltersValues" (click)="toggleBins('KBBin')" inputId="KBB" (onChange)="checkBinKBShowValue(chkElmkbbin.inputId,$event)" #chkElmkbbin></p-checkbox>
      <label for="KBB" class="mx-2 txt-gry">KB Bin</label>
    </div>
    <div class="field-checkbox d-flex align-items-center " *ngIf="(currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">
      <p-checkbox name="group1" value="KI Geofence" [(ngModel)]="selectedMapFiltersValues" (click)="binGeoFence('KIGeofence')"  inputId="KIG" (onChange)="checkGeoKIShowValue(chkElmkigeo.inputId,$event)" #chkElmkigeo></p-checkbox>
      <label for="KIG" class="mx-2 txt-gry">KI Geofence</label>
    </div>
    <div class="field-checkbox d-flex align-items-center " *ngIf="(currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">
      <p-checkbox name="group1" value="KB Geofence" [(ngModel)]="selectedMapFiltersValues" (click)="binGeoFence('KBGeofence')"  inputId="KBG" (onChange)="checkGeoKBShowValue(chkElmkbgeo.inputId,$event)" #chkElmkbgeo></p-checkbox>
      <label for="KBG" class="mx-2 txt-gry">KB Geofence</label>
    </div>
 
  </div>
</div>
<div id="map">
</div>
<agm-map (boundsChange)="checkMarkersInBounds($event)" (zoomChange)="onZoomChange($event)" 
         [latitude]="latitude" class="position-relative" [disableDefaultUI]="false" [zoom]="zoom" [longitude]="longitude" (mapReady)="onMapReady($event)">
   <!--when entered coordinates and address getting pinpoint-->
  <agm-marker [latitude]="searchlatitude" [longitude]="searchlongitude" [markerDraggable]="true"></agm-marker>
  <agm-marker-cluster>
    <div *ngIf="showAllVehicles; else showOneVehicle">
      <agm-marker *ngFor="let vehicle of apiVehiclesData" [latitude]="vehicle.latitude" [longitude]="vehicle.longitude"
                  [iconUrl]="$any(vehicle.vehicleStatus)" (mouseOut)="onMouseOut(vehicle.vehicleId, vehicle.vehicleRegNo)" (mouseOver)="onMouseClick(vehicle.vehicleId, vehicle.vehicleRegNo)" (markerClick)="onMarkerClicked(vehicle.vehicleId, vehicle.vehicleRegNo)">
        <agm-info-window *ngIf="currentSelectedmarker !=null" [isOpen]="currentSelectedmarker.vehicleId == vehicle.vehicleId">
          <p class="txt-gry fs-12 mb-2"> Reg No: <a><span class="text-dark fw-bold">{{currentSelectedmarker.vehicleRegNo}}</span></a></p>
          <p class="txt-gry fs-12 mb-2"> Id: <span class="text-dark fw-bold">{{currentSelectedmarker.vehicleId}}</span></p>
          <p class="txt-gry fs-12 mb-2"> Company: <span class="text-dark fw-bold">{{currentSelectedmarker.contact}}</span></p>
          <p class="txt-gry fs-12 mb-2"> Type: <span class="text-dark fw-bold">{{currentSelectedmarker.model}}</span></p>
          <p class="txt-gry fs-12 mb-2"> Speed: <span class="text-dark fw-bold">{{currentSelectedmarker.speed}}</span></p>
          <p class="txt-gry fs-12 mb-1"> Address: <span class="text-dark fw-bold">{{currentSelectedmarker.address}}</span></p>
          <p class="txt-gry fs-12 mb-1"> Status: <span class="text-dark fw-bold">{{currentSelectedmarker.status}}</span></p>
        </agm-info-window>
      </agm-marker>
    </div>
    <ng-template #showOneVehicle>
      <agm-marker [latitude]="currentSelectedVehicle.latitude" [longitude]="currentSelectedVehicle.longitude"
                  [iconUrl]="$any(currentSelectedVehicle.vehicleStatus)"  (mouseOut)="onMouseOut(currentSelectedVehicle.vehicleId, currentSelectedVehicle.vehicleRegNo)"  (mouseOver)="onMouseClick(currentSelectedVehicle.vehicleId, currentSelectedVehicle.vehicleRegNo)" (markerClick)="onMarkerClicked(currentSelectedVehicle.vehicleId, currentSelectedVehicle.vehicleRegNo)" (markerRightClick)="onRightClick($event)" >
        <agm-info-window *ngIf="currentSelectedmarker !=null" [isOpen]="currentSelectedmarker.vehicleId == currentSelectedVehicle.vehicleId">
          <span> Reg No: <a>{{currentSelectedmarker.vehicleRegNo}}</a></span><br />
          <span> Id: {{currentSelectedmarker.vehicleId}}</span><br />
          <span> Company{{currentSelectedmarker.contact}}</span><br />
          <span> Type: {{currentSelectedmarker.model}}</span><br />
          <span> Speed: {{currentSelectedmarker.speed}}</span><br />
          <span> Address: {{currentSelectedmarker.address}}</span><br />
          <span> Status: {{currentSelectedmarker.status}}</span><br />
        </agm-info-window>
      </agm-marker>
    </ng-template>

    <div *ngIf="!showSchedules && (currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">
      <agm-marker *ngFor="let bin of routeBinStart"
                  [latitude]="bin.latitude"
                  [longitude]="bin.longitude">
      </agm-marker>
      <agm-marker *ngFor="let bin of routeBinEnd"
                  [latitude]="bin.latitude"
                  [longitude]="bin.longitude" [iconUrl]="$any(endPointIcon)">
      </agm-marker>
      <agm-marker *ngFor="let cords of routeCords"
                  [latitude]="cords.latitude"
                  [longitude]="cords.longitude"
                  [iconUrl]="$any(dotIcon)" (markerClick)="onDotClicked(cords.id)">

        <agm-info-window *ngIf="currentSelectedRoutes != null" [isOpen]="currentSelectedRoutes.id == cords.id">
          <span class="pr-3 mb-1"> Id: <a>{{currentSelectedRoutes.id}}</a></span><br />
          <span class="mb-1"> latitude: <a>{{currentSelectedRoutes.latitude}}</a></span><br />
          <span class="mb-1"> longitude: {{currentSelectedRoutes.longitude}}</span><br />
          <span class="mb-1"> deviceTime: {{currentSelectedRoutes.deviceTime}}</span><br />
        </agm-info-window>
      </agm-marker>
      <agm-polyline [visible]="true" [strokeWeight]="3" [strokeColor]="'#07b57a'">
        <agm-polyline-point *ngFor="let cords of routeCords"
                            [latitude]="cords.latitude"
                            [longitude]="cords.longitude">
        </agm-polyline-point>
        <agm-icon-sequence [strokeColor]="'blue'" [repeat]="'10px'" [offset]="'0'" [strokeWeight]="1.5" [scale]="0.5" [strokeOpacity]="50" [path]="'M 0,-1 0,1'">
        </agm-icon-sequence>
      </agm-polyline>
    </div>
    <div *ngIf="showBins">
      <!--agm-circle *ngFor="let bin of totalBins" [latitude]="bin.coords.latitude" [longitude]="bin.coords.longitude" (mouseOver)="onBinClicked(bin.binScheduleLskId)"
                  [radius]="bin.ActivityCode == 'KI' && kiBinGeofence ? 30 : 0"
                  [fillColor]="'red'">
      </agm-circle>
      <agm-circle *ngFor="let bin of totalBins" [latitude]="bin.coords.latitude" [longitude]="bin.coords.longitude" (mouseOver)="onBinClicked(bin.binScheduleLskId)"
                  [radius]="bin.ActivityCode == 'KB' && kbBinGeofence ? 30 : 0"
                  [fillColor]="'green'" >
      </agm-circle-->

      <!--div *ngFor="let bin of totalBins">
        <span *ngIf="bin.ActivityCode == 'KI' && kiBinToggle">
          <agm-marker [latitude]="bin.coords.latitude" [longitude]="bin.coords.longitude"
                      [iconUrl]="$any(bin.coords.finalBin)" (markerClick)="onBinClicked(bin.binScheduleLskId)">
            <agm-info-window *ngIf="currentSelectedBins != null" [isOpen]="currentSelectedBins.binScheduleLskId == bin.binScheduleLskId">
              <p class="pr-3 mb-1"> Id: <a>{{currentSelectedBins.binScheduleLskId}}</a></p>
              <p class="pr-3 mb-1"> Bin Type: <a>{{currentSelectedBins.ActivityCode}}</a></p>
              <p class="mb-1"> latitude: <a>{{currentSelectedBins.coords.latitude}}</a></p>
              <p class="mb-1"> longitude: {{currentSelectedBins.coords.longitude}}</p>
              <p class="mb-1"> Status: {{currentSelectedBins.flagUpdate}}</p>
              <p class="mb-1"> Colected By: <a>{{currentSelectedBins.vehicleRegNo}}</a></p>
              <p class="mb-1"> Collected Time: {{currentSelectedBins.collectedTime | date: "dd-MM-yyyy, h:mm a"}}</p>
            </agm-info-window>
          </agm-marker>
        </span>

        <span *ngIf="bin.ActivityCode == 'KB' && kbBinToggle">
          <agm-marker [latitude]="bin.coords.latitude" [longitude]="bin.coords.longitude"
                      [iconUrl]="$any(bin.coords.finalBin)" (markerClick)="onBinClicked(bin.binScheduleLskId)">
            <agm-info-window *ngIf="currentSelectedBins != null" [isOpen]="currentSelectedBins.binScheduleLskId == bin.binScheduleLskId">
              <p class="pr-3 mb-1"> Id: <a>{{currentSelectedBins.binScheduleLskId}}</a></p>
              <p class="mb-1"> latitude: <a>{{currentSelectedBins.coords.latitude}}</a></p>
              <p class="mb-1"> longitude: {{currentSelectedBins.coords.longitude}}</p>
              <p class="mb-1"> Colected By: <a>{{currentSelectedBins.vehicleRegNo}}</a></p>
              <p class="mb-1"> Collected Time: {{currentSelectedBins.collectedTime | date: "dd-MM-yyyy, h:mm a"}}</p>
            </agm-info-window>
          </agm-marker>
        </span>
      </div-->
  <!--agm-marker-cluster
      class="map-cluster"
      [maxZoom]="clulster_max_zoom"
      [minimumClusterSize]="minClusterSize"
      [styles]="mapOptions.styles"
      [calculator]="mapOptions.calculator"
      [maxZoom]="6"-->
      
      <agm-marker *ngFor="let bin of filterBin" 
                  [latitude]="bin.latitude"
                  [longitude]="bin.longitude"
                  [iconUrl]="bin.activity_code == 'KI' ? (bin.bin_status == 'C' ? $any(kiBinCollected) :$any(kiBin)) : (bin.bin_status == 'C' ? $any(kbBinCollected) :$any(kbBin))"             
                  (markerClick)="onBinClicked(bin.id)">

          <!--agm-info-window  #infowindow2 >
            <div>
        
              <span class="pr-3 mb-1"> Id: <a>{{currentSelectedBins.id}}</a></span><br />
              <span class="mb-1"> latitude: <a>{{currentSelectedBins.latitude}}</a></span><br />
              <span class="mb-1"> longitude: {{currentSelectedBins.longitude}}</span><br />
          </div>
           
          </agm-info-window-->

          <agm-info-window *ngIf="currentSelectedBins != null" [isOpen]="currentSelectedBins.id == bin.id">

            <div>
        
                <span class="pr-3 mb-1"> id: <a>{{currentSelectedBins.id}}</a></span><br />
                <span class="mb-1"> scheme: <a>{{currentSelectedBins.scheme}}</a></span><br />
                <span class="mb-1"> route: {{currentSelectedBins.route}}</span><br />
                <span class="mb-1"> park: <a>{{currentSelectedBins.park}}</a></span><br />
                <span class="mb-1"> road name: {{currentSelectedBins.road_name}}</span><br />
                <span class="mb-1"> road no: <a>{{currentSelectedBins.road_id}}</a></span><br />
                <span class="mb-1"> premise type: {{currentSelectedBins.premisetype}}</span><br />
                <span class="mb-1"> company: <a>{{currentSelectedBins.company}}</a></span><br />
                <span class="mb-1"> frequency: {{currentSelectedBins.frequency}}</span><br />
                <span class="mb-1"> bin type: <a>{{currentSelectedBins.bintype}}</a></span><br />
                <span class="mb-1"> bin quantity: <a>{{currentSelectedBins.bin_quantity}}</a></span><br />
                <span class="mb-1"> schedule_no: {{currentSelectedBins.schedule_no}}</span><br />
                <span class="mb-1"> schedule vehicle: {{currentSelectedBins.schedule_vehicle}}</span><br />
                <span class="mb-1"> pdib_no: <a>{{currentSelectedBins.pdib_no}}</a></span><br />
                <span class="mb-1"> latitude: <a>{{currentSelectedBins.latitude}}</a></span><br />
                <span class="mb-1"> longitude: {{currentSelectedBins.longitude}}</span><br />
                <span class="mb-1"> bin status: {{currentSelectedBins.bin_status}}</span><br />               
                <span class="mb-1"> collected by: {{currentSelectedBins.collected_by}}</span><br />
                <span class="mb-1"> collected time: <a>{{currentSelectedBins.collected_time}}</a></span><br />
                
            </div>
            
          </agm-info-window >
          
      </agm-marker>
    <!--/agm-marker-cluster-->
      <agm-circle *ngFor="let bin of filterGeofence" [latitude]="bin.latitude" [longitude]="bin.longitude" 
                  [radius]="30"
                  [fillColor]="(bin.bin_status == 'C') ? 'green' :'red'"
                  >
      </agm-circle>
    </div>
  </agm-marker-cluster>
</agm-map>
