import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigGroups } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-groups',
  templateUrl: './config-groups.component.html',
  styleUrls: ['./config-groups.component.scss']
})
export class ConfigGroupsComponent implements OnInit {

  @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
  @ViewChild('dt', { static: true }) table!: ElementRef;

  dataDialog: boolean = false;

  datas: ConfigGroups[]= [];

  data: ConfigGroups = {ModuleConfigGroups:{}};

  modalAssignVehicle: boolean = false;

  assignedVehicleList: any[] = [];

  selectedDatas: any[] = [];

  submitted: boolean = false;

  statuses: any[] = ['Active', 'Inactive'];

  items: MenuItem[] = [];

  loading: boolean = true;

  menuitems: MenuItem[]=[];

  vehicle: any[] = [];

  selectedVehicle: any;

  isCreateNew = true;

  showLoader: boolean = false;
  
  Id: any[] = [];

  configGroupsParam =  { id: null, isactiveonly: true };

  isLoading: boolean = true;

  nameFormControl = new FormControl('', [
      Validators.required,
  ]);

  configGroupsForm = this.formBuilder.group({
    
      Name: this.nameFormControl,
      Description: '',
      Active: true,
      createdBy: localStorage.getItem('username'),
      // created: null,
  });

  constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder, private router:Router) { }

  ngOnInit(): void {
      this.getDatas();


      this.getAllModuleVehicle();

      this.items = [
          {label: 'Edit', icon: 'pi pi-pencil', command: () => {
              this.editData(this.data);
          }},
          {label: 'Delete', icon: 'pi pi-trash', command: () => {
              this.deleteData(this.data);
          }},
          {label: 'Assign Vehicle', icon: 'pi pi-pencil', command: () => {
            this.assignVehicle(this.data);
        }}
      ];

      this.menuitems = GlobalComponent.submenus;
  }

  getDatas(){
    this.isLoading = true;
      this.commonService.getAllConfigGroups(this.configGroupsParam).subscribe((resp: any) => {

          if (resp.length != 0) {
              this.datas = resp.Result;
              this.isLoading = false;
            
          } else {
            
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
          
      }
    
  }

  getAllModuleVehicle(){
    this.commonService.getAllConfigModuleVehicle(this.configGroupsParam).subscribe((resp: any) => {
        if (resp.length != 0) {
            this.vehicle = resp.Result;
            this.loading = false;              
        } else {
          
        }
    }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
    }
}

  onSubmit(): void {

      this.submitted = true;
      if (this.configGroupsForm.valid) {
          if (this.isCreateNew == true) {
              this.createConfigGroups();
          } else {
              this.updateConfigGroups();
          }
      }
  }

  selectData(data: any){
      this.data = data;
      
  }

  clearselectData(){
      this.selectedDatas = [];
  }

  openNew() {
      this.data= { ModuleConfigGroups: {}};
      this.isCreateNew = true;
      this.submitted = false;
      this.dataDialog = true;
  }

  
  hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
      this.modalAssignVehicle = false;
      this.isCreateNew = true;

  }

  editData(data: any) {
      this.data = {...data};
      this.dataDialog = true;
      this.isCreateNew = false;
      this.Id = data.Id;
  }

  createConfigGroups(){
    this.isLoading = true;
      this.commonService.createConfigGroups(this.configGroupsForm.value).subscribe((resp: any) => {

          if (resp.Success == true) {
              this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
              this.isLoading = false;
              this.dataDialog = false;
              //this.isCreateNew=true;
              this.getDatas();
            
          } else {
              this._appSharedService.showError('Error', "Something went wrong!");
              this.isLoading = false;
          }
      }), (error: any) => {

          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }

      this.isCreateNew = false;
  }

  updateConfigGroups(){
    this.isLoading = true;
      this.configGroupsForm.value.id = this.Id;

      this.commonService.updateConfigGroups(this.configGroupsForm.value).subscribe((resp: any) => {

          if (resp.Success == true) {
              this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
              this.isLoading = false;
              this.dataDialog = false;
              this.getDatas();
            
          } else {
              this._appSharedService.showError('Error', "Something went wrong!");
              this.isLoading = false;
          }
      }), (error: any) => {

          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }

      //this.isCreateNew = false;
  }

  deleteData(data: any) {
    this.isLoading = true;
      data.ModifiedBy = localStorage.getItem('username');
      data.Active = false;
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + data.Name + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {

              this.commonService.deleteConfigGroups(data).subscribe((resp: any) => {

                  if (resp.Success == true) {
                      this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                      this.isLoading = false;
                      this.dataDialog = false;
                      this.getDatas();
                    
                  } else {
            
                      this._appSharedService.showError('Error', "Something went wrong!");
                      this.isLoading = false;
                  }
              }), (error: any) => {
          
                  this._appSharedService.showError('Error', "Something went wrong!");
                  this.isLoading = false;
              }
          }
      });
  }

  findIndexById(Id: string): number {
      let index = -1;
      for (let i = 0; i < this.datas.length; i++) {
          if (this.datas[i].Id === Id) {
              index = i;
              break;
          }
      }

      return index;
  }

  exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.datas);
          worksheet['!cols'] = [];
          worksheet['!cols'][0] = { hidden: true };
          worksheet['!cols'][4] = { hidden: true };
          
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          /* save to file */
          XLSX.writeFile(workbook, 'ConfigRole.xlsx');
      
      });
  }



  onChangeFilter(event: any) {
    this.selectedVehicle = event.value; 
}

  //VEHICLE
  assignVehicleForm(data: any){
    this.showLoader = true;
    var dataAssignVehicle = {
        "groupId": data.Id,
        "vehicleId": this.selectedVehicle.Id
    };
    // var dataAssignCompany = {
    //     "userId": data.Id,
    //     "companyId": this.selectedCompany.Id
    // };
    this.commonService.AssignGroupVehicle(dataAssignVehicle).subscribe((resp: any) => {
    // this.commonService.userAssignCompany(dataAssignCompany).subscribe((resp: any) => {
        if (resp.Success == true) {
            var userId = {"groupid": data.Id};
            this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
            // this.dataDialog = false;
            this.getAssignedVehicleList(data);
        } else {
            this._appSharedService.showError('Error', "Something went wrong!");
        }

        this.showLoader = false;

    }), (error: any) => {

        this._appSharedService.showError('Error', "Something went wrong!");
    }

}



  getAssignedVehicleList(data: any){
    var groupId = {"id": data.Id};
    // var userId = {"userId": data.Id};
    this.showLoader = true;

    this.commonService.GetGroupVehicleByGroupID(groupId).subscribe((resp: any) => {
    // this.commonService.getAssignedCompanyByUser(userId).subscribe((resp: any) => {
        if (resp.Total != 0) {
          this.assignedVehicleList = resp.Result;
        } else {
          this.assignedVehicleList = [];
        }
        this.showLoader = false;
    }), (error: any) => {
      this._appSharedService.showError('Error', "Something went wrong with groups schedule!");
    }
}

  assignVehicle(data: any){
    this.modalAssignVehicle = true;
    this.getAssignedVehicleList(data);
}

unassignVehicle(data: any){
    var dataUnassignVehicle = {
            "GroupId": data.GroupId,
            "VehicleId": data.VehicleId
        };
    this.confirmationService.confirm({
        message: 'Are you sure you want to unassign ' + data.DetailDescription + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.showLoader = true;

            // this.commonService.deleteAssignedCompany(data).subscribe((resp: any) => {
            this.commonService.UnassignVehicle(dataUnassignVehicle).subscribe((resp: any) => {

                if (resp.Success == true) {
                    this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                    this.getAssignedVehicleList(this.data);
                } else {
                    this._appSharedService.showError('Error', "Something went wrong!");
                }

                this.showLoader = false;

            }), (error: any) => {
        
                this._appSharedService.showError('Error', "Something went wrong!");
            }
        }
    });
}

}


