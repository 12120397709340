<div class="w-100">
  <div class="bg-white  rounded p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <h4 class="txt-gry  font-weight-bold">Route</h4>
            <button class="btn btn-primary" (click)="addShowDialog()">Add Route</button>
          </div>
          <hr class="my-3" />
        </div>
        <div class="col-md-12">
          <div class="device_data mt-3">
            <div class="card" *ngIf="dataPresent == true;else noRoutes">
              <p-table [value]="routes" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Route Id</th>
                    <th>Route Name</th>
                    <th style="width:75px;">Status</th>
                    <th style="width:75px;">Edit</th>
                    <th style="width:75px;">Delete</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                  <tr>
                    <td>{{customer.routeId}}</td>
                    <td>{{customer.name}}</td>
                    <td><button style="width:38px;height:38px;" [ngClass]="customer.status ? 'btn btn-success rounded-circle fa fa-check':'btn btn-warning rounded-circle fa fa-close'" class=" fs-12"></button></td>
                    <td><button class="btn btn-warning rounded-circle fs-12 lh-23" (click)="editShowDialog(customer.routeId)"><i class="fa fa-pencil"></i></button></td>
                    <td><button class="btn btn-danger rounded-circle fs-12 lh-23" (click)="deleteRoute(customer.routeId)"><i class="fa fa-trash"></i></button></td>
                  </tr>
                </ng-template>
                <!--  <ng-template pTemplate="paginatorleft">
                  <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
                </ng-template>
                <ng-template pTemplate="paginatorright">
                  <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
                </ng-template>-->
              </p-table>
            </div>
            <ng-template #noRoutes>
              <span>
                No Routes to Display
              </span>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="w-100">
        <p-dialog [(visible)]="addDisplay" [style]="{width: '50vw'}" [modal]="true" [baseZIndex]="10000">
          <ng-template pTemplate="header">
            <h5 class="txt-dgry p-3 border-bottom w-100">Add Route</h5>
          </ng-template>
          <div class="forms_group overflow-hidden">
            <form [formGroup]="routeForm" (ngSubmit)="addRoute()">
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Route Name</label>
                <input type="text" class="form-control" placeholder="Route Name" formControlName="name" />
              </div>
              <!--<div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Bins</label>
                <input type="text" class="form-control" placeholder="Bins" formControlName="bins" />
              </div>-->
              <div class="d-flex justify-content-center">
                <button class="btn btn-light w-25" (click)="addCancLBtn()">Cancel</button>
                <button class="btn btn-success w-25" type="submit">Save</button>
              </div>
            </form>
          </div>
        </p-dialog>
      </div>

      <div class="w-100">
        <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" key="myDialog"></p-confirmDialog>
      </div>

      <div class="w-100">
        <p-dialog [(visible)]="editDisplay" [style]="{width: '50vw'}" [modal]="true" [baseZIndex]="10000">
          <ng-template pTemplate="header">
            <h5 class="txt-dgry p-3 border-bottom w-100">Edit Route</h5>
          </ng-template>
          <div class="forms_group overflow-hidden">
            <form [formGroup]="routeForm" (ngSubmit)="editRoute()">
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Route Name</label>
                <input type="text" class="form-control" placeholder="Route Name" formControlName="name" />
              </div>
              <!--<div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Bins</label>
                <input type="text" class="form-control" placeholder="Bins" formControlName="bins" />
              </div>-->
              <div class="forms_group toggle_switch my-2 mx-3">
                <div class=" align-items-center">
                  <div>Status</div>
                  <div>
                    <input type="checkbox" id="switch" formControlName="status" /><label for="switch">Toggle</label>
                  </div>
                </div>
              </div>

            </form>
          </div>
          <!--   <div class="d-flex justify-content-center mt-3">

             </div>-->
          <ng-template pTemplate="footer">
            <button class="btn btn-light w-25" (click)="editCancLBtn()">Cancel</button>
            <button class="btn btn-success w-25" type="submit">Save</button>
          </ng-template>
        </p-dialog>
      </div>
    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>
