<div class="header_admin ">
  <div class="w-100">
    <nav class="navbar navbar-expand-lg navbar-light bg-white  inpu-shadows1 fixed-top" >
      <a class="navbar-brand" href="#"> <img src="./assets/images/brand-imgs/logo-mfloratel-black.png" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle cursor-pointer" id="navbarDarkDropdownMenuLink" data-toggle="dropdown"><span class=" p-1 mx-1" style="font-size:18px;" ><i class="fa fa-user-circle"></i></span>Profile</a>
            <ul class="dropdown-menu dropdown-menu-dark " aria-labelledby="navbarDarkDropdownMenuLink" style="left:-50px;" >
              <li><a class="dropdown-item my-2"><i class="fa fa-user" aria-hidden="true"></i>
                <span class="mx-3">My Profile</span></a></li>
              <li><a class="dropdown-item my-2"><i class="fa fa-cog"></i> <span class="mx-3">Settings</span></a></li>
              <li><a class="dropdown-item my-2" (click)="logout()"><i class="fa fa-sign-out"></i> <span class="mx-3">Logout</span></a></li>
            </ul>
          </li>

        </ul>
      </div>
    </nav>
  </div>
</div>
