import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './pages/layout/layout.component';
import { PocComponent } from './pages/poc/poc.component';
import { LoginComponent } from './auth/login/login.component';
import { VehicleStatusComponent } from './pages/vehicle-status/vehicle-status.component';
import { RouteComponent } from './pages/route/route.component';
import { BackupVehiclesComponent } from './pages/backup-vehicles/backup-vehicles.component'
import { MapComponent } from './pages/map/map.component';
import { BinDetailsComponent } from './pages/route/bin-details/bin-details.component';
import { TripsComponent } from './pages/trips/trips.component';
import { EventsComponent } from './pages/events/events.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { UsersComponent } from './pages/settings/users/users.component';
import { PdibComponent } from './pages/settings/pdib/pdib.component';
import { PointOfCollectionComponent } from './pages/settings/point-of-collection/point-of-collection.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { ChangePasswordComponent } from '../app/pages/shared/change-password/change-password.component';
import { AdminModule } from '../app/admin/admin.module';
import { UserAuthGuard } from './auth/user-auth.guard';
import { ConfigMenuComponent } from './pages/settings/config-menu/config-menu.component';
import { ConfigRoleComponent } from './pages/settings/config-role/config-role.component';
import { ConfigSchemeComponent } from './pages/settings/config-scheme/config-scheme.component';
import { ConfigActivityTypeComponent } from './pages/settings/config-activity-type/config-activity-type.component';
import { ConfigCategoryTypeComponent } from './pages/settings/config-category-type/config-category-type.component';
import { ConfigParkComponent } from './pages/settings/config-park/config-park.component';
import { ConfigPBTComponent } from './pages/settings/config-pbt/config-pbt.component';
import { ConfigPremiseTypeComponent } from './pages/settings/config-premise-type/config-premise-type.component';
import { ConfigRoadComponent } from './pages/settings/config-road/config-road.component';
import { ConfigRouteComponent } from './pages/settings/config-route/config-route.component';
import { ConfigServiceAreaComponent } from './pages/settings/config-service-area/config-service-area.component';
import { ConfigStateComponent } from './pages/settings/config-state/config-state.component';
import { ConfigSubRouteComponent } from './pages/settings/config-sub-route/config-sub-route.component';
import { ConfigCityComponent } from './pages/settings/config-city/config-city.component';
import { ConfigModulevehicleComponent } from './pages/settings/config-modulevehicle/config-modulevehicle.component';
import { ConfigGroupsComponent } from './pages/settings/config-groups/config-groups.component';
import { ConfigVehicletypeComponent } from './pages/settings/config-vehicletype/config-vehicletype.component';
import { ConfigBintypeComponent } from './pages/settings/config-bintype/config-bintype.component';
import { ConfigMenutypeComponent } from './pages/settings/config-menutype/config-menutype.component';
import { ConfigModulecompanyComponent } from './pages/settings/config-modulecompany/config-modulecompany.component';
import { ConfigStatusComponent } from './pages/settings/config-status/config-status.component';
import { ConfigPreferenceSettingComponent } from './pages/settings/config-preference-setting/config-preference-setting.component';
import { ConfigPermissionComponent } from './pages/settings/config-permission/config-permission.component';
import { VehicleShiftComponent } from './pages/vehicle-shift/vehicle-shift.component';
import { VehicleStatusDetailsComponent } from './pages/vehicle-status-details/vehicle-status-details.component'
import { ConfigModuleActionLogComponent } from './pages/settings/config-module-action-log/config-module-action-log.component';
import { VehiclePendingTaskComponent } from './pages/vehicle-pending-task/vehicle-pending-task.component';

import { ModuleBhkComponent } from './pages/module-bhk/module-bhk.component';

import { ReportStopComponent } from './pages/report-stop/report-stop.component';
import { ReportRouteComponent } from './pages/report-route/report-route/report-route.component';
import { ReportTripsComponent } from './pages/report-trips/report-trips/report-trips.component';
import { ReportSummaryComponent } from './pages/report-summary/report-summary/report-summary.component';
import { ReportDailysummaryComponent } from './pages/report-dailysummary/report-dailysummary/report-dailysummary.component';

const routes: Routes = [
  {
    path: "admin",
    loadChildren: () => AdminModule
  },
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'change-password', component: ChangePasswordComponent},
  {
    canActivate: [UserAuthGuard],
    path: 'home', component: LayoutComponent, children: [
      // { path: '', component: VehicleStatusComponent},
     
      { path: 'live-tracking', component: VehicleStatusComponent },
      { path: 'route', component: RouteComponent },
      { path: 'backup-vehicles', component: BackupVehiclesComponent },
      { path: 'vehicle-shift', component: VehicleShiftComponent },
      { path: 'bin-details/:id', component: BinDetailsComponent },
      { path: 'trips', component: TripsComponent },
      { path: 'events', component: EventsComponent },
      { path: 'analytics', component: AnalyticsComponent },
      { path: 'coming-soon', component: ComingSoonComponent },
      { path: 'poc', component: PocComponent },
      { path: 'settings/users', component: UsersComponent },
      { path: 'settings/pdib', component: PdibComponent },
      { path: 'settings/point-of-collection', component: PointOfCollectionComponent },
      { path: 'settings/config-menu', component: ConfigMenuComponent },
      { path: 'settings/config-role', component: ConfigRoleComponent },
      { path: 'settings/config-scheme', component: ConfigSchemeComponent },
      { path: 'settings/config-activity-type', component: ConfigActivityTypeComponent },
      { path: 'settings/config-category-type', component: ConfigCategoryTypeComponent },
      { path: 'settings/config-park', component: ConfigParkComponent },
      { path: 'settings/config-pbt', component: ConfigPBTComponent },
      { path: 'settings/config-premise-type', component: ConfigPremiseTypeComponent },
      { path: 'settings/config-road', component: ConfigRoadComponent },
      { path: 'settings/config-route', component: ConfigRouteComponent },
      { path: 'settings/config-service-area', component: ConfigServiceAreaComponent },
      { path: 'settings/config-state', component: ConfigStateComponent },
      { path: 'settings/config-sub-route', component: ConfigSubRouteComponent },
      { path: 'settings/config-city', component: ConfigCityComponent },
      { path: 'settings/config-modulevehicle', component: ConfigModulevehicleComponent },
      { path: 'settings/config-groups', component: ConfigGroupsComponent },
      { path: 'settings/config-vehicletype', component: ConfigVehicletypeComponent },
      { path: 'settings/config-bintype', component: ConfigBintypeComponent },
      { path: 'settings/config-menutype', component: ConfigMenutypeComponent },
      { path: 'settings/config-modulecompany', component: ConfigModulecompanyComponent },
      { path: 'settings/config-status', component: ConfigStatusComponent },
      { path: 'settings/config-preference-setting', component: ConfigPreferenceSettingComponent },
      { path: 'settings/config-permission', component: ConfigPermissionComponent },
      { path: 'settings/config-module-action-log', component: ConfigModuleActionLogComponent },
      { path: 'vehicle-pending-task', component: VehiclePendingTaskComponent },
      { path: 'module-bhk', component: ModuleBhkComponent },
      

      { path: 'report-stop', component: ReportStopComponent },
      { path: 'report-route', component: ReportRouteComponent },
      { path: 'report-trips', component: ReportTripsComponent },
      { path: 'report-summary', component: ReportSummaryComponent },
      { path: 'report-dailysummary', component: ReportDailysummaryComponent },
      

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
