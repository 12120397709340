<p-card styleClass="p-card-content-0">

  <p-menubar [model]="menuitems">
  </p-menubar>

  <h5 style="margin-top: 1.0rem; margin-left:1.1rem; padding-bottom: 22px;">Report Stop</h5>
  <div class="all-vehicle-details custom-scrollbar">
    <div class="w-100 result-found-blocks">
      <div class="result-found-list found-fonts fs-10 lh-12">
        <div class="w-100 tableFixHead">
          <div class="ptable-css ptable-scrollable">

                <div class="row w-100 justify-content-between mt-5" style="margin-left: -0.1rem;">
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0" style="width: 100%;">
                    <li class="d-flex align-items-center nav-item w-auto pr-0" >
                      <div class="range-calendar position-relative" style="width: 100%;">
                        <p-calendar selectionMode="range" [(ngModel)]="range_date" dateFormat="dd/mm/yy"
                          [maxDate]="dateTime" [showIcon]="true" placeholder="Select Range Date" class="custom-report"
                          (ngModelChange)="parseDate($event)" [showIcon]="true" inputId="icon"></p-calendar>
                      </div>
                    </li>
                  </div>
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0">
                      <div class="fillet-boxs-css range-calendar position-relative" style="width: 100%;">
                        <p-dropdown [options]="vehicleList" [(ngModel)]="selectedVehicle" optionLabel="vehicleRegNo"
                          [filter]="true" filterBy="vehicleRegNo" [showClear]="true" placeholder="Select a Vehicle"
                          (onChange)="parseDate($event)" class="custom-bhk-dropdown">
                          <ng-template pTemplate="selectedItem">
                            <div class="filler-h-item" *ngIf="selectedVehicle">
                              <div>{{selectedVehicle.vehicleRegNo}}</div>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </div>
                    </li>
                  </div>
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0" >
                      <div class="range-calendar" style="width: 100%;">
                        <span class="p-input-icon-right" style="width: 100%">
                          <i class="pi pi-search" ></i>
                          <input pInputText type="text" style="width: 100%; height: 40px" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Search..." />
                        </span>
                      </div>
                    </li>
                  </div>
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0">
                      <div class="export-button" style="width: 100%;; height:48px">
                        <button type="button"  style="width: 100%; ; height:39px" class="border px-3 py-2 filter-bt-btm bg-transparent" (click)="exportToExcel(selectedVehicleRegNo)"><i class="	fa fa-download"></i> Export</button>
                      </div>
                    </li>
                  </div>
                </div>

            <div #dtMain>
              <p-table #dt [value]="datas" [rows]="10" [paginator]="true"
                [globalFilterFields]="['deviceName','startTime', 'endOdometer','address','endTime','duration','engineHours', 'spentFuel']"
                [(selection)]="selectedDatas" [rowHover]="true" dataKey="id"
                styleClass="p-datatable-striped p-datatable-sm" [loading]="loading" responsiveLayout="scroll"
                [rowsPerPageOptions]="[10,25,50]" [scrollable]="true" scrollHeight="60vh"
                [tableStyle]="{'max-width': '100vw'}"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                  <div class="flex align-items-center justify-content-between" style="margin-left: -0.8rem;">
                    <div class="flex align-items-center justify-content-between summary-message" >
                      {{ datas ? datas.length : 0 }} result(s) found .
                      <span style="color: #19A3F1; border-bottom: 1px solid; cursor: pointer;"
                        (click)="clearFilterData()">Clear Filter </span>
                      &nbsp;
                    </div>
                  </div>
                </ng-template>

                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="deviceName" style="width:150px">Vehicle<p-sortIcon
                        field="deviceName"></p-sortIcon></th>
                    <th pSortableColumn="startTime" style="width:150px">Start Time<p-sortIcon
                        field="startTime"></p-sortIcon></th>
                    <th pSortableColumn="endOdometer" style="width:150px">Odometer<p-sortIcon
                        field="endOdometer"></p-sortIcon></th>
                    <th pSortableColumn="address" style="width:150px">Address<p-sortIcon
                        field="address"></p-sortIcon></th>
                    <th pSortableColumn="endTime" style="width:150px">End Time <p-sortIcon
                        field="endTime"></p-sortIcon></th>
                    <th pSortableColumn="duration" style="width:150px">Duration<p-sortIcon
                        field="duration"></p-sortIcon></th>
                    <th pSortableColumn="engineHours" style="width:150px">Engine Hours <p-sortIcon
                        field="engineHours"></p-sortIcon></th>
                    <!-- <th pSortableColumn="spentFuel" style="width:150px">Spent Fuel<p-sortIcon
                        field="spentFuel"></p-sortIcon></th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-datas>
                  <tr class="p-selectable-row">
                    <td style="width:150px">{{datas.deviceName}}</td>
                    <td style="width:150px">{{datas.startTime | date:'dd/MM/yyyy hh:mm a'}}</td>
                    <td style="width:150px">{{(datas.endOdometer / 1000).toFixed(2)}} km</td>
                    <td style="width:150px">{{datas.address}}</td>
                    <td style="width:150px">{{datas.endTime | date:'dd/MM/yyyy hh:mm a'}}</td>
                    <td style="width:150px">{{datas.duration /1000 | duration}}</td>
                    <td style="width:150px">{{datas.engineHours /1000 | duration}} </td>
                    <!-- <td style="width:150px">{{datas.spentFuel.toFixed(1)}} | </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="8" class="empty-message">No data found.</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-between summary-message">
                    In total there are {{datas ? datas.length : 0 }} record(s).
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-card>