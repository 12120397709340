import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { HeaderComponent } from './shared/header/header.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { CompanyComponent } from './company/company.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { DeviceComponent } from './device/device.component';
import { RouteComponent } from './route/route.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    AdminComponent,
    SidebarComponent,
    HeaderComponent,
    AdminLoginComponent,
    CompanyComponent,
    VehicleComponent,
    DeviceComponent,
    RouteComponent,
    ScheduleComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule, ToastModule,
    FormsModule, ReactiveFormsModule, TableModule, DropdownModule,DialogModule, ButtonModule, ConfirmDialogModule, MessagesModule
  ]
})
export class AdminModule { }
