import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import { MenuItem } from 'primeng/api';
import { VehiclePendingTask } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import {TabViewModule} from 'primeng/tabview';

@Component({
    selector: 'app-vehicle-pending-task',
    templateUrl: './vehicle-pending-task.component.html',
    styleUrls: ['./vehicle-pending-task.component.scss']
})

// 
export class VehiclePendingTaskComponent implements OnInit {

    dataDialog: boolean = false;
    datasBin: VehiclePendingTask[]= [];
    datasSchedule: VehiclePendingTask[]= [];
    dataBin: VehiclePendingTask = { ModuleVehiclePendingTask: {}};
    dataSchedule: VehiclePendingTask = { ModuleVehiclePendingTask: {}};
    items: MenuItem[] = [];
    loading: boolean = false;
    menuitems: MenuItem[]=[];
    schdlDate = "";
    showLoader: boolean = false;
    showVehicleLoader: boolean = false;
    loading_vehicle: boolean = false;
    activeIndex: number = 0;
    selectedDatas: any[] = [];
    vehicle_list: any[] = [];
    vehicle_list_filtered: any[] = [];
    service_area_list: any[] = [];
    scheme_list: any[] = [];
    route_list: any[] = [];
    route_list_filtered: any[] = [];
    subroute_list: any[] = [];
    subroute_list_filtered: any[] = [];
    configModuleVehicleParam =  { id: null, isactiveonly: true };
    currentDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    pending_bin = 0;
    pending_schedule = 0;
    total_schedule = 0;
    total_bin = 0;
    vehicle_no = "";
    company_name = '';
    random_color = "red";
    data_list: any[] = [];
    current_user: any = JSON.parse(localStorage.getItem('currentData') || '{}');
    filter_type = "";
    filter_scheme: any[] = [];
    p_subroute = '';
    disabled_service_area = true;
    disabled_scheme = true;
    disabled_route = true;
    disabled_sub_route = true;
    disabled_vehicle = true;
    selected_scheme = '';
    selected_route = '';
    selected_subroute = '';
    selected_vehicle = '';


    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder, public datepipe: DatePipe) {}

    ngOnInit(): void {
        this.filterByUser();
        // this.getAllVehicle();  
        this.getVehicleByUser();  
        this.getAllConfigServiceArea();
        this.getAllConfigScheme();
    }

    parseDate(e: any) {
        if(e != null){

            // this.service_area_list = [];
            this.filter_scheme = [];
            this.route_list_filtered = [];
            this.subroute_list_filtered = [];
            this.vehicle_list_filtered = [];


            this.disabled_service_area = false;
            this.disabled_scheme = true;
            this.disabled_route = true;
            this.disabled_sub_route = true;
            this.disabled_vehicle = true;

            this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
            this.currentDate = formatDate(new Date(e),'yyyy-MM-dd', 'en-US');

        }
        
    }

    parseServiceArea(e: any) {

        this.filter_scheme = [];
        this.route_list_filtered = [];
        this.subroute_list_filtered = [];
        this.vehicle_list_filtered = [];

        this.disabled_scheme = false;
        this.disabled_route = true;
        this.disabled_sub_route = true;
        this.disabled_vehicle = true;

        this.selected_scheme = '';


        this.scheme_list.forEach((item, key) => {
            if (item && item.ServiceArea != null && item.ServiceArea.Id == e.value.Id) {
                this.filter_scheme[key] = item;
            }
        });

        const distinctSchemeName = this.filter_scheme.map(item => item)
            .filter((value, index, self) => self.indexOf(value) === index).sort();

        this.filter_scheme = distinctSchemeName;

    }

    parseScheme(e: any) {

        this.route_list_filtered = [];
        this.subroute_list_filtered = [];
        this.vehicle_list_filtered = [];

        this.disabled_route = true;
        this.disabled_sub_route = true;
        this.disabled_vehicle = true;

        this.selected_route = '';

        var data = {
            Date: this.currentDate,
            SchemeId: e.value.Id
        }
        this.loading_vehicle = true;
        this.getRouteBySchemeDate(data);

    }

    parseRoute(e: any) {
        this.subroute_list_filtered = [];
        this.vehicle_list_filtered = [];

        this.disabled_vehicle = true;
        this.loading_vehicle = true;

        this.selected_subroute = '';

        var data = {
            Date: this.currentDate,
            RouteId: e.value.Id
        }

        this.getSubRouteByRouteAndDate(data);
    }

    parseSubRoute(e: any) {
        this.p_subroute = '';
        this.p_subroute = e.value.Name;

        this.vehicle_list_filtered = [];

        this.disabled_vehicle = true;
        this.loading_vehicle = true;

        this.selected_vehicle = '';


        var vehicle_param = {
            date: this.currentDate,
            subRoute: this.p_subroute
        }
        this.selected_vehicle = '';

        this.getVehicleByDateAndSubRoute(vehicle_param);
    }

    parseVehicle(e: any) {

        if(e.value.VehicleRegNo != null && this.currentDate != null){
            this.vehicle_no = e.value.VehicleRegNo;

            var data = {
                date: this.currentDate,
                vehicle_reg_no: e.value.VehicleRegNo,
                filter_type: this.filter_type,
                sub_route: this.p_subroute
            }

            this.getDatasBinPending(data);
            this.getDatasSchedulePending(data);
            this.getTotalScheduleBin(data);
        }
        else{
        }
            
    }

    getDatasBinPending(data: any){
        this.loading = true;
        this.data_list = [];
        this.datasBin = [];

        this.commonService.getBinPendingTask(data).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.pending_bin = resp.Total;
                this.company_name = resp.Result[0].company_name;
                resp.Result.forEach((currentValue: any, index: any) => {

                    this.data_list.push({
                        code: currentValue.code,
                        date: currentValue.date,
                        park: currentValue.park,
                        pdib_no: currentValue.pdib_no,
                        premise: currentValue.premise,
                        road: currentValue.road,
                        route: currentValue.route,
                        location: currentValue.location,
                        bin_type: currentValue.bin_type,
                        schedule_no: currentValue.schedule_no,
                        sub_route: currentValue.sub_route,
                        vehicle_reg_no: currentValue.vehicle_reg_no,
                        color: this.random_color,
                    });

                });

                this.datasBin = this.data_list;
                this.loading = false;

            } else {
                this.datasBin = [];
                this.pending_bin = 0;
                this.loading = false;
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getDatasSchedulePending(data: any){
        this.loading = true;

        this.commonService.getSchedulePendingTask(data).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.datasSchedule = resp.Result;
                this.pending_schedule = resp.Total;
                this.loading = false;
            } else {
                this.datasSchedule = [];
                this.pending_schedule = 0;
                this.loading = false;
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getTotalScheduleBin(data: any){
        this.commonService.getVehicleTotalTask(data).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.total_schedule = resp.Result['0'].total_schedule;
                this.total_bin = resp.Result['0'].total_bin;
                
                this.loading = false;
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getRandomColor(index: any) {

        if(index % 2 != 0){
            this.random_color = '#19A3F1';
        } else{
            this.random_color = '#20B86F';
        }
        
    }

    // getAllVehicle(){
    //     this.commonService.getAllConfigModuleVehicle(this.configModuleVehicleParam).subscribe((resp: any) => {
    //         if (resp.length != 0) {
    //             this.vehicle_list = resp.Result;
    //         }
    //     }), (error: any) => {
    //         this._appSharedService.showError('Error', "Something went wrong!");
    //     }
    // }
    getVehicleByUser(){
        this.commonService.getVehicleByUser(this.currentDate).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.vehicle_list = resp.Result;
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getAllConfigServiceArea(){
        this.commonService.getAllConfigServiceArea(this.configModuleVehicleParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.service_area_list = resp.Result;
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getAllConfigScheme(){
        this.commonService.getAllConfigScheme(this.configModuleVehicleParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.scheme_list = resp.Result;
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getRouteBySchemeDate(data: any){
        this.commonService.getRouteBySchemeAndDate(data).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.route_list_filtered = resp.Result;
                this.loading_vehicle = false;
                this.disabled_route = false;

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getSubRouteByRouteAndDate(data: any){
        this.commonService.getSubRouteByRouteAndDate(data).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.subroute_list_filtered = resp.Result;
                this.loading_vehicle = false;
                this.disabled_sub_route = false;

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getVehicleByDateAndSubRoute(param: any){
        this.commonService.getVehicleByDateAndSubRoute(param).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.vehicle_list_filtered = [];

                this.vehicle_list_filtered = resp.Result;

                this.loading_vehicle = false;
                this.disabled_vehicle = false;

            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    filterByUser(){
        if (this.current_user.Roles[0].Name == 'Administrator') {
            this.filter_type = 'all';
        } else {
            this.filter_type = 'default';
        }
    }

}
