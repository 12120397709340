import { Component, OnInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { combineLatest, map, Subject } from 'rxjs';
import { AppSharedService } from '../../app.SharedService';
import { AgmInfoWindow, MapsAPILoader } from '@agm/core';
import { SocketService } from '../../services/socket.service';
import { CommonService } from '../../services/common.service';
import { formatDate } from '@angular/common';
import { InfoWindow } from '@agm/core/services/google-maps-types';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';




@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnDestroy, OnInit {
  private geoCoder!: google.maps.Geocoder;
  @ViewChild('search')
  public searchElementRef!: ElementRef;
  private unsubscribe = new Subject<void>();

  lat: number = 0;
  lng: number = 0;
  zoom: number = 12;
  currentZoom: number = 0;
  defaultZoom: number = 12;
  latitude:any;
  longitude:any;
  searchlatitude:any;
  searchlongitude:any;
  address: any;
  apiVehiclesData: any[] = [];
  routeCords: any[] = [];
  binSplittedCoords: any[] = [];
  currentSelectedRoutes: any = null;
  currentSelectedBins: any = null;
  currentSelectedmarker: any = null;
  currentSelectedVehicle: any = null;
  MapFilters: boolean = false;
  showBins: boolean = false;
  showSchedules: boolean = false;
  kiBinGeofence: boolean = false;
  kbBinGeofence: boolean = false;
  showAllVehicles: boolean = true;
  bounds: any;
  date: any;
  totalBins: any;
  filterBin: any;
  filterGeofence: any;
  selectedCities: string[] = [];
  routeBinStart: any[] = [];
  routeBinEnd: any[] = [];
  fileterdBinIcon: any;
  selectedAddress: any = 'A';
  enteredAddress: any;
  coordinateSearch: string = 'val2';
  dotIcon = { url: '../../assets/images/web-112021/icons/blueDot.png', scaledSize: { height: 10, width: 10 } };
  endPointIcon = { url: '../../assets/images/web-112021/icons/endPoint.png', scaledSize: { height: 40, width: 40 } };
  kbBin = { url: '../../assets/images/web-112021/icons/ongoing-kb-bin.png', scaledSize: { height: 30, width: 30 } };
  kbBinCollected = { url: '../../assets/images/web-112021/icons/online-kb-bin.png', scaledSize: { height: 30, width: 30 } };
  kiBin = { url: '../../assets/images/web-112021/icons/ongoing-KI-bin.png', scaledSize: { height: 30, width: 30 } };
  kiBinCollected = { url: '../../assets/images/web-112021/icons/online-KI-bin.png', scaledSize: { height: 30, width: 30 } };
  searchBy: any[] = [{ search: 'Address', key: 'A' }, { search: 'Coordinates', key: 'C' }];
  kbBinToggle: boolean = false;
  kiBinToggle: boolean = false;
  vehicleDetails: boolean = false;
  vehicleId: any;
  kiBinShow: boolean = false;
  kbBinShow: boolean = false;
  kiGeoShow: boolean = false;
  kbGeoShow: boolean = false;
  showLoaderBinDetails: boolean = false;
  infoWindowOpened:any = null;
  infoWindow: any;
  onlineIcon2 = { url: '../../assets/images/web-112021/icons/online-vehicle.png', scaledSize: { height: 30, width: 30 } };
  offlineIcon2 = { url: '../../assets/images/web-112021/icons/offline-vehicle.png', scaledSize: { height: 30, width: 30 } };
  idleIcon2 = { url: '../../assets/images/web-112021/icons/idle-vehicle.png', scaledSize: { height: 30, width: 30 } };
  signalLostIcon2 = { url: '../../assets/images/web-112021/icons/signal-lost.png', scaledSize: { height: 30, width: 30 } };
  selectedMapFiltersValues: string[] = [];
  mapFormFilter: FormGroup;
  mapfilterData: any[] = [];
  uncheckMapFilter = false;
  isChecked = [];
  currentUser: any;
  
  

  public constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _appSharedService: AppSharedService,
    private commonService: CommonService,
    public webSocketService: SocketService,
    private formBuilder: FormBuilder
    ) 
    { 
      this.mapFormFilter = this.formBuilder.group({
        orders: new FormArray([])
      });
      this.mapfilterData = this.getCheckBoxFilters();
      this.addFilterCheckboxes();
    }

  public ngOnInit() {
    const URL = window.location.href;
    this.selectedMapFiltersValues = [];

    this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');

    // this.formFilter = this.formBuilder.group({
    //   orders: new FormArray([], minSelectedCheckboxes(1))
    // });

    



    if(URL.toString().match('/home/live-tracking'))
    {
      this._appSharedService.apiVehicleDetails$.subscribe(value => {this.apiVehiclesData = value});
      this._appSharedService.toggleVehiclesView$.subscribe(value => {
        if (value) {
          this.routeCords = [];
          this.routeBinStart = [];
          this.routeBinEnd = [];
          this.showBins = false;
        }
      });
      this._appSharedService.moveMapLocation$.subscribe(value => { this.moveToVehicle(value) });
      this._appSharedService.showAllVehicles$.subscribe(value => { this.showAllVehicles = value; this.setDefaultZoom(); });
      
      // console.log(this.latitude)
      // console.log(this.longitude)
  
      this.webSocketService.subject.subscribe(value => { this.subscribeVehicle(value) });
    }
    
    //Added 06/09/2022
    this._appSharedService.showVehicleHistoryLog$.subscribe(value => {
        
      if (value != null) {
        this.loadVehicleHistoryLog(value);
      }
      
    });

    this._appSharedService.resetBinsSchedules$.subscribe(value => {
     
      this.resetBinShow();
    });

    this._appSharedService.showBinsSchedules$.subscribe(value => {
     
      if (value != null) {
        this.showBinsAndSchedules(value);
      }
      else
      {
        //move to last location if route not selected
        if(this.routeBinEnd != null)
            this.moveToRouteEnd();
      }
    });
    
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      // Set country restriction.
      autocomplete.setComponentRestrictions({
        country: ["my"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          console.log(place.formatted_address);
          console.log(place.website);
          console.log(place.name);
          console.log(place.address_components)
          //console.log(place.address_components[place.address_components.length - 1].long_name);
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          console.log(place.geometry.location.lat())
          console.log(place.geometry.location.lng())
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.searchlatitude = place.geometry.location.lat();
          this.searchlongitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });


  }

  changeCheckmark(boolEvt: boolean, intId: number, stringName: string) {
    console.log(boolEvt + ' ' + intId + ' ' + stringName);

    let params = {
      id: intId,
      name: stringName,
    };

    console.log(this.isChecked);
  }

  clickButton() {
    console.log('clicked');
    this.isChecked = [];
    this.selectedMapFiltersValues = [];
  }

  addFilterCheckboxes() {
    this.mapfilterData.forEach((o, i) => {
      const control = new FormControl(); // if first item set to true, else false
      (this.mapFormFilter.controls['orders'] as FormArray).push(control);
    });
  }

  getCheckBoxFilters() {
    return [
      { id: 1, name: 'Point of interest' },
      { id: 2, name: 'KI Bin' },
      { id: 3, name: 'KB Bin' },
      { id: 3, name: 'KI Geofence' },
      { id: 3, name: 'KB Geofence' }
    ];
  }

  unCheckAllMapFilters($event: any){
    this.uncheckMapFilter = true;
    if($event.target.checked){
    //   this.diableButton = false
    // } else {
    //   this.diableButton = true
     }
      this.mapFormFilter.reset();
  }

  onChangeMapFilters($event: any) {
    if(this.mapFormFilter.invalid){
      //his.diableButton = true;
    } else {
      //this.diableButton = false;
    }
      console.log(this.mapFormFilter)
    this.uncheckMapFilter = false;
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        //window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  onMapReady(map: any) {
    var myStyles = [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          { visibility: "off" }
        ]
      }
    ];

    map.setOptions({
      center: { lat: 3.154430, lng: 101.715103 },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: true,
      styles: myStyles
    });
  }

  checkMarkersInBounds(event: any) {
    this.bounds = event;
  }

  showBinsAndSchedulesOld(value: any) {
    this.showSchedules = true;
    this.showBins = true;
    combineLatest({
      vehicleBins: this.commonService.vehicleBinData(value.vehicleRegNo, value.date), //bin schedule
      scheduleData: this.commonService.vehicleScheduleDetails(value.vehicleRegNo, value.date) // vehicle history log
    }).pipe(map((response) => {
      const vehicleBins = <Array<any>>response.vehicleBins;//bin schedule
      const scheduleData = <Array<any>>response.scheduleData;// vehicle history log
      return { 'vehicleBins': vehicleBins, 'scheduleData': scheduleData };
    })).subscribe((data) => {
      this.showSchedules = false;
      var sortedSchedules = data.scheduleData.reduce((accumalator, current) => {
        if (
          !accumalator.some(
            (item: { latitude: any; longitude: any; }) => item.latitude === current.latitude && item.longitude === current.longitude
          )
        ) {
          accumalator.push(current);
        }
        return accumalator;
      }, []);
      this.mapVehicleBins(data.vehicleBins);
    });
  }

  showBinsAndSchedules(value: any) {

    this.resetBinShow();
    this.showSchedules = true;
    this.showBins = true;
    console.log(value);

    if(value.route != null)
    {
      

      console.log(value);
      this.commonService.vehicleBinScheduleDataByRoute(value.route, value.date, value.archive_year).subscribe((resp: any) => {
        if (resp.Total != 0) {
          
          
          var sortedSchedules = null;
          this.mapVehicleBins(resp.Result);
        } 
        this.showSchedules = false;
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }

    }
    else
    {
     
      this.commonService.vehicleBinScheduleData(value.vehicleRegNo, value.date, value.archive_year).subscribe((resp: any) => {
        if (resp.Total != 0) {
         
         
          var sortedSchedules = null;
          this.mapVehicleBins(resp.Result);
        } 
        this.showSchedules = false;
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }
    }

  }

  showBinsAndSchedules2(value: any) {
    this.showSchedules = true;
    this.showBins = true;
    console.log(value);
    combineLatest({
      vehicleBins: (value.route != null)?(this.commonService.vehicleBinScheduleDataByRoute(value.route, value.date, value.archive_year)):(this.commonService.vehicleBinScheduleData(value.vehicleRegNo, value.date, value.archive_year)), //bin schedule
      //scheduleData: this.commonService.vehicleScheduleDetails(value.vehicleRegNo, value.date) // vehicle history log
    }).pipe(map((response) => {
      const vehicleBins = <Array<any>>response.vehicleBins.Result;//bin schedule
      //const scheduleData = <Array<any>>response.scheduleData;// vehicle history log
      return { 'vehicleBins': vehicleBins, //'scheduleData': scheduleData 
    };
    })).subscribe((data) => {
      this.showSchedules = false;
      var sortedSchedules = null;
      //var sortedSchedules = data.scheduleData.reduce((accumalator, current) => {
      //  if (
      //    !accumalator.some(
      //      (item: { latitude: any; longitude: any; }) => item.latitude === current.latitude && item.longitude === current.longitude
      //    )
      //  ) {
      //    accumalator.push(current);
      //  }
      //  return accumalator;
      //}, []);
     
      this.mapVehicleBins(data.vehicleBins);
    });
  }

  mapVehicleBins(bins: any) {
    //Commented 06/09/2022
    //this.routeCords = schedules.sort((objA: any, objB: any) => new Date(objB.deviceTime).getTime() - new Date(objA.deviceTime).getTime());
    //if (this.routeCords.length > 0) {
    //  this.routeBinStart.push(this.routeCords[0]);
    //  this.routeBinEnd.push(this.routeCords[this.routeCords.length - 1]);
    //}
    
    this.totalBins = bins;
    this.kiBinShow = false;
    this.kbBinShow = false;

    this.binSplittedCoords = [];
    if (this.totalBins.length > 0) {

      this.moveToBinLocation();
    }
    else {
      this._appSharedService.showWarn('Info', "No Schedules for this vehicle");
    }
  }

  mapVehicleBinsOld(bins: any, schedules: any) {
    //Commented 06/09/2022
    //this.routeCords = schedules.sort((objA: any, objB: any) => new Date(objB.deviceTime).getTime() - new Date(objA.deviceTime).getTime());
    //if (this.routeCords.length > 0) {
    //  this.routeBinStart.push(this.routeCords[0]);
    //  this.routeBinEnd.push(this.routeCords[this.routeCords.length - 1]);
    //}
    this.totalBins = bins;
    this.binSplittedCoords = [];
    if (this.totalBins.length > 0) {
      for (var i = 0; i < this.totalBins.length; i++) {
        if (this.totalBins[i].activityTypeId == "KI") {
          if (this.totalBins[i].flagUpdate) {
            this.fileterdBinIcon = this.kiBinCollected
          }
          else {
            this.fileterdBinIcon = this.kiBin
          }
        }
        else if (this.totalBins[i].activityTypeId == "KB") {
          if (this.totalBins[i].flagUpdate) {
            this.fileterdBinIcon = this.kbBinCollected
          }
          else {
            this.fileterdBinIcon = this.kbBin
          }
        }
        var data = this.totalBins[i].coordinate.split(", ");
        var temp = {
          latitude: Number(data[0]),
          longitude: Number(data[1]),
          finalBin: this.fileterdBinIcon
        }
        this.binSplittedCoords.push(temp);
        for (let j = 0; j < this.totalBins.length; j++) {
          this.totalBins[j].coords = this.binSplittedCoords[j];
        }
      }
    }
    else {
      this._appSharedService.showWarn('Info', "No Schedules for this vehicle");
    }
  }

  binGeoFence(binGeofence: any) {
    if (binGeofence == 'KIGeofence')
      this.kiBinGeofence = !this.kiBinGeofence;
    else if (binGeofence == 'KBGeofence')
      this.kbBinGeofence = !this.kbBinGeofence;
  }

  //Added 05/09/2022
  loadVehicleHistoryLog(value: any)
  {
    
    var dateStart = new Date (new Date(new Date(value.date).getFullYear(),new Date(value.date).getMonth(), new Date(value.date).getDate(), 0, 0, 0));
    var dateEnd = new Date (new Date(new Date(value.date).getFullYear(),new Date(value.date).getMonth(), new Date(value.date).getDate(), 23, 59, 0));
   
    //'2022-09-04T16:00:00Z','2022-09-05T16:59:00Z'
   
    var historyLog = this.commonService.vehicleHistoryLog(value.vehicleId, dateStart.toISOString(),dateEnd.toISOString()).subscribe((data) => {
      this.routeCords = data;
      this._appSharedService.allVehicleLogs(this.routeCords);
    
       
    });
    

  
    if (this.routeCords.length > 0) {
      this.routeBinStart.push(this.routeCords[0]);
      this.routeBinEnd.push(this.routeCords[this.routeCords.length - 1]);
    }
  }
 

  toggleBins(bins: any) {
    
    if (bins == 'KIBin')
      this.kiBinToggle = !this.kiBinToggle;
    else if (bins == 'KBBin')
      this.kbBinToggle = !this.kbBinToggle;

      //console.log(this.selectedMapFiltersValues);
  }

  checkBinKIShowValue(id:any,event: any){

    if(id = 'Kib' && event.checked.length == 0)
    {
      this.kiBinShow = false;
    }
    else if(id = 'Kib' && event.checked.length > 0 )
    {
      if (event.checked[0] == 'KI Bin')
          this.kiBinShow = true;
      else if (event.checked[1] == 'KI Bin')
          this.kiBinShow = true;
      else if (event.checked[2] == 'KI Bin')
          this.kiBinShow = true;
      else if (event.checked[3] == 'KI Bin')
          this.kiBinShow = true;
      else if (event.checked[4] == 'KI Bin')
          this.kiBinShow = true;
      else
          this.kiBinShow = false;
    }
   
   
    this.filterBinData();
  }

  checkBinKBShowValue(id:any,event: any){
   
    if(id = 'KBB' && event.checked.length == 0)
    {
      this.kbBinShow = false;
    }
    else if(id = 'KBB' && event.checked.length > 0 )
    {
      if (event.checked[0] == 'KB Bin')
          this.kbBinShow = true;
      else if (event.checked[1] == 'KB Bin')
          this.kbBinShow = true;
      else if (event.checked[2] == 'KB Bin')
          this.kbBinShow = true;
      else if (event.checked[3] == 'KB Bin')
          this.kbBinShow = true;
      else if (event.checked[4] == 'KB Bin')
          this.kbBinShow = true;
      else
          this.kbBinShow = false;
      
    }
   
    
    this.filterBinData();
  }

  checkGeoKIShowValue(id:any,event: any){
    
    if(id = 'KIG' && event.checked.length == 0)
    {
      this.kiGeoShow = false;
    }
    else if(id = 'KIG' && event.checked.length > 0 )
    {
      if (event.checked[0] == 'KI Geofence')
          this.kiGeoShow = true;
      else if (event.checked[1] == 'KI Geofence')
          this.kiGeoShow = true;
      else if (event.checked[2] == 'KI Geofence')
          this.kiGeoShow = true;
      else if (event.checked[3] == 'KI Geofence')
          this.kiGeoShow = true;
      else if (event.checked[4] == 'KI Geofence')
          this.kiGeoShow = true;
      else
          this.kiGeoShow = false;
      
    }
   
    this.filterGeofenceData();
  }

  checkGeoKBShowValue(id:any,event: any){

    if(id = 'KBG' && event.checked.length == 0)
    {
      this.kbGeoShow = false;
    }
    else if(id = 'KBG' && event.checked.length > 0 )
    {
      if (event.checked[0] == 'KB Geofence')
          this.kbGeoShow = true;
      else if (event.checked[1] == 'KB Geofence')
          this.kbGeoShow = true;
      else if (event.checked[2] == 'KB Geofence')
          this.kbGeoShow = true;
      else if (event.checked[3] == 'KB Geofence')
          this.kbGeoShow = true;
      else if (event.checked[4] == 'KB Geofence')
          this.kbGeoShow = true;
      else
          this.kbGeoShow = false;
      
    }

    this.filterGeofenceData();
  }

  filterBinData(){



    if(this.totalBins != null){
      
      if(this.kiBinShow && !this.kbBinShow)
      {
        this.filterBin = this.totalBins;     
        this.filterBin = this.filterBin.filter((x: { activity_code: any; }) => x.activity_code.toLowerCase().includes('ki'));      
      }
      else if(this.kiBinShow && this.kbBinShow)
      {
        this.filterBin = this.totalBins;      
      }
      else if(this.kbBinShow && !this.kiBinShow)
      {      
        this.filterBin = this.totalBins;   
        this.filterBin = this.filterBin.filter((x: { activity_code: any; }) => x.activity_code.toLowerCase().includes('kb'));     
      }
      else if(!this.kbBinShow && !this.kiBinShow)
      {    
        this.filterBin = null;   
      }
    }


  }

  filterGeofenceData(){


    if(this.totalBins != null){
      
      if(this.kiGeoShow && !this.kbGeoShow)
      {
        this.filterGeofence = this.totalBins;     
        this.filterGeofence = this.filterBin.filter((x: { activity_code: any; }) => x.activity_code.toLowerCase().includes('ki'));      
      }
      else if(this.kiGeoShow && this.kbGeoShow)
      {
        this.filterGeofence = this.totalBins;      
      }
      else if(this.kbGeoShow && !this.kiGeoShow)
      {      
        this.filterGeofence = this.totalBins;   
        this.filterGeofence = this.filterBin.filter((x: { activity_code: any; }) => x.activity_code.toLowerCase().includes('kb'));     
      }
      else if(!this.kbGeoShow && !this.kiGeoShow)
      {    
        this.filterGeofence = null;   
      }
    }


  }

  resetBinShow(){

    this.selectedMapFiltersValues = [];
    this.totalBins = null;
    this.filterBin = null;
    this.filterGeofence = null;

    this.kiBinShow = false;
    this.kbBinShow = false;

    this.kiGeoShow = false;
    this.kbGeoShow = false;
  }

  addressSelection(event: any) {
    this.selectedAddress = event.key;
    this.enteredAddress = '';
  }

  addressSlected(event: any) {
    if (this.selectedAddress == "C") {
      var hasLetter = /[a-zA-Z]/  //to check hasNumber /\d/
      if (hasLetter.test(this.enteredAddress)) {
        this._appSharedService.showWarn('Info', "Coordinate should only contain numericals! Ex:1.000, 1.000");
        this.enteredAddress = '';
      }
      else {
        var coords = this.enteredAddress.split(", ");
        this.latitude = Number(coords[0]);
        this.longitude = Number(coords[1]);
        this.zoom = 12;
      }
    }
  }

  resetSearch(){
    this.searchlatitude = null;
    this.searchlongitude = null;
    this.enteredAddress = '';
  }
  onMarkerClicked(vehicleId: any, vehicleRegNo: any) {
    this.currentSelectedmarker = this.apiVehiclesData.filter(x => x.vehicleId == vehicleId)[0];
    let Details = {
      vehicleId: vehicleId,
      vehicleRegNo: vehicleRegNo
    }
    this._appSharedService.sendDataFromMap(Details);
    this._appSharedService.setdata(this.vehicleDetails = true);
    console.log("filterBin",this.filterBin);
  }
  onRightClick(event: any) {
    this.setDefaultZoom();
  
  }
  
  onMouseClick(vehicleId: any, vehicleRegNo: any) {
    this.currentSelectedmarker = this.apiVehiclesData.filter(x => x.vehicleId == vehicleId)[0];
    
  }
  onMouseOut(vehicleId: any, vehicleRegNo: any) {
    this.currentSelectedmarker = false;
  }
  onBinClicked(binScheduleLskId: any) {

    this.currentSelectedBins = this.totalBins.filter((x: { id: any; }) => x.id == binScheduleLskId)[0];
  

   // this.showLoaderBinDetails = true;
   // this.commonService.vehicleBinScheduleDataDetails(binScheduleLskId).subscribe((resp: any) => {
   //   if (resp.Total != 0) {
   //     this.currentSelectedBins = resp.Result;
       
        //this.showLoaderBinDetails = false;
    //  } else { this.showLoaderBinDetails = false; }
   // }), (error: any) => {
   //   this._appSharedService.showError('Error', "Something went wrong!");
   // }
   // console.log(this.currentSelectedBins);

  //  if (this.infoWindowOpened) {
  //    this.infoWindowOpened.close();
  //  }
   // this.infoWindowOpened = infoWindows;

    

 
   // var historyLog = this.commonService.vehicleBinScheduleDataDetails(binScheduleLskId).subscribe((data) => {
   //   //this.routeCords = data;
   //   this.currentSelectedBins = data.Result
   //   //console.log(data);
   // });
    //this.currentSelectedBins = this.totalBins.filter((x: { Id: any; }) => x.Id == binScheduleLskId)[0];

    
  }
  onBinMouseOut(infoWindow:any) {
    
  }
 

  onDotClicked(positionId: any) {
    this.currentSelectedRoutes = this.routeCords.filter((x: { id: any; }) => x.id == positionId)[0];
  }

  moveToVehicle(vehicle: any) {
    this.currentSelectedVehicle = vehicle;
    this.latitude = vehicle.latitude;
    this.longitude = vehicle.longitude;
    this.zoom = this.currentZoom + 12;
    this.currentZoom = 0;
    this.date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.routeCords = [];
    this.routeBinStart = [];
    this.routeBinEnd = [];
    this.showBins = false;
  }

  moveToBinLocation() {
    //this.currentSelectedVehicle = data;
    let firstPoint = this.totalBins[0];
    this.latitude = firstPoint.latitude;
    this.longitude = firstPoint.longitude;   
    this.zoom = this.currentZoom + 5;
    this.currentZoom = 0;
    this.date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    //this.routeCords = [];
    //this.routeBinStart = [];
    //this.routeBinEnd = [];
    //this.showBins = false;
  }

  moveToRouteEnd() {
    //this.currentSelectedVehicle = data;
    let lastPoint = this.routeBinEnd[0];
    this.latitude = lastPoint.latitude;
    this.longitude = lastPoint.longitude;
    this.zoom = this.currentZoom + 5;
    this.currentZoom = 0;
    this.date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    //this.routeCords = [];
    //this.routeBinStart = [];
    //this.routeBinEnd = [];
    //this.showBins = false;
  }

  subscribeVehicle(details: any) {
   
    var vehicles = JSON.parse(details);
    
    

    if(vehicles.positions != undefined)
    {
        console.log(vehicles.positions[0].latitude);
        var lat = vehicles.positions[0].latitude;
        var lng = vehicles.positions[0].longitude;

       if (this.apiVehiclesData.filter(x => x.deviceId == vehicles.positions[0].deviceId.toString())[0] != undefined) {
       
        var objIndex = this.apiVehiclesData.findIndex((obj => obj.deviceId == vehicles.positions[0].deviceId.toString()));

          this.apiVehiclesData.filter(x => x.deviceId == vehicles.positions[0].deviceId)[0].latitude = lat.toString();
          this.apiVehiclesData.filter(x => x.deviceId == vehicles.positions[0].deviceId)[0].longitude = lng.toString();
          this.apiVehiclesData[objIndex].speed = vehicles.positions[0].speed;
          this.apiVehiclesData[objIndex].address = vehicles.positions[0].address;

          this._appSharedService.updateVehicle(vehicles);
          
         }
    }

    if(vehicles.devices != undefined)
    {
    
        
        if (this.apiVehiclesData.filter(x => x.deviceId == vehicles.devices[0].id.toString())[0] != undefined) {
          
         
          
          var objIndex = this.apiVehiclesData.findIndex((obj => obj.deviceId == vehicles.devices[0].id.toString()));

          this.apiVehiclesData[objIndex].status = vehicles.devices[0].status;
          this.apiVehiclesData[objIndex].vehicleRegNo = vehicles.devices[0].name;
          this.apiVehiclesData[objIndex].model = vehicles.devices[0].model;
          this.apiVehiclesData[objIndex].category = vehicles.devices[0].category;
          this.apiVehiclesData[objIndex].contact = vehicles.devices[0].contact;

          if ((vehicles.devices[0].status == 'online')) {
            this.apiVehiclesData[objIndex].vehicleStatus = this.onlineIcon2;
          }
          else if (vehicles.devices[0].status == 'offline') {
            this.apiVehiclesData[objIndex].vehicleStatus = this.offlineIcon2;
          }
          else if (vehicles.devices[0].status == 'unknown') {
            this.apiVehiclesData[objIndex].vehicleStatus = this.signalLostIcon2;
          }
          else {

            this.apiVehiclesData[objIndex].vehicleStatus = this.idleIcon2;

          }
         
          this._appSharedService.updateVehicle(vehicles);
        }
    }
   
  }

  vehicleRotation(past: any, curr: any) {
    return Math.atan2(curr.lng - past.lng, curr.lat - past.lat) * 180 / Math.PI - 90;
  }

  vehicleCoutBox() {
    this._appSharedService.closeSidebarView(0);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  mapFilters() {
    this.MapFilters = !this.MapFilters;
  }

  onZoomChange(zoom: number) {
    this.currentZoom = zoom;
    //console.log(this.currentZoom);
  }

  setDefaultZoom(): void {
 
    this.latitude = 3.154430;
    this.longitude = 101.715103;
    this.zoom = 12;
    this.currentZoom = 0;
    //this.date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    //this.routeCords = [];
    //this.routeBinStart = [];
    //this.routeBinEnd = [];
    //this.showBins = false;
    //this.zoom = 8;
    //lat: 3.154430, lng: 101.715103
  }

  // transition(lat:any, long:any,latOld:any, longOld:any, index:any){
  //   var numDeltas = 100;
  //   var delay = 10; //milliseconds
  //   var i = 0;
  //   var deltaLat;
  //   var deltaLng;

  //   deltaLat = (lat - latOld)/numDeltas;
  //   deltaLng = (long - longOld)/numDeltas;
  //   this.moveMarker(deltaLat,deltaLng,latOld,longOld,index);
  // }

  // moveMarker(deltaLat: any,deltaLng:any,latOld:any, longOld:any, index:any){
  //   var lat = latOld + deltaLat;
  //   var long = longOld + deltaLng;
  //   var latlng = new google.maps.LatLng(lat, long);
  //   this.apiVehiclesData[index].setPosition(latlng);
  //   if(i!=numDeltas){
  //       i++;
  //       setTimeout(moveMarker, delay);
  //   }
  // }
}
