import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import { AppSharedService } from '../../app.SharedService';
import { ConfirmationService, Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [ConfirmationService]
})
export class ScheduleComponent implements OnInit {
  scheduleForm!: FormGroup;
  showLoader: boolean = false;
  dataPresent: boolean = false;
  schedules: any[] = [];
  vehicles: any[] = [];
  data: any[] = [];
  routes: any[] = [];
  formActionType: string = '';
  formSubmitting: boolean = false;
  schedule: any = null;
  addDisplay: boolean = false;
  editDisplay: boolean = false;
  msgs: Message[] = [];

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private appCom: AppSharedService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig) { }

  first = 0;
  display: boolean = false;
  rows = 10;

  ngOnInit(): void {
    this.scheduleForm = this.fb.group({
      scheduleId: ["", Validators.compose([Validators.required])],
      vehicleId: ["", Validators.compose([Validators.required])],
      routeId: ["", Validators.compose([Validators.required,])],
      scheduleNo: ["", Validators.compose([Validators.required])],
      startTime: ["", Validators.compose([Validators.required])],
      endTime: ["", Validators.compose([Validators.required,])],
      frequency: ["", Validators.compose([Validators.required,])],
      active: ["", Validators.compose([Validators.required])],
    });
    this.getAllSchedules();
    this.getAllVehicles();
    this.getAllRoutes();
  }

  addShowDialog() {
    this.scheduleForm.reset();
    this.addDisplay = true;
  }

  addCancLBtn() {
    this.addDisplay = false;
  }

  editShowDialog(id: any) {
    this.editDisplay = true;
    this.formActionType = 'Edit';
    this.schedule = null;
    this.schedule = this.schedules.filter(x => x.scheduleId == id)[0];
    if (this.schedule != null) {
      this.scheduleForm.controls['vehicleId'].setValue(this.schedule.vehicleId);
      this.scheduleForm.controls['routeId'].setValue(this.schedule.routeId);
      this.scheduleForm.controls['scheduleNo'].setValue(this.schedule.scheduleNo);
      this.scheduleForm.controls['startTime'].setValue(this.schedule.startTime);
      this.scheduleForm.controls['endTime'].setValue(this.schedule.endTime);
      this.scheduleForm.controls['frequency'].setValue(this.schedule.frequency);
      this.scheduleForm.controls['active'].setValue(this.schedule.active);
    }
    else
      this.appCom.showError("Something went wrong!", 'wrong');
  }

  editCancLBtn() {
    this.editDisplay = false;
  }

  getAllSchedules() {
    this.showLoader = true;
    this.adminService.getAllSchedules().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.schedules = [];
          this.schedules = resp;
        }
        else {
          this.schedules = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
      /*  error => {
          this.appCom.showError("Something went wrong!", "wrong");
          this.showLoader = false;
        }*/
    );
  }

  getAllVehicles() {
    this.showLoader = true;
    this.adminService.getAllVehicles().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.vehicles = [];
          this.data = resp;
          for (var i = 0; i < resp.length; i++) {
            if (this.data[i].active == true) {
              this.vehicles.push(this.data[i].vehicleId);
            }
          }
        }
        else {
          this.vehicles = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
    );
  }

  getAllRoutes() {
    this.showLoader = true;
    this.adminService.getAllRoutes().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.routes = [];
          this.data = resp;
          for (var i = 0; i < resp.length; i++) {
            if (this.data[i].active == true) {
              this.routes.push(this.data[i].routeId);
            }
          }
        }
        else {
          this.routes = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
      /* error => {
         this.appCom.showError("Something went wrong!", "wrong");
         this.showLoader = false;
       }*/
    );
  }

  addSchedule() {
    
    this.formActionType = 'Add';
    this.showLoader = true;
    this.formSubmitting = false;
    const schedule = {
      vehicleId: this.scheduleForm.controls['vehicleId'].value,
      routeId: this.scheduleForm.controls['routeId'].value,
      scheduleNo: this.scheduleForm.controls['scheduleNo'].value,
      startTime: this.scheduleForm.controls['startTime'].value,
      endTime: this.scheduleForm.controls['endTime'].value,
      frequency: this.scheduleForm.controls['frequency'].value,
      active: true
    };
    this.addDisplay = false;
    this.adminService.addSchedule(schedule).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully added!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  editSchedule() {
    this.showLoader = true;
    this.formSubmitting = false;
    const schedule = {
      scheduleId: this.schedule.scheduleId,
      vehicleId: this.scheduleForm.controls['vehicleId'].value,
      routeId: this.scheduleForm.controls['routeId'].value,
      scheduleNo: this.scheduleForm.controls['scheduleNo'].value,
      startTime: this.scheduleForm.controls['startTime'].value,
      endTime: this.scheduleForm.controls['endTime'].value,
      frequency: this.scheduleForm.controls['frequency'].value,
      active: this.scheduleForm.controls['status'].value
    };
    this.editDisplay = false;
    this.adminService.editSchedule(schedule).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully updated!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  deleteSchedule(id: any) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.adminService.deleteSchedule(id).subscribe(
          resp => {
            if (resp.statusCode == 200) {
              this.appCom.showSuccess(`Successfully deleted!`, 'success');
              this.ngOnInit();
            }
            else {
              this.appCom.showError("Something went wrong!", 'wrong');
            }
            this.showLoader = false;
          }, error => {
            this.appCom.showError("Something went wrong!", 'wrong');
            this.showLoader = false;
          });
      },
      reject: () => {
        this.appCom.showWarn("Rejected", "You have rejected");
      },
      key: "myDialog"
    });
  }

  validatedName(event: { charCode: number; }) {
    return (
      (event.charCode > 96 && event.charCode < 123) || //alphabets
      (event.charCode > 64 && event.charCode < 91) || //capital Alphabets
      event.charCode == 8 || //backspace
      event.charCode == 32 //Space
    );
  }

  validatedExtension(event: { charCode: number; }) {
    return (
      (event.charCode > 47 && event.charCode < 58) || // numericals
      event.charCode == 8 || //backspace
      event.charCode == 43
    );
  }
}
