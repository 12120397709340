<p-card styleClass="p-card-content-0">
    <p-menubar  [model]="menuitems">   
    </p-menubar>
    <h5 style="margin-top: 1.0rem; margin-left:1.2rem; padding-bottom: 25px;">Manage User Action Log</h5>
    <!-- <p>&nbsp;</p> -->
    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
         
          
          <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
              <!--Route schedule block-->
              <div class="ptable-css ptable-scrollable">
               
                  <!-- <div class="w-100 d-flex justify-content-between my-4"> -->
                    <!-- <div class="vehicle-fillter">
                        <ul class="nav"> -->
                <div class="row w-100 justify-content-between mt-4" style="margin-left:-0.1rem">
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0" >
                            <!-- <li class="d-flex align-items-center nav-item w-auto"> -->
                                <div class="fillet-boxs-css range-calendar position-relative" style="width: 195px" style="width:100%">
                                    <p-dropdown [options]="user_dropdown" [filter]="true" [ngModelOptions]="{standalone: true}"
                                      [showClear]="true" placeholder="Select User" (onChange)="parseDate($event)" class="custom-module-log"
                                      optionLabel="ModulePerson.FullName">
                                    </p-dropdown>
                                </div>
                            </li>
                    
                  </div>
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0" style="width:100%">
                            <!-- <li class="d-flex align-items-center nav-item w-auto"> -->
                                <div class="range-calendar position-relative" style="width:100%">
                                    <p-calendar selectionMode="range" style="width:100%;" [(ngModel)]="range_date" dateFormat="dd/mm/yy" 
                                    class="custom-module-log" [minDate]="min_date" [maxDate]="dateTime" [showIcon]="true" placeholder="Select Range Date"
                                    (ngModelChange)="parseDate($event)" [showIcon]="true" inputId="icon"></p-calendar>
                                </div>
                            <!-- </li> -->
                        <!-- </ul>
                      </div> -->
                    </li>
                  </div>
    
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0">
                    </li>
                  </div>
                      <!-- <div class="vehicle-fillter">
                        <ul class="nav"> -->
                  <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                    <li class="d-flex align-items-center nav-item w-auto pr-0">
                          <!-- <li class="nav-item"> -->
                            <div class="range-calendar"style="width:100%">
                              <span class="p-input-icon-right" style="width:100%">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" style="width:100%; height:38px"(input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                              </span>
                            </div>
                          <!-- </li> -->
                    </li>
                  </div>
                        <!-- </ul>
                      </div> -->
                  <!-- </div> -->

                </div>
                <div #dtMain>
                  <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['Controller','Action','Description', 'FullName','UserId','ExecutedTime']" 
                  [(selection)]="selectedDatas"  [rowHover]="true" dataKey="id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                   responsiveLayout="scroll" [rowsPerPageOptions]="[10,25,50]"
                  [scrollable]="true" scrollHeight="60vh" [tableStyle]="{'max-width': '100vw'}"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                  <ng-template pTemplate="caption">
                      <div class="flex align-items-center justify-content-between">
                        <div class="flex align-items-center justify-content-between summary-message" style="margin-left:-0.7rem; margin-top:-0.8rem">
                          {{ datas ? datas.length : 0 }} result(s) found in the last {{ month_preference }} month(s) . <span style="color: #19A3F1; border-bottom: 1px solid; cursor: pointer;" (click)="clearFilterData()">Clear Filter</span>
                      </div>
                      </div>
                  </ng-template>
                  
                  <ng-template pTemplate="header">
                      <tr>
                        <th pSortableColumn="ExecutedTime" style="width:150px">Executed Time<p-sortIcon field="ExecutedTime"></p-sortIcon></th>
                        <th pSortableColumn="Name" style="width:150px">Controller <p-sortIcon field="Controller"></p-sortIcon></th>
                        <th pSortableColumn="Action" style="width:150px">Action <p-sortIcon field="Action"></p-sortIcon></th>
                        <th pSortableColumn="FullName" style="width:150px">Full Name<p-sortIcon field="FullName"></p-sortIcon></th>
                        <th pSortableColumn="UserId" style="width:150px">User<p-sortIcon field="UserId"></p-sortIcon></th>
                        <th pSortableColumn="Description" style="width:150px">Description<p-sortIcon field="Description"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data>
                      <tr class="p-selectable-row">
                        <td style="width:150px">{{data.ExecutedTime | date:'dd/MM/yyyy hh:mm a' }}</td>
                          <td style="width:150px">{{data.Controller}}</td>
                          <td style="width:150px">{{data.Action}}</td>
                          <td style="width:150px">{{data.FullName}}</td>
                          <td style="width:150px">{{data.UserId}}</td>
                          <td style="width:150px">{{data.Description}}</td>
                        </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6" class="empty-message">No data found.</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between summary-message">
                        In total there are {{datas ? datas.length : 0 }} record(s).
                    </div>
                </ng-template>
        
              </p-table>
                </div>
      
                
              </div>
              
            </div>
          </div>
        </div>
    </div>
    
</p-card>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
