<p-toast></p-toast>
<h5 style="margin-top: 0.5rem; margin-left:1rem">LSK On-going</h5>    
<div class="all-vehicle-details custom-scrollbar">
    <div class="w-100 result-found-blocks" *ngIf="filter_type == 'default'">
        <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
                <div class="ptable-css ptable-scrollable">
                    <div class="row w-100 justify-content-between m-0">
                        <div class="col-md-6 col-12 vehicle-fillter mb-0 pr-3 pl-0">
                            <ul class="nav">
                                <li class="d-flex align-items-center pt-2 w-auto">
                                    <div class="fillet-boxs-css range-calendar position-relative" style="width: 220px">
                                        <p-dropdown [options]="vehicle_list" [filter]="true" [ngModelOptions]="{standalone: true}"
                                          [showClear]="true" placeholder="Select Vehicle" (onChange)="parseVehicle($event)"
                                          optionLabel="DescriptionCustom">
                                        </p-dropdown>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-12 vehicle-fillter mb-0 p-0 custom-text">
                            <ul class="nav">
                                <li class="nav-item p-0" style="width: 100%;">
                                    <div class="fillet-boxs-css range-calendar position-relative">
                                        <span class="p-input-icon-right" style="width: 220px">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-accordion *ngIf="filter_type == 'all'">
        <p-accordionTab header="Filter" [selected]="false" class="custom-accordion">
            <p-progressBar *ngIf="loading_vehicle" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>

            <div class="row mt-1">
                <div class="col-lg-3 col-md-4 col-12 mb-1">
                    <div class="range-calendar position-relative">
                        <div class="p-field p-col-12 ">
                        <p-calendar [(ngModel)]="schdlDate" dateFormat="dd-mm-yy" [showIcon]="true" placeholder="Select Date" (ngModelChange)="parseDate($event)" [showIcon]="true" inputId="icon"></p-calendar>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12 mb-1">
                    <div class="fillet-boxs-css position-relative">
                        <p-dropdown [options]="service_area_list" [filter]="true" [ngModelOptions]="{standalone: true}"
                            [showClear]="true" placeholder="Select Service Area" (onChange)="parseServiceArea($event)"
                            optionLabel="Name" class="custom-dropdown-pending-task" [disabled]="disabled_service_area">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12 mb-1">
                    <div class="fillet-boxs-css position-relative">
                        <p-dropdown [options]="filter_scheme" [filter]="true" [(ngModel)] = "selected_scheme"
                            [showClear]="true" placeholder="Select Scheme" (onChange)="parseScheme($event)"
                            optionLabel="name" class="custom-dropdown-pending-task" [disabled]="disabled_scheme">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12 mb-1">
                    <div class="fillet-boxs-css position-relative">
                        <p-dropdown [options]="route_list_filtered" [filter]="true" [(ngModel)] = "selected_route"
                            [showClear]="true" placeholder="Select Route" (onChange)="parseRoute($event)"
                            optionLabel="Name" class="custom-dropdown-pending-task" [disabled]="disabled_route">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12 mb-1">
                    <div class="fillet-boxs-css position-relative">
                        <p-dropdown [options]="subroute_list_filtered" [filter]="true" [(ngModel)] = "selected_subroute"
                            [showClear]="true" placeholder="Select Sub Route" (onChange)="parseSubRoute($event)"
                            optionLabel="Name" class="custom-dropdown-pending-task" [disabled]="disabled_sub_route">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-12 mb-1">
                    <div class="fillet-boxs-css position-relative">
                        <p-dropdown [options]="vehicle_list_filtered" [filter]="true" [(ngModel)] = "selected_vehicle"
                            [showClear]="true" placeholder="Select Vehicle" (onChange)="parseVehicle($event)"
                            optionLabel="VehicleRegNo" class="custom-dropdown-pending-task" [disabled]="disabled_vehicle">
                        </p-dropdown>
                    </div>
                </div>
                
            </div>
        </p-accordionTab>
        
    </p-accordion>
    <p-tabView>
        <p-tabPanel class="p-0">
            <ng-template pTemplate="header">
                <span>PoC <span style="color: red">({{pending_bin}})</span></span>
            </ng-template>
            <div class="custom-scrollbar">
                <div class="w-100 result-found-blocks">
                    <div class="result-found-list found-fonts fs-10 lh-12">
                        <div class="w-100 tableFixHead custom-head-table">
                            <div class="ptable-css ptable-scrollable">
                                <div #dtMain>
                                    <p-table #dt [value]="datasBin" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"  [globalFilterFields]="['route','sub_route','premise','road','park','pdib_no','code']" 
                                    [(selection)]="selectedDatas" [rowHover]="true" dataKey="Id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                                    responsiveLayout="scroll" [scrollable]="true" scrollHeight="50vh" [tableStyle]="{'max-width': '100vw'}"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" class="custom-head-table">
                                        <ng-template pTemplate="caption" >
                                            <div class="flex align-items-center justify-content-between">
                                                <div class="flex align-items-center justify-content-between summary-message">
                                                    {{datasBin ? datasBin.length : 0 }} result(s) found.
                                                </div>
                                                <div *ngIf="datasBin.length > 0" style="font-size: 16px; font-weight: 700; background-color: #dee2e6;" class="custom-header mt-2">
                                                    <div style="color: black;">{{ vehicle_no }} (<span style="color: red; font-weight: 600;">{{datasBin ? ((datasBin.length/total_bin)*100).toFixed(2) : 0 }}% incomplete</span>)</div>
                                                    <div style="font-size: 12px; font-weight: 600;"><span style="color: black; font-weight: 600;">{{company_name}}</span></div>
                                                    <div style="font-size: 12px; font-weight: 600;">Total PoC : <span style="color: black; font-weight: 600;">{{total_bin}}</span></div>
                                                    <div style="font-size: 12px; font-weight: 600;">Pending PoC : <span style="color: red; font-weight: 600;">{{datasBin ? datasBin.length : 0 }}</span></div>
                                                    <!-- <div style="font-size: 12px; font-weight: 600;">Percentage Pending Bin : <span style="color: red; font-weight: 600;">{{datasBin ? ((datasBin.length/total_bin)*100).toFixed(2) : 0 }}%</span></div> -->
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-data>
                                            <tr class="p-selectable-row mb-2">
                                                <td style="width:90%; border: 1px solid #dee2e6;border-radius: 7px; border-left-color: {{data.color}}; border-left-width: 7px;">
                                                    <div class="w-100">
                                                        <div class="w-100 flex-wrap">
                                                            <div>
                                                                <div class="">
                                                                    <div class="vehicleId d-flex w-100">
                                                                    <div class="vehicleId font-custom-gray fs-16 font-weight-bold">{{data.route}}</div>
                                                                    <div class="vehicle-status-info pl-2 align-items-sm-end">
                                                                        <div class="vehicle-circles rounded-circle"
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{data.sub_route}}</div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{data.premise}}</div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{data.road |titlecase}}, {{data.park |titlecase}}, {{ data.location |titlecase }}</div>
                                                                    <div class="dynamic-size-bin">
                                                                        <table>
                                                                            <tbody class="custom-td">
                                                                                <tr>
                                                                                    <td style="color: #19A3F1 !important">PDIB No</td>
                                                                                    <td style="color: #19A3F1 !important">Activity</td>
                                                                                    <td style="color: #19A3F1 !important">Bin Type</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="font-custom-gray">{{ data.pdib_no }}</td>
                                                                                    <td class="font-custom-gray">{{ data.code }}</td>
                                                                                    <td class="font-custom-gray">{{ data.bin_type }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="ml-auto" style="margin-block: auto">
                                                            
                                                                <!-- <div class="vehicle-route fs-12"> 
                                                                    <button *ngIf="isValidCurrentDate" pButton label="Edit" icon="pi pi-pencil" class="p-button-info" type="button" (click)="editData(data)"></button>                                                            
                                                                </div>  -->
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="6" class="empty-message">No data found.</td>
                                            </tr>
                                            
                                        </ng-template>
                                        <ng-template pTemplate="summary">
                                            
                                            <div class="flex align-items-center justify-content-between summary-message">
                                                In total there are {{datasBin ? datasBin.length : 0 }} record(s).
                                            </div>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <span>Schedule <span style="color: red">({{pending_schedule}})</span></span>
            </ng-template>
            <div class="custom-scrollbar">
                <div class="w-100 result-found-blocks">
                    <div class="result-found-list found-fonts fs-10 lh-12">
                        <div class="w-100 tableFixHead custom-head-table">
                            <div class="ptable-css ptable-scrollable">
                                <div #dtMain>
                                    <p-table #dt [value]="datasSchedule" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"  [globalFilterFields]="['route','sub_route','schedule_no','schedule_id','premise','road', 'park', 'pdib_no', 'code']" 
                                    [(selection)]="selectedDatas" [rowHover]="true" dataKey="Id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                                    responsiveLayout="scroll" [scrollable]="true" scrollHeight="50vh" [tableStyle]="{'max-width': '100vw'}"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" class="custom-head-table">
                                        <ng-template pTemplate="caption" >
                                            <div class="flex align-items-center justify-content-between">
                                                <div class="flex align-items-center justify-content-between summary-message">
                                                    {{datasSchedule ? datasSchedule.length : 0 }} result(s) found.
                                                </div>
                                                <div *ngIf="datasSchedule.length > 0" style="font-size: 16px; font-weight: 700; background-color: #dee2e6;" class="custom-header mt-2">
                                                    <div style="color: black;">{{ vehicle_no }} (<span style="color: red; font-weight: 600;">{{datasSchedule ? ((datasSchedule.length/total_schedule)*100).toFixed(2) : 0 }} % incomplete</span>)</div>
                                                    <div style="font-size: 12px; font-weight: 600;"><span style="color: black; font-weight: 600;">{{company_name}}</span></div>
                                                    <div style="font-size: 12px; font-weight: 600;">Total Schedule : <span style="color: black; font-weight: 600;">{{total_schedule}}</span></div>
                                                    <div style="font-size: 12px; font-weight: 600;">Pending Schedule : <span style="color: red; font-weight: 600;">{{datasSchedule ? datasSchedule.length : 0 }}</span></div>
                                                    <!-- <div style="font-size: 12px; font-weight: 600;">Percentage Pending Schedule : <span style="color: red; font-weight: 600;">{{datasSchedule ? ((datasSchedule.length/total_schedule)*100).toFixed(2) : 0 }} %</span></div> -->
                                                </div>
                                                
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-dataSchedule>
                                            <tr class="p-selectable-row mb-2">
                                                <td style="width:90%; border: 1px solid #dee2e6;border-radius: 7px;">
                                                    <div class="w-100">
                                                        <div class="w-100 flex-wrap">
                                                            <div>
                                                                <div class="">
                                                                    <div class="vehicleId d-flex w-100">
                                                                    <div class="vehicleId font-custom-gray fs-16 font-weight-bold">{{dataSchedule.route}}</div>
                                                                        <div class="vehicle-status-info pl-2 align-items-sm-end">
                                                                            <div class="vehicle-circles rounded-circle"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{dataSchedule.sub_route}}</div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{dataSchedule.schedule_no}}</div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{dataSchedule.schedule_id}}</div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{dataSchedule.premise}}</div>
                                                                    <div class="w-100 font-custom-gray vehicle-route fs-12 my-1">{{dataSchedule.road |titlecase}}, {{dataSchedule.park |titlecase}}</div>
                                                                    <div class="dynamic-size-schedule">
                                                                        <table>
                                                                            <tbody class="custom-td">
                                                                                <tr>
                                                                                    <td style="color: #19A3F1 !important">PDIB No</td>
                                                                                    <td style="color: #19A3F1 !important">Activity</td>
                                                                                    <td style="color: #19A3F1 !important">PoC Pending</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="font-custom-gray">{{ dataSchedule.pdib_no }}</td>
                                                                                    <td class="font-custom-gray">{{ dataSchedule.code }}</td>
                                                                                    <td class="font-custom-gray"><span style="color: red">{{ dataSchedule.bin_pending }}</span> / {{ dataSchedule.bin_total }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="ml-auto" style="margin-block: auto">
                                                            
                                                                <!-- <div class="vehicle-route fs-12"> 
                                                                    <button *ngIf="isValidCurrentDate" pButton label="Edit" icon="pi pi-pencil" class="p-button-info" type="button" (click)="editData(data)"></button>                                                            
                                                                </div>  -->
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="6" class="empty-message">No data found.</td>
                                            </tr>
                                            
                                        </ng-template>
                                        <ng-template pTemplate="summary">
                                            
                                            <div class="flex align-items-center justify-content-between summary-message">
                                                In total there are {{datasSchedule ? datasSchedule.length : 0 }} record(s).
                                            </div>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
<!-- MODAL -->

<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
