import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as Stomp from 'stompjs';
import { AppSharedService } from '../app.SharedService';

const END_POINT = 'ws://34.143.214.182/greeting';

@Injectable({ providedIn: 'root' })
export class SocketService {
  public subject: Subject<any> = new Subject<any>();
  webSocket!: WebSocket;
  connection: any;

  constructor(private appSharedService: AppSharedService) {
    this.appSharedService.logout$.subscribe(isLogout => {
      if (isLogout)
        this.dispose();
      else
        this.connect();
    });
    this.connect();
  }

  connect() {
    this.connection = Stomp.over(new WebSocket(END_POINT));
    this.connection.debug = () => { }
    this.connection.connect({}, () => {
      this.connection.subscribe('/errors', console.log);
      this.connection.subscribe('/topic/reply', (msg: any) => {
        //console.log(msg.body);
        //var split = String(msg.body).split('|');
        //var details = { 'vehicleId': split[0], 'lat': Number(split[1]), 'lng': Number(split[2]) };
        this.subject.next(msg.body)
      });
    });
  }

  public closeWebSocket() {
    this.webSocket.close();
  }

  dispose() {
    this.connection.disconnect(function () {
    });
  }
}
