<p-toast></p-toast>

<p-card styleClass="p-card-content-0">
    <p-menubar  [model]="menuitems">   
    </p-menubar>
    <h5 style="margin-top: 2.0rem; margin-left:1rem; margin-bottom:-2rem">Config Module Vehicle</h5>
    
    <p>&nbsp;</p>

    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
         
          
          <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
              <!--Route schedule block-->
              <div class="ptable-css ptable-scrollable">
                <div class="w-100 d-flex justify-content-between my-4">
                    <!-- <div class="vehicle-fillter">
                          <ul class="nav">
                              <li class="d-flex align-items-center nav-item w-auto">                           
                                  <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus" class="p-button-info rounded cursor-pointer" (click)="openNew()"></button>
                              </li>
                          </ul>
                      </div> -->
                      <div class="w-100 d-flex justify-content-between my-4">
                        <div class="vehicle-fillter">
                        </div>
                        <div class="vehicle-fillter">
                          <ul class="nav">
                            <li class="nav-item" style="margin-bottom: -2rem;">
                              <div class="range-calendar">
                                <span class="p-input-icon-right">
                                  <i class="pi pi-search"></i>
                                  <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                <div #dtMain>
                  <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['VehicleRegNo','ModuleCompany.Name','VehicleType.Name','Decription','Active']" 
                  [(selection)]="selectedDatas"  [rowHover]="true" dataKey="id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="isLoading"
                   responsiveLayout="scroll" [rowsPerPageOptions]="[10,25,50]"
                  [scrollable]="true" scrollHeight="60vh" [tableStyle]="{'max-width': '100vw'}"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                  <ng-template pTemplate="caption">
                      <div class="flex align-items-center justify-content-between" style="margin-top: -1rem; margin-left: -0.7rem;">
                        <div class="flex align-items-center justify-content-between summary-message">
                          {{datas ? datas.length : 0 }} result(s) found.
                      </div>
                      </div>
                  </ng-template>
                  
                  <ng-template pTemplate="header">
                      <tr>
                        <!-- <th style="width:3vw;max-width:3vw;" class="bg-white text-center fillet-boxs-css">
                              <p-tableHeaderCheckbox class="mx-auto"></p-tableHeaderCheckbox>
                          </th> -->
                        <th pSortableColumn="VehicleRegNo" style="width:150px">Vehicle RegNo <p-sortIcon field="VehicleRegNo"></p-sortIcon></th>
                        <th pSortableColumn="ModuleCompany.Name" style="width:150px">Company Name <p-sortIcon field="ModuleCompany.Name"></p-sortIcon></th> 
                        <th pSortableColumn="VehicleType.Name" style="width:150px">Vehicle Type <p-sortIcon field="VehicleType.Name"></p-sortIcon></th>
                        <th pSortableColumn="Active" style="width:150px">Status <p-sortIcon field="Active"></p-sortIcon></th>
                        <th style="width:150px"></th>
        
                         
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data>
                      <tr class="p-selectable-row">
                          <!-- <td style="width:3vw;max-width:3vw;" class="fillet-boxs-css">
                              <p-tableCheckbox [value]="data" class="mx-auto"></p-tableCheckbox>
                          </td> -->
                          <td style="width:150px">{{data.VehicleRegNo}}</td>
                          <td style="width:150px">{{data.ModuleCompany.Name}}</td>
                          <td style="width:150px">{{data.VehicleType.Name}}</td>
                          <td style="width:150px"><span style="display: none;">{{data.Active}}</span>
                            <i *ngIf="data.Active" class="pi pi-check" style="font-size: 1rem; color:green"></i>                                                        
                            <i *ngIf="!data.Active"  class="pi pi-times" style="font-size: 1rem; color:red"></i>                           
                           </td>
                          <td style="width:150px">
                              <p-splitButton label="Action" [model]="this.items" styleClass="p-button-outlined p-button-info" class="border-0 rounded cursor-pointer" (click)="selectData(data)"></p-splitButton>
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6" class="empty-message">No data found.</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between summary-message">
                        In total there are {{datas ? datas.length : 0 }} record(s).
                    </div>
                </ng-template>
        
              </p-table>
                </div>
      
                
              </div>
              
            </div>
          </div>
        </div>
    </div>
    
</p-card>
<form [formGroup]="configModuleVehicleForm" (ngSubmit)="onSubmit()">
    <p-dialog [(visible)]="dataDialog" [style]="{width: '450px', height: 'auto'}" header="Details" [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content" class="custom-pad-content">
            <div class="field">
                <label for="CompanyId">Company</label>
                <p-dropdown formControlName="CompanyId" [options]="company" [(ngModel)]="data.CompanyId" placeholder="Select a Company" optionLabel="Name" optionValue="Id" (onChange)="onChange($event)"></p-dropdown>
            </div>
            <div class="field">
                <label for="VehicleTypeId">Vehicle Type</label>
                <p-dropdown formControlName="VehicleTypeId" [options]="vehicle" [(ngModel)]="data.VehicleTypeId" placeholder="Select a Vehicle Type" optionLabel="Name" optionValue="Id"></p-dropdown>
            </div>
            <div class="field custom-pad-bot">
                <label for="VehicleRegNo">Vehicle Reg No</label>
                <input type="text" formControlName="vehicleregno" pInputText id="VehicleRegNo" name="VehicleRegNo" [(ngModel)]="data.VehicleRegNo" autofocus />
                <small class="p-error" *ngIf="submitted && !data.VehicleRegNo">Vehicle RegNo is required.</small>
            </div>
           
            
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" type="button" [disabled]="isLoading"></button>
            <button pButton pRipple class="p-button-text" type="submit" [disabled]="isLoading">
                <span *ngIf="!isLoading" style="font-weight: 700"> <i class="pi pi-check"></i> Save</span>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
            </button>
        </ng-template>
    </p-dialog>
</form>

<!------------------------------- MODAL ASSIGN SCHEME ---------------------------------->
<div id="formSubmit">
    <p-dialog [(visible)]="modalAssignScheme" [maximizable]="true" [breakpoints]="{'960px': '70vw'}" [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
      <ng-template pTemplate="header">
          <div class="w-100 d-flex justify-content-between align-items-center h-100">
            <div class="title-fiters">Assign Scheme</div>
          </div>
      </ng-template>
      <div class="row">
          <div class="col-sm-6 col-md-12">
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
            </div>
          </div>
      </div>
      <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
          <table class="mb-3">
              <tr>
                  <td style="width:30vw;font-size: 90%;"><b>Vehicle Reg No</b></td>
                  <td style="width:10vw;font-size: 90%;">:</td>
                  <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataModal.VehicleRegNo}}</span></td>
              </tr>
              <tr>
                  <td style="width:30vw;font-size: 90%"><b>Company Name</b></td>
                  <td style="width:10vw;font-size: 90%">:</td>
                  <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataModal.ModuleCompany.Name}}</span></td>
              </tr>
              <tr>
                  <td style="width:30vw;font-size: 90%"><b>Vehicle Type</b></td>
                  <td style="width:10vw;font-size: 90%">:</td>
                  <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataModal.VehicleType.Name}}</span></td>
              </tr>
              <tr>
                  <td  style="width:30vw;font-size: 90%"><b> <div class="w-100 fillet-boxs-css mb-4 position-relative">
                      Assign Scheme
                  </div></b></td>
                  <td><div class="w-100 fillet-boxs-css mb-4 position-relative">
                      :
                  </div></td>
                  <td>
                    <span>
                      <div class="w-100 fillet-boxs-css mb-4 position-relative">
                        <p-dropdown [options]="allScheme" [(ngModel)]="selectedScheme" [filter]="true" [ngModelOptions]="{standalone: true}"
                                      [showClear]="true" placeholder="Select Vehicle" (onChange)="onChangeFilter($event)"
                                      optionLabel="Name">
                        </p-dropdown>
                      </div>
                    </span>
                  </td>
              </tr>
          </table>
          <div class="w-100 fillet-boxs-css mb-4 position-relative">                
              <button pButton label="Assign" class="p-button-info" (click)="assignSchemeForm(data)" [disabled]="!selectedScheme"></button>
          </div>
          <div *ngIf="showLoader" class="align-items-center justify-content-center" style="text-align: center; display: flex;">
              <div class="loader"></div>
          </div>
          <div class="scrollable-table">
            <table class="table mt-3 outside-border" *ngIf="!showLoader">
                <thead class="thead-light" style="background-color: #e9ecef;">
                    <tr>
                        <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Scheme</td>
                    </tr>
                </thead>
                  <tbody> 
                    <ng-template [ngIf]="assignedSchemeList.length!=0" [ngIfElse]="noData">
                        <tr *ngFor="let assignedScheme of assignedSchemeList">
                            <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">{{assignedScheme.scheme_id}}</td>
                            <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash" style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right" (click)="unassignScheme(assignedScheme)"></i></td>
                        </tr>
                    </ng-template>
                    <ng-template #noData>
                        <tr>
                            <td style="padding: 0.3rem;" class="font-italic empty-message">No data</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
          </div>
      </ng-template>
      <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
          <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component" (click)="hideDialog()" type="button"></button>
      </ng-template>
      <ng-template #loaderProgress>
          <div class="Loading"></div>
      </ng-template>
    </p-dialog>
</div>
  
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
