import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigPermission } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';

@Component({
    selector: 'app-config-permission',
    templateUrl: './config-permission.component.html',
    styleUrls: ['./config-permission.component.scss']
})
export class ConfigPermissionComponent implements OnInit {

    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigPermission[]= [];
    data: ConfigPermission = {ModuleConfigPermission:{}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    configPermissionParam =  { id: null, isactiveonly: true };
    modalAssignRole = false;
    selectedRole: any = [];
    allRole: any[] = [];
    showLoader: boolean = false;
    assignedRoleList:any = [];
    isLoading: boolean = true;

    nameFormControl = new FormControl('', [
        Validators.required,
    ]);
    descriptionFormControl = new FormControl('', [
        Validators.required,
    ]);
    abbreviationFormControl = new FormControl('', [
        Validators.required,
    ]);

    configPermissionForm = this.formBuilder.group({
        name: this.nameFormControl,
        description: this.descriptionFormControl,
        abbreviation: this.abbreviationFormControl,
        active: true,
        createdBy: localStorage.getItem('username'),
    });

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.getDatas();
        this.getAllConfigRoles();

        this.items = [
            {label: 'Assign Role', icon: 'pi pi-pencil', command: () => {
                this.assignRole(this.data);
            }},
            {label: 'Edit', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Delete', icon: 'pi pi-trash', command: () => {
                this.deleteData(this.data);
            }}
        ];

        this.menuitems = GlobalComponent.submenus;
    }

    selectData(data: any){
        this.data = data;
        this.Id = data.Id;
    }

    openNew(){
        this.data= { ModuleConfigPermission: {}};
        this.submitted = false;
        this.dataDialog = true;
        this.isCreateNew = true;
    }

    editData(data: any) {
        this.data = {...data};
        this.dataDialog = true;
        this.isCreateNew = false;
        this.Id = data.Id;
    }

    hideDialog() {
        this.dataDialog = false;
        this.submitted = false;
        this.modalAssignRole = false;
    }

    onSubmit(): void {
        this.submitted = true;
        if (this.configPermissionForm.valid) {
            if (this.isCreateNew == true) {
                this.createConfigPermission();
            } else {
                this.updateConfigPermission();
            }
        }
    }
    // 
    getAllConfigRoles(){
        this.commonService.getAllConfigRoles().subscribe((resp: any) => {
            if (resp.length != 0) {
                this.allRole = resp.Result;
                this.loading = false;              
            } else {
                
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getDatas(){
        this.isLoading = true;
        this.commonService.getConfigPermission(this.configPermissionParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.datas = resp.Result;
                this.isLoading = false;
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    createConfigPermission(){
        this.isLoading = true;
        this.commonService.createConfigPermission(this.configPermissionForm.value).subscribe((resp: any) => {
            if (resp.Success == true) {
                this.messageService.add({severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {

                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    updateConfigPermission(){
        this.isLoading = true;
        this.configPermissionForm.value.id = this.Id;
        this.commonService.updateConfigPermission(this.configPermissionForm.value).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    deleteData(data: any) {
        this.isLoading = true;
        data.CreatedBy = localStorage.getItem('username');
        data.Active = false;
        
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.Name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.commonService.deleteConfigPermission(data).subscribe((resp: any) => {

                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        this.isLoading = false;
                        this.dataDialog = false;
                        this.getDatas();
                      
                    } else {
              
                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.isLoading = false;
                    }
                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                    this.isLoading = false;
                }
            }
        });
    }

    onChangeFilter(event: any) {
        this.selectedRole = event.value;
    }

    assignRole(data: any){
        this.modalAssignRole = true;
        this.getAssignedRoleList();
    }

    assignRoleForm(data: any){

        if(this.selectedRole == null){
            this.messageService.add({key: 'alertMsg', severity:'warn', summary: 'Warn', detail: 'Please Select Vehicle!'});
        } else { 
            this.showLoader = true;

            var dataPermissionRole = {
                "PermissionId": data.Id,
                "RoleId": this.selectedRole.Id
            };

            this.commonService.assignPermissionRole(dataPermissionRole).subscribe((resp: any) => {
                if (resp.Success == true) {
                    this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                    this.getAssignedRoleList();
                } else {
                    this._appSharedService.showError('Error', "Something went wrongg!");
                }
                this.showLoader = false;
    
            }), (error: any) => {
    
                this._appSharedService.showError('Error', "Something went wrongd!");
            }
        } 
    }

    getAssignedRoleList(){
      
        var permission = {
            "permissionId": this.Id
        };
        this.showLoader = true;

        this.commonService.getPermissionRoleByPermissionId(permission).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.assignedRoleList = resp.Result;
            } else {
                this.assignedRoleList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    unassignVehicle(data: any){
        this.confirmationService.confirm({
            message: 'Are you sure you want to unassign ' + data.RoleName + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                this.commonService.unassignPermissionRole(data).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedRoleList();
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

}
