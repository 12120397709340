<div class="w-100">
  <div class="custom-scrollbar">
    <div class="bg-bluecolor vh-100 d-flex ">
      <ul class="w-100 navbar-nav py-2 ">
        <li class="w-100 nav-item">
          <a class="w-100 d-flex align-items-center cursor-pointer text-decoration-none" routerLink="/admin/device"
            routerLinkActive="active">
            <span class="navIcons ">
              <i class="fa fa-microchip"></i>
            </span>
            <span class="navNames fs-12">Devices</span>
          </a>
        </li>
        <li class="w-100 nav-item">
          <a class="w-100 d-flex align-items-center cursor-pointer text-decoration-none" routerLink="/admin/company"
            routerLinkActive="active">
            <span class="navIcons ">
              <i class="fa fa-building"></i>
            </span>
            <span class="navNames fs-12">Company</span>
          </a>
        </li>
        <li class="w-100 nav-item">
          <a class="w-100 d-flex align-items-center cursor-pointer text-decoration-none" routerLink="/admin/vehicle"
            routerLinkActive="active">
            <span class="navIcons ">
              <i class="fa fa-truck"></i>
            </span>
            <span class="navNames fs-12">Vehicle</span>
          </a>
        </li>
        <li class="w-100 nav-item">
          <a class="w-100 d-flex align-items-center cursor-pointer text-decoration-none" routerLink="/admin/route"
            routerLinkActive="active">
            <span class="navIcons ">
              <i class="fa fa-map-marker"></i>
            </span>
            <span class="navNames fs-12">Route</span>
          </a>
        </li>
        <li class="w-100 nav-item">
          <a class="w-100 d-flex align-items-center cursor-pointer text-decoration-none" routerLink="/admin/schedule"
            routerLinkActive="active">
            <span class="navIcons ">
              <i class="fa fa-calendar-plus"></i>
            </span>
            <span class="navNames fs-12">Schedule</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>