<div class="w-100 position-absolute map-styles">
  <app-map></app-map>
</div >
<!-- <p-sidebar [(visible)]="displayVehicleList" styleClass="p-sidebar-lg" [style]="{width:'90em'}">

<app-vehicle-status-details></app-vehicle-status-details>
</p-sidebar>
<p-button type="text" (click)="displayVehicleList = true" icon="pi pi-plus" label="Show"></p-button> -->

<div class=" vehicle-status-blocks position-absolute z-index-1">
<div class="" *ngIf="closeVehicleStatu" style="width: 380px !important;">
<div class="z-index-1 tab-vehicle-details-blocks bg-white ">
  <span style="float:right"> <span style="margin-right: 0.3em;"><p-badge  value="{{this.speedVehiclesCount2}} Speed Alert" severity="danger" *ngIf="this.speedVehiclesCount2 > 0"></p-badge></span>
    <p-badge value="{{this.idleVehiclesCount2}} Idle Alert" severity="warning" *ngIf="this.idleVehiclesCount2 > 0"></p-badge></span>
<div class="w-100 d-flex navItemTitle position-relative justify-content-between align-items-center">

<div class="liveTitles lh-23 fs-20">Vehicle Status
  <div class="navFilterButton  ml-auto position-absolute ">
    <!--div class="clear-btn cursor-pointer pr-3" (click)="clearFilters()">Clear Filters</div-->
    <button (click)="clearFilters()" class="filter-btn border-0 p-0 bg-transparent  cursor_grap"><span style="font-size: 11px;margin-right:0.5em;margin-top:2em">Clear Filters</span> 
      <!--img src="./assets/images/web-112021/icons/icon-filters.png" /-->
    </button>
    <!--button (click)="showFilterDialog()" class="btn btn-primary fs-12">Advanced Filter</button-->
  </div>
  
</div>



<div (click)="closeHelpTopic()" [class.d-none]="closeStatus" class="navCloseButton ml-auto position-absolute cursor-pointer">
  <i class="fa fa-close cursor-pointer" id="search_close2" (click)="clearSearch()"></i>
</div>
</div>
<div *ngIf="!showLoader; else loading">
<div class="w-100 position-relative">
  <div class="row">
    <div class="col-sm-3 col-md-9">
      <div class="vihicle-search-input input-placeholder-color position-relative">
        <input class="form-control filters-bg" type="text" [(ngModel)]="searchValue" placeholder="Search vehicle, company or route..." (input)="searchVehicles($event)">
        <div class="position-absolute icon-search d-flex">
          <i class="fa fa-close cursor-pointer fs-14" id="search_close" (click)="clearSearch()"></i>
        </div>
      </div>

    </div>
    <div class="col-sm-3 col-md-3 formgroup-inline">
      <button class="filters-bg" type="button" (click)="showFilterDialog()">
        <div class="w-100 d-flex align-items-center">
          <div>Filters</div>
          <div class="ml-auto">
            <img src="./assets/images/web-112021/icons/icon-filters.png" />
          </div>
        </div>
      </button>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-sm-3 col-md-9">
      <p-badge value="{{this.speedVehiclesCount2}} Speed Alert" severity="danger" *ngIf="this.speedVehiclesCount2 > 0"></p-badge>
      <p-badge value="{{this.idleVehiclesCount2}} Idle Alert" severity="warning" *ngIf="this.idleVehiclesCount2 > 0"></p-badge>
    </div>

    
    
  </div> -->
</div>
<div class="w-100">
  <!-- <p-badge value="{{this.speedVehiclesCount2}} Speed Alert" severity="danger" *ngIf="this.speedVehiclesCount2 > 0"></p-badge>
  <p-badge value="{{this.idleVehiclesCount2}} Idle Alert" severity="warning" *ngIf="this.idleVehiclesCount2 > 0"></p-badge> -->
  <div class=" tab-names position-relative" [ngClass]="tabsHide? 'moveTabs' : 'overflow-hidden'">
    <ul class="nav flex-nowrap nav-pills position-relative" id="myTab" role="tablist" [ngClass]="tabsHide? 'moveTabs2' : 'overflow-hidden'">
      <li class="nav-item">
        <a class="nav-link cursor-pointer  d-flex align-items-center text-center fs-10  lh-12 active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" [class.disabled]="allVehiclesCount == 0" aria-selected="true" (click)="filterVehiclesToDisplay('all')">
          <div class="pr-1 txt-black">All </div>
          <div class="nus-boxs all-bg  d-flex justify-content-center align-items-center mx-auto text-white fs-14">{{allVehiclesCount}}</div>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link cursor-pointer  d-flex align-items-center text-center fs-10  lh-12" id="profile-tab" data-toggle="tab" href="#home" role="tab" aria-controls="profile" [class.disabled]="onlineVehiclesCount == 0" aria-selected="false" (click)="filterVehiclesToDisplay('online')">
          <div class="pr-1 txt-black">Online </div>
          <div class="nus-boxs online-bg  d-flex justify-content-center align-items-center mx-auto text-white fs-14">{{onlineVehiclesCount}}</div>
        </a>
      </li>
      <!--li class="nav-item">
        <a class="nav-link cursor-pointer  d-flex align-items-center text-center fs-10  lh-12" id="contact-tab" data-toggle="tab" href="#home" role="tab" aria-controls="contact" [class.disabled]="idleVehiclesCount == 0" aria-selected="false" (click)="filterVehiclesToDisplay('idle')">
          <div class="pr-1 txt-black">Idle </div>
          <div class="nus-boxs ldle-bg  d-flex justify-content-center align-items-center mx-auto text-white fs-14">{{idleVehiclesCount}}</div>
        </a>
      </li-->
      <li class="nav-item">
        <a class="nav-link cursor-pointer  d-flex align-items-center text-center fs-10  lh-12" id="profile-tab2" data-toggle="tab" href="#home" role="tab" aria-controls="profile2" [class.disabled]="offlineVehiclesCount == 0" aria-selected="false" (click)="filterVehiclesToDisplay('offline')">
          <div class="pr-1 txt-black">Offline </div>
          <div class="nus-boxs offline-bg  d-flex justify-content-center align-items-center mx-auto text-white fs-14">{{offlineVehiclesCount}}</div>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link cursor-pointer  d-flex align-items-center text-center fs-10 plh-12" id="contact-tab3" data-toggle="tab" href="#home" role="tab" aria-controls="contact3" [class.disabled]="signalLostVehiclesCount == 0" aria-selected="false" (click)="filterVehiclesToDisplay('signalLost')" >
          <div class="pr-1 txt-black">Signal Lost </div>
          <div class="nus-boxs signal-lost-bg d-flex justify-content-center align-items-center mx-auto text-white fs-14">{{signalLostVehiclesCount}}</div>
        </a>
      </li>
      <li (click)="tabsMove2()" [ngClass]="tabsHide? 'd-block':'d-none'" class="position-absolute leftArrow cursor-pointer">
        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.55552 6.77898L2.29258 6.46439L2.54958 6.14492L6.25458 1.5394L6.25458 1.5394L6.25603 1.53761C6.28574 1.50103 6.30792 1.45893 6.32131 1.41375C6.33469 1.36857 6.33902 1.32118 6.33403 1.27432C6.32905 1.22746 6.31485 1.18205 6.29226 1.14069C6.26967 1.09934 6.23912 1.06285 6.20238 1.03334L6.20238 1.03334L6.20032 1.03167C6.16374 1.00197 6.12165 0.979781 6.07646 0.966394C6.03128 0.953007 5.98389 0.948682 5.93703 0.953667C5.89017 0.958652 5.84476 0.97285 5.8034 0.995443C5.76205 1.01804 5.72556 1.04858 5.69605 1.08532L5.69573 1.08572L1.55334 6.23155L1.55336 6.23157L1.55011 6.23552C1.49751 6.29951 1.46875 6.37978 1.46875 6.46262C1.46875 6.54501 1.4972 6.62486 1.54926 6.68868C1.54954 6.68903 1.54982 6.68937 1.55011 6.68972L5.83616 11.833L5.83697 11.8339C5.86699 11.8701 5.90386 11.9001 5.94547 11.922C5.98708 11.944 6.03261 11.9575 6.07945 11.9618C6.12629 11.9661 6.17353 11.9612 6.21845 11.9472C6.26337 11.9332 6.3051 11.9105 6.34124 11.8804L6.34121 11.8804L6.34633 11.8762C6.38735 11.843 6.4205 11.801 6.44338 11.7534C6.46609 11.7061 6.47813 11.6544 6.47865 11.602C6.47853 11.5191 6.44964 11.4389 6.39691 11.3749C6.39674 11.3747 6.39656 11.3745 6.39639 11.3743L2.55552 6.77898Z" fill="#19A3F1" stroke="#19A3F1" />
        </svg>
      </li>
      <li (click)="tabsMove()" [ngClass]="tabsHide? 'd-none':'d-block'" class="position-absolute  rightArrow cursor-pointer">
        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000116242 11.1525C-0.000275284 10.9521 0.069515 10.7579 0.197373 10.6036L4.0396 6.00667L0.334596 1.40115C0.263355 1.31342 0.210154 1.21248 0.178051 1.10413C0.145948 0.995774 0.135576 0.882144 0.147531 0.769769C0.159486 0.657394 0.193531 0.548489 0.247712 0.449315C0.301892 0.35014 0.375139 0.262651 0.463241 0.191877C0.550967 0.120636 0.651908 0.0674356 0.760261 0.0353326C0.868615 0.00322974 0.982244 -0.00714241 1.09462 0.00481239C1.20699 0.0167672 1.3159 0.0508131 1.41507 0.104993C1.51425 0.159174 1.60174 0.23242 1.67251 0.320523L5.81491 5.46636C5.94105 5.61982 6.01001 5.81231 6.01001 6.01096C6.01001 6.20961 5.94105 6.4021 5.81491 6.55556L1.52671 11.7014C1.45472 11.7882 1.36629 11.86 1.26651 11.9126C1.16672 11.9653 1.05754 11.9977 0.94521 12.008C0.832879 12.0184 0.719609 12.0065 0.611886 11.973C0.504164 11.9395 0.404105 11.8851 0.317443 11.8129C0.21906 11.7331 0.139579 11.6325 0.0847117 11.5183C0.0298445 11.4041 0.000953971 11.2792 0.000116242 11.1525Z" fill="#19A3F1" />
        </svg>
      </li>
    </ul>
  </div>

  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <div class="w-100 tab-cont-dteails custom-scrollbar">
        <ul class="nav tab-vehicle-details">
          <li class="nav-item w-100"
              *ngFor="let vehicle of vehiclesToDisplay; let i=index">
            <a (click)="vehicleDetails(vehicle.vehicleId, vehicle.vehicleRegNo, i)" [ngClass]="activeShowIndex === i ? 'active' : ''" class="nav-link d-block cursor-pointer">
              <div class="w-100">
                <div class="w-100 d-flex flex-wrap">
                  <div>
                    <div class="">
                      <div class="vehicleId d-flex  w-100">
                        <div class="vehicleId fs-16 font-weight-bold">{{vehicle.vehicleRegNo}}</div>
                        <div class="vehicle-status-info pl-2 align-items-sm-end">
                          <div class="vehicle-circles rounded-circle"
                               [ngClass]="currentUser.Roles[0].Name == 'Administrator' ? checkAdminStatus(vehicle.vehicleId) : checkCntrctrStatus(vehicle.vehicleId)">
                          </div>
                        </div>
                      </div>
                      <div class="w-100 txt-gry vehicle-route fs-12 text-capitalize my-1">{{vehicle.model}}</div>
                    </div>
                  </div>
                  <div class="ml-auto">
                    <div class="vehicle-route fs-12"> <small>{{vehicle.lastUpdate2}}</small></div>
                    
                    
                  </div>
                </div>
                <div class="txt-gry vehicle-route fs-12">{{vehicle.contact}}</div>
                <div class="vehicle-route fs-12">{{vehicle.routeName}}</div>
                <div class="vehicle-route fs-12" *ngIf="vehicle.speed2 != null"><p-badge value="{{vehicle.speed2}} km/h" severity="danger"></p-badge></div>
                <div class="vehicle-route fs-12" *ngIf="vehicle.idle != null"><p-badge value="{{vehicle.idle}} km/h" severity="warning"></p-badge></div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</div>
<ng-template #loading>

<div class="row w-100 align-items-center justify-content-center" style="height:80vh;">
  <div class="col-md-3">
    <div class="loader"></div>
  </div>
</div>
</ng-template>
</div>

</div>
</div>
<!--vehiclesummary tab start-->
<div class="vehicle-locations bg-white z-index-1" *ngIf="vehiInfos == true">
<div class="vehicle-details-two-tabs" *ngIf="vehicleInfos">
<div class="w-100 d-flex navItemTitle position-relative">
<div class="vehicleId d-flex w-100">
<div class="liveTitles lh-23 fs-20">{{vehicleInfos.vehicleRegNo}}</div>
</div>
<div (click)="closeVehDetails()" [class.d-none]="vehiFullInfos" class="navCloseButton ml-auto position-absolute cursor-pointer">
<i class="fa fa-close cursor-pointer" id="search_close2"></i>
</div>
</div>
<div class="w-100 h-location-fonts fs-12 lh-14">
<div class="w-100 d-flex pb-2 align-items-center">
<div>
  <img src="./assets/images/web-112021/icons/icon-gis-route-start.png" />
</div>
<div class="pl-3">{{vehicleInfos.address}}</div>
</div>
<div class="w-100 d-flex">
<div class="pl-5">{{vehicleInfos.lastUpdate | date: 'dd/MM/yyyy hh:mm a'}}</div>
</div>
</div>
<div class="w-100">
<div class="tab-names position-relative">
<ul class="nav navlist-tabs flex-nowrap nav-pills position-relative" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link cursor-pointer d-flex align-items-center px-4 text-center fs-10 position-relative lh-12 active" id="home2-tab" data-toggle="tab" href="#home2" role="tab" aria-controls="home" aria-selected="true">
      <div class="pr-1">Summary </div>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link cursor-pointer d-flex align-items-center px-4 text-center fs-10 position-relative lh-12" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="true">
      <div class="pr-1">Status </div>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link cursor-pointer d-flex align-items-center px-4 text-center fs-10 position-relative lh-12" id="schedule-tab" data-toggle="tab" href="#schedule" role="tab" aria-controls="schedule" aria-selected="true">
      <div class="pr-1">Schedule </div>
    </a>
  </li>
</ul>
</div>
<ng-template #loaderProgress>
<div class="Loading"></div>
</ng-template>
<ng-template #loaderProgress2>
<div class="Loading"></div>
</ng-template>
<div class="h-tab-details tab-content" id="myTabContent">
<div class="tab-pane fade show active" id="home2" role="tabpanel" aria-labelledby="home2-tab">
  <div class="w-100 tab-cont-dteails custom-scrollbar">
    <ul class="nav tab-vehicle-details">

      <li class="nav-item w-100">
        <a class="nav-link d-block">
          <div class="w-100">
            <div class="w-100 d-flex flex-wrap fs-12">
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Company</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBar; else loaderProgress">{{vehicleInfos.contact}}</div>
              </div>
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Vehicle Type</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBar; else loaderProgress">{{vehicleInfos.model}}</div>
              </div>
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Route</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBar; else loaderProgress">{{vehicleInfos.routeName}}</div>
              </div>
              <!--div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Total Time Online</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBar; else loaderProgress">{{vehicleSummary.totalTimeOnline == null ? 'No data found' :  vehicleSummary.totalTimeOnline}}</div>
              </div-->
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Speed</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBar; else loaderProgress">{{(vehicleInfos.speed != null && vehicleInfos.speed != "" && vehicleInfos.speed != 0)?(vehicleInfos.speed*1.852).toFixed(2):0}} km/h</div>
              </div>
            
            </div>

          </div>
        </a>
        <div class="w-100 d-flex justify-content-center">
          <button (click)="vehicleFullDetails()" [disabled]="vehiFullInfos" class="btn btn-primary fs-12" style="margin-right:0.5em;">Vehicle details</button>
          <!-- <button class="btn btn-primary fs-12">Vehicle Movement</button> -->
        </div>

      </li>
    </ul>
  </div>
</div>
<div class="tab-pane fade" id="status" role="tabpanel" aria-labelledby="status-tab">
  <div class="w-100 tab-cont-dteails custom-scrollbar">
    <ul class="nav tab-vehicle-details">
      <li class="nav-item w-100">
        <a class="nav-link d-block">
          <div class="w-100">
            <div class="w-100 d-flex flex-wrap fs-12">
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Total Schedule</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBarBinStatus; else loaderProgress2">{{binsStatus.TotalSchedule}}</div>
              </div>
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Total PoC</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBarBinStatus; else loaderProgress2">{{binsStatus.TotalBin}}</div>
              </div>
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Total PoC Collected</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBarBinStatus; else loaderProgress2">{{binsStatus.TotalCollectionBin}}</div>
              </div>
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Total PoC Ongoing</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBarBinStatus; else loaderProgress2">{{binsStatus.TotalOngoingBin}}</div>
              </div>
              <div class="w-100 pb-2">
                <div class="txt-gry vehicle-route">Total PoC Collection (<span class="txt-black font-weight-bold">%</span>)</div>
                <div class="vehicle-route font-weight-bold" *ngIf="!progressBarBinStatus; else loaderProgress2">{{binsStatus.PercentageComplete | number}}</div>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
  <div class="w-100 tab-cont-dteails custom-scrollbar">
    <ul class="nav tab-vehicle-details">
      <li class="nav-item w-100">
        <div class="px-4  pt-1">
          <label for="icon" class="mb-0 fs-14">Schedule Date</label>
        </div>
        <div class="px-3 range-calendar2 text-center">
           <p-calendar [(ngModel)]="schdlDate" disabled="false" [readonlyInput]="false" [showButtonBar]="true" [maxDate]="maxDate" (ngModelChange)="printDate($event)" [showIcon]="true" inputId="icon"></p-calendar>
   
           <!--p-calendar [(ngModel)]="schdlDate"  [showButtonBar]="true" [maxDate]="maxDate" (ngModelChange)="printDate($event)" [showIcon]="true" inputId="icon"></p-calendar-->
        </div>
        <div class="w-100 d-flex justify-content-center px-3 py-3">
          <button type="submit" (click)="getSchedules(vehicleInfos.vehicleRegNo)" class="btn btn-primary fs-12">View Vehicle Schedules</button>
        </div>
      </li>
    </ul>
  </div>
</div>
</div>
</div>
</div>
</div>

<!--vehicel details tab start here-->
<div class="vehicle-locations2 bg-white z-index-1" [ngClass]="vehiFullInfos? 'd-block': 'd-none'">
<div class="vehicle-locations2 bg-white z-index-1" [ngClass]="vehiFullInfos? 'd-block': 'd-none'">
<div class="vehicle-details-two-tabs h-full-details">
<div class="w-100 d-flex navItemTitle position-relative">
<div class="liveTitles lh-23 fs-20">Vehicle Details</div>
<div (click)="closeVehFull()" class="navCloseButton ml-auto position-absolute cursor-pointer">
  <i class="fa fa-close cursor-pointer" id="search_close2"></i>
</div>
</div>
<div class="h-tab-details" *ngIf="vehicleInfos">
<div class="w-100 tab-cont-dteails custom-scrollbar">
  <ul class="nav tab-vehicle-details">
    <li class="nav-item w-100">
      <a class="nav-link d-block">
        <div class="w-100">
          <div class="w-100 d-flex flex-wrap fs-12">
            <div class="w-100">
              <div class="row pb-2">
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Vehicle No</div>
                  <div class="vehicle-route font-weight-bold">{{vehicleInfos.vehicleRegNo}}</div>
                </div>
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Vehicle Type</div>
                  <div class="vehicle-route font-weight-bold">{{vehicleInfos.category == null ? 'Not found' : vehicleInfos.category}}</div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Model</div>
                  <div class="vehicle-route font-weight-bold">{{vehicleInfos.model}}</div>
                </div>
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Company</div>
                  <div class="vehicle-route font-weight-bold">{{vehicleInfos.contact}}</div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Speed</div>
                  <div class="vehicle-route font-weight-bold">{{(vehicleInfos.speed != null && vehicleInfos.speed != "" && vehicleInfos.speed != 0)?(vehicleInfos.speed*1.852).toFixed(2):0}} km/h</div>
                </div>
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Distance Travelled</div>
                  <div class="vehicle-route font-weight-bold">{{(vehicleInfos.distance == null || vehicleInfos.distance == '') ? '0' : (vehicleInfos.distance/1000).toFixed(2)}} km</div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Last updated</div>
                  <div class="vehicle-route font-weight-bold">{{vehicleInfos.lastUpdate | date: 'dd/MM/yyyy hh:mm a'}}</div>
                </div>
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Total Distance</div>
                  <div class="vehicle-route font-weight-bold">{{(vehicleInfos.totalDistance == null || vehicleInfos.totalDistance == '') ? '0' : (vehicleInfos.totalDistance/1000).toFixed(2)}} km</div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Total Engine Hour</div>
                  <div class="vehicle-route font-weight-bold">{{(vehicleInfos.hours == null || vehicleInfos.hours == '') ? '0' : vehicleInfos.hours.toFixed(2)}}</div>
                </div>
                <div class="col-sm-6">
                  <div class="txt-gry vehicle-route">Altitude</div>
                  <div class="vehicle-route font-weight-bold">{{vehicleInfos.altitude}}</div>
                </div>
              </div>
            </div>
            <div class="w-100 pb-2">
              <div class="txt-gry vehicle-route">Address</div>
              <div class="vehicle-route font-weight-bold">



                {{vehicleInfos.address}}
                <!--{{vehicleInfos.parkName}}, {{vehicleInfos.streetName}}, {{vehicleInfos.location}}-->
              </div>
            </div>
            <div class="w-100 pb-2">
              <div class="txt-gry vehicle-route">Coordinates</div>
              <div class="vehicle-route font-weight-bold">{{vehicleInfos.latitude}},{{vehicleInfos.longitude}}</div>
            </div>
          </div>
        </div>
      </a>
      <div class="w-100 d-flex justify-content-center">
        <button (click)="startVehicle(vehicleInfos.vehicleId)" class="btn btn-primary fs-12">View route history</button>
      </div>
      <div class="text-danger text-center py-2" *ngIf="vihicleNotOnline">
        selected vehicle is not online
      </div>
    </li>
  </ul>

</div>
</div>
</div>
</div>
</div>

<!--collection summary code start here-->
<div class="position-absolute colleted-blocks">
<div class="w-100 bin-cout-details d-flex position-relative bin-cout-height">

<div (click)="vehicleCoutBox()" class="position-fixed v-tracking-left-btn bg-white cursor-pointer">
<div class="w-100 h-100 d-flex justify-content-center align-items-center">
<img *ngIf="!vehicleMoving" src="./assets/images/web-112021/icons/icon-left.png" />
<img *ngIf="vehicleMoving" src="./assets/images/web-112021/icons/icon-right.png" />
</div>
</div>
</div>
</div>
<div class="w-100 vh-100 overflow-hidden" [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }"
#myBounds>
<div class="colleted-blocks position-relative ">
<div class="" *ngIf="vehicleMoving">
<div class="bin-cout-infos position-absolute" [ngDraggable]="dragaable" (edge)="checkEdge($event)" [bounds]="myBounds" [inBounds]="inBounds">
<div class="bin-titles w-100 d-flex justify-content-between" id="people">
  <div>Percentage of Collection</div>
  
  <div class="d-flex">
    
   
    <button class="border-0 p-0 bg-transparent draggble cursor_grap">
      <img src="./assets/images/web-112021/icons/icon-zoom.png" />
    </button>
    <button class="ml-3 border-0 p-0 bg-transparent " (click)="dragPin()">
      <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" [ngClass]="pinActive ? 'd-none':'d-block'">
        <path d="M6.0975 1.51051C6.21087 1.28375 6.37649 1.08718 6.58073 0.936983C6.78498 0.78679 7.02198 0.687281 7.27222 0.646654C7.52247 0.606028 7.77878 0.625448 8.02005 0.703314C8.26131 0.78118 8.48062 0.915262 8.6599 1.09451L11.9055 4.34011C12.0848 4.51939 12.2188 4.7387 12.2967 4.97997C12.3746 5.22123 12.394 5.47755 12.3534 5.72779C12.3127 5.97803 12.2132 6.21504 12.063 6.41928C11.9128 6.62352 11.7163 6.78915 11.4895 6.90251L8.7231 8.28651C8.46028 8.41782 8.25476 8.64099 8.1455 8.91371L6.9967 11.7857C6.94749 11.9086 6.86854 12.0174 6.76692 12.1024C6.6653 12.1873 6.5442 12.2456 6.41447 12.2722C6.28475 12.2987 6.15045 12.2927 6.02364 12.2545C5.89683 12.2164 5.78147 12.1474 5.6879 12.0537L3.5999 9.96571L0.965502 12.6001H0.399902V12.0345L3.0343 9.40011L0.946302 7.31211C0.852602 7.21855 0.783593 7.10318 0.745465 6.97637C0.707338 6.84956 0.701284 6.71527 0.727848 6.58554C0.754412 6.45581 0.812762 6.33471 0.897665 6.23309C0.982567 6.13147 1.09137 6.05252 1.2143 6.00331L4.0863 4.85451C4.35933 4.74541 4.5828 4.53988 4.7143 4.27691L6.0983 1.51051H6.0975ZM8.0943 1.66011C8.00466 1.57057 7.89503 1.5036 7.77444 1.46472C7.65385 1.42583 7.52575 1.41615 7.40069 1.43646C7.27563 1.45677 7.15718 1.50649 7.05509 1.58154C6.953 1.65658 6.8702 1.7548 6.8135 1.86811L5.4295 4.63451C5.21069 5.0728 4.83863 5.41553 4.3839 5.59771L1.5119 6.74651L6.2535 11.4881L7.4023 8.61611C7.58448 8.16138 7.92722 7.78932 8.3655 7.57051L11.1319 6.18651C11.2452 6.12981 11.3434 6.04701 11.4185 5.94493C11.4935 5.84284 11.5432 5.72439 11.5636 5.59932C11.5839 5.47426 11.5742 5.34616 11.5353 5.22557C11.4964 5.10498 11.4294 4.99535 11.3399 4.90571L8.0943 1.66011Z" fill="#91AAB9" />
      </svg>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="pinActive ? 'd-block':'d-none'">
        <path d="M6.47616 1.80446C6.58953 1.5777 6.75516 1.38112 6.9594 1.23093C7.16364 1.08074 7.40064 0.981226 7.65089 0.9406C7.90113 0.899973 8.15745 0.919393 8.39871 0.997259C8.63998 1.07512 8.85928 1.20921 9.03856 1.38846L12.2842 4.63406C12.4634 4.81334 12.5975 5.03265 12.6754 5.27391C12.7532 5.51518 12.7726 5.77149 12.732 6.02174C12.6914 6.27198 12.5919 6.50899 12.4417 6.71323C12.2915 6.91747 12.0949 7.08309 11.8682 7.19646L9.10176 8.58046C8.83894 8.71177 8.63343 8.93493 8.52416 9.20766L7.37536 12.0797C7.32616 12.2026 7.2472 12.3114 7.14558 12.3963C7.04397 12.4812 6.92286 12.5395 6.79314 12.5661C6.66341 12.5927 6.52912 12.5866 6.40231 12.5485C6.2755 12.5104 6.16013 12.4414 6.06656 12.3477L3.97856 10.2597L1.34416 12.8941H0.778564V12.3285L3.41296 9.69406L1.32496 7.60606C1.23126 7.51249 1.16225 7.39713 1.12413 7.27032C1.086 7.14351 1.07995 7.00921 1.10651 6.87949C1.13307 6.74976 1.19142 6.62866 1.27633 6.52704C1.36123 6.42542 1.47003 6.34647 1.59296 6.29726L4.46496 5.14846C4.73799 5.03936 4.96146 4.83382 5.09296 4.57086L6.47616 1.80446ZM8.47296 1.95406C8.38332 1.86452 8.27369 1.79755 8.15311 1.75866C8.03252 1.71978 7.90442 1.71009 7.77935 1.7304C7.65429 1.75071 7.53584 1.80044 7.43375 1.87548C7.33166 1.95053 7.24886 2.04875 7.19216 2.16206L5.80816 4.92846C5.58935 5.36674 5.2173 5.70948 4.76256 5.89166L1.89056 7.04046L6.63216 11.7821L7.78096 8.91006C7.96314 8.45533 8.30588 8.08327 8.74416 7.86446L11.5106 6.48046C11.6239 6.42376 11.7221 6.34096 11.7971 6.23887C11.8722 6.13678 11.9219 6.01833 11.9422 5.89327C11.9625 5.76821 11.9528 5.64011 11.914 5.51952C11.8751 5.39893 11.8081 5.2893 11.7186 5.19966L8.47296 1.95406Z" fill="#19A3F1" />
        <path d="M8.47296 1.95406C8.38332 1.86452 8.27369 1.79755 8.15311 1.75866C8.03252 1.71978 7.90442 1.71009 7.77935 1.7304C7.65429 1.75071 7.53584 1.80044 7.43375 1.87548C7.33166 1.95053 7.24886 2.04875 7.19216 2.16206L5.80816 4.92846C5.58935 5.36674 5.2173 5.70948 4.76256 5.89166L1.89056 7.04046L6.63216 11.7821L7.78096 8.91006C7.96314 8.45533 8.30588 8.08327 8.74416 7.86446L11.5106 6.48046C11.6239 6.42376 11.7221 6.34096 11.7971 6.23887C11.8722 6.13678 11.9219 6.01833 11.9422 5.89327C11.9625 5.76821 11.9528 5.64011 11.914 5.51952C11.8751 5.39893 11.8081 5.2893 11.7186 5.19966L8.47296 1.95406Z" fill="#19A3F1" />
      </svg>
    </button>
  </div>
</div>
<div>
  
  
  <!--div class="clear-btn cursor-pointer my-3 ml-auto text-right" (click)="clearFilters()">Clear Filters</div-->
  <p class="txt-gry fs-12 my-2">Filter:<b> 
    <br>
    <span style="color: #19A3F1;" *ngIf="!vehiInfos">
   
      {{(this.selectedFilter != null && this.selectedFilter != "")?convertToUpperCase(this.selectedFilter.value.key):" Show All"}}
      {{(this.selectedCompanySchedule != null)? (this.selectedCompanySchedule.company) : ""}}
      {{(this.selectedVehicleSchedule != null)? (this.selectedVehicleSchedule.vehicle_no) : ""}}
      {{(this.selectedRouteSchedule != null)? this.selectedRouteSchedule.main_route : ""}}
    </span>
    <span style="color: #19A3F1;" *ngIf="vehiInfos">
      <!-- {{(this.vehiInfos != null)? ("VEHICLE: " + this.vehicleInfos.vehicleRegNo) :""}} -->
 
    </span>
    

  </b>
  
  </p>
  
  <span></span>
  
</div>
<div>
  <p class="txt-gry fs-12 my-2">Activity type</p>
  <div class="d-flex">
    <div>
      <div class="d-flex">
        <div>

          <button class="fs-12 btn btn-outline-primary"  [ngClass]="btnKIIsActive  ? 'ki_kb_active':''" style="cursor:pointer;" (click)="checkBinKIButton()">
            KI
          </button>
        </div>
        <div class="mx-2">
          <button class=" fs-12 btn btn-outline-primary" [ngClass]="btnKBIsActive  ? 'ki_kb_active':''" style="cursor:pointer;" (click)="checkBinKBButton()">
            KB
          </button>
        </div>
       
      </div>
    </div>
  </div>
</div>
<div class="w-100 bin-progressbar position-relative">
  <div class="progress">
    <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{width: outputBinPercentage + '%'}">
      <div class="progress-parentage position-absolute lh-14 fs-12">{{outputBinPercentage | number}}%</div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex mb-3 justify-content-center w-100">
    <div class="loader"></div>
  </div>
</ng-template>
<ng-template #loading2>
  <div class="d-flex mb-3 justify-content-center w-100">
    <div class="loader2"></div>
  </div>
</ng-template>
<div class="w-100 d-flex justify-content-between align-items-center" *ngIf="(currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">
  <div class="text-center pb-3">
    <div class="count-name lh-15 fs-12 pb-2">Collected</div>
    <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="!showLoader2; else loading2">
      <div class="bin-box bg-green-box mr-1"></div>
      <div class="bin-box-count">{{collectedBins}}</div>
    </div>
  </div>
  <div class="text-center pb-3">
    <div class="count-name lh-15 fs-12 pb-2">On-going</div>
    <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="!showLoader2; else loading2">
      <div class="bin-box bg-yellow-box stop-bg mr-1"></div>
      <div class="bin-box-count">{{ongoingBins}}</div>
    </div>
  </div>
  <div class="text-center pb-3">
    <div class="count-name lh-15 fs-12 pb-2">Total PoC</div>
    <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="!showLoader2; else loading2">
      <div class="bin-box bg-blue-box all-bg mr-1"></div>
      <div class="bin-box-count">{{totalBins}}</div>
    </div>
  </div>
</div>
<div (click)="vehicleCoutBox()" class="position-absolute right-clode-arror-bk bg-white cursor-pointer">
  <button class="border-0 p-0 bg-white">
    <img src="./assets/images/web-112021/icons/icon-right.png" />
  </button>
</div>
</div>
</div>
</div>
</div>
<p-dialog [(visible)]="filterDialogBox" [modal]="true" [maximizable]="true" [baseZIndex]="0000"
  [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}"
  [draggable]="false" [resizable]="false" styleClass="dialog-overflow-visible">
<ng-template pTemplate="header">
<div class="w-100 d-flex justify-content-between align-items-center h-100">
<div class="title-fiters">Filters</div>
<div class="d-flex align-items-center">
<div class="clear-btn cursor-pointer pr-3" (click)="clearFilters()">Clear Filters</div>
</div>
</div>
</ng-template>
<div class="row">
<div class="col-sm-6 col-md-12">
<div class="w-100 fillet-boxs-css mb-4 position-relative">

<p-dropdown  [options]="searchBy" [(ngModel)]="selectedSearchBy" [ngModelOptions]="{standalone: true}"
            [showClear]="true" placeholder="Select Filter" (onChange)="selectByFilter($event)"
            optionLabel="search" >
  <ng-template pTemplate="selectedItem">
    <div class="filler-h-item" *ngIf="selectedSearchBy">
      <div>{{selectedSearchBy.search}}</div>
    </div>
  </ng-template>
</p-dropdown>
</div>
</div>

</div>
<div class="row">

<div class="col-sm-6 col-md-12" *ngIf="isCompanyFilterShow">
<div class="w-100 fillet-boxs-css mb-4 position-relative">

<p-dropdown  [options]="companySchedules" [(ngModel)]="selectedtempCompanySchedule" [filter]="true" [ngModelOptions]="{standalone: true}"
                      [showClear]="true" placeholder="Select Company" (onChange)="onChangeCompanyFilter($event)"
                      optionLabel="company" >
              
            <ng-template pTemplate="selectedItem">
              <div class="filler-h-item" *ngIf="selectedtempCompanySchedule">
                <div>{{selectedtempCompanySchedule.company}}</div>
              </div>
            </ng-template>
           
</p-dropdown>
</div>
</div>

<div class="col-sm-6 col-md-12" *ngIf="isVehicleFilterShow">
<div class="w-100 fillet-boxs-css mb-4 position-relative">

<p-dropdown  [options]="vehicleSchedules" [(ngModel)]="selectedtempVehicleSchedule" [filter]="true" [ngModelOptions]="{standalone: true}"
                      [showClear]="true" placeholder="Select Vehicle" (onChange)="onChangeVehicleFilter($event)"
                      optionLabel="vehicle_no" >
            <ng-template pTemplate="selectedItem">
              <div class="filler-h-item" *ngIf="selectedtempVehicleSchedule">
                <div>{{selectedtempVehicleSchedule.vehicle_no}}</div>
              </div>
            </ng-template>
</p-dropdown>
</div>
</div>

<div class="col-sm-6 col-md-12" *ngIf="isRouteFilterShow">
<div class="w-100 fillet-boxs-css mb-4 position-relative">

<p-dropdown  [options]="routeSchedules" [(ngModel)]="selectedtempRouteSchedule" [filter]="true" [ngModelOptions]="{standalone: true}"
                      [showClear]="true" placeholder="Select Route" (onChange)="onChangeRoutesFilters($event)"
                      optionLabel="main_route" >
            <ng-template pTemplate="selectedItem">
              <div class="filler-h-item" *ngIf="selectedtempRouteSchedule">
                <div>{{selectedtempRouteSchedule.main_route}}</div>
              </div>
            </ng-template>
</p-dropdown>
</div>
</div>

<div class="col-sm-6 col-md-12" *ngIf="isSubRouteFilterShow">
<div class="w-100 fillet-boxs-css mb-4 position-relative">

<p-dropdown  [options]="subrouteSchedules" [(ngModel)]="selectedtempSubRouteSchedule" [filter]="true" [ngModelOptions]="{standalone: true}"
                      [showClear]="true" placeholder="Select SubRoute" (onChange)="onChangeSubRouteFilter($event)"
                      optionLabel="sub_route" >
            <ng-template pTemplate="selectedItem">
              <div class="filler-h-item" *ngIf="selectedtempSubRouteSchedule">
                <div>{{selectedtempSubRouteSchedule.sub_route}}</div>
              </div>
            </ng-template>
</p-dropdown>
</div>
</div>


</div>
<ng-template pTemplate="footer">
<button (click)="clearFilters()" class="border-0 shadow-none btn bg-transparent">Cancel</button>
<button (click)="filterDialogBox=false;this.applyFilter()" type="submit" class="btn btn-info">Apply</button>
</ng-template>
</p-dialog>

<p-toast key="myKey1"  position="top-right"></p-toast>


