import { Component, OnInit } from '@angular/core';
import { AppSharedService } from '../../app.SharedService';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit {

  constructor(private _appSharedService: AppSharedService) { }

  vehicleData: any;

  ngOnInit(): void {
    this.vehicleData = JSON.parse(localStorage.getItem('vehiclesData') || '{}');
    this._appSharedService.showAllVehicles(true);
    this._appSharedService.apiVehicleDetails(this.vehicleData);
    this._appSharedService.toggleVehiclesView(true);
  }

}
