import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import { AppSharedService } from '../../app.SharedService';
import { ConfirmationService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [ConfirmationService]
})
export class CompanyComponent implements OnInit {
  companyForm!: FormGroup;
  showLoader: boolean = false;
  dataPresent: boolean = false;
  companies: any[] = [];
  formActionType: string = '';
  formSubmitting: boolean = false;
  company: any = null;
  addDisplay: boolean = false;
  editDisplay: boolean = false;
  msgs: Message[] = [];
  data: any;

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private appCom: AppSharedService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig) { }

  first = 0;
  display: boolean = false;
  rows = 10;

  ngOnInit(): void {
    this.companyForm = this.fb.group({
      name: ["", Validators.compose([Validators.required])],
      description: ["", Validators.compose([Validators.required])],
      regNo: ["", Validators.compose([Validators.required,])],
      phoneNo: ["", Validators.compose([Validators.required])],
      isActive: ["", Validators.compose([Validators.required])],
    });
    this.getAllCompanies();
  }

  addShowDialog() {
    this.companyForm.reset();
    this.addDisplay = true;
  }

  addCancLBtn() {
    this.addDisplay = false;
  }

  editShowDialog(id: any) {
    this.editDisplay = true;
    this.formActionType = 'Edit';
    this.company = null;
    this.company = this.companies.filter(x => x.companyId == id)[0];
    if (this.company != null) {
      this.companyForm.controls['name'].setValue(this.company.name);
      this.companyForm.controls['regNo'].setValue(this.company.regNo);
      this.companyForm.controls['description'].setValue(this.company.description);
      this.companyForm.controls['phoneNo'].setValue(this.company.phoneNo);
      this.companyForm.controls['isActive'].setValue(this.company.active);
    }
    else
      this.appCom.showError("Something went wrong!", 'wrong');
  }

  editCancLBtn() {
    this.editDisplay = false;
  }

  getAllCompanies() {
    this.showLoader = true;
    this.adminService.getAllCompanies().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.data = [];
          this.companies = [];
          this.data = resp;
          for (var i = 0; i < resp.length; i++) {
            if (this.data[i].active == true) {
              this.companies.push(this.data[i]);
            }
          }
          //this.appCom.allDevicesData(this.companies);
        }
        else {
          this.data = [];
          this.companies = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
      /*  error => {
          this.appCom.showError("Something went wrong!", "wrong");
          this.showLoader = false;
        }*/
    );
  }

  addCompany() {
    this.formActionType = 'Add';
    this.showLoader = true;
    this.formSubmitting = false;
    const company = {
      name: this.companyForm.controls['name'].value,
      regNo: this.companyForm.controls['regNo'].value,
      description: this.companyForm.controls['description'].value,
      phoneNo: this.companyForm.controls['phoneNo'].value,
      isActive: true
    };
    this.addDisplay = false;

    this.adminService.addCompany(company).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully added!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  editCompany() {
    const company = {
      companyId: this.company.companyId,
      name: this.companyForm.controls['name'].value,
      regNo: this.companyForm.controls['regNo'].value,
      description: this.companyForm.controls['description'].value,
      phoneNo: this.companyForm.controls['phoneNo'].value,
      isActive: this.companyForm.controls['isActive'].value
    };
    this.editDisplay = false;

    this.adminService.editCompany(company).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully updated!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  deleteCompany(id: any) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.adminService.deleteCompany(id).subscribe(
          resp => {
            if (resp.statusCode == 200) {
              this.appCom.showSuccess(`Successfully deleted!`, 'success');
              this.ngOnInit();
            }
            else {
              this.appCom.showError("Something went wrong!", 'wrong');
            }
            this.showLoader = false;
          }, error => {
            this.appCom.showError("Something went wrong!", 'wrong');
            this.showLoader = false;
          });
      },
      reject: () => {
        this.appCom.showWarn("Rejected", "You have rejected");
      },
      key: "myDialog"
    });
  }

  validatedName(event: { charCode: number; }) {
    return (
      (event.charCode > 96 && event.charCode < 123) || //alphabets
      (event.charCode > 64 && event.charCode < 91) || //capital Alphabets
      event.charCode == 8 || //backspace
      event.charCode == 32 //Space
    );
  }

  validatedExtension(event: { charCode: number; }) {
    return (
      (event.charCode > 47 && event.charCode < 58) || // numericals
      event.charCode == 8 || //backspace
      event.charCode == 43
    );
  }
}
