import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import { AppSharedService } from '../../app.SharedService';
import { ConfirmationService, Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss'],
  providers: [ConfirmationService]
})
export class RouteComponent implements OnInit {
  routeForm!: FormGroup;
  showLoader: boolean = false;
  dataPresent: boolean = false;
  routes: any[] = [];
  formActionType: string = '';
  formSubmitting: boolean = false;
  route: any = null;
  first = 0;
  display: boolean = false;
  rows = 10;
  addDisplay: boolean = false;
  editDisplay: boolean = false;
  msgs: Message[] = [];

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private appCom: AppSharedService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig) { }

  ngOnInit(): void {
    this.routeForm = this.fb.group({
      routeId: ["", Validators.compose([Validators.required])],
      name: ["", Validators.compose([Validators.required])],
      //bins: ["", Validators.compose([Validators.required])],
      status: ["", Validators.compose([Validators.required])],
    });
    this.getAllRoutes();
  }

  addShowDialog() {
    this.routeForm.reset();
    this.addDisplay = true;
  }

  addCancLBtn() {
    this.addDisplay = false;
  }

  editShowDialog(id: any) {
    this.editDisplay = true;
    this.formActionType = 'Edit';
    this.route = null;
    this.route = this.routes.filter(x => x.routeId == id)[0];
    if (this.route != null) {
      this.routeForm.controls['name'].setValue(this.route.name);
      //this.routeForm.controls['bins'].setValue(this.route.bins);
      this.routeForm.controls['status'].setValue(this.route.active);
    }
    else
      this.appCom.showError("Something went wrong!", 'wrong');
  }

  editCancLBtn() {
    this.editDisplay = false;
  }

  getAllRoutes() {
    this.showLoader = true;
    this.adminService.getAllRoutes().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          this.dataPresent = true;
          this.routes = [];
          this.routes = resp;
        }
        else {
          this.routes = [];
          this.dataPresent = false;
        }
        this.showLoader = false;
      }),
      /* error => {
         this.appCom.showError("Something went wrong!", "wrong");
         this.showLoader = false;
       }*/
    );
  }

  addRoute() {
    this.formActionType = 'Add';
    this.showLoader = true;
    this.formSubmitting = false;
    const route = {
      name: this.routeForm.controls['name'].value,
      //bins: this.routeForm.controls['bins'].value,
      isActive: true
    };
    this.addDisplay = false;
    this.adminService.addRoute(route).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully added!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  editRoute() {
    const route = {
      routeId: this.route.routeId,
      name: this.routeForm.controls['name'].value,
      //bins: this.routeForm.controls['bins'].value,
      isActive: this.routeForm.controls['status'].value
    };
    this.editDisplay = false;
    this.adminService.editRoute(route).subscribe(
      ((resp: any) => {
        if (resp.statusCode == 200) {
          this.ngOnInit();
          this.formSubmitting = false;
          this.appCom.showSuccess("Successfully updated!", 'success');
          this.showLoader = false;
        }
        else
          this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }),
      (error: any) => {
        this.appCom.showError("Something went wrong!", 'wrong');
        this.showLoader = false;
      }
    );
  }

  deleteRoute(id: any) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.adminService.deleteRoute(id).subscribe(
          resp => {
            if (resp.statusCode == 200) {
              this.appCom.showSuccess(`Successfully deleted!`, 'success');
              this.ngOnInit();
            }
            else {
              this.appCom.showError("Something went wrong!", 'wrong');
            }
            this.showLoader = false;
          }, error => {
            this.appCom.showError("Something went wrong!", 'wrong');
            this.showLoader = false;
          });
      },
      reject: () => {
        this.appCom.showWarn("Rejected", "You have rejected");
      },
      key: "myDialog"
    });
  }

  validatedName(event: { charCode: number; }) {
    return (
      (event.charCode > 96 && event.charCode < 123) || //alphabets
      (event.charCode > 64 && event.charCode < 91) || //capital Alphabets
      event.charCode == 8 || //backspace
      event.charCode == 32 //Space
    );
  }

  validatedExtension(event: { charCode: number; }) {
    return (
      (event.charCode > 47 && event.charCode < 58) || // numericals
      event.charCode == 8 || //backspace
      event.charCode == 43
    );
  }
}
