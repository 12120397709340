import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../_helpers/auth.service';

@Injectable({
  providedIn: 'root'
})

export class UserAuthGuard implements CanActivate {
  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (AuthService.isAuthenticated() && AuthService.isAutherized('user'))
      return true;
    else {
      // not logged in so redirect to login page.
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
