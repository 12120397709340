<h5 style="margin-top: 0.5rem; margin-left:1rem">Module BHK</h5>
<p>&nbsp;</p>

<div class="all-vehicle-details custom-scrollbar">
    <div class="w-100 result-found-blocks">
        <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
                <div class="ptable-css ptable-scrollable">
                    <div class="row w-100 justify-content-between m-0">
                        <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                            <ul class="nav">
                                <li class="d-flex align-items-center nav-item w-auto">
                                    <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus"
                                        class="p-button-info rounded cursor-pointer" [disabled]="isBtnNewDisabled"
                                        (click)="openNew()"></button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                            <li class="d-flex align-items-center nav-item w-auto pr-0">
                                <div class="range-calendar position-relative" style="width: 100%">
                                    <p-calendar selectionMode="range" [(ngModel)]="range_date" dateFormat="dd/mm/yy"
                                        [showIcon]="true" placeholder="Select Date" (ngModelChange)="parseDate($event)"
                                        [showIcon]="true" inputId="icon" class="custom-bhk-calendar"></p-calendar>
                                </div>
                            </li>
                        </div>
                        <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                            <li class="d-flex align-items-center nav-item w-auto pr-0">
                                <div class="range-calendar position-relative" style="width: 100%">
                                    <p-dropdown [options]="servicearea_dropdown" [filter]="true"
                                        [ngModelOptions]="{standalone: true}" [showClear]="true"
                                        placeholder="Select Service Area" (onChange)="parseDate($event)"
                                        optionLabel="Name" class="custom-bhk-dropdown">
                                    </p-dropdown>
                                </div>
                            </li>
                        </div>
                        <div class="col-md-3 col-12 vehicle-fillter mb-3 pr-3 pl-0">
                            <li class="d-flex align-items-center nav-item w-auto pr-0">
                                <div class="range-calendar position-relative" style="width: 100%">
                                    <span class="p-input-icon-right" style="width: 100%">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" style="height: 35px"
                                            (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </div>
                            </li>
                        </div>
                    </div>
                    <!-- <div class="w-100 d-flex justify-content-between">
                        <div class="vehicle-fillter">
                            <ul class="nav">
                                <li class="d-flex align-items-center nav-item w-auto">
                                    <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus"
                                        class="p-button-info rounded cursor-pointer" [disabled]="isBtnNewDisabled"
                                        (click)="openNew()"></button>
                                </li>
                            </ul>

                        </div>
                        <table class="" style="width: 40%">
                            <tr>
                                <td style="width:250px;font-size: 90%">

                                    <li class="d-flex align-items-center nav-item w-auto">
                                        <div class="range-calendar position-relative">
                                            <p-calendar selectionMode="range" [(ngModel)]="range_date"
                                                dateFormat="dd/mm/yy" [showIcon]="true" placeholder="Select Date"
                                                (ngModelChange)="parseDate($event)" [showIcon]="true"
                                                inputId="icon"></p-calendar>
                                        </div>
                                    </li>

                                </td>
                                <td>
                                    <p-dropdown [options]="servicearea_dropdown" [filter]="true"
                                        [ngModelOptions]="{standalone: true}" [showClear]="true"
                                        placeholder="Select Service Area" (onChange)="parseDate($event)"
                                        optionLabel="Name" class="customwidth">
                                    </p-dropdown>
                                </td>
                                <td>
                                    <div >
                                        <ul class="nav">
                                            <li class="nav-item p-0" style="width: 100%;">
                                                <div class="range-calendar">
                                                    <span class="p-input-icon-right">
                                                        <i class="pi pi-search"></i>
                                                        <input pInputText type="text" style="height: 35px"
                                                            (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                                                            placeholder="Search..." />
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:100px;font-size: 90%"></td>
                                <td><ng-container *ngIf="showDatepicker">
                                        <div class="range-calendar position-relative">
                                            <div class="p-field p-col-12 ">
                                                <p-calendar dateFormat="dd-mm-yy" [showIcon]="true"
                                                    placeholder="Select Date" [showIcon]="true"
                                                    inputId="icon"></p-calendar>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="showMonthYear">
                                        <p-calendar dateFormat="mm/yy" view="month"></p-calendar>
                                        <div class="range-calendar position-relative">
                                            <div class="p-field p-col-12 ">
                                                <p-calendar dateFormat="M yy" view="month" [showIcon]="true"
                                                    placeholder="Select Date" [showIcon]="true"
                                                    inputId="icon"></p-calendar>
                                            </div>
                                        </div>
                                    </ng-container>
                                </td>
                                <td style="width:100px;font-size: 90%"></td>
                            </tr>
                        </table>
                    </div> -->
                    <div #dtMain>

                        <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['bhk_id','status','internal','start_date','start_time','end_date','Activity.Name','Scheme.name', 'Route.Name', 'SubRoute.Name', 'road_name', 'Park.Name',
                             'Bhktype.Name','bhk_detail', 'vehicle_name','created','created_by','verified_by']"
                            [(selection)]="selectedDatas" [rowHover]="true" dataKey="Id" [scrollable]="true"
                            scrollHeight="60vh" scrollDirection="both" styleClass="p-datatable-striped p-datatable-sm"
                            [loading]="isLoading" [rowsPerPageOptions]="[10,25,50]"
                            [tableStyle]="{'max-width': '100vw'}"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true">
                            <ng-template pTemplate="caption">
                                <div class="flex align-items-center justify-content-between">
                                    <div class="flex align-items-center justify-content-between summary-message">
                                        {{datas ? datas.length : 0 }} result(s) found.
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <!-- <th style="width:3vw;max-width:3vw;" class="bg-white text-center fillet-boxs-css">
                                        <p-tableHeaderCheckbox class="mx-auto"></p-tableHeaderCheckbox>
                                    </th>                             -->
                                    <th pSortableColumn="bhk_id" style="width:200px">BHK ID <p-sortIcon
                                            field="bhk_id"></p-sortIcon></th>
                                    <th pSortableColumn="status" style="width:100px">Status
                                        <p-sortIcon field="status"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="internal" style="width:100px">Internal
                                        <p-sortIcon field="internal"></p-sortIcon>
                                    </th>
                                    <!-- <th pSortableColumn="ScheduleNo" style="width:6vw;max-width:6vw;">Schedule No
                                        <p-sortIcon field="ScheduleNo"></p-sortIcon>
                                    </th> -->
                                    <th pSortableColumn="start_date" style="width:100px">Start Date
                                        <p-sortIcon field="start_date"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="start_time" style="width:100px">Start Time
                                        <p-sortIcon field="start_time"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="end_date" style="width:100px">End Date
                                        <p-sortIcon field="end_date"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="Activity.Name" style="width:100px">Activity Type
                                        <p-sortIcon field="Activity.Name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="Scheme.name" style="width:100px">Scheme
                                        <p-sortIcon field="Scheme.Name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="Route.Name" style="width:100px">Route
                                        <p-sortIcon field="Route.Name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="SubRoute.Name" style="width:100px">SubRoute
                                        <p-sortIcon field="SubRoute.Name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="Park.Name" style="width:100px">Park
                                        <p-sortIcon field="Park.Name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="road_name" style="width:100px">Road
                                        <p-sortIcon field="road_name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="Bhktype.Name" style="width:100px">BHK Type
                                        <p-sortIcon field="Bhktype.Name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="bhk_detail" style="width:100px">BHK Detail
                                        <p-sortIcon field="bhk_detail"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="vehicle_name" style="width:100px">Vehicle No.
                                        <p-sortIcon field="vehicle_name"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="created" style="width:100px">Created
                                        <p-sortIcon field="created"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="created_by" style="width:250px">Created By
                                        <p-sortIcon field="created_by"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="verified_by" style="width:250px">Verified By
                                        <p-sortIcon field="verified_by"></p-sortIcon>
                                    </th>
                                    <th style="width:100px"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data>
                                <tr class="p-selectable-row">
                                    <!-- <td style="width:3vw;max-width:3vw;" class="fillet-boxs-css">
                                        <p-tableCheckbox [value]="data" class="mx-auto"></p-tableCheckbox>
                                    </td> -->
                                    <td style="width:200px" pTooltip="{{data.bhk_id}}">
                                        {{(data.bhk_id.length>30)? (data.bhk_id |
                                        slice:0:30)+'...':(data.bhk_id) }}</td>
                                    <td style="width:100px"><span>
                                            <p-tag *ngIf="data.status=='Approve'"
                                                severity="success">{{data.status}}</p-tag>
                                            <p-tag *ngIf="data.status=='Pending'"
                                                severity="warning">{{data.status}}</p-tag>
                                            <p-tag *ngIf="data.status=='Reject'"
                                                severity="danger">{{data.status}}</p-tag>
                                        </span>
                                        <!-- <p-tag value="Primary" [rounded]="true"></p-tag>{{data.status}} -->
                                    </td>
                                    <td style="width:100px"><span>
                                            <p-label *ngIf="data.internaldata==true">Yes</p-label>
                                            <p-label *ngIf="data.internaldata==false">No</p-label>
                                        </span>
                                        <!-- <p-tag value="Primary" [rounded]="true"></p-tag>{{data.status}} -->
                                    </td>
                                    <!-- <td style="width:6vw;max-width:6vw;">{{data.schedule_no}}</td> -->
                                    <td style="width:100px">{{data.start_date | date:'dd/MM/yyyy'}} </td>
                                    <td style="width:100px">{{data.start_time}}</td>
                                    <td style="width:100px">{{data.end_date | date:'dd/MM/yyyy'}}</td>
                                    <td style="width:100px">{{data.Activity.Name}}</td>
                                    <td style="width:100px">{{data.Scheme.name}}</td>
                                    <td style="width:100px">{{data.Route.Name}}</td>
                                    <td style="width:100px">{{data.SubRoute.Name}}</td>
                                    <td style="width:100px">{{data.Park.Name}}</td>
                                    <td style="width:100px" pTooltip="{{data.road_name}}">
                                        {{(data.road_name.length>30)? (data.road_name |
                                        slice:0:30)+'...':(data.road_name) }}</td>
                                    <td style="width:100px">{{data.Bhktype.Name}}</td>
                                    <td style="width:100px">{{data.bhk_detail}}</td>
                                    <td style="width:100px">{{data.vehicle_name}}</td>
                                    <td style="width:100px">{{data.created | date:'dd/MM/yyyy hh:mm'}}
                                    </td>
                                    <td style="width:250px" pTooltip="{{data.created_by}}">
                                        {{(data.created_by.length>30)?
                                        (data.created_by |
                                        slice:0:30)+'...':(data.created_by) }}</td>
                                    <td style="width:250px" pTooltip="{{data.verified_by}}">
                                        {{(data.verified_by.length>30)?
                                        (data.verified_by |
                                        slice:0:30)+'...':(data.verified_by) }}</td>

                                    <!-- <td style="width:6vw;max-width:6vw;"><span
                                            style="display: none;">{{data.Active}}</span>
                                        <i *ngIf="data.Active" class="pi pi-check"
                                            style="font-size: 1rem; color:green"></i>
                                        <i *ngIf="!data.Active" class="pi pi-times"
                                            style="font-size: 1rem; color:red"></i>
                                    </td> -->
                                    <td style="width:100px">
                                        <p-splitButton label="Action" [model]="data.status == 'Pending' ? this.items: this.itemcustom"
                                            styleClass="p-button-outlined p-button-info"
                                            class="border-0 rounded cursor-pointer"
                                            (click)="selectData(data)"></p-splitButton>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="18" class="empty-message" style="width:2300px;">No data found.</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <div class="flex align-items-center justify-content-between summary-message">
                                    In total there are {{datas ? datas.length : 0 }} record(s).
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- SUBMIT BHK -->
<form [formGroup]="submitBHK" (ngSubmit)="onSubmit(data)" id="formSubmit" [ngClass]="{'loading': isLoading}">
    <p-dialog [(visible)]="dataDialog" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"
        [style]="{width: '70vw', overflow: 'auto'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible"
        [closable]="false">
        <p-progressBar *ngIf="isLoading" mode="indeterminate"></p-progressBar>
        <ng-template pTemplate="header">
            <div class="w-100 d-flex justify-content-between align-items-center h-100">
                <div class="title-fiters">New BHK</div>
            </div>
        </ng-template>
        <div class="row">
            <div class="col-sm-6 col-md-12">
                <div class="w-100 fillet-boxs-css mb-4 position-relative">
                </div>
            </div>
        </div>
        <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">

            <table class="mb-3">
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Activity
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-dropdown name="selectedActivity" [options]="activity_dropdown" [filter]="true"
                                    [ngModel]="selectedActivity" optionLabel="Name" optionValue="Id"
                                    [ngModelOptions]="{standalone: true}" (onChange)="onChangeActivity($event)"
                                    placeholder="Select Activity" class="custom-width">
                                </p-dropdown>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Start Date</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">
                            <div class="range-calendar position-relative">
                                <li class="d-flex align-items-center nav-item w-auto">
                                    <div class="range-calendar position-relative">
                                        <p-calendar #calendar1 dateFormat="dd/mm/yy" name="startdate"
                                            [minDate]="calendarOptions.minDate" [maxDate]="calendarOptions.maxDate"
                                            placeholder="Select Date" (onSelect)="parseDateOnly($event)"
                                            [showIcon]="true" inputId="icon"></p-calendar>
                                    </div>
                                </li>
                            </div>
                        </span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>StartTime</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td><span class=" fs-12 text-capitalize">
                            <div class="range-calendar position-relative">
                                <li class="d-flex align-items-center nav-item w-auto">
                                    <div class="range-calendar position-relative">
                                        <p-calendar #calendar2 [timeOnly]="true" [hourFormat]="'12'" icon="fa fa-clock"
                                            class="custom-calendar" [showIcon]="true" (onSelect)="parseTimeOnly($event)"
                                            dataType="string" placeholder="Select Time" inputId="timeonly"></p-calendar>
                                    </div>
                                </li>
                            </div>
                        </span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Internal
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-checkbox [binary]="true" [(ngModel)]="cbInternal"
                                    [ngModelOptions]="{standalone: true}"></p-checkbox>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Scheme
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-dropdown name="selectedScheme" [options]="scheme_dropdown" [filter]="true"
                                    [ngModel]="selectedScheme" [ngModelOptions]="{standalone: true}"
                                    placeholder="Select Scheme" (onChange)="schemeChangeEvent($event)"
                                    [disabled]=disableschemedropdown
                                    [ngClass]="{'disable-dropdown': schemeBG === 'inactive'}" class="custom-width">
                                </p-dropdown>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Route
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-dropdown name="selectedRoute" [options]="route_dropdown" [filter]="true"
                                    [ngModel]="selectedRoute" [ngModelOptions]="{standalone: true}"
                                    placeholder="Select Route" (onChange)="routeChangeEvent($event)"
                                    [disabled]=disableroutedropdown
                                    [ngClass]="{'disable-dropdown': routeBG === 'inactive'}" class="custom-width">
                                </p-dropdown>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Sub Route
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-dropdown [options]="subroute_dropdown" [filter]="true" [ngModel]="selectedSubRoute"
                                    [ngModelOptions]="{standalone: true}" placeholder="Select Sub Route"
                                    (onChange)="subRouteChangeEvent($event)" [disabled]=disablesubroutedropdown
                                    [ngClass]="{'disable-dropdown': subrouteBG === 'inactive'}" class="custom-width">
                                </p-dropdown>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Park
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-dropdown [options]="park_dropdown" [filter]="true" [ngModel]="selectedPark"
                                    [ngModelOptions]="{standalone: true}" placeholder="Select Park"
                                    (onChange)="parkChangeEvent($event)" [disabled]=disableparkdropdown
                                    [ngClass]="{'disable-dropdown': parkBG === 'inactive'}" class="custom-width">
                                </p-dropdown>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Road
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-multiSelect name="selectedRoad" [options]="road_dropdown" [filter]="true"
                                    [ngModel]="selectedRoad" [ngModelOptions]="{standalone: true}"
                                    placeholder="Select Road" [disabled]=disableroaddropdown
                                    (onChange)="roadChangeEvent($event)"
                                    [ngClass]="{'disable-multiselect': roadBG === 'inactive'}" class="custom-width"
                                    selectedItemsLabel="{0} items selected">
                                </p-multiSelect>
                                <!-- <p-dropdown [options]="road_dropdown" [filter]="true" [ngModel]="selectedRoad"
                                    [ngModelOptions]="{standalone: true}" placeholder="Select Road"
                                    [disabled]=disableroaddropdown (onChange)="roadChangeEvent($event)"
                                    [ngClass]="{'disable-dropdown': roadBG === 'inactive'}" class="custom-width">
                                </p-dropdown> -->

                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Obstacle Type
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-dropdown name="selectObstacle" [options]="bhktype_dropdown" [filter]="true"
                                    [ngModel]="selectedBHKType" optionLabel="Name" optionValue="Id"
                                    [ngModelOptions]="{standalone: true}" (onChange)="bhkTypeChangeEvent($event)"
                                    [disabled]=disablebhktypedropdown
                                    [ngClass]="{'disable-dropdown': bhktypeBG === 'inactive'}" class="custom-width"
                                    placeholder="Select Obstacle">
                                </p-dropdown>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Vehicle No
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <p-dropdown name="selectedVehicle" [options]="vehicle_dropdown" [filter]="true"
                                    [ngModel]="selectedVehicle" [ngModelOptions]="{standalone: true}"
                                    placeholder="Select Vehicle" [nullable]="true" [disabled]=disablevehicledropdown
                                    (onChange)="vehicleChangeEvent($event)"
                                    [ngClass]="{'disable-dropdown': vehicleBG === 'inactive'}" class="custom-width">
                                </p-dropdown>
                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Detail Obstacle
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <div class="w-100 fillet-boxs-css position-relative">
                                <!-- <textarea rows="5" cols="110" [(ngModel)]="DetailObstacle"
                                    style="width: 44vw"></textarea> -->
                                <textarea p-inputTextarea rows="5" cols="110" formControlName="DetailObstacle"
                                    style="width: 44vw"></textarea>



                            </div>
                        </span> </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>
                            <div class="w-100 fillet-boxs-css position-relative">
                                Attachment
                            </div>
                        </b></td>
                    <td>
                        <div class="w-100 fillet-boxs-css position-relative">
                            :
                        </div>
                    </td>
                    <td><span>
                            <p-fileUpload #fileUpload name="file" [showUploadButton]="false" [customUpload]="true"
                                (uploadHandler)="upload($event)" [multiple]="true" accept="image/*"
                                [maxFileSize]="5000000">
                                <!-- <p-fileUpload #fileUpload name="file[]" [showUploadButton]="true" (onUpload)="upload($event)" [multiple]="true" accept="image/*"
                                [maxFileSize]="1000000"> -->
                                <ng-template pTemplate="content">
                                    <ul *ngIf="uploadedFiles.length">
                                        <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes
                                        </li>
                                    </ul>
                                </ng-template>
                            </p-fileUpload>
                        </span> </td>
                </tr>
            </table>
            <p-messages></p-messages>
            <div *ngIf="!showBHKLoader" class="w-100 fillet-boxs-css position-relative">
                <button pButton label="Submit" class="p-button-info" type="submit"></button>
            </div>
            <div *ngIf="showBHKLoader" class="align-items-center justify-content-center"
                style="text-align: center; display: flex;">
                <div class="loader"></div>
            </div>

        </ng-template>
        <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
            <button pButton label="Close" icon="pi pi-times"
                class="p-button-outlined p-button-info p-button p-component" (click)="hideDialog()"
                type="button"></button>
        </ng-template>
        <ng-template #loaderProgress>
            <div class="Loading"></div>
        </ng-template>
    </p-dialog>
</form>
<!-- Details BHK -->
<form [formGroup]="DetailBHK">
    <p-dialog [(visible)]="dataEditDialog" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"
        [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible" [closable]="true">
        <ng-template pTemplate="header">
            <div class="w-100 d-flex justify-content-between align-items-center h-100">
                <div class="title-fiters">Details BHK</div>
            </div>
        </ng-template>
        <div class="row">
            <div class="col-sm-6 col-md-12">
                <div class="w-100 fillet-boxs-css mb-4 position-relative">
                </div>
            </div>
        </div>

        <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">

            <table class="mb-3 custom-table-head">
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>BHK ID</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.bhk_id}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Activity</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.Activity?.Name}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Internal</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize"><p-label
                                *ngIf="dataEdit.internaldata==true">Yes</p-label>
                            <p-label *ngIf="dataEdit.internaldata==false">No</p-label></span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Scheme</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.Scheme?.Name}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Route</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.Route?.Name}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Start Date</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.start_date |
                            date:'dd/MM/yyyy'}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Start Time</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.start_time}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>BHK Type</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.Bhktype?.Name}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Status</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataEdit.status}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Attachment</b></td>
                    <td style="width:100px;font-size: 90%;">:</td>
                    <td style="width:40vw;">
                        <span class=" fs-12 text-capitalize custom-preview">
                            <p-image *ngFor="let url of images" [src]="url" alt="Image" width="35" height="35"
                                [preview]="true"></p-image>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b></b></td>
                    <td style="width:100px;font-size: 90%;">
                    </td>
                    <td style="width:100px; text-align: end">
                        <div class="fillet-boxs-css position-relative">
                            <button #btnPrint pButton pRipple type="button" pTooltip="Print" tooltipPosition="top"
                                placeholder="Top" icon="pi pi-print" class="p-button-secondary"
                                style="width: fit-content; margin-right: 5px" (click)="openPrintableView()"></button>
                            <button #btnApprove pButton label="Approve" class="p-button-success"
                                style="width: fit-content; margin-right: 5px" [disabled]="isBtnApproveRejectDisabled"
                                (click)="updateBHKStatus(dataEdit.id,'Approve')"></button>
                            <button #btnReject pButton label="Reject" class="p-button-danger"
                                style="width: fit-content;" [disabled]="isBtnApproveRejectDisabled"
                                (click)="updateBHKStatus(dataEdit.id,'Reject')"></button>
                        </div>


                        <!-- <div *ngIf="showBHKLoader" class="align-items-center justify-content-center"
                            style="text-align: center; display: flex;">
                            <div class="loader"></div>
                        </div> -->
                    </td>
                </tr>
            </table>
            <div *ngIf="showBHKLoader" class="align-items-center justify-content-center"
                style="text-align: center; display: flex;">
                <div class="loader"></div>
            </div>
            <!-- <div *ngIf="showLoader" class="align-items-center justify-content-center"
                style="text-align: center; display: flex;">
                <div class="loader"></div>
            </div> -->
            <div class="scrollable-table">
                <table class="table mt-3 outside-border" *ngIf="!showBHKLoader">
                    <thead class="thead-light" style="background-color: #e9ecef;">
                        <tr>
                            <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="11">BHK Schedule
                                List
                            </td>
                        </tr>
                    </thead>
                    <!-- <tbody > -->

                    <!-- <tbody [ngIf]="backupVehicleList.length!=0" [ngIfElse]="loading"> -->
                    <tbody>

                        <ng-template [ngIf]="BHKScheduleList.length!=0" [ngIfElse]="noData">

                            <tr>
                                <td style="width:30vw;font-size: 90%"><b>Schedule No.</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Route</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Date</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Start Time</b></td>
                                <td style="width:30vw;font-size: 90%"><b>End Time</b></td>
                                <td style="width:30vw;font-size: 90%"><b>SubRoute</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Park</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Road</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Location</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Bin Qty.</b></td>
                                <td style="width:30vw;font-size: 90%"><b>Company</b></td>
                            </tr>
                            <tr *ngFor="let item of BHKScheduleList">
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.schedule_no}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.route_name}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.date}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.start_time}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.end_time}}</span>
                                </td>
                                <td style="width:30vw;"><span
                                        class=" fs-12 text-capitalize">{{item.sub_route_name}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.park_name}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.road_name}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.location}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.qtybin}}</span>
                                </td>
                                <td style="width:30vw;"><span class=" fs-12 text-capitalize">{{item.company}}</span>
                                </td>
                            </tr>
                            <!-- <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">
                                <b>{{item.schedule_no}}</b> ({{item.RoadName}})-{{item.ParkName}}
                            </td> -->

                        </ng-template>
                        <ng-template #noData>
                            <tr>
                                <td style="padding: 0.3rem;" class="font-italic empty-message">No Schedule Available
                                </td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>


        </ng-template>
        <!-- <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
            <button pButton label="Close" icon="pi pi-times"
                class="p-button-outlined p-button-info p-button p-component" (click)="hideEditDialog()"
                type="button"></button>
        </ng-template> -->
        <ng-template #loaderProgress>
            <div class="Loading"></div>
        </ng-template>
    </p-dialog>
</form>
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-toast key="alertError" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>