import { Component, OnInit } from '@angular/core';
import { AppSharedService } from '../../app.SharedService';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  leftSideBoxHidden: boolean = false;

  constructor(private _appSharedService: AppSharedService) { }

  ngOnInit(): void {
   
    this._appSharedService.navSidebarView$.subscribe(value => { this.leftSideBoxHidden = value; });
  }

}
