import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APIResponse } from '../app.models';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { AppSharedService } from '../app.SharedService';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

const CryptoSecretKey = 'P^!*@T*n$E9M#D&';
const USER_PERSISTENCE_TOKEN = "cuti";
const USER_PERSISTENCE_TOKEN_TIMEOUT = "tolouasto"; //tologoutuseraftersessiontimeout
const USER_PERSISTENCE_STR = "currentData";
const USER_PERSISTENCE_ROLE = "elor";

@Injectable({
  providedIn: "root"
})

export class AuthService {
  private sessionInterval: any = null;
  publicKey: string = '';
  private lastActiveDate: any;
  private now: any;

  constructor(private http: HttpClient,
    private cookies: CookieService,
    private _appSharedService: AppSharedService,
    private router: Router) {
    fetch('/assets/security/public_key.txt').then(res => res.text()).then(data => this.publicKey = data);
  }

  static isAuthenticated() {
    var token = this.getCurrentToken();
    var data = this.getCurrentUserData();
    if (data != null && token != null)
      return true;
    else
      return false;
  }

  static isAutherized(role: string) {
    return this.getCurrentRole() == role;
  }

  static getCurrentToken() {
    try {
      var encryptedToken = localStorage.getItem(USER_PERSISTENCE_TOKEN);
      if (encryptedToken != null && encryptedToken != undefined && encryptedToken != '') {
        return atob(encryptedToken);
      }
      else
        return null;
    } catch (e) {
      return null;
    }
  }

  static getCurrentUserData() {
    try {
      var data = localStorage.getItem(USER_PERSISTENCE_STR);
      return JSON.parse(data == null ? 'null' : data);
    } catch (e) {
    }
  }

  static getCurrentRole() {
    try {
      var encryptedRole = localStorage.getItem(USER_PERSISTENCE_ROLE);
      if (encryptedRole != null && encryptedRole != undefined && encryptedRole != '') {
        return atob(encryptedRole);
      }
      else
        return null;
    } catch (e) {
      return null;
    }
  }

  getUserCookie(name: any) {
    let encUserCookie = this.cookies.get(name); // credentials to login user (clu)
    if (encUserCookie != null && encUserCookie != undefined && encUserCookie != '') {
      const bytes = CryptoJS.AES.decrypt(encUserCookie, CryptoSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }
  }

  setUserCookie(name: any, obj: any) {
    this.cookies.set(name, CryptoJS.AES.encrypt(JSON.stringify(obj), CryptoSecretKey).toString());
  }

  removeUserCookie(name: any) {
    this.cookies.delete(name);
  }

  login(credentials: Object, isAdmin: boolean) {
    return this.http.post('/api/Authorizations/Authentications/SignIn', credentials).pipe(
      map((res: APIResponse) => {
        if (res.Success) {
  
          localStorage.setItem(USER_PERSISTENCE_STR, JSON.stringify(res.Result));
          localStorage.setItem(USER_PERSISTENCE_TOKEN, btoa(res.Result.JWTToken));
          localStorage.setItem(USER_PERSISTENCE_TOKEN_TIMEOUT, btoa("1430"));
          localStorage.setItem(USER_PERSISTENCE_ROLE, btoa(isAdmin ? "admin" : "user"));
          this.updateUserSession();
        }
        return res;
      })
    );
  }

  logout(loginPath: string) {
    this._appSharedService.logout(true);
    clearInterval(this.sessionInterval);
    localStorage.removeItem('vehiclesData');
    localStorage.clear();
    this.router.navigate(["/login"]);
    this.router.navigate([loginPath]);
  }

  updateUserSession() {
    if (AuthService.getCurrentToken() != null) {
      var lastActiveDate = localStorage.getItem('lastActive');
      
      if (lastActiveDate != null) {
     
        localStorage.removeItem('lastActive');
        this.now = new Date(formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US'));
        this.lastActiveDate = new Date(formatDate(lastActiveDate, 'yyyy-MM-dd hh:mm', 'en-US'));
        var diff = Math.abs(this.now - this.lastActiveDate) / 60000; // Will get in minutes
        var minsOut = Number(atob(localStorage.getItem(USER_PERSISTENCE_TOKEN_TIMEOUT) || ''));
        if (minsOut > diff) {
          localStorage.setItem(USER_PERSISTENCE_TOKEN_TIMEOUT, btoa((minsOut - diff).toString()));
        }
        else {
          this.logout('/login');
        }
      }
      

      this.sessionInterval = window.setInterval(() => {
        
        var timeOutInMins = Number(atob(localStorage.getItem(USER_PERSISTENCE_TOKEN_TIMEOUT) || ''));
        if (timeOutInMins == 2) {
          this._appSharedService.showWarn('Your session is going to expire!', "");
          localStorage.setItem(USER_PERSISTENCE_TOKEN_TIMEOUT, btoa((--timeOutInMins).toString()));
        }
        else if (timeOutInMins == 1) {
          this._appSharedService.showWarn('Your session has expired!', "");
          this.logout('/login');
        }
        else
          localStorage.setItem(USER_PERSISTENCE_TOKEN_TIMEOUT, btoa((--timeOutInMins).toString()));

       
      }, 60000); // For every 1 min, the session will be recounted
    }
  }
}
