import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSharedService } from '../../app.SharedService';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit {



  value: number = 0;
  binsWidthsPs: number = 0;
  vehicleMoving: boolean = true;
  vehicleBottomDetails: boolean = false;
  dragaable: boolean = true;
  pinActive: boolean = false;
  map: any;
  map2: any;
  successMsgBox: boolean = false;
  data: any;
  vehicleData: any;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  showLoader: boolean = false;
  CollectedBins: any = [];
  outputBins: number = 0;
  collectedBins: number = 0;
  ongoingBins: number = 0;
  totalBins: number = 0;
  outputBinPercentage: number = 0;
  btnKIIsActive: boolean = true;
  btnKBIsActive: boolean = true;
  binCollectedSummary: any = [];
  currentUser: any;
  constructor(private _appSharedService: AppSharedService, private cmnService: CommonService) { }

  ngOnInit(): void {

    this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');
    //this._appSharedService.updateBinValue$.subscribe(value => { this.data = value });
    //Added 06/09/2022
    this._appSharedService.updateBinValue$.subscribe(value => {
        
      if (value != null) {
        this.getcollectedBins(value);
      }
      
    });
    //this.vehicleData = JSON.parse(localStorage.getItem('vehiclesData') || '{}');
    //this._appSharedService.showAllVehicles(true);
    //this._appSharedService.apiVehicleDetails(this.vehicleData);
    //this._appSharedService.toggleVehiclesView(true);
    //this.getcollectedBins();
  }
  alertBoxClose() {
    this.successMsgBox = false;
  }
  vehicleBottomView() {
    this.vehicleBottomDetails = !this.vehicleBottomDetails;
    this._appSharedService.toggleSideMenu(0);
    this._appSharedService.closeSidebarView(0);
  }
  vehicleCoutBox() {
    this.vehicleMoving = !this.vehicleMoving;
    this._appSharedService.closeSidebarView(0);
  }
  dragPin() {
    this.dragaable = !this.dragaable;
    this.pinActive = !this.pinActive;
  }

  checkEdge(event: { top: boolean; bottom: boolean; left: boolean; right: boolean; }) {
    this.edge = event;
  }
  getcollectedBins(value: any) {
    this.showLoader = true;

    if(value.route != null)
    {
      this.cmnService.getBinTotalSummaryRouteByDate(value.date,value.route,value.archive_year).subscribe((resp: any) => {
        if (resp.Total != 0) {
  
          this.binCollectedSummary = resp.Result;
          console.log(this.binCollectedSummary);
          this.setCollectionSummary();
          this.showLoader = false;
  
       } else { this.showLoader = false; }
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
     }
    }

    if(value.vehicleRegNo != null)
    {
      this.cmnService.getBinTotalSummaryVehicleByDate(value.date,value.vehicleRegNo,value.archive_year).subscribe((resp: any) => {
        if (resp.Total != 0) {
          this.binCollectedSummary = resp.Result;
          this.setCollectionSummary();
          this.showLoader = false;
        } else { this.showLoader = false; }
      }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }

      
    }
    
  }

  

  checkBinKIButton(){

    if(this.btnKIIsActive)
      this.btnKIIsActive = false;
    else
      this.btnKIIsActive = true;

    this.setCollectionSummary();
  }

  checkBinKBButton(){

    if(this.btnKBIsActive)
      this.btnKBIsActive = false;
    else
      this.btnKBIsActive = true;

    this.setCollectionSummary();
  }

  setCollectionSummary() {

    if (this.btnKIIsActive && this.btnKBIsActive) {

     
      this.collectedBins = this.binCollectedSummary.TotalCollectionBin;
      this.ongoingBins = this.binCollectedSummary.TotalOngoingBin;
      this.totalBins = this.binCollectedSummary.TotalBin;
      this.outputBinPercentage = this.binCollectedSummary.PercentageComplete;
      
    }
    else if (this.btnKIIsActive && !this.btnKBIsActive) {
     this.collectedBins = this.binCollectedSummary.TotalCollectionBinKI;
     this.ongoingBins = this.binCollectedSummary.TotalOngoingBinKI;
     this.totalBins = this.binCollectedSummary.TotalBinKI;
     this.outputBinPercentage = this.binCollectedSummary.PercentageCompleteKI;

    }
    else if (!this.btnKIIsActive && this.btnKBIsActive) {
      this.collectedBins = this.binCollectedSummary.TotalCollectionBinKB;
      this.ongoingBins = this.binCollectedSummary.TotalOngoingBinKB;
      this.totalBins = this.binCollectedSummary.TotalBinKB;
      this.outputBinPercentage = this.binCollectedSummary.PercentageCompleteKB;
 
     }

    // this.filterVehiclesCount(this.allVehicles);
    // this.filterVehiclesToDisplay('all', true);
  }
}
