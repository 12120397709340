import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '../admin/admin/admin.component';
import { AdminLoginComponent } from '../admin/admin-login/admin-login.component';
import { CompanyComponent } from '../admin/company/company.component';
import { DeviceComponent } from '../admin/device/device.component';
import { RouteComponent } from '../admin/route/route.component';
import { ScheduleComponent } from '../admin/schedule/schedule.component';
import { VehicleComponent } from '../admin/vehicle/vehicle.component';
import { AdminAuthGuard } from '../admin/admin-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      { path: 'device', component: DeviceComponent },
      { path: "company", component: CompanyComponent },
      { path: "route", component: RouteComponent },
      { path: "schedule", component: ScheduleComponent },
      { path: "vehicle", component: VehicleComponent },
      { path: "", redirectTo: "device" },
    ],
    canActivate: [AdminAuthGuard],
    runGuardsAndResolvers: "always"
  },
  { path: "login", component: AdminLoginComponent },
  { path: "", redirectTo: "login" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
