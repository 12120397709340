import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import * as _ from "lodash";
import { tap } from "rxjs/operators";
const ignore = [/\.json$/];
import { Router } from '@angular/router';

function finalUrl(url: any, prefix = true) {
  
  if (prefix) {
    if (url.match('device.telematic.mflora.com.my')) {
      
      return environment.apiDataURL + url;
      
    }
    else if (url.startsWith('/api')) {
      return environment.apiBaseURL + url;
    } 
  } else {
    return url;
  }
}

@Injectable()

export class APIInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = new HttpParams({ fromString: request.params.toString() });

    const prefix = !_.find(ignore, (re: { test: (arg0: string) => any; }) => re.test(request.url)),
      stream = next.handle(
        request.clone({
          url: finalUrl(request.url, prefix),
          params: params
        })
      );
    return !prefix
      ? stream
      : stream.pipe(
        tap(response => {
          if (response instanceof HttpResponse) {
            if (response.status == 401) { // Session expired
              localStorage.clear();
              this.router.navigate(['/login']);
            }
            const { message, responseCode, requestId } = response.body;
            if (responseCode && requestId && typeof message === "string") {
              response.body.message = "api.response." + message;
            }
          }
        })
      );
  }
}


