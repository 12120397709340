import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import { MenuItem } from 'primeng/api';
import { ConfigActionLog } from 'src/app/app.models';
import { FormBuilder } from '@angular/forms';
import { GlobalComponent } from 'src/app/global-component';
import {CalendarModule} from 'primeng/calendar';
import { formatDate } from '@angular/common';


@Component({
    selector: 'app-config-module-action-log',
    templateUrl: './config-module-action-log.component.html',
    styleUrls: ['./config-module-action-log.component.scss']
})

export class ConfigModuleActionLogComponent implements OnInit {

    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigActionLog[]= [];
    data: ConfigActionLog = { ModuleConfigActionLog: {}};
    selectedDatas: any[] = [];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    date_filter: any[] = [];
    user_filter= "";
    user_dropdown: any[] = [];
    hidden_calendar = "display: none !important";
    param_preference = { key: "Month"};
    month_preference = 0;
    dateTime = new Date();
    min_date = new Date();
    range_date = new Date();

    min_date_filter = new Date();

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) {}

    ngOnInit(): void {

        this.getDatasPreference();
        // this.getDatas(range_date_default);
        this.menuitems = GlobalComponent.submenus;

        this.commonService.getAllUSersBasic().subscribe((resp: any) => {

            if (resp.length != 0) {
                this.user_dropdown = resp.Result;
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getDatas(range_date : any){

        this.commonService.getModuleConfigActionLog(range_date).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.datas = resp.Result;
                this.loading = false;
              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
      
    }

    parseDate(e : any) {
        console.log(e);
        if (e[1] != null) {
            this.date_filter = e;
        }

        if(e.value != null) {
            this.user_filter = e.value.ModulePersonId;
        }

        if(this.user_filter.length > 0 && this.date_filter.length > 0) {
            var range_date_filter = {
                start_date: formatDate(new Date(this.date_filter[0]),'yyyy-MM-dd', 'en-US'),
                end_date: formatDate(new Date(this.date_filter[1]),'yyyy-MM-dd', 'en-US'),
                module_person_id: this.user_filter
            }

            this.getDatas(range_date_filter);

        } else if(this.user_filter.length > 0) {
            var range_date_filter = {
                start_date: formatDate(new Date(this.min_date),'yyyy-MM-dd', 'en-US'),
                end_date: formatDate(new Date(this.dateTime),'yyyy-MM-dd', 'en-US'),
                module_person_id: this.user_filter
            }

            this.getDatas(range_date_filter);
        }
    }

    getDatasPreference(){
        this.commonService.getConfigPreferenceSettingsByKey(this.param_preference).subscribe((resp: any) => {
            if (resp.length != 0) {

                this.month_preference = resp.Result[0].Value;
                
                this.min_date = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - resp.Result[0].Value, 
                    new Date().getDate());
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
      
    }

    clearFilterData(){
        window.location.reload();
        //this.range_date.reset();

        var range_date_filter = {
            start_date: null,
            end_date: null,
            module_person_id: null
        }

        // this.getDatas(range_date_filter);
    }


}
