<p-toast></p-toast>

<p-card styleClass="p-card-content-0">
    <p-menubar  [model]="menuitems">   
    </p-menubar>
    <h5 style="margin-top: 2.0rem; margin-left:1rem">Config Route</h5>
    <!-- <p>&nbsp;</p> -->
    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
         
          
          <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
              <!--Route schedule block-->
              <div class="ptable-css ptable-scrollable">
               
                  <div class="w-100 d-flex justify-content-between my-4">
                    <div class="vehicle-fillter">
                    
                        <ul class="nav">

                          <li class="d-flex align-items-center nav-item w-auto">                           
                            <!-- <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus" class="p-button-info rounded cursor-pointer" (click)="openNew()"></button> -->
                          </li>
                          <!-- <li class="d-flex align-items-center nav-item w-auto">
                           
                            <button style="width: 5rem;"  pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger  rounded cursor-pointer" (click)="deleteSelectedDatas()" [disabled]="!selectedDatas || !selectedDatas.length"></button>
                          </li> -->
                          <li class="d-flex align-items-center nav-item w-auto">
                           
                            <!-- <button style="width: 5rem;"  pButton pRipple label="Clear" icon="pi pi-filter-slash" class="p-button-info  rounded cursor-pointer" (click)="clearselectData()" [disabled]="!selectedDatas || !selectedDatas.length"></button> -->
                          </li>

                        </ul>
                      </div>
    
                      <div class="vehicle-fillter">
                        <ul class="nav">
                          <li class="nav-item">
                            <div class="range-calendar" style="margin-top: 0.5rem;">
                              <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <!--input type="text" [(ngModel)]="selectedDatas" pInputText placeholder="Search"-->
                                <!--input pInputText type="text" (input)="dt1.ElementRef.filterGlobal($event.target.value, 'contains')" placeholder="Search..." /-->
                                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                              </span>
                            </div>
                          </li>
                          <!-- <li class="d-flex align-items-center nav-item w-auto pr-0 dropdown down-drop-btn-bk">
                            <button class="border px-3 py-2 filter-btn-btm bg-transparent d-flex align-items-center h-100  d-flex dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <div>Export</div>
                              <div class="pl-3">
                                <img src="./assets/images/web-112021/icons/export.png" />
                              </div>
                            </button>
                            <ul class="dropdown-menu border-0 p-0 mt-0 w-100 bg-white" aria-labelledby="navbarDropdown"> -->
                              <!-- <li>
                                <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportExcel()">Download all</a>
                              </li> -->
                              <!-- <li>
                                <a class="dropdown-item fs-12 txt-black" style="cursor:pointer">Download selected</a>
                              </li>
                            </ul>
                          </li> -->
                        </ul>
                      </div>
                  </div>
                <div #dtMain>
                  <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['Name','ServiceArea.Name', 'ShiftStatus','Active']" 
                  [(selection)]="selectedDatas"  [rowHover]="true" dataKey="id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                   responsiveLayout="scroll" [rowsPerPageOptions]="[10,25,50]"
                  [scrollable]="true" scrollHeight="60vh" [tableStyle]="{'max-width': '100vw'}"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                  <ng-template pTemplate="caption">
                      <div class="flex align-items-center justify-content-between" style="margin-top: -1rem; margin-left: -0.7rem;">
                        <div class="flex align-items-center justify-content-between summary-message">
                          {{datas ? datas.length : 0 }} result(s) found.
                      </div>
                      </div>
                  </ng-template>
                  
                  <ng-template pTemplate="header">
                      <tr>
                        <th pSortableColumn="Name" style="width:150px">Route Name<p-sortIcon field="Name"></p-sortIcon></th>
                        <th pSortableColumn="ShiftStatus" style="width:150px">Shift Type<p-sortIcon field="ShiftStatus"></p-sortIcon></th>
                        <th pSortableColumn="ServiceArea.Name" style="width:150px">Service Area Name<p-sortIcon field="ServiceAreaName"></p-sortIcon></th>
                        <th pSortableColumn="Active" style="width:150px">Status<p-sortIcon field="Active"></p-sortIcon></th>
                        <th style="width:150px"></th>        
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data>
                      <tr class="p-selectable-row">
                          <td style="width:25vw;max-width:25vw;">
                            <div class="w-100">
                              <div class="w-100 d-flex flex-wrap">
                                <div class="w-100 d-flex">{{data.Name}}</div>
                              </div>
                            </div>
                          </td>
                          <td style="width:150px">
                            <span class="fs-10 text-capitalize badge badge-pill badge-warning" *ngIf="data.ShiftStatus == 0">Day</span>
                            <span class="fs-10 text-capitalize badge badge-pill badge-secondary" *ngIf="data.ShiftStatus == 1">Night</span>
                            <span class="fs-10 text-capitalize badge badge-pill badge-success" *ngIf="data.ShiftStatus == 2">Day & Night</span>
                          </td>
                          <td style="width:150px">{{data.ServiceArea.Name}}</td>
                          <td style="width:150px">
                            <i *ngIf="data.Active" class="pi pi-check" style="font-size: 1rem; color:green"></i>                                                        
                            <i *ngIf="!data.Active"  class="pi pi-times" style="font-size: 1rem; color:red"></i>                           
                           </td>
                          <td style="width:150px">
                            <button pButton label="Edit" icon="pi pi-pencil" class="p-button-info" type="button" (click)="editData(data)"></button>                                                            
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="5" class="empty-message">No data found.</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between summary-message">
                        In total there are {{datas ? datas.length : 0 }} record(s).
                    </div>
                </ng-template>
        
              </p-table>
                </div>
      
                
              </div>
              
            </div>
          </div>
        </div>
    </div>
    
</p-card>
<!-- MODAL -->
<form [formGroup]="routeShift" (ngSubmit)="onSubmit(data)" id="formSubmit">
  <p-dialog [(visible)]="dataDialog" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"   [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
      <ng-template pTemplate="header">
          <div class="w-100 d-flex justify-content-between align-items-center h-100">
            <div class="title-fiters">Manage Shift</div>
          </div>
      </ng-template>
      <div class="row">
          <div class="col-sm-6 col-md-12">
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
            </div>
          </div>
      </div>
      <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">

          <table class="mb-3">
              
              <tr>
                  <td style="width:30vw;font-size: 90%"><b>Route</b></td>
                  <td style="width:10vw;font-size: 90%">:</td>
                  <td style="width:60vw;font-size: 90%"><span class="text-capitalize">{{data.Name}}</span></td>
              </tr>
              <tr>
                  <td style="width:30vw;font-size: 90%"><b>Status</b></td>
                  <td style="width:10vw;font-size: 90%">:</td>
                  <td style="width:60vw;font-size: 90%">
                      <span class="text-capitalize badge badge-pill badge-warning" *ngIf="data.ShiftStatus == '0'">Day</span>
                      <span class="text-capitalize badge badge-pill badge-secondary" *ngIf="data.ShiftStatus == '1'">Night</span>
                      <span class="text-capitalize badge badge-pill badge-success" *ngIf="data.ShiftStatus == '2'">Day & Night</span>
                  </td>
              </tr>
              <tr>
                  <td style="width:30vw;font-size: 90%"><b> <div class="w-100 fillet-boxs-css mb-4 position-relative">
                      Assign Shift
                  </div></b></td>
                  <td style="width:10vw;font-size: 90%">
                      <div class="w-100 fillet-boxs-css mb-4 position-relative">
                          :
                      </div>
                  </td>
                  <td style="width:60vw;;font-size: 90%">
                    <span><div class="w-100 fillet-boxs-css mb-4 position-relative">
                      <p-dropdown [options]="shift" [ngModelOptions]="{standalone: true}"
                                    [showClear]="true" placeholder="Select Shift" (onChange)="onChangeVehicleFilter($event)"
                                    optionLabel="name">
                      </p-dropdown>
                    </div></span>
                  </td>
              </tr>
          </table>
          <div class="w-100 fillet-boxs-css mb-4 position-relative text-align-center" style="text-align: center; color: #19A3F1">                
              <button *ngIf="!isLoading" pButton label="Assign" class="p-button-info" type="submit"></button>
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span>

          </div>
          <!-- <div *ngIf="showVehicleLoader" class="align-items-center justify-content-center" style="text-align: center; display: flex;">
              <div class="loader"></div>
          </div> -->

      </ng-template>
      <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
          <!-- <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component" (click)="hideDialog()" type="button"></button> -->
      </ng-template>
      <ng-template #loaderProgress>
          <div class="Loading"></div>
      </ng-template>
  </p-dialog>
</form>
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
