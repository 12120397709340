<!-- <p-toast></p-toast> -->

<p-card styleClass="p-card-content-0">
    <p-menubar [model]="menuitems">   
    </p-menubar>
    <h5 style="margin-top: 2.0rem; margin-left:1rem; margin-bottom: -0.5rem;">Config Scheme</h5>
    
    <p>&nbsp;</p>

    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
          <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
              <!--Route schedule block-->
              <div class="ptable-css ptable-scrollable">
                <div class="w-100 d-flex justify-content-between my-4">
                    <div class="vehicle-fillter">
                    </div>
                    <div class="vehicle-fillter">
                      <ul class="nav">
                        <li class="nav-item">
                          <div class="range-calendar">
                            <span class="p-input-icon-right">
                              <i class="pi pi-search"></i>
                              <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                </div>
                <div #dtMain>
                  <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['Code','Name','Description','ServiceArea.Name','Active']" 
                  [(selection)]="selectedDatas"  [rowHover]="true" dataKey="id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                   responsiveLayout="scroll" [rowsPerPageOptions]="[10,25,50]"
                  [scrollable]="true" scrollHeight="60vh" [tableStyle]="{'max-width': '100vw'}"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                    <ng-template pTemplate="caption">
                        <div class="flex align-items-center justify-content-between" style="margin-top: -1rem; margin-left: -0.7rem;">
                          <div class="flex align-items-center justify-content-between summary-message">
                            {{datas ? datas.length : 0 }} result(s) found.
                        </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                          <th pSortableColumn="Code" style="width:150px">Code <p-sortIcon field="Code"></p-sortIcon></th>            
                          <th pSortableColumn="Name" style="width:150px">Scheme Name <p-sortIcon field="Name"></p-sortIcon></th>
                          <th pSortableColumn="Description" style="width:150px">Description <p-sortIcon field="Description"></p-sortIcon></th>
                          <th pSortableColumn="ServiceArea.Name" style="width:150px">Service Area Name <p-sortIcon field="ServiceAreaName"></p-sortIcon></th>
                          <th pSortableColumn="Active" style="width:150px">Status <p-sortIcon field="Active"></p-sortIcon></th>
                          <th style="width:150px"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr class="p-selectable-row">
                            <td style="width:150px">{{data.Code}}</td>
                            <td style="width:150px">{{data.Name}}</td>
                            <td style="width:150px">{{data.Description}}</td>
                            <td style="width:150px">{{data.ServiceArea.Name}}</td>
                            <td style="width:150px"><span style="display: none;">{{data.Active}}</span>
                              <i *ngIf="data.Active" class="pi pi-check" style="font-size: 1rem; color:green"></i>                                                        
                              <i *ngIf="!data.Active"  class="pi pi-times" style="font-size: 1rem; color:red"></i>                           
                            </td>
                            <td style="width:150px">
                                <p-splitButton label="Action" [model]="this.items" styleClass="p-button-outlined p-button-info" class="border-0 rounded cursor-pointer" (click)="selectData(data)"></p-splitButton>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6" class="empty-message">No data found.</td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div class="flex align-items-center justify-content-between summary-message">
                          In total there are {{datas ? datas.length : 0 }} record(s).
                      </div>
                    </ng-template>
        
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    
</p-card>
<!------------------------------- MODAL ASSIGN VEHICLE ---------------------------------->
<div id="formSubmit">
  <p-dialog [(visible)]="modalAssignVehicle" [maximizable]="true" [breakpoints]="{'960px': '70vw'}" [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
    <ng-template pTemplate="header">
        <div class="w-100 d-flex justify-content-between align-items-center h-100">
          <div class="title-fiters">Assign Vehicle</div>
        </div>
    </ng-template>
    <div class="row">
        <div class="col-sm-6 col-md-12">
          <div class="w-100 fillet-boxs-css mb-4 position-relative">
          </div>
        </div>
    </div>
    <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
        <table class="mb-3">
            <tr>
                <td style="width:30vw;font-size: 90%;"><b>Scheme Name</b></td>
                <td style="width:10vw;font-size: 90%;">:</td>
                <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataModal.Name}}</span></td>
            </tr>
            <tr>
                <td style="width:30vw;font-size: 90%"><b>Service Area</b></td>
                <td style="width:10vw;font-size: 90%">:</td>
                <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{dataModal.ServiceArea.Name}}</span></td>
            </tr>
            <tr>
                <td  style="width:30vw;font-size: 90%"><b> <div class="w-100 fillet-boxs-css mb-4 position-relative">
                    Assign Vehicle
                </div></b></td>
                <td><div class="w-100 fillet-boxs-css mb-4 position-relative">
                    :
                </div></td>
                <td>
                  <span>
                    <div class="w-100 fillet-boxs-css mb-4 position-relative">
                      <p-dropdown [options]="allVehicle" [(ngModel)]="selectedVehicle" [filter]="true" [ngModelOptions]="{standalone: true}"
                                    [showClear]="true" placeholder="Select Vehicle" (onChange)="onChangeFilter($event)"
                                    optionLabel="DescriptionCustom">
                          <!-- <ng-template pTemplate="selectedItem">
                            <div class="filler-h-item" *ngIf="allVehicle">
                              <div>{{allVehicle.VehicleRegNo}}-{{allVehicle.VehicleType.Name}}</div>
                            </div>
                          </ng-template> -->
                      </p-dropdown>
                    </div>
                  </span>
                </td>
            </tr>
        </table>
        <div class="w-100 fillet-boxs-css mb-4 position-relative">                
          <button pButton label="Assign" class="p-button-info" (click)="assignVehicleForm(dataModal)"></button>
          <!-- <button pButton label="Assign" class="p-button-info" (click)="assignVehicleForm(dataModal)" [disabled]="!selectedVehicle"></button> -->
        </div>
        <div *ngIf="showLoader" class="align-items-center justify-content-center" style="text-align: center; display: flex;">
            <div class="loader"></div>
        </div>
        <div class="scrollable-table">
          <table class="table mt-3 outside-border" *ngIf="!showLoader">
              <thead class="thead-light" style="background-color: #e9ecef;">
                  <tr>
                      <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Vehicle</td>
                  </tr>
              </thead>
                <tbody> 
                  <ng-template [ngIf]="assignedVehicleList.length!=0" [ngIfElse]="noData">
                      <tr *ngFor="let assignedVehicle of assignedVehicleList">
                          <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">{{assignedVehicle.vehicle_id}}</td>
                          <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash" style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right" (click)="unassignVehicle(assignedVehicle)"></i></td>
                      </tr>
                  </ng-template>
                  <ng-template #noData>
                      <tr>
                          <td style="padding: 0.3rem;" class="font-italic empty-message">No data</td>
                      </tr>
                  </ng-template>
              </tbody>
          </table>
        </div>
    </ng-template>
    <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
        <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component" (click)="hideDialog()" type="button"></button>
    </ng-template>
    <ng-template #loaderProgress>
        <div class="Loading"></div>
    </ng-template>
  </p-dialog>

</div>

<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
