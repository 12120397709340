<div *ngIf="loader" class="w-100 vh-100 background_Loader position-absolute z-index-1 d-flex align-items-center justify-content-center">
  <div class="loader"></div>
</div>
<div class="vw-100 vh-100 auth-bg-color">
  <div class="vw-100 vh-100 auth-bg">
    <div class="d-flex align-items-center vh-100 containet-center-blocks">
      <div class="container">
        <div class=" auth-bks-fields">
          <div class="brand-ele">
            <img src="./assets/images/brand-imgs/logo-mfloratel-black.png" />
          </div>
          <div class="auth-titles font-weight-bold txt-black py-3">
            Reset password.
          </div>
          <div class="auth-sub-titles pb-4">
            Enter your email and you will receive an email to reset your password.
          </div>
          <form [formGroup]="resetPassword" (ngSubmit)="resetPasswordSubmit()" class="w-100">
            <div>
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="username">Email</label>
                <input formControlName="userId" class="rounded inpu-shadows p-2" id="username" type="text" pInputText />
                <div *ngIf="resetPassword.controls['userId'].invalid && (resetPassword.controls['userId'].dirty || resetPassword.controls['userId'].touched)"
                     class="text-danger position-absolute error-msgs error-input err-top-72 fs-10">
                  <div class="w-100 lh-10" *ngIf="resetPassword.controls['userId'].errors?.['required']">
                    Email ID is required.
                  </div>
                  <div class="w-100 lh-10" *ngIf="resetPassword.controls['userId'].errors?.['email']">
                    Email ID must be of type email.
                  </div>
                </div>


              </div>
            </div>
            <div class="d-flex w-100 justify-content-between pb-3">
              <div class="d-flex align-items-center">
                <a routerLink="/login" class="btn btn-lg light-blue-btn d-flex align-items-center rounded fs-12" type="submit">
                  <div class="pr-2"><i class="pi pi-arrow-left fs-12"></i></div>
                  <div>Back</div>
                </a>
              </div>
              <div class="w-100 pl-3">
                <button class="btn btn-lg btn-primary w-100 btn-block rounded fs-12" type="submit">Reset password</button>
              </div>
            </div>
          </form>
          <div class="fs-12 pb-3">
            Can’t remember your email?<br />
            <span class="txt-default-color">Contact support.</span>
          </div>
          <form *ngIf="resetPasswordConfirmForm" [formGroup]="resetPasswordConfirm" (ngSubmit)="resetPasswordConfirmSubmit()" class="w-100">
            <div>
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="password">New Password</label>
                <input formControlName="password" class="rounded inpu-shadows p-2" id="password" type="text" pInputText />
                <div *ngIf="resetPasswordConfirm.controls['password'].invalid && (resetPasswordConfirm.controls['password'].dirty || resetPasswordConfirm.controls['password'].touched)"
                     class="text-danger position-absolute error-msgs error-input err-top-72 fs-10">
                  <div class="w-100 lh-10" *ngIf="resetPasswordConfirm.controls['password'].errors?.['required']">
                    Password is required.
                  </div>
                  
                </div>


              </div>
            </div>
            <div class="d-flex w-100 justify-content-between pb-3">
              <div class="d-flex align-items-center">
                <a routerLink="/login" class="btn btn-lg light-blue-btn d-flex align-items-center rounded fs-12" type="submit">
                  <div class="pr-2"><i class="pi pi-arrow-left fs-12"></i></div>
                  <div>Back</div>
                </a>
              </div>
              <div class="w-100 pl-3">
                <button class="btn btn-lg btn-primary w-100 btn-block rounded fs-12" type="submit">Confirm Reset password</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="alert alert-danger alert-dismissible fade show position-absolute alertBoxs" role="alert" *ngIf="errorMsgBox">
  <strong class="text-capitalize">Hello User</strong> Please enter user name
  <button type="button" class="close" (click)="alertBoxClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress progress-infos bg-transparent" style="height: 5px;">
    <div class="progress-bar bg-danger" role="progressbar" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
</div>
<div class="alert alert-success alert-dismissible fade show position-absolute alertBoxs" role="alert" *ngIf="successMsgBox">
  <strong class="text-capitalize">{{userName}}</strong> Reset password Successfully!
  <button type="button" class="close" (click)="alertBoxClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress progress-infos bg-transparent" style="height: 5px;">
    <div class="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
</div>
<p-toast position="top-right"></p-toast>
