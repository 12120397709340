<div class="vw-100 vh-100 auth-bg-color">
  <div class="vw-100 vh-100 auth-bg">
    <div class="d-flex align-items-center vh-100 containet-center-blocks custom-scrollbar">
      <div class="container">
        <div class=" auth-bks-fields">
          <div class="auth-titles font-weight-bold txt-black py-3">
            Login
          </div>
          <div class="auth-sub-titles pb-4">
            Please enter your credentials to Login!
          </div>
          <form [formGroup]="logInForm" (ngSubmit)="onSubmit(logInForm.value)" class="w-100">
            <div>
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="email">Email</label>
                <input formControlName="email" class="rounded border-0 inpu-shadows p-2 w-100" id="email" type="text" pInputText />
                <!--  <div *ngIf="logInForm.controls['email'].invalid && (logInForm.controls['email'].dirty || logInForm.controls['email'].touched)"
                       class="fs-10 text-danger position-absolute error-msgs error-input err-top-72">
                    <div class="w-100 lh-10" *ngIf="logInForm.controls['email'].errors?.['required']">
                      Email ID is required.
                    </div>
                    <div class="w-100 lh-10" *ngIf="logInForm.controls['email'].errors?.['email']">
                      Email ID must be of type email.
                    </div>
                  </div>-->
              </div>
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="password">Password</label>
                <div class="w-100 position-relative pwd-eye-btn">
                  <input formControlName="password" class="rounded border-0 inpu-shadows p-2 w-100" id="password" type="{{pwdEye ? 'text':'password'}}" pInputText />
                  <button type="button" (click)="pwdEye = !pwdEye" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
                    <span class="material-icons">
                      {{pwdEye ? 'visibility':'visibility_off'}}
                    </span>
                  </button>
                </div>
                <!--   <div *ngIf="logInForm.controls['password'].invalid && (logInForm.controls['password'].dirty || logInForm.controls['password'].touched)"
                        class="text-danger position-absolute error-msgs error-input err-top-72 fs-10">
                     <div class="w-100 lh-10" *ngIf="logInForm.controls['password'].errors?.['required']">
                       password is required.
                     </div>
                     <div class="w-100 lh-10" *ngIf="logInForm.controls['password'].errors?.['minlength']">
                       password must be at least 4 digits.
                     </div>
                     <div class="w-100 lh-10" *ngIf="logInForm.controls['password'].errors?.['maxlength']">
                       password must be at most 8 digits.
                     </div>
                   </div>-->
              </div>
            </div>
            <div class="d-flex w-100 justify-content-between pb-3">
              <div class="d-flex align-items-center fillet-boxs-css">
                <input type="checkbox" formControlName="rememberMe" />
                <label for="rememberMe" class="txt-default-color fs-12 m-0 pl-2">Remember me</label>
              </div>
            </div>
            <div class="pb-3">
              <button class="btn btn-lg btn-primary btn-block rounded fs-12" type="submit" >Sign in</button>
            </div>
            <!--            <div class="text-danger text-center" style="clear:both;padding:5px;justify-content:center;display:flex" *ngIf="formError!=''">{{formError}}</div>
            -->
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
