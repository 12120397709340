import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigVehicleType } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-vehicletype',
  templateUrl: './config-vehicletype.component.html',
  styleUrls: ['./config-vehicletype.component.scss']
})
export class ConfigVehicletypeComponent implements OnInit {

  @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
  @ViewChild('dt', { static: true }) table!: ElementRef;

  dataDialog: boolean = false;

  datas: ConfigVehicleType[]= [];

  data: ConfigVehicleType = {ModuleConfigVehicleType:{}};

  selectedDatas: any[] = [];

  submitted: boolean = false;

  statuses: any[] = ['Active', 'Inactive'];

  items: MenuItem[] = [];

  loading: boolean = true;

  menuitems: MenuItem[]=[];

  isCreateNew = true;
  
  Id: any[] = [];

  configVehicleTypeParam =  { id: null, isactiveonly: true };

  isLoading: boolean = true;

  nameFormControl = new FormControl('', [
      Validators.required,
  ]);

  configVehicleTypeForm = this.formBuilder.group({
    
      name: this.nameFormControl,
      description: '',
      active: true,
      // created: null,
  });

  constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder, private router:Router) { }

  ngOnInit(): void {
      this.getDatas();

      this.items = [
          {label: 'Edit', icon: 'pi pi-pencil', command: () => {
              this.editData(this.data);
          }},
          {label: 'Delete', icon: 'pi pi-trash', command: () => {
              this.deleteData(this.data);
          }}
      ];

      this.menuitems = GlobalComponent.submenus;
  }

  getDatas(){
    this.isLoading = true;
      this.commonService.getAllConfigVehicleType(this.configVehicleTypeParam).subscribe((resp: any) => {

          if (resp.length != 0) {
              this.datas = resp.Result;
              this.isLoading = false;
            
          } else {
            
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }
    
  }

  onSubmit(): void {

      this.submitted = true;
      if (this.configVehicleTypeForm.valid) {
          if (this.isCreateNew == true) {
              this.createConfigVehicleType();
          } else {
              this.updateConfigVehicleType();
          }
      }
  }

  selectData(data: any){
      this.data = data;
  }

  clearselectData(){
      this.selectedDatas = [];
  }

  openNew() {
      this.data= { ModuleConfigVehicleType: {}};

      this.submitted = false;
      this.dataDialog = true;
  }

 hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
  }

  editData(data: any) {
      this.data = {...data};
      this.dataDialog = true;
      this.isCreateNew = false;
      this.Id = data.Id;
  }

  createConfigVehicleType(){
    this.isLoading = true;
      this.commonService.createConfigVehicleType(this.configVehicleTypeForm.value).subscribe((resp: any) => {

          if (resp.Success == true) {
              this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
              this.isLoading = false;
              this.dataDialog = false;
              this.getDatas();
            
          } else {

              this._appSharedService.showError('Error', "Something went wrong!");
              this.isLoading = false;
          }
      }), (error: any) => {

          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }
  }

  updateConfigVehicleType(){
    this.isLoading = true;
      this.configVehicleTypeForm.value.id = this.Id;

      this.commonService.updateConfigVehicleType(this.configVehicleTypeForm.value).subscribe((resp: any) => {

          if (resp.Success == true) {
              this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
              this.isLoading = false;
              this.dataDialog = false;
              this.getDatas();
            
          } else {
              this._appSharedService.showError('Error', "Something went wrong!");
              this.isLoading = false;
          }
      }), (error: any) => {

          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }
  }

  deleteData(data: any) {
    this.isLoading = true;
      data.ModifiedBy = localStorage.getItem('username');
      data.Active = false;
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + data.Name + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {

              this.commonService.deleteConfigVehicleType(data).subscribe((resp: any) => {

                  if (resp.Success == true) {
                      this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                      this.isLoading = false;
                      this.dataDialog = false;
                      this.getDatas();
                    
                  } else {
            
                      this._appSharedService.showError('Error', "Something went wrong!");
                      this.isLoading = false;
                  }
              }), (error: any) => {
          
                  this._appSharedService.showError('Error', "Something went wrong!");
                  this.isLoading = false;
              }
          }
      });
  }

  findIndexById(Id: string): number {
      let index = -1;
      for (let i = 0; i < this.datas.length; i++) {
          if (this.datas[i].Id === Id) {
              index = i;
              break;
          }
      }

      return index;
  }

  exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.datas);
          worksheet['!cols'] = [];
          worksheet['!cols'][0] = { hidden: true };
          worksheet['!cols'][4] = { hidden: true };
          
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          /* save to file */
          XLSX.writeFile(workbook, 'ConfigRole.xlsx');
      
      });
  }
}

