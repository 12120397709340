
    <!-- <div *ngIf="showLoader" class="w-100 vh-100 background-custom background_Loader position-absolute d-flex align-items-center justify-content-center">
        <div class="loader"></div>
    </div> -->
    <div style="padding:5px 20px">
        <p-menubar [model]="menuitems">
        </p-menubar>
    </div>
    
    <h5 style="margin-top: 0.5rem; margin-left:1rem">Manage Backup Vehicle</h5>    
    <p>&nbsp;</p>

    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
            <div class="result-found-list found-fonts fs-10 lh-12">
                <div class="w-100 tableFixHead">
                    <div class="ptable-css ptable-scrollable">
                        <div class="row w-100 justify-content-between m-0">
                            <div class="col-md-6 col-6 vehicle-fillter mb-3 pr-3 pl-0">
                              <ul class="nav">
                                  <li class="d-flex align-items-center nav-item w-auto p-0">                           
                                        <div class="range-calendar position-relative">
                                            <div class="p-field p-col-12 ">
                                            <p-calendar [(ngModel)]="schdlDate" dateFormat="dd-mm-yy" [showIcon]="true" placeholder="Select Date" (ngModelChange)="parseDate($event)" [showIcon]="true" inputId="icon"></p-calendar>
                                            </div>
                                        </div>
                                  </li>
                              </ul>
                            </div>
                            <div class="col-md-6 col-6 vehicle-fillter mb-3 p-0 text-right">
                                <ul class="nav">
                                    <li class="nav-item p-0" style="width: 100%;">
                                        <div class="range-calendar">
                                            <span class="p-input-icon-right">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                       
                        <div #dtMain>
                            <p-table #dt [value]="datas"  [paginator]="true" [rows]="10" [showCurrentPageReport]="true"  [globalFilterFields]="['route','subRoute','company', 'vehicle_reg_no','active','backup_vehicle_no']" 
                            [(selection)]="selectedDatas"  [rowHover]="true" dataKey="Id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                            responsiveLayout="scroll" [scrollable]="true" scrollHeight="70vh"
                             [tableStyle]="{'max-width': '100vw'}"
                            
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" >
                                <ng-template pTemplate="caption">
                                    <div class="flex align-items-center justify-content-between">
                                        <div class="flex align-items-center justify-content-between summary-message">
                                            {{datas ? datas.length : 0 }} result(s) found.
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="route" style="width:90%;">Details<p-sortIcon field="route"></p-sortIcon></th>            
                                    </tr>
                                </ng-template>
                                
                                <ng-template pTemplate="body" let-data>
                                    <tr class="p-selectable-row">
                                        <td style="width:90%;">
                                            <div class="w-100">
                                                <div class="w-100 d-flex flex-wrap">
                                                    <div>
                                                        <div class="">
                                                          <div class="vehicleId d-flex  w-100">
                                                            <div class="vehicleId fs-16 font-weight-bold">{{data.route}} <i *ngIf="data.backup_vehicle_no" style="color: #ffc107;" class="fa fa-truck" aria-hidden="true"></i></div>
                                                            <div class="vehicle-status-info pl-2 align-items-sm-end">
                                                              <div class="vehicle-circles rounded-circle"
                                                                   >
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="w-100 txt-gry vehicle-route fs-12 text-capitalize my-1">{{data.subRoute}}</div>
                                                          <div class="w-100 txt-gry vehicle-route fs-12 text-capitalize my-1">{{data.company}}</div>
                                                        </div>
                                                        <div class="vehicle-route fs-12"><Span style="color: #19A3F1;">{{data.vehicle_reg_no}} ({{data.vehicle_type}})</Span></div>
                                                        <div class="vehicle-route fs-12" style="padding-top: 0.5rem;">
                                                            <span style="font-style: italic;"><b>Backup Vehicle</b> : 
                                                            {{data.backup_vehicle_no}}</span>
                                                        </div>
                                                      </div>
                                                      <div class="ml-auto">
                                                        <div class="vehicle-route fs-12">   
                                                            
                                                        </div>
                                                      

                                                      </div>
                                                      <div class="ml-auto">
                                                    
                                                        <div class="vehicle-route fs-12"> 
                                                            <button *ngIf="isValidCurrentDate" pButton  label="Edit" icon="pi pi-pencil" class="p-button-info" type="button" (click)="editData(data)"></button>                                                            
                                                        </div> 
                                                      </div>
                                                </div>
                                                
                                            </div>
                                        </td>
                                    </tr>
                                    
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="6" class="empty-message">No data found.</td>
                                    </tr>
                                  
                                </ng-template>
                                <ng-template pTemplate="summary">
                                   
                                    <div class="flex align-items-center justify-content-between summary-message">
                                        In total there are {{datas ? datas.length : 0 }} record(s).
                                    </div>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--/p-card -->
<form [formGroup]="assignBackupVehicle" (ngSubmit)="onSubmit(data)" id="formSubmit">
    <p-dialog [(visible)]="dataDialog" [maximizable]="true" [breakpoints]="{'960px': '70vw'}"   [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
        <ng-template pTemplate="header">
            <div class="w-100 d-flex justify-content-between align-items-center h-100">
              <div class="title-fiters">Assign Backup - {{data.vehicle_reg_no}}</div>
            </div>
        </ng-template>
        <div class="row">
            <div class="col-sm-6 col-md-12">
              <div class="w-100 fillet-boxs-css mb-4 position-relative">
              </div>
            </div>
        </div>
        <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
  
            <table class="mb-3">
                <tr>
                    <td style="width:30vw;font-size: 90%;"><b>Date</b></td>
                    <td style="width:10vw;font-size: 90%;">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.backup_date}}</span></td>
                </tr>
                <tr>
                    <td style="width:30vw;font-size: 90%"><b>Route</b></td>
                    <td style="width:10vw;font-size: 90%">:</td>
                    <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.route}}</span></td>
                </tr>
                <tr>
                    <td  style="width:30vw;font-size: 90%"><b>Sub Route</b></td>
                    <td  style="width:10vw;font-size: 90%">:</td>
                    <td><span class=" fs-12 text-capitalize">{{data.subRoute}}</span></td>
                </tr>
                <tr>
                    <td  style="width:30vw;font-size: 90%"><b>Vehicle</b></td>
                    <td  style="width:10vw;font-size: 90%">:</td>
                    <td><span class=" fs-12 text-capitalize">{{data.vehicle_reg_no}} ({{data.vehicle_type}})</span></td>
                </tr>
                <tr>
                    <td  style="width:30vw;font-size: 90%"><b> <div class="w-100 fillet-boxs-css mb-4 position-relative">
                        Assign Backup
                    </div></b></td>
                    <td><div class="w-100 fillet-boxs-css mb-4 position-relative">
                        :
                    </div></td>
                    <td><span><div class="w-100 fillet-boxs-css mb-4 position-relative">
                        <p-dropdown  [options]="unassignVehicle" [(ngModel)]="selectedunassignVehicle" [filter]="true" [ngModelOptions]="{standalone: true}"
                                      [showClear]="true" placeholder="Select Vehicle" (onChange)="onChangeVehicleFilter($event)"
                                      optionLabel="Description" >
                            <ng-template pTemplate="selectedItem">
                              <div class="filler-h-item" *ngIf="selectedunassignVehicle">
                                <div>{{selectedunassignVehicle.VehicleRegNo}}-{{selectedunassignVehicle.ModuleCompany.Name}}</div>
                              </div>
                            </ng-template>
                           
                         </p-dropdown>
             
                    </div></span> </td>
                </tr>
            </table>
            <div *ngIf="!showVehicleLoader" class="w-100 fillet-boxs-css mb-4 position-relative">                
                <button pButton  label="Assign" class="p-button-info" type="submit"></button>
            </div>
            <div *ngIf="showVehicleLoader" class="align-items-center justify-content-center" style="text-align: center; display: flex;">
                <div class="loader"></div>
            </div>
            <table class="table mt-3 outside-border" *ngIf="!showVehicleLoader">
                <thead class="thead-light" style="background-color: #e9ecef;">
                    <tr>
                        <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Backup Vehicle</td>
                    </tr>
                </thead>
                <!-- <tbody > -->
                    
                <!-- <tbody [ngIf]="backupVehicleList.length!=0" [ngIfElse]="loading"> -->
                    <tbody> 
                  
                    <ng-template [ngIf]="backupVehicleList.length!=0" [ngIfElse]="noData">
                        <tr *ngFor="let backup of backupVehicleList">
                            <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;"><b>{{backup.backup_vehicle_no}}</b> ({{backup.vehicle_type}})-{{backup.company}}</td>
                            <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash" style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right" (click)="unassign(backup)"></i></td>
                        </tr>
                    </ng-template>
                    <ng-template #noData>
                        <tr>
                            <td style="padding: 0.3rem;" class="font-italic empty-message">No Backup Vehicle</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>

        </ng-template>
        <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
            <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component" (click)="hideDialog()" type="button"></button>
        </ng-template>
        <ng-template #loaderProgress>
            <div class="Loading"></div>
        </ng-template>
    </p-dialog>
</form>
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
