import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigScheme } from 'src/app/app.models';
import { FormBuilder } from '@angular/forms';
import { GlobalComponent } from 'src/app/global-component';

@Component({
  selector: 'app-config-scheme',
  templateUrl: './config-scheme.component.html',
  styleUrls: ['./config-scheme.component.scss']
})
export class ConfigSchemeComponent implements OnInit {

  @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
  @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigScheme[]= [];
    data: ConfigScheme = { ModuleConfigServiceArea: {}};
    dataModal: any;
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    assignedVehicleList: any[] = [];
    configSchemeParam =  {id: null, isactiveonly: true};
    modalAssignVehicle = false;
    showLoader: boolean = false;
    selectedVehicle: any;
    allVehicle: any;
    configModuleVehicleParam =  { id: null, isactiveonly: true };


    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }


    ngOnInit(): void {
        this.getDatas();

        this.items = [
            {label: 'Assign Vehicle', icon: 'pi pi-pencil', command: () => {
                this.assignVehicle(this.dataModal);
            }},
        ];

        this.menuitems = GlobalComponent.submenus;
    }

    getAllConfigModuleVehicle(){
        this.commonService.getAllConfigModuleVehicle(this.configModuleVehicleParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.allVehicle = resp.Result;
                this.loading = false;              
            } else {
                
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getDatas(){
        this.commonService.getAllConfigScheme(this.configSchemeParam).subscribe((resp: any) => {

            if (resp.length != 0) {
                this.datas = resp.Result;
                this.getAllConfigModuleVehicle();
                this.loading = false;
            } else {
            
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    
    }

    selectData(data: any){
        // this.data = data;
        this.dataModal = data;
    }

    assignVehicle(data: any){
        this.modalAssignVehicle = true;
        this.getAssignedVehicleList();
    }

    assignVehicleForm(data: any){

        if(this.selectedVehicle == null){
            this.messageService.add({key: 'alertMsg', severity:'warn', summary: 'Warn', detail: 'Please Select Vehicle!'});
        } else { 
            this.showLoader = true;

            var dataSchemeVehicle = {
                "scheme_id": data.Name,
                "vehicle_id": this.selectedVehicle.VehicleRegNo
            };

            this.commonService.AssignSchemeVehicleBQ(dataSchemeVehicle).subscribe((resp: any) => {
                if (resp.Success == true) {
                    this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                    this.getAssignedVehicleList();
                } else {
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
                this.showLoader = false;
    
            }), (error: any) => {
    
                this._appSharedService.showError('Error', "Something went wrong!");
            }
        }

        
    }

    getAssignedVehicleList(){
        var scheme = {
            "scheme_id": this.dataModal.Name,
            "vehicle_id": null
        };
        this.showLoader = true;

        this.commonService.GetVehicleOrSchemeBQ(scheme).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.assignedVehicleList = resp.Result;
            } else {
                this.assignedVehicleList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    unassignVehicle(data: any){
        this.confirmationService.confirm({
            message: 'Are you sure you want to unassign ' + data.vehicle_id + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                this.commonService.UnassignSchemeVehicleBQ(data).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedVehicleList();
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

    hideDialog() {
        this.modalAssignVehicle = false;
        this.submitted = false;
    }

    onChangeFilter(event: any) {
        this.selectedVehicle = event.value; 
    }

    // exportExcel() {
    //     import("xlsx").then(xlsx => {
    //         const worksheet = xlsx.utils.json_to_sheet(this.datas);
    //         worksheet['!cols'] = [];
    //         worksheet['!cols'][0] = { hidden: true };
    //         worksheet['!cols'][4] = { hidden: true };
            
    //         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

    //         XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //         /* save to file */
    //         XLSX.writeFile(workbook, 'ConfigScheme.xlsx');
        
    //     });
    // }

}



// editData(data: any) {
//   this.data = {...data};
//   this.dataDialog = true;
//   this.isCreateNew = false;
//   this.Id = data.Id;
// }


