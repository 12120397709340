<p-card styleClass="p-card-content-0">
    <p-menubar [model]="menuitems">   
    </p-menubar>
    <h5 style="margin-top: 2.0rem; margin-left:1rem">Config Vehicle Type</h5>
    
    <p>&nbsp;</p>

    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
            <div class="result-found-list found-fonts fs-10 lh-12">
                <div class="w-100 tableFixHead">
                    <div class="ptable-css ptable-scrollable">
                        <div class="w-100 d-flex justify-content-between my-4">
                          <div class="vehicle-fillter">
                              <ul class="nav">
                                  <li class="d-flex align-items-center nav-item w-auto">                           
                                      <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus" class="p-button-info rounded cursor-pointer" (click)="openNew()"></button>
                                  </li>
                              </ul>
                            </div>
                            <div class="vehicle-fillter">
                                <ul class="nav">
                                    <li class="nav-item">
                                        <div class="range-calendar">
                                            <span class="p-input-icon-right">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div #dtMain>
                            <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['Name','Description','Active']" 
                            [(selection)]="selectedDatas"  [rowHover]="true" dataKey="Id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="isLoading"
                            responsiveLayout="scroll" [rowsPerPageOptions]="[10,25,50]"
                            [scrollable]="true" scrollHeight="60vh" [tableStyle]="{'max-width': '100vw'}"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                                <ng-template pTemplate="caption">
                                    <div class="flex align-items-center justify-content-between" style="margin-top: -1rem; margin-left: -0.7rem;">
                                        <div class="flex align-items-center justify-content-between summary-message">
                                            {{datas ? datas.length : 0 }} result(s) found.
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>                         
                                        <th pSortableColumn="Name" style="width:150px">Name <p-sortIcon field="Name"></p-sortIcon></th>
                                        <th pSortableColumn="Description" style="width:150px">Description <p-sortIcon field="Description"></p-sortIcon></th>
                                        <th pSortableColumn="Active" style="width:150px">Status <p-sortIcon field="Active"></p-sortIcon></th>
                                        <th style="width:150px"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr class="p-selectable-row">
                                        <td style="width:150px">{{data.Name}}</td>
                                        <td style="width:150px">{{data.Description}}</td>
                                        <td style="width:150px"><span style="display: none;">{{data.Active}}</span>
                                            <i *ngIf="data.Active" class="pi pi-check" style="font-size: 1rem; color:green"></i>                                                        
                                            <i *ngIf="!data.Active"  class="pi pi-times" style="font-size: 1rem; color:red"></i>                           
                                        </td>
                                        <td style="width:150px">
                                            <p-splitButton label="Action" [model]="this.items" styleClass="p-button-outlined p-button-info" class="border-0 rounded cursor-pointer" (click)="selectData(data)"></p-splitButton>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="5" class="empty-message">No data found.</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between summary-message">
                                        In total there are {{datas ? datas.length : 0 }} record(s).
                                    </div>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card>
<form [formGroup]="configVehicleTypeForm" (ngSubmit)="onSubmit()">
    <p-dialog [(visible)]="dataDialog" [style]="{width: '450px'}" header="Details" [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content" class="custom-pad-content">
            <div class="field custom-pad-bot">
                <label for="Name">Vehicle Type Name</label>
                <input type="text" formControlName="name" pInputText id="Name" name="Name" [(ngModel)]="data.Name" autofocus />
                <small class="p-error" *ngIf="submitted && !data.Name">Vehicle Type Name is required.</small>
            </div>
            <div class="field custom-pad-bot">
                <label for="Description">Vehicle Type Description</label>
                <input type="text" formControlName="description" pInputText id="Description" name="Description" [(ngModel)]="data.Description" autofocus />
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" type="button" [disabled]="isLoading"></button>
            <button pButton pRipple class="p-button-text" type="submit" [disabled]="isLoading">
                <span *ngIf="!isLoading" style="font-weight: 700"> <i class="pi pi-check"></i> Save</span>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
            </button>
        </ng-template>
    </p-dialog>
</form>
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
