<div class="w-100">
  <div class="bg-white rounded p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <h4 class="txt-gry font-weight-bold">Schedule</h4>
            <button class="btn btn-primary" (click)="addShowDialog()">Add Schedule</button>
          </div>
          <hr my="3" />
        </div>
        <div class="col-md-12">
          <div class="device_data mt-3">
            <div class="card" *ngIf="dataPresent == true;else noSchedules">
              <p-table [value]="schedules" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Schedule Id</th>
                    <th>Vehicle Id</th>
                    <th>Route Id</th>
                    <th>Schedule No</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Frequency</th>
                    <th style="width:75px;">Status</th>
                    <th style="width:75px;">Edit</th>
                    <th style="width:75px;">Delete</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                  <tr>
                    <td>{{customer.scheduleId}}</td>
                    <td>{{customer.vehicle.vehicleId}}</td>
                    <td>{{customer.route.routeId}}</td>
                    <td>{{customer.scheduleNo}}</td>
                    <td>{{customer.startTime}}</td>
                    <td>{{customer.endTime}}</td>
                    <td>{{customer.frequency}}</td>
                    <td><button [ngClass]="customer.active ? 'btn btn-success rounded-circle fa fa-check':'btn btn-warning rounded-circle fa fa-close'" class="lh-23 fs-12"></button></td>
                    <td><button class="btn btn-warning rounded-circle fs-12 lh-23" (click)="editShowDialog(customer.scheduleId)"><i class="fa fa-pencil"></i></button></td>
                    <td><button class="btn btn-danger rounded-circle fs-12 lh-23" (click)="deleteSchedule(customer.scheduleId)"><i class="fa fa-trash"></i></button></td>
                  </tr>
                </ng-template>
                <!--  <ng-template pTemplate="paginatorleft">
        <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
      </ng-template>-->
              </p-table>
            </div>
            <ng-template #noSchedules>
              <span>
                No Schedules to Display
              </span>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="w-100">
        <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" key="myDialog"></p-confirmDialog>
      </div>
      <div class="w-100">
        <p-dialog [(visible)]="addDisplay" [style]="{width: '50vw'}" [modal]="true" [baseZIndex]="10000">
          <ng-template pTemplate="header">
            <h5 class="txt-dgry p-3 border-bottom w-100">Add Schedule</h5>
          </ng-template>
          <div class="forms_group overflow-hidden">
            <form [formGroup]="scheduleForm" (ngSubmit)="addSchedule()">
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Vehcile Id</label><br />
                <p-dropdown [style]="{'width':'100%'}" [options]="vehicles" placeholder="Vehicle ID" formControlName="vehicleId"
                            [showClear]="true"></p-dropdown>
              </div>
              <div class="forms_group inpu-shadow mx-3">
                <label class="txt-gry">Route Id</label><br />
                <p-dropdown  [style]="{'width':'100%'}" [options]="routes" placeholder="Route ID" formControlName="routeId"
                            [showClear]="true"></p-dropdown>
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Schedule No</label>
                <input type="text" class="form-control" placeholder="Schedule Name" formControlName="scheduleNo" />
              </div>
              <div class="forms_group inpu-shadow mx-3">
                <label class="txt-gry">Start Time</label>
                <input type="time" class="form-control" placeholder="Start Time" formControlName="startTime" />
              </div>
              <div class="forms_group inpu-shadow mx-3">
                <label class="txt-gry">End Time</label>
                <input type="time" class="form-control" placeholder="End Time" formControlName="endTime" />
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Frequency</label>
                <input type="text" class="form-control" placeholder="Frequency" formControlName="frequency" />
              </div>
            
            </form>
          </div>
          <ng-template pTemplate="footer">
            <button class="btn btn-light w-25" (click)="addCancLBtn()">Cancel</button>
            <button class="btn btn-success w-25" type="submit">Save</button>
          </ng-template>
        </p-dialog>
      </div>

      <div class="w-100">
        <p-dialog [(visible)]="editDisplay" [style]="{width: '50vw'}" [modal]="true" [baseZIndex]="10000">
          <ng-template pTemplate="header">
            <h5 class="txt-dgry p-3 border-bottom w-100">Edit Schedule</h5>
          </ng-template>
          <div class="forms_group overflow-hidden">
            <form [formGroup]="scheduleForm" (ngSubmit)="editSchedule()">
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Vehcile Id</label>
                <input type="text" class="form-control" placeholder="vehicle Name" formControlName="vehicleId" />
              </div>
              <div class="forms_group inpu-shadow mx-3">
                <label class="txt-gry">Route Id</label>
                <input type="text" class="form-control" placeholder="Route id" formControlName="routeId" />
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Schedule No</label>
                <input type="text" class="form-control" placeholder="Schedule Name" formControlName="scheduleNo" />
              </div>
              <div class="forms_group inpu-shadow mx-3">
                <label class="txt-gry">Start Time</label>
                <input type="time" class="form-control" placeholder="Start Time" formControlName="startTime" />
              </div>
              <div class="forms_group inpu-shadow mx-3">
                <label class="txt-gry">End Time</label>
                <input type="time" class="form-control" placeholder="End Time" formControlName="endTime" />
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Frequency</label>
                <input type="text" class="form-control" placeholder="Frequency" formControlName="frequency" />
              </div>
              <div class="forms_group toggle_switch my-2 mx-3">
                <div class=" align-items-center">
                  <div>Status</div>
                  <div>
                    <input type="checkbox" id="switch" formControlName="active" /><label for="switch">Toggle</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <ng-template pTemplate="footer">
            <button class="btn btn-light w-25" (click)="editCancLBtn()">Cancel</button>
            <button class="btn btn-success w-25" type="submit">Save</button>
          </ng-template>
        </p-dialog>
      </div>
    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>
