<div class="vw-100 vh-100 auth-bg-color">
  <div class="vw-100 vh-100 auth-bg">
    <div class="d-flex align-items-center vh-100 containet-center-blocks custom-scrollbar">
      <div class="container">
        <div class=" auth-bks-fields">
          <div class="brand-ele">
            <img src="./assets/images/brand-imgs/logo-mfloratel-black.png" />
          </div>
          <div class="auth-titles font-weight-bold txt-black py-3">
            Change Password.
          </div>
          <div class="auth-sub-titles pb-4">
            Please enter your new password!
          </div>
          <form [formGroup]="changePasswordForm" class="w-100" (ngSubmit)="changePassword()">
            <div>
              <!--old password-->
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="password">Old Password</label>
                <div class="w-100 position-relative pwd-eye-btn">
                  <input formControlName="oldPwd" class="rounded inpu-shadows p-2 w-100" id="password" type="{{pwdEye1 ? 'text':'password'}}" pInputText />
                  <button type="button" (click)="pwdEye1 = !pwdEye1" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
                    <span class="material-icons">
                      {{pwdEye1 ? 'visibility':'visibility_off'}}
                    </span>
                  </button>
                </div>
                <div *ngIf="changePasswordForm.controls['oldPwd'].invalid && (changePasswordForm.controls['oldPwd'].dirty || changePasswordForm.controls['oldPwd'].touched)"
                     class="text-danger position-absolute error-msgs error-input err-top-72 fs-10">
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['oldPwd'].errors?.['required']">
                    Old password is required.
                  </div>
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['oldPwd'].errors?.['minlength']">
                    password must be at least 4 digits.
                  </div>
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['oldPwd'].errors?.['maxlength']">
                    password must be at most 8 digits.
                  </div>
                </div>
              </div>

              <!--new password-->
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="password">New Password</label>
                <div class="w-100 position-relative pwd-eye-btn">
                  <input formControlName="newPwd" class="rounded inpu-shadows p-2 w-100" id="password" type="{{pwdEye2 ? 'text':'password'}}" pInputText />
                  <button type="button" (click)="pwdEye2 = !pwdEye2" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
                    <span class="material-icons">
                      {{pwdEye2 ? 'visibility':'visibility_off'}}
                    </span>
                  </button>
                </div>
                <div *ngIf="changePasswordForm.controls['newPwd'].invalid && (changePasswordForm.controls['newPwd'].dirty || changePasswordForm.controls['newPwd'].touched)"
                     class="text-danger position-absolute error-msgs error-input err-top-72 fs-10">
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['newPwd'].errors?.['required']">
                    New password is required.
                  </div>
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['newPwd'].errors?.['minlength']">
                    password must be at least 4 digits.
                  </div>
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['newPwd'].errors?.['maxlength']">
                    password must be at most 8 digits.
                  </div>
                </div>
              </div>
              <div class="p-inputgroup flex-wrap pb-4 position-relative">
                <label class="w-100 fs-12 input-names" for="password">Retype Password</label>
                <div class="w-100 position-relative pwd-eye-btn">
                  <input formControlName="retypePwd" class="rounded inpu-shadows p-2 w-100" id="password" type="{{pwdEye3 ? 'text':'password'}}" pInputText />
                  <button type="button" (click)="pwdEye3 = !pwdEye3" class="rounded-0 position-absolute bg-transparent border-0 eye-btn">
                    <span class="material-icons">
                      {{pwdEye3 ? 'visibility':'visibility_off'}}
                    </span>
                  </button>
                </div>
                <div *ngIf="changePasswordForm.controls['retypePwd'].invalid && (changePasswordForm.controls['retypePwd'].dirty || changePasswordForm.controls['retypePwd'].touched)"
                     class="text-danger position-absolute error-msgs error-input err-top-72 fs-10">
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['retypePwd'].errors?.['required']">
                    Retype password is required.
                  </div>
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['retypePwd'].errors?.['minlength']">
                    password must be at least 4 digits.
                  </div>
                  <div class="w-100 lh-10" *ngIf="changePasswordForm.controls['retypePwd'].errors?.['maxlength']">
                    password must be at most 8 digits.
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-3">
              <button class="btn btn-lg btn-primary btn-block rounded fs-12" type="submit">Submit</button>
            </div>
            <div class="text-danger text-center" style="clear:both;padding:5px;justify-content:center;display:flex" *ngIf="formError!=''">{{formError}}</div>
          </form>
          <div class="fs-12 pb-3">
            Don’t have an account?<br />
            <span class="txt-default-color">Contact support to join us!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="alert alert-danger alert-dismissible fade show position-absolute alertBoxs" role="alert" *ngIf="errorMsgBox">
  <strong class="text-capitalize">Error</strong> Something went wrong!
  <button type="button" class="close" (click)="alertBoxClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress progress-infos bg-transparent" style="height: 5px;">
    <div class="progress-bar bg-danger" role="progressbar" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
</div>
<div class="alert alert-success alert-dismissible fade show position-absolute alertBoxs" role="alert" *ngIf="successMsgBox">
  <strong class="text-capitalize"></strong> Password Changed Successfully!
  <button type="button" class="close" (click)="alertBoxClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress progress-infos bg-transparent" style="height: 5px;">
    <div class="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
</div>

