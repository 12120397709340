<div class="w-100 position-absolute map-styles">
  <app-map></app-map>
</div >
<div class="alert alert-success alert-dismissible fade show position-absolute alertBoxs" role="alert" *ngIf="successMsgBox">
  <strong>Vehicle already started collecting bins!</strong>
  <button type="button" class="close" (click)="alertBoxClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress progress-infos bg-transparent" style="height: 5px;">
    <div class="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
</div>

<div class="position-absolute count-bin-block w-100">
  <div class="all-vehicle-details-blocks w-100">
    <div class="w-100 text-center justify-content-center mx-auto bottom-togle-btns" [class.bg-white]="vehicleBottomDetails">
      <button (click)="vehicleBottomView()" class="border-0 bg-white p-0">
        <img src="./assets/images/web-112021/icons/icon-up-arrow.png" [class.imgRotate]="vehicleBottomDetails" />
      </button>
    </div>
    <div class="test bg-white all-vehicle-details-info" [ngClass]="vehicleBottomDetails ? 'd-block' : 'd-none'">
      <route-schedule></route-schedule>
    </div>
  </div>
</div>
<div class="position-absolute colleted-blocks" [class.bottom-400]="vehicleBottomDetails">
  <div class="w-100 bin-cout-details d-flex position-relative bin-cout-height">

    <div (click)="vehicleCoutBox()" class="position-fixed v-tracking-left-btn bg-white cursor-pointer">
      <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <img *ngIf="!vehicleMoving" src="./assets/images/web-112021/icons/icon-left.png" />
        <img *ngIf="vehicleMoving" src="./assets/images/web-112021/icons/icon-right.png" />
      </div>
    </div>
  </div>
</div>
<div class="w-100 vh-100 overflow-hidden" [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }"
     #myBounds>
  <div class="colleted-blocks position-relative ">
    <div class="" *ngIf="vehicleMoving">
      <div class="bin-cout-infos position-absolute" [ngDraggable]="dragaable" (edge)="checkEdge($event)" [bounds]="myBounds" [inBounds]="inBounds">
        <div class="bin-titles w-100 d-flex justify-content-between" id="people">
          <div>Percentage of Collection</div>
          <div class="d-flex">
            <button class="border-0 p-0 bg-transparent draggble cursor_grap">
              <img src="./assets/images/web-112021/icons/icon-zoom.png" />
            </button>
            <button class="ml-3 border-0 p-0 bg-transparent " (click)="dragPin()">
              <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" [ngClass]="pinActive ? 'd-none':'d-block'">
                <path d="M6.0975 1.51051C6.21087 1.28375 6.37649 1.08718 6.58073 0.936983C6.78498 0.78679 7.02198 0.687281 7.27222 0.646654C7.52247 0.606028 7.77878 0.625448 8.02005 0.703314C8.26131 0.78118 8.48062 0.915262 8.6599 1.09451L11.9055 4.34011C12.0848 4.51939 12.2188 4.7387 12.2967 4.97997C12.3746 5.22123 12.394 5.47755 12.3534 5.72779C12.3127 5.97803 12.2132 6.21504 12.063 6.41928C11.9128 6.62352 11.7163 6.78915 11.4895 6.90251L8.7231 8.28651C8.46028 8.41782 8.25476 8.64099 8.1455 8.91371L6.9967 11.7857C6.94749 11.9086 6.86854 12.0174 6.76692 12.1024C6.6653 12.1873 6.5442 12.2456 6.41447 12.2722C6.28475 12.2987 6.15045 12.2927 6.02364 12.2545C5.89683 12.2164 5.78147 12.1474 5.6879 12.0537L3.5999 9.96571L0.965502 12.6001H0.399902V12.0345L3.0343 9.40011L0.946302 7.31211C0.852602 7.21855 0.783593 7.10318 0.745465 6.97637C0.707338 6.84956 0.701284 6.71527 0.727848 6.58554C0.754412 6.45581 0.812762 6.33471 0.897665 6.23309C0.982567 6.13147 1.09137 6.05252 1.2143 6.00331L4.0863 4.85451C4.35933 4.74541 4.5828 4.53988 4.7143 4.27691L6.0983 1.51051H6.0975ZM8.0943 1.66011C8.00466 1.57057 7.89503 1.5036 7.77444 1.46472C7.65385 1.42583 7.52575 1.41615 7.40069 1.43646C7.27563 1.45677 7.15718 1.50649 7.05509 1.58154C6.953 1.65658 6.8702 1.7548 6.8135 1.86811L5.4295 4.63451C5.21069 5.0728 4.83863 5.41553 4.3839 5.59771L1.5119 6.74651L6.2535 11.4881L7.4023 8.61611C7.58448 8.16138 7.92722 7.78932 8.3655 7.57051L11.1319 6.18651C11.2452 6.12981 11.3434 6.04701 11.4185 5.94493C11.4935 5.84284 11.5432 5.72439 11.5636 5.59932C11.5839 5.47426 11.5742 5.34616 11.5353 5.22557C11.4964 5.10498 11.4294 4.99535 11.3399 4.90571L8.0943 1.66011Z" fill="#91AAB9" />
              </svg>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="pinActive ? 'd-block':'d-none'">
                <path d="M6.47616 1.80446C6.58953 1.5777 6.75516 1.38112 6.9594 1.23093C7.16364 1.08074 7.40064 0.981226 7.65089 0.9406C7.90113 0.899973 8.15745 0.919393 8.39871 0.997259C8.63998 1.07512 8.85928 1.20921 9.03856 1.38846L12.2842 4.63406C12.4634 4.81334 12.5975 5.03265 12.6754 5.27391C12.7532 5.51518 12.7726 5.77149 12.732 6.02174C12.6914 6.27198 12.5919 6.50899 12.4417 6.71323C12.2915 6.91747 12.0949 7.08309 11.8682 7.19646L9.10176 8.58046C8.83894 8.71177 8.63343 8.93493 8.52416 9.20766L7.37536 12.0797C7.32616 12.2026 7.2472 12.3114 7.14558 12.3963C7.04397 12.4812 6.92286 12.5395 6.79314 12.5661C6.66341 12.5927 6.52912 12.5866 6.40231 12.5485C6.2755 12.5104 6.16013 12.4414 6.06656 12.3477L3.97856 10.2597L1.34416 12.8941H0.778564V12.3285L3.41296 9.69406L1.32496 7.60606C1.23126 7.51249 1.16225 7.39713 1.12413 7.27032C1.086 7.14351 1.07995 7.00921 1.10651 6.87949C1.13307 6.74976 1.19142 6.62866 1.27633 6.52704C1.36123 6.42542 1.47003 6.34647 1.59296 6.29726L4.46496 5.14846C4.73799 5.03936 4.96146 4.83382 5.09296 4.57086L6.47616 1.80446ZM8.47296 1.95406C8.38332 1.86452 8.27369 1.79755 8.15311 1.75866C8.03252 1.71978 7.90442 1.71009 7.77935 1.7304C7.65429 1.75071 7.53584 1.80044 7.43375 1.87548C7.33166 1.95053 7.24886 2.04875 7.19216 2.16206L5.80816 4.92846C5.58935 5.36674 5.2173 5.70948 4.76256 5.89166L1.89056 7.04046L6.63216 11.7821L7.78096 8.91006C7.96314 8.45533 8.30588 8.08327 8.74416 7.86446L11.5106 6.48046C11.6239 6.42376 11.7221 6.34096 11.7971 6.23887C11.8722 6.13678 11.9219 6.01833 11.9422 5.89327C11.9625 5.76821 11.9528 5.64011 11.914 5.51952C11.8751 5.39893 11.8081 5.2893 11.7186 5.19966L8.47296 1.95406Z" fill="#19A3F1" />
                <path d="M8.47296 1.95406C8.38332 1.86452 8.27369 1.79755 8.15311 1.75866C8.03252 1.71978 7.90442 1.71009 7.77935 1.7304C7.65429 1.75071 7.53584 1.80044 7.43375 1.87548C7.33166 1.95053 7.24886 2.04875 7.19216 2.16206L5.80816 4.92846C5.58935 5.36674 5.2173 5.70948 4.76256 5.89166L1.89056 7.04046L6.63216 11.7821L7.78096 8.91006C7.96314 8.45533 8.30588 8.08327 8.74416 7.86446L11.5106 6.48046C11.6239 6.42376 11.7221 6.34096 11.7971 6.23887C11.8722 6.13678 11.9219 6.01833 11.9422 5.89327C11.9625 5.76821 11.9528 5.64011 11.914 5.51952C11.8751 5.39893 11.8081 5.2893 11.7186 5.19966L8.47296 1.95406Z" fill="#19A3F1" />
              </svg>
            </button>
          </div>
        </div>
        <div>
          <p class="txt-gry fs-12 my-2">Activity type</p>
          <div class="d-flex">
            <div>
              <div class="d-flex">
                <div>

                  <button class="fs-12 btn btn-outline-primary"  [ngClass]="btnKIIsActive  ? 'ki_kb_active':''" style="cursor:pointer;" (click)="checkBinKIButton()">
                    KI
                  </button>
                </div>
                <div class="mx-2">
                  <button class=" fs-12 btn btn-outline-primary" [ngClass]="btnKBIsActive  ? 'ki_kb_active':''" style="cursor:pointer;" (click)="checkBinKBButton()">
                    KB
                  </button>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 bin-progressbar position-relative">
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{width: outputBinPercentage + '%'}">
              <div class="progress-parentage position-absolute lh-14 fs-12">{{outputBinPercentage | number}}%</div>
            </div>
          </div>
        </div>
        <ng-template #loading>
          <div class="d-flex mb-3 justify-content-center w-100">
            <div class="loader"></div>
          </div>
        </ng-template>
        <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="(currentUser.Roles[0].Name == 'Administrator' || currentUser.Roles[0].Name == 'Monitoring')">
          <div class="text-center pb-3">
            <div class="count-name lh-15 fs-12 pb-2">Collected</div>
            <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="!showLoader; else loading"> 
              <div class="bin-box bg-green-box mr-1"></div>
              <div class="bin-box-count" >{{collectedBins}}</div>
            </div>
          </div>
          <div class="text-center pb-3">
            <div class="count-name lh-15 fs-12 pb-2">On-going</div>
            <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="!showLoader; else loading">
              <div class="bin-box bg-yellow-box stop-bg mr-1"></div>
              <div class="bin-box-count">{{ongoingBins}}</div>
            </div>
          </div>
          <div class="text-center pb-3">
            <div class="count-name lh-15 fs-12 pb-2">Total PoC</div>
            <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="!showLoader; else loading">
              <div class="bin-box bg-blue-box all-bg mr-1"></div>
              <div class="bin-box-count">{{totalBins}}</div>
            </div>
          </div>
        </div>
        <div (click)="vehicleCoutBox()" class="position-absolute right-clode-arror-bk bg-white cursor-pointer">
          <button class="border-0 p-0 bg-white">
            <img src="./assets/images/web-112021/icons/icon-right.png" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

