import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigModuleVehicle } from 'src/app/app.models';
import { ConfigCompany } from 'src/app/app.models';
import { ConfigVehicleType } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';
import {DropdownModule} from 'primeng/dropdown';

@Component({
  selector: 'app-config-modulevehicle',
  templateUrl: './config-modulevehicle.component.html',
  styleUrls: ['./config-modulevehicle.component.scss']
})
export class ConfigModulevehicleComponent implements OnInit {
  @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
  @ViewChild('dt', { static: true }) table!: ElementRef;

  dataDialog: boolean = false;
  datas: ConfigModuleVehicle[]= [];
  data: ConfigModuleVehicle = { ConfigCompany: {}, ConfigVehicleType: {}};
  selectedDatas: any[] = [];
  submitted: boolean = false;
  statuses: any[] = ['Active', 'Inactive'];
  items: MenuItem[] = [];
  loading: boolean = true;
  menuitems: MenuItem[]=[];
  isCreateNew = true;
  Id: any[] = [];
  configModuleVehicleParam =  { id: null, isactiveonly: true };
  configCompanyParam =  {id: null, isactiveonly: true};
  configVehicleParam =  {id: null, isactiveonly: true};
  selectedCompany: ConfigCompany[] = [];
  selectedVehicle: ConfigVehicleType[] = [];
  company: any[] = [];
  vehicle: any[] = [];
  allScheme: any[] = [];
  dataModal: any;
  modalAssignScheme = false;
  showLoader = false;
  selectedScheme: any;
  assignedSchemeList: any[] = [];
  CompanyName:any;
  isLoading: boolean = true;

  vehicleregnoFormControl = new FormControl('', [
      Validators.required,
  ]);
  companyFormControl = new FormControl('', [
      Validators.required,
  ]);
  descriptionFormControl = new FormControl('', [
      Validators.required,
  ]);
  vehicletypeidFormControl = new FormControl('', [
    Validators.required,
  ]);

  configModuleVehicleForm = this.formBuilder.group({
      vehicleregno: this.vehicleregnoFormControl,
      VehicleTypeId: this.vehicletypeidFormControl,
      active: true,
      CompanyId: this.companyFormControl,
      CompanyName:'',
      createdBy: localStorage.getItem('username'),
  });

  // constructor() { }
  constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
      this.getDatas();
      this.getAllScheme();

      this.items = [
            {label: 'Assign Scheme', icon: 'pi pi-pencil', command: () => {
                this.assignScheme(this.dataModal);
            }},
            {label: 'Edit', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Delete', icon: 'pi pi-trash', command: () => {
                this.deleteData(this.data);
            }}
      ];

      this.menuitems = GlobalComponent.submenus;

      this.commonService.getAllConfigCompany(this.configCompanyParam).subscribe((resp: any) => {

          if (resp.length != 0) {
              this.company = resp.Result;
              this.loading = false;              
          } else {
            
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
      }

      this.commonService.getAllConfigVehicleType(this.configVehicleParam).subscribe((resp: any) => {

        if (resp.length != 0) {
            this.vehicle = resp.Result;
            this.loading = false;              
        } else {
          
        }
    }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
    }

    }

    onChange($event : any){
        // let text = $event.target.options[$event.target.options.selectedIndex].text;
        this.CompanyName = ($event.originalEvent.srcElement.ariaLabel);
        //console.log($event.originalEvent.srcElement.ariaLabel);
    }


    getAllScheme(){
        this.commonService.getAllConfigScheme(this.configCompanyParam).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.allScheme = resp.Result;
                this.loading = false;              
            } else {
            
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

  getDatas(){
    this.isLoading = true;
      this.commonService.getAllConfigModuleVehicle(this.configModuleVehicleParam).subscribe((resp: any) => {

          if (resp.length != 0) {
              this.datas = resp.Result;
              this.isLoading = false;              
          } else {
            
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }
  }

  onSubmit(): void {
      this.submitted = true;
      if (this.configModuleVehicleForm.valid) {
          if (this.isCreateNew == true) {
              this.CreateConfigModuleVehicle();
          } else {
              this.UpdateConfigModuleVehicle();
          }
      }
  }

  selectData(data: any){
      this.data = data;
      this.dataModal = data;
  }

  clearselectData(){
      this.selectedDatas = [];
  }

  openNew() {
      this.data= { ConfigCompany: {}, ConfigVehicleType:{}};
      this.isCreateNew = true;
      this.submitted = false;
      this.dataDialog = true;
  }

  hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
      this.modalAssignScheme = false;
  }

  editData(data: any) {
      this.data = {...data};
      this.dataDialog = true;
      this.isCreateNew = false;
      this.Id = data.Id;
  }

  CreateConfigModuleVehicle(){
    this.isLoading = true;
    this.configModuleVehicleForm.value.CompanyName = this.CompanyName;
      //console.log(this.configModuleVehicleForm.value)
      this.commonService.createConfigModuleVehicle(this.configModuleVehicleForm.value).subscribe((resp: any) => {

          if (resp.Success == true) {
              this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
              this.isLoading = false;
              this.dataDialog = false;
              this.getDatas();
            
          } else {

              this._appSharedService.showError('Error', "Something went wrong!");
              this.isLoading = false;
          }
      }), (error: any) => {

          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }
  }

  UpdateConfigModuleVehicle(){
    this.isLoading = true;
      this.configModuleVehicleForm.value.id = this.Id;

      this.commonService.updateConfigModuleVehicle(this.configModuleVehicleForm.value).subscribe((resp: any) => {

          if (resp.Success == true) {
              this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
              this.isLoading = false;
              this.dataDialog = false;
              this.getDatas();
            
          } else {
              this._appSharedService.showError('Error', "Something went wrong!");
              this.isLoading = false;
          }
      }), (error: any) => {

          this._appSharedService.showError('Error', "Something went wrong!");
          this.isLoading = false;
      }
  }

  deleteData(data: any) {
    this.isLoading = true;
    data.ModifiedBy = localStorage.getItem('username');
          this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + data.VehicleRegNo +  '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              // this.datas = this.datas.filter(val => val.Id !== data.Id);
              this.commonService.deleteConfigModuleVehicle(data).subscribe((resp: any) => {
                  if (resp.Success == true) {
                      this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                      this.isLoading = false;
                      this.dataDialog = false;
                      this.getDatas();
                    
                  } else {
            
                      this._appSharedService.showError('Error', "Something went wrong!");
                      this.isLoading = false;
                  }
              }), (error: any) => {
          
                  this._appSharedService.showError('Error', "Something went wrong!");
                  this.isLoading = false;
              }
          }
      });
  }

  // findIndexById(Id: string): number {
  //     let index = -1;
  //     for (let i = 0; i < this.datas.length; i++) {
  //         if (this.datas[i].Id === Id) {
  //             index = i;
  //             break;
  //         }
  //     }

  //     return index;
  // }

  exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.datas);
          worksheet['!cols'] = [];
          worksheet['!cols'][0] = { hidden: true };
          worksheet['!cols'][4] = { hidden: true };
          
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          /* save to file */
          XLSX.writeFile(workbook, 'ConfigRole.xlsx');
      
      });
  }

    assignScheme(data: any){
        this.modalAssignScheme = true;
        this.getAssignedSchemeList();
    }

    assignSchemeForm(data: any){


        if(this.selectedScheme == null){
            this.messageService.add({key: 'alertMsg', severity:'warn', summary: 'Warn', detail: 'Please Select Scheme!'});
        } else {
            this.showLoader = true;

            var dataSchemeVehicle = {
                "scheme_id": this.selectedScheme.name,
                "vehicle_id": data.VehicleRegNo
            };
    
            this.commonService.AssignSchemeVehicleBQ(dataSchemeVehicle).subscribe((resp: any) => {
                if (resp.Success == true) {
                    this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                    this.getAssignedSchemeList();
                } else {
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
                this.showLoader = false;
    
            }), (error: any) => {
    
                this._appSharedService.showError('Error', "Something went wrong!");
            }
        }
    }

    getAssignedSchemeList(){
        var scheme = {
            "vehicle_id": this.dataModal.VehicleRegNo,
            "scheme_id": null
        };
        this.showLoader = true;

        this.commonService.GetVehicleOrSchemeBQ(scheme).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.assignedSchemeList = resp.Result;
            } else {
                this.assignedSchemeList = [];
            }
            this.showLoader = false;
        }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    unassignScheme(data: any){
        this.confirmationService.confirm({
            message: 'Are you sure you want to unassign ' + data.scheme_id + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;

                this.commonService.UnassignSchemeVehicleBQ(data).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                        this.getAssignedSchemeList();
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                    }

                    this.showLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

    onChangeFilter(event: any) {
        this.selectedScheme = event.value; 
    }

}
