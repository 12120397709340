import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import { CommonService } from '../../services/common.service';
import { AppSharedService } from '../../app.SharedService';

@Component({
  selector: 'app-vehicle-status-details',
  templateUrl: './vehicle-status-details.component.html',
  styleUrls: ['./vehicle-status-details.component.scss']
})
export class VehicleStatusDetailsComponent implements OnInit {

  constructor(private commonService: CommonService,  
    private _appSharedService: AppSharedService) { }

    // @ViewChild('vihecleTable', { static: true }) tabl!: ElementRef;
    // @ViewChild('TABLE', { static: true }) table!: ElementRef;


  schdlDate = new Date();
  fromDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  calnedarDisp: boolean = false;
  searchBy: any[] = [

    { search: 'Vehicle No.', key: 'Vehicle' }, 
    { search: 'Route No.', key: 'Route' }
 
  ];
  selectedSearchBy: any;
  selectedDate: any = null;
  showLoader: boolean = false;
  schedules: any[] = [];
  selectedvihelcelTableDetails: any[] = [];
  routeCords: any[] = [];
  showVehicleLoader: boolean = false;
  dataVehicles: any[] =[];
  dataAllVehicles: any[] =[];
  fillerVihelcelDetails: any;
  selectedfillerVehicleNo: any = null;
  selectedfillerVehicle: any;
  apiVehiclesData: any[] = [];
  loading: boolean = true;
  
 
  onlineIcon2 = "../../assets/images/web-112021/icons/online-vehicle.png";
  offlineIcon2 = "../../assets/images/web-112021/icons/offline-vehicle.png";
  idleIcon2 = "../../assets/images/web-112021/icons/idle-vehicle.png";
  signalLostIcon2 = "../../assets/images/web-112021/icons/signal-vehicle.png";



  ngOnInit(): void {

    this._appSharedService.apiVehicleDetails$.subscribe(value => 
      {
        this.apiVehiclesData = value;
        this.apiVehiclesData = this.apiVehiclesData.sort((a:any, b:any) => {  
          return a.speed >= b.speed
            ? -1
            : 1
        });
      });

    //this.getAllVehicles();
    this.loading = false;
  }

  parseDate(e: Date) {
    
    //this.selectedDate = formatDate(e, 'yyyy-MM-dd', 'en-US');
    this.fromDate = formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US');
    //this.selectedDate = formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US');
 
 
  }

  calendarModal() {
    this.calnedarDisp = !this.calnedarDisp;
  }

  canclCalendar() {
    this.calnedarDisp = false;
  }

  selectByFilter(event: any) {
    

    // this.isVehicleFilterShowlsk = false;
    // this.isRouteFilterShowlsk = false;

    // this.selectedVehicleSchedule = null;
    // this.selectedRouteSchedule = null;


    // if(event.value != null)
    // {
    //   if (event.value.key == 'Vehicle') {
    //     this.isVehicleFilterShowlsk = true;
    //   }
    //   else if (event.value.key == 'Route') {
    //     this.isRouteFilterShowlsk = true;
    //   }

    // }
 
  }

  getAllVehicles() {
    this.showVehicleLoader = true;
    this.commonService.vehicleDetails().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          const result = resp.filter((obj: any) => {

           

            // if(obj.disabled === false || obj.vehicleRegNo.includes('_MB_'))
            // {
            //   if(obj.vehicleRegNo.includes('_MB_'))
            //   {
        
            //     let vehicle = obj.vehicleRegNo;
            //     obj.vehicleRegNo = vehicle.substring(0,obj.vehicleRegNo.indexOf('_MB_'));
            //     return obj;
            //   }
            //   else
            //   {
            //     return obj;
            //   }
              
            // }
            return (obj.disabled === false || obj.vehicleRegNo.includes('_MB_'));
            
          });
 
          this.dataAllVehicles = result;
          this.dataVehicles = this.dataAllVehicles;
          this.showVehicleLoader = false;
        }
      }));
  }

  searchVehicles(searchValue: any) {
    
    var value = searchValue.target.value;
    if (value)
      this.dataAllVehicles = this.fillerVihelcelDetails.filter((x: { vehicleRegNo: string; }) => x.vehicleRegNo.toLowerCase().includes(value.toLowerCase()));
    else
      this.dataAllVehicles = this.fillerVihelcelDetails;
  }

  checkStatus(status: string): string {

    if ((status== 'online')) {
      return this.onlineIcon2;
 
    }
    else if (status == 'offline') {
      return this.offlineIcon2;
    
    }
    else if (status== 'unknown') {
      return this.signalLostIcon2;

    }
    else {

      return this.idleIcon2;
   
    }

  }

  onChangeVehicleFilters(event: any) {
  
    // this.selectedRouteSchedule =null;
    // this.routeSchedules = this.allRouteSchedules;
    // this.selectedfillerVehicleNo = null;

    // if(event.value != null)
    // {

    //     this.selectedfillerVehicleNo = event.value;
          
    //     let selectRoute = this.summaryVehicleRoutes.filter(x => (x.vehicle_no == event.value.vehicleRegNo))[0];

    //     if(selectRoute != undefined)
    //     {
    //       //this.isRouteDisabled = true;
    //       this.routeSchedules = this.routeSchedules.filter(x=> x.main_route == selectRoute.main_route);
    //       this.selectedRouteSchedule = this.routeSchedules.filter(x=> x.main_route == selectRoute.main_route)[0];
    //     }               
    // }
    
  }

  clearAllFilters() {
    this.selectedfillerVehicleNo = null;
    //this.selectedfillerRoute = [];
    this.selectedfillerVehicle = '';
    this.selectedDate = null;
    this.fromDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.canclCalendar();
    //this.selectedRouteSchedule = null;
    //this.routeSchedules = this.allRouteSchedules;
    this.dataVehicles = this.dataAllVehicles;
    this.routeCords = [];

  }

  exportToExcel() {
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    // ws['!cols'] = [];
    // ws['!cols'][0] = { hidden: true };
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.writeFile(wb, 'Schedule.xlsx');
  }




}
