import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { MenuItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { ReportRoute } from 'src/app/app.models';
import { Component, OnInit,ViewChild  } from '@angular/core';
import { GlobalComponent } from 'src/app/global-component';
import { AppSharedService } from 'src/app/app.SharedService';
import { CommonService } from 'src/app/services/common.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss']
})
export class ReportSummaryComponent implements OnInit {

  menuitems: MenuItem[]=[];
  range_date = new Date();
  dateTime = new Date();
  vehicleList: any[] = [];
  selectedVehicle: any;
  datas: ReportRoute[]= [];
  Alldatas: ReportRoute[]= [];
  selectedDatas: any[] = [];
  loading: boolean = true;
  month_preference = 0;
  date_filter: any[] = [];
  vehicle_filter= "";
  param_preference = { key: "Month"};
  min_date = new Date();
  showVehicleLoader: boolean = false;
  selectedVehicleRegNo: string;
  dataAllVehicles: any[] =[];
  contractorVehicles : any[] =[];
  dataVehicles: any[] =[];
  showLoader: boolean = false;
  currentUser: any;
  totalwait: number = 0;
  @ViewChild('dt')
  table!: Table;

  constructor(private commonService: CommonService, private _appSharedService: AppSharedService,) { this.selectedVehicleRegNo = ''; }

  ngOnInit(): void {

    this.getAllVehicle();
    this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');
    this.menuitems = GlobalComponent.report_menus;
    this.loading = false;
  }

  getAllVehicle(){
    this.showVehicleLoader = true;
    this.commonService.vehicleDetails().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          const result = resp.filter((obj: any) => {
            if(obj.disabled === false || obj.vehicleRegNo.includes('_MB_'))
            {
              if(obj.vehicleRegNo.includes('_MB_'))
              {
        
                let vehicle = obj.vehicleRegNo;
                obj.vehicleRegNo = vehicle.substring(0,obj.vehicleRegNo.indexOf('_MB_'));
                return obj;
              }
              else
              {
                return obj;
              }
            }
            //return obj.disabled === false;
          });

          if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
            this.dataAllVehicles = result;
            this.vehicleList = this.dataAllVehicles;
            this.showVehicleLoader = false;
          }
          else
          {
            this.dataAllVehicles = result;
            this.getAllUserVehicles();
            
            //this.showVehicleLoader = false;
          }
          
          
        }
      }));
  }

  getAllUserVehicles(){
    this.commonService.getUserVehicle().subscribe(
      ((resp: any) => {

        var uniqueVehicle:any = [];
       
        if (resp.Result.length != 0) {

          var userVehicles : any[] =[];

          var arrayUniqueByKey = resp.Result.forEach((element: any) => {

            var objIndex = this.dataAllVehicles.findIndex((obj => obj.vehicleRegNo.replace(/\s/g, '') == element.VehicleRegNo.replace(/\s/g, '')));
      
            var objVehicle = this.dataAllVehicles[objIndex];

            if(objIndex >= 0)
            {
              userVehicles.push(objVehicle);
            }

          });


          this.contractorVehicles = userVehicles;
          this.vehicleList = userVehicles;          
          this.showVehicleLoader = false;
        }
        else {
          this.vehicleList = [];
          this.showLoader = false;
        }
      }),
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
        this.showLoader = false;
      }
    );
  }

  getDatasOld(range_date_filter : any){

    this.loading = true;

    this.commonService.getReportSummary(range_date_filter).subscribe(
      (resp: any) => {
        // console.log(resp);
        if (resp.length > 0) {
          
            this.datas = resp;
            this.loading = false;
        } else {
            this.datas = resp;
            this.loading = false;
        }
      },
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }
    );
  }


  getDatas2(range_date_filter : any){

    this.loading = true;

    for (var d = new Date(range_date_filter.dateStart); d <= new Date(range_date_filter.dateEnd); d.setDate(d.getDate() + 1)) {

      let interval = setInterval(() => {

     var dateStart = new Date (d.getFullYear(),d.getMonth(), d.getDate(), 0, 0, 0);
     var dateEnd = new Date (d.getFullYear(),d.getMonth(), d.getDate(), 23, 59, 0);  
     var range_date = {
          
        start_date: dateStart.toISOString(),
        end_date: dateEnd.toISOString(),
        deviceId: this.vehicle_filter
      };
      
        console.log(range_date);
        clearInterval(interval);
      }, 1000)



     
  
  

     }

    // this.commonService.getReportSummary(range_date).subscribe(
    //   (resp: any) => {

    //     //this.datas = [];
    //     if (resp.length > 0) {
            
    //       console.log(resp.startTime)
    //         if(resp.startTime == undefined) 
    //         {
              
    //           //this.datas.push(resp);
    //         }
            
    //         this.loading = false;
    //     } else {
    //         //this.datas.push(resp);
    //         this.loading = false;
    //     }
    //   },
    //   (error: any) => {
    //     this._appSharedService.showError('Error', "Something went wrong!");
    //   }
    //   );

  
    // this.commonService.getReportSummary(range_date_filter).subscribe(
    //   (resp: any) => {
    //     // console.log(resp);
    //     if (resp.length > 0) {
          
    //         this.datas = resp;
    //         this.loading = false;
    //     } else {
    //         this.datas = resp;
    //         this.loading = false;
    //     }
    //   },
    //   (error: any) => {
    //     this._appSharedService.showError('Error', "Something went wrong!");
    //   }
    // );
  }

  getDataVehicle(range_date_filter : any){

    let vehicles: string[] = ['W5884Q',
    'BKA3617',
    'W4475C',
    'WXJ5463',
    'WXW1317',
    'WXH3532',
    'WXA9142',
    'WXH3947',
    'WXJ5246',
    'WXJ5638',
    'WXW1276',
    'BKF4403',
    'WXU8433',
    'WYB6915',
    'WXJ6024',
    'WXL8598',
    'WYF5779',
    'WYF2235',
    'WYB6965',
    'WYB6895',
    'WYE897',
    'WYR5943',
    'WYE9557',
    'WYB6908',
    'VM6931',
    'WYS3789',
    'WYB6845',
    'WYF7518',
    'WXY6243',
    'WYB6924',
    'WYE1592',
    'VM6912',
    'WYE1607',
    'WYF6835',
    'WYE1578',
    'WB9174D',
    'WYG204',
    'WYE4358',
    'BKB8048',
    'BKB8476',
    'BJS5278',
    'BKF4389',
    'WXG4837',
    'WXF6272',
    'WXJ7261',
    'WXJ6597',
    'BLP6838',
    'BRD1988',
    'WXW1141',
    'WXR8901',
    'WYC9402',
    'WYF6024',
    'WB9041D',
    'WYF2461',
    'WYG891',
    'WYF195',
    'WYB6846',
    'WYF5461',
    'WYF5642',
    'WYF5811',
    'KET8527',
    'VHJ1988',
    'VEJ1988',
    'AMQ1988',
    'BRD1992',
    'VGH2264',
    'VJS4339',
    'WYF9817',
    'WYF6040',
    'VKF1988',
    'VEG1988',
    'VEH6117',
    'BKF4828',
    'BKF4410',
    'WYF6756',
    'BJW6334',
    'BJS5284',
    'WXF6592',
    'WXF6645'
    ];

    var count = 0;
    var arrayUniqueByKey = vehicles.forEach((element: any) => {
      count ++;
      var vehicle = this.vehicleList.filter(x=> x.vehicleRegNo.replace(' ','') == element.replace(' ',''));
      if(vehicle.length > 0)
      {

        range_date_filter.deviceId = vehicle[0].vehicleId;
        this.getDatas(range_date_filter,count,vehicles.length,true);
      }
      else
      {
        this.getDatas(range_date_filter,count,vehicles.length,false);
      }

     

      // if(count == vehicles.length){

      //   console.log('end',count)

      //   console.log(this.Alldatas);
      //   this.datas = this.Alldatas;              
      //   this.loading = false;

      // }

    });

    

    
  }


  
  getDatas = async (range_date_filter : any, current: number, length: number, valid: boolean) => {
  this.loading = true;
    try {
      var dateEndLast = new Date(range_date_filter.dateEnd);
      var isLast = false;

      if(valid){
        for (var d = new Date(range_date_filter.dateStart); d <= new Date(range_date_filter.dateEnd); d.setDate(d.getDate() + 1)) {
          var dateStart = new Date (d.getFullYear(),d.getMonth(), d.getDate(), 0, 0, 0);
          var dateEnd = new Date (d.getFullYear(),d.getMonth(), d.getDate(), 23, 59, 0);  
  
          
          var range_date = {
               
             start_date: dateStart.toISOString(),
             end_date: dateEnd.toISOString(),
             //deviceId: this.vehicle_filter
             deviceId: range_date_filter.deviceId
           };
              const res = await this.getDatasByDate(range_date);
    
              if(new Date (d.getFullYear(),d.getMonth(), d.getDate()).toLocaleDateString() == new Date (dateEndLast.getFullYear(),dateEndLast.getMonth(), dateEndLast.getDate()).toLocaleDateString() )
              {
                  //console.log('end')
                  //console.log(this.Alldatas.length);
                  //console.log(this.Alldatas);

                  isLast = true;
                 
                  //this.datas = this.Alldatas;
                
                  //this.loading = false;
              }
         
        }
      }
      else
      {
        isLast = true;
      }

       if(current == length && isLast){
        console.log('end')
        console.log(this.Alldatas)

        // this.datas = this.Alldatas;
        // this.loading = false;
        setTimeout(() => {
        this.datas = this.Alldatas;
        this.loading = false;
        //resolve();
      }, 10000);
      }

      // if(current == length && isLast){
      //   console.log('end')
      //   console.log(this.Alldatas)
      //   setTimeout(() => {
      //   // this.datas = this.Alldatas;
      //   // this.loading = false;
      //   //resolve();
      // }, 5000);
      // }
      

   
    } catch(err) { 
        console.log(err);	    	
    }

    
 }

  async  getDatasOld2 (range_date_filter : any): Promise<void> {
    var dateEndLast = new Date(range_date_filter.dateEnd);
    for (var d = new Date(range_date_filter.dateStart); d <= new Date(range_date_filter.dateEnd); d.setDate(d.getDate() + 1)) {

      var dateStart = new Date (d.getFullYear(),d.getMonth(), d.getDate(), 0, 0, 0);
      var dateEnd = new Date (d.getFullYear(),d.getMonth(), d.getDate(), 23, 59, 0);  
      var range_date = {
           
         start_date: dateStart.toISOString(),
         end_date: dateEnd.toISOString(),
         deviceId: this.vehicle_filter
       };
       let data = await this.getDatasByDate(range_date)
       
    }
    //this.datas = this.Alldatas;
    console.log(this.Alldatas)
    this.loading = false;
  }

  getDatasByDate(range_date_filter : any): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            console.log(`Make the first API call at index`);
            this.commonService.getReportSummary(range_date_filter).subscribe(
              (resp: any) => {

                //console.log(resp[0].startTime)
                if(resp[0].startTime == undefined)
                {
                  resp[0].startTime = range_date_filter.start_date
          
                }
                //this.totalwait += 1000;
                //console.log(resp[0])
                //this.datas.push(resp[0]);
                this.Alldatas.push(resp[0]);
                //return resp;
                // if (resp.length > 0) {
                //     console.log('te',resp.start_date)
                //     return resp;
                //     //this.Alldatas.push(resp);
                //     //console.log(resp);
                //     //console.log(this.Alldatas);
                    
                // } else {
                //     return resp;
                //     //this.datas = resp;
                    
                // }
              },
              (error: any) => {
                this.loading = false;
                this._appSharedService.showError('Error', "Something went wrong!");
              }
            );
            resolve();
        }, 1000);
    });
  }

  parseDate(e: any) {
  
    if (e[1] != null) {
      this.date_filter = e;
    }
  
    if (e.value != null) {
      this.vehicle_filter = e.value.deviceId;
    }
  
    if (this.vehicle_filter && this.date_filter) {
      var dateStart = new Date (new Date(new Date(this.date_filter[0]).getFullYear(),new Date(this.date_filter[0]).getMonth(), new Date(this.date_filter[0]).getDate(), 0, 0, 0));
      var dateEnd = new Date (new Date(new Date(this.date_filter[1]).getFullYear(),new Date(this.date_filter[1]).getMonth(), new Date(this.date_filter[1]).getDate(), 23, 59, 0));
      var range_date_filter = {
        // start_date: formatDate(new Date(this.date_filter[0]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        // end_date: formatDate(new Date(this.date_filter[1]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        start_date: dateStart.toISOString(),
        end_date: dateEnd.toISOString(),
        deviceId: this.vehicle_filter
      };
      // console.log(range_date_filter);
      this.getDatasOld(range_date_filter);
    }
    
  }

  parseDate2(e: any) {
  
    if (e[1] != null) {
      this.date_filter = e;
    }
  
    if (e.value != null) {
      this.vehicle_filter = e.value.deviceId;
    }
  
    if (this.vehicle_filter && this.date_filter) {
      var dateStart = new Date (new Date(new Date(this.date_filter[0]).getFullYear(),new Date(this.date_filter[0]).getMonth(), new Date(this.date_filter[0]).getDate(), 0, 0, 0));
      var dateEnd = new Date (new Date(new Date(this.date_filter[1]).getFullYear(),new Date(this.date_filter[1]).getMonth(), new Date(this.date_filter[1]).getDate(), 23, 59, 0));  
      var range_date_filter = {
          // start_date: formatDate(new Date(this.date_filter[0]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        // end_date: formatDate(new Date(this.date_filter[1]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        start_date: dateStart.toISOString(),
        end_date: dateEnd.toISOString(),
        deviceId: this.vehicle_filter,
        dateStart: dateStart,
        dateEnd:dateEnd
      };

      this.getDatasOld(range_date_filter);
 
      //this.getDatas(range_date_filter);
      //this.getDataVehicle(range_date_filter);
    }
    
  }

  getDatasPreference(){
    this.commonService.getConfigPreferenceSettingsByKey(this.param_preference).subscribe((resp: any) => {
        if (resp.length != 0) {

            this.month_preference = resp.Result[0].Value;
            
            this.min_date = new Date(
                new Date().getFullYear(),
                new Date().getMonth() - resp.Result[0].Value, 
                new Date().getDate());
        } else {
          
        }
    }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
    }

  }

  clearFilterData(){
    window.location.reload();

    var range_date_filter = {
        start_date: null,
        end_date: null,
        module_person_id: null
    }
  }

  exportToExcel(selectedVehicleRegNo: string): void {
    const exportData: any[][] = [];

    const titleRow = ['Report Type: ', 'Summary']; 

    this.fetchDataFromDatabase().then((data) => {
      if (data.length === 0) {
        const noDataMessage = ['No data found'];
        const startTime = this.date_filter[0] ? formatDate(this.date_filter[0], 'dd/MM/yyyy', 'en-US') : '';
        const endTime = this.date_filter[1] ? formatDate(this.date_filter[1], 'dd/MM/yyyy', 'en-US') : '';
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(selectedVehicleRegNo);
        const sentence1Row = ['Device: ', selectedVehicleRegNo];
        const sentence3Row = ['Period: ', startTime + ' - ' + endTime ];
        const headers = ['Vehicle', 'Start Date', 'Distance', 'Start Odometer', 'End Odometer', 'Top Speed', 'Average Speed', 'Engine Hours' ]; 
        exportData.push(titleRow, [], sentence1Row, sentence3Row, [], headers, noDataMessage);

        worksheet.addRows(exportData);

        worksheet.columns.forEach((column) => {
          column.width = 15; // width in characters
        });

        const headerRow = worksheet.getRow(7); // header row

        headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00BDEC' }, // background color
        };
        cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' }, // font color
        };
        cell.alignment = {
            vertical: 'middle', // vertical alignment to middle
        };
        });

        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
          const excelBlob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
    
          saveAs(excelBlob, 'Report_Summary_' + selectedVehicleRegNo);
        });

      } else {
    
        const deviceName = data.length > 0 ? data[0].deviceName : '';
        const startTime = this.date_filter[0] ? formatDate(this.date_filter[0], 'dd/MM/yyyy', 'en-US') : '';
        const endTime = this.date_filter[1] ? formatDate(this.date_filter[1], 'dd/MM/yyyy', 'en-US') : '';
        const sentence1Row = ['Device: ', deviceName];
        const sentence3Row = ['Period: ', startTime + ' - ' + endTime ];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(deviceName);
        exportData.push(titleRow, [], sentence1Row, [], sentence3Row, []);

        const headers = ['Vehicle', 'Date', 'Start Time', 'End Time', 'Distance', 'Start Odometer', 'End Odometer', 'Top Speed', 'Average Speed', 'Engine Hours']; 
        exportData.push(headers);
        
        data.forEach((datas: any) => {
          const FormattedDistance = (datas.distance/1000).toFixed(2);
          const FormattedDistanceWithUnit = `${FormattedDistance} km`;
          const FormattedStartOdometer = (datas.startOdometer/1000).toFixed(2);
          const FormattedStartOdometerWithUnit = `${FormattedStartOdometer} km`;
          const FormattedEndOdometer = (datas.endOdometer/1000).toFixed(2);
          const FormattedEndOdometerWithUnit = `${FormattedEndOdometer} km`;
          const FormattedAverageSpeed = (datas.averageSpeed * 1.852).toFixed(1);
          const FormattedAverageSpeedWithUnit = `${FormattedAverageSpeed} km/h`;
          const FormattedMaxSpeed = (datas.maxSpeed * 1.852).toFixed(1);
          const FormattedMaxSpeedWithUnit = `${FormattedMaxSpeed} km/h`;
          const FormattedSpentFuel = datas.spentFuel.toFixed(1);
          const FormattedSpentFuelWithUnit = `${FormattedSpentFuel} |`;

          const durationAfterDevide = (datas.engineHours/1000)
          const hours = Math.floor(durationAfterDevide / 3600);
          const minutes = Math.floor((durationAfterDevide % 3600) / 60);
          const FormattedEngineHours = `${hours}h ${minutes}m`;

          const row = [datas.deviceName,formatDate(datas.startTime,  'dd/MM/yyyy', 'en-US'), (!datas.endTime) ? 'N/A':formatDate(datas.startTime,  'hh:mm a', 'en-US'), (!datas.endTime) ? 'N/A':formatDate(datas.endTime,  'hh:mm a', 'en-US'), FormattedDistanceWithUnit, FormattedStartOdometerWithUnit, FormattedEndOdometerWithUnit, FormattedMaxSpeedWithUnit, FormattedAverageSpeedWithUnit, FormattedEngineHours];
          exportData.push(row);
        });

        worksheet.addRows(exportData);

        worksheet.columns.forEach((column) => {
            column.width = 15; // width in characters
        });

        const headerRow = worksheet.getRow(7); // header row

        headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00BDEC' }, // background color
        };
        cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' }, // font color
        };
        cell.alignment = {
            vertical: 'middle', // vertical alignment to middle
        };
        });

        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
          const excelBlob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
    
          saveAs(excelBlob, 'Report_Summary_' + deviceName);
        });
      }
    });
  }

  fetchDataFromDatabase(): Promise<any[]> {

    return new Promise<any[]>((resolve, reject) => {

        const data = this.datas;
        resolve(data);
    });
}

}
