import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import { AuthService } from '../../_helpers/auth.service';
import { Location } from '@angular/common';
import { AppSharedService } from '../../app.SharedService';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})

export class AdminLoginComponent implements OnInit {
  pwdEye: boolean = false;
  currentAdmin: any = null;
  userName: any;
  logInForm!: FormGroup;
  formError: string = "";
  publicKey: string = '';

  constructor(public router: Router,
    private fb: FormBuilder,
    private _location: Location,
    private authService: AuthService,
    private appShared: AppSharedService) { }

  ngOnInit() {
    if (AuthService.isAuthenticated() && AuthService.isAutherized('admin')) {
      this.router.navigate(["/admin/device"]);
    }
    else if (AuthService.getCurrentRole() == 'user')
      this.router.navigate(["/home/live-tracking"]);
    else {
      this.logInForm = this.fb.group({
        email: ["", Validators.compose([Validators.required, Validators.email])],
        password: ["", Validators.compose([Validators.required])],
        rememberMe: [false]
      });
      fetch('/assets/security/public_key.txt').then(res => res.text()).then(data => this.publicKey = data);
    }
    var cookie = this.authService.getUserCookie('clu'); // credentials to login user (clu)
    var cookiee = this.authService.getUserCookie('cpu'); // credentials to password user (cpu)
    if ((cookie && cookiee) != null) {
      this.logInForm.controls['email'].setValue(cookie);
      this.logInForm.controls['password'].setValue(cookiee);
      this.logInForm.controls['rememberMe'].setValue(true);
    }
  }

  onSubmit(log: any) {
    //var rsa = forge.pki.publicKeyFromPem(this.publicKey);
    //var encryptedPassword = window.btoa(rsa.encrypt(log.password));
    this.userName = this.logInForm.value.email.substring(0, log.email.lastIndexOf("@"));

    const loginObj = {
      email: log.email,
      password: log.password
    };

    if (this.logInForm.value.rememberMe) {
      this.authService.setUserCookie('clu', log.email);
      this.authService.setUserCookie('cpu', log.password);
    }
    else {
      this.authService.removeUserCookie('clu');
      this.authService.removeUserCookie('cpu');
    }
    this.authService.login(loginObj, true).subscribe(
      (res: any) => {
        if (res.Success == true) {
          this.appShared.showSuccess("Login Successfully", this.userName);
          let interval = setInterval(() => {
            this.router.navigate(['/admin/device']);
            clearInterval(interval);
          }, 1500)
          /*this.successMsgBox = true;*/
        }
        else
          this.appShared.showError("Something went wrong", this.formError = res.Message);

      }), (error: any) => {
        this.formError = "Something went wrong!";
      }

  }
}
