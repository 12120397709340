import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { VehicleShift } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';



@Component({
    selector: 'app-vehicle-shift',
    templateUrl: './vehicle-shift.component.html',
    styleUrls: ['./vehicle-shift.component.scss']
})
export class VehicleShiftComponent implements OnInit {

    dataDialog: boolean = false;
    datas: VehicleShift[]= [];
    data: VehicleShift = { ModuleVehicleShift: {}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    items: MenuItem[] = [];
    loading: boolean = false;
    menuitems: MenuItem[]=[];
    Id: any[] = [];
    schdlDate = "";
    unassignVehicle: any[]= [];
    assignedBackupVehicleShiftList: any[]= [];
    currentDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    assignedBackup : boolean = false;
    currentUser: any = JSON.parse(localStorage.getItem('currentData') || '{}');
    showLoader: boolean = false;
    showVehicleLoader: boolean = false;
    isValidCurrentDate : boolean = false;
    select_backup_vehicle_shift = null;
    shift : any[] = [];
    isLoading: boolean = true;

    assignShiftVehicle = this.formBuilder.group({
        shift_id: this.data.shift_id,
        vehicle_reg_no: this.data.vehicle_reg_no,
        route: this.data.route,
        status: this.data.status,
        subRoute: this.data.subRoute,
        date: this.data.schedule_date,
        createdBy: this.currentUser,
        // created: null,
    });


    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder, public datepipe: DatePipe) {}

    ngOnInit(): void {

        // if(this.currentDate == formatDate(new Date(), 'yyyy-MM-dd', 'en-US')){
        //     this.isValidCurrentDate = true;
        // }
        // else{
        //     this.isValidCurrentDate = false;
        // }

        // this.getDatas(this.currentDate);
        this.menuitems = GlobalComponent.submenus_backup;
        this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');
      
    }

    parseDate(e: Date) {
        this.currentDate = formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US');

        var previous_date = new Date(
            new Date().getFullYear(),
            new Date().getMonth(), 
            new Date().getDate() - 1);
        if(this.currentDate == formatDate(new Date(), 'yyyy-MM-dd', 'en-US') || this.currentDate == formatDate(previous_date,  'yyyy-MM-dd', 'en-US')){
            

            this.isValidCurrentDate = true;
        }
        else{
            this.isValidCurrentDate = false;
        }
            
        this.getDatas(this.currentDate);
    }

    hideDialog() {
        this.dataDialog = false;
        this.submitted = false;
    }

    editData(data: any) {
        this.data = {...data};
        this.dataDialog = true;
        this.Id = data.Id;
        this.showVehicleLoader = true;
        this.getUnassignVehicleList(this.data);
        this.GetAssignedBackupVehicleShiftList(this.data);
    }

    onChangeVehicleBackup(event: any) {
        this.select_backup_vehicle_shift  = event.value.VehicleRegNo;
    }

    getDatas(currentDateTime: any){
        // this.loading = true;

        this.commonService.getAllVehicleShiftByDate(currentDateTime).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.datas = resp.Result;
                
                this.isLoading = false;
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    onSubmit(data: any): void {

        this.submitted = true;
    
        this.showVehicleLoader = true;
        var dataVehicleShift = {
            shift_date: this.data.schedule_date,
            schedule_vehicle_no:this.data.vehicle_reg_no,
            sub_route: this.data.subRoute,
            created_by: this.currentUser.Id,
            shift_backup_vehicle_no: this.select_backup_vehicle_shift
        }
        this.showLoader = true;
        this.assignShift(dataVehicleShift);
    }

    assignShift(data: any){

        if(data.shift_backup_vehicle_no == null){
            this.messageService.add({key: 'alertMsg', severity:'warn', summary: 'Warn', detail: 'Please Select Vehicle!'});
            this.showVehicleLoader = false;
        } else { 
            this.commonService.assignVehicleShift(data).subscribe((resp: any) => {

                if (resp.Success == true) {
                    this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                    
                    this.getDatas(this.currentDate);
                    this.GetAssignedBackupVehicleShiftList(this.data);

                } else {

                    this._appSharedService.showError('Error', "Something went wrong!");
                }

                this.showVehicleLoader = false;

            }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            }
        }
    }

    unassignShift(data: any){

        var UnAssignedBackupVehicleShift = {
            shift_id : data.shift_id,
            schedule_vehicle_no : data.schedule_vehicle_no,
            sub_route : data.subRoute,
            shift_date : data.shift_date,
            shift_backup_vehicle_no : data.shift_backup_vehicle_no,
        }

        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.shift_backup_vehicle_no + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;
                this.showVehicleLoader = true;

                this.commonService.unAssignVehicleShift(UnAssignedBackupVehicleShift).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        this.GetAssignedBackupVehicleShiftList(this.data);
                        this.getDatas(this.currentDate);
                        this.showLoader = false;
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.showLoader = false;
                    }

                    this.showVehicleLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

    getUnassignVehicleList(data: any){

        var unassignVehicleList = {
            backup_date : data.schedule_date,
            schedule_vehicle_no : data.vehicle_reg_no,
            sub_route : data.subRoute
        }

        this.commonService.getUnassignVehicle(unassignVehicleList).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.unassignVehicle = resp.Result;
 
            } 
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    GetAssignedBackupVehicleShiftList(data: any){

        var AssignedBackupVehicleShift = {
            shift_date : data.schedule_date,
            schedule_vehicle_no : data.vehicle_reg_no,
            sub_route : data.subRoute
        }

        this.commonService.GetAssignedBackupVehicleShift(AssignedBackupVehicleShift).subscribe((resp: any) => {
            if (resp.Total != 0) {
                this.assignedBackupVehicleShiftList = resp.Result; 
            } else {
                this.assignedBackupVehicleShiftList = [];

            }
            this.showVehicleLoader = false;

        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    

}
