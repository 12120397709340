import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppSharedService } from 'src/app/app.SharedService';
import { ConfigActionLog, ReportStop, VehiclePendingTask } from 'src/app/app.models';
import { GlobalComponent } from 'src/app/global-component';
import { CommonService } from 'src/app/services/common.service';
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';

@Component({
  selector: 'app-report-stop',
  templateUrl: './report-stop.component.html',
  styleUrls: ['./report-stop.component.scss']
})

export class ReportStopComponent implements OnInit {

    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;


    dataDialog: boolean = false;
    datasBin: VehiclePendingTask[]= [];
    datasSchedule: VehiclePendingTask[]= [];
    datas: ReportStop[]= [];
    data: ConfigActionLog = { ModuleConfigActionLog: {}};
    selectedDatas: any[] = [];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    date_filter: any[] = [];
    vehicle_filter= "";
    user_dropdown: any[] = [];
    hidden_calendar = "display: none !important";
    param_preference = { key: "Month"};
    month_preference = 0;
    dateTime = new Date();
    min_date = new Date();
    range_date = new Date();
    vehicleList: any[] = [];
    selectedVehicle: any;
    configModuleVehicleParam =  { id: null, isactiveonly: true };
    vehicle_no = "";
    schdlDate = "";
    showLoader: boolean = false;
    showVehicleLoader: boolean = false;
    activeIndex: number = 0;
    pending_bin = 0;
    pending_schedule = 0;
    total_schedule = 0;
    total_bin = 0;
    random_color = "";
    data_list: any[] = [];
    // currentDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    tableData: string = '';
    deviceId_filter= "";
    dataAllVehicles: any[] =[];
    dataVehicles: any[] =[];
    selectedVehicleRegNo: string;
    min_date_filter = new Date();
    contractorVehicles : any[] =[];
    currentUser: any;
    

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { this.selectedVehicleRegNo = ''; } 

    ngOnInit(): void {
    
      this.getAllVehicle();

      this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');
        
      this.menuitems = GlobalComponent.report_menus;

      this.loading = false;

    }
    
    getDatas(range_date_filter : any){

      this.loading = true;

      this.commonService.getReportStop(range_date_filter).subscribe(
        (resp: any) => {
          // console.log(resp);
          if (resp.length > 0) {
            console.log(resp);
            this.datas = resp;
            this.loading = false;
          } else {
            
            this.datas = resp;
            this.loading = false;
          }
        },
        (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
        }
      );
    }
    


    parseDate(e: any) {
      // console.log(e);
    
      if (e[1] != null) {
        this.date_filter = e;
        // console.log(this.date_filter);
      }
    
      if (e.value != null) {
        this.vehicle_filter = e.value.deviceId;
        // console.log(this.vehicle_filter);
      }
    
      if (this.vehicle_filter && this.date_filter) {
        var dateStart = new Date (new Date(new Date(this.date_filter[0]).getFullYear(),new Date(this.date_filter[0]).getMonth(), new Date(this.date_filter[0]).getDate(), 0, 0, 0));
        var dateEnd = new Date (new Date(new Date(this.date_filter[1]).getFullYear(),new Date(this.date_filter[1]).getMonth(), new Date(this.date_filter[1]).getDate(), 23, 59, 0));  
        var range_date_filter = {
          // start_date: formatDate(new Date(this.date_filter[0]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        // end_date: formatDate(new Date(this.date_filter[1]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        start_date: dateStart.toISOString(),
        end_date: dateEnd.toISOString(),
          deviceId: this.vehicle_filter
        };
        // console.log(range_date_filter);
    
        this.getDatas(range_date_filter); // Pass the correct selected vehicle here
      }
      
    }


  getRandomColor(index: any) {

    if(index % 2 != 0){
        this.random_color = '#19A3F1';
    } else{
        this.random_color = '#20B86F';
    }
    
  }

    getDatasBinPending(data: any){
      this.loading = true;
      this.data_list = [];
      this.datasBin = [];

      this.commonService.getBinPendingTask(data).subscribe((resp: any) => {
          if (resp.Total != 0) {
              // this.datasBin = resp.Result;
              this.pending_bin = resp.Total;
              var count = 1;
              resp.Result.forEach((currentValue: any, index: any) => {
                  if(currentValue.schedule_no != resp.Result[index > 0 ? index - 1 : 0 ].schedule_no){
                      this.getRandomColor(count);
                      count++;

                      this.data_list.push({
                          code: currentValue.code,
                          date: currentValue.date,
                          park: currentValue.park,
                          pdib_no: currentValue.pdib_no,
                          premise: currentValue.premise,
                          road: currentValue.road,
                          route: currentValue.route,
                          schedule_no: currentValue.schedule_no,
                          sub_route: currentValue.sub_route,
                          vehicle_reg_no: currentValue.vehicle_reg_no,
                          color: this.random_color,
                      });

                  } else if(index == 0){

                      this.data_list.push({
                          code: currentValue.code,
                          date: currentValue.date,
                          park: currentValue.park,
                          pdib_no: currentValue.pdib_no,
                          premise: currentValue.premise,
                          road: currentValue.road,
                          route: currentValue.route,
                          schedule_no: currentValue.schedule_no,
                          sub_route: currentValue.sub_route,
                          vehicle_reg_no: currentValue.vehicle_reg_no,
                          color: "#20B86F",
                      });

                  } else {
                      this.data_list.push({
                          code: currentValue.code,
                          date: currentValue.date,
                          park: currentValue.park,
                          pdib_no: currentValue.pdib_no,
                          premise: currentValue.premise,
                          road: currentValue.road,
                          route: currentValue.route,
                          schedule_no: currentValue.schedule_no,
                          sub_route: currentValue.sub_route,
                          vehicle_reg_no: currentValue.vehicle_reg_no,
                          color: this.random_color,
                      })
                      
                  }
              });

              this.datasBin = this.data_list;
              this.loading = false;

          } else {
              this.datasBin = [];
              this.pending_bin = 0;
              this.loading = false;
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
      }
  }

  getDatasSchedulePending(data: any){
    this.loading = true;

    this.commonService.getSchedulePendingTask(data).subscribe((resp: any) => {
        if (resp.Total != 0) {
            this.datasSchedule = resp.Result;
            this.pending_schedule = resp.Total;
            this.loading = false;
        } else {
            this.datasSchedule = [];
            this.pending_schedule = 0;
            this.loading = false;
        }
    }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
    }
}

  getTotalScheduleBin(data: any){
      this.commonService.getVehicleTotalTask(data).subscribe((resp: any) => {
          if (resp.Total != 0) {
              this.total_schedule = resp.Result['0'].total_schedule;
              this.total_bin = resp.Result['0'].total_bin;
              
              this.loading = false;
          } else {
            
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
      }
  }

    getDatasPreference(){
      this.commonService.getConfigPreferenceSettingsByKey(this.param_preference).subscribe((resp: any) => {
          if (resp.length != 0) {

              this.month_preference = resp.Result[0].Value;
              
              this.min_date = new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - resp.Result[0].Value, 
                  new Date().getDate());
          } else {
            
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
      }

    }

    clearFilterData(){
      window.location.reload();
      //this.range_date.reset();

      var range_date_filter = {
          start_date: null,
          end_date: null,
          module_person_id: null
      }

      // this.getDatas(range_date_filter);
    }

    exportToExcel(selectedVehicleRegNo: string): void {
        const exportData: any[][] = [];

        const titleRow = ['Report Type: ', 'Stops']; 
        const sentence2Row = ['Group: '];

        this.fetchDataFromDatabase().then((data) => {
          if (data.length === 0) {

            const noDataMessage = ['No data found'];
            const startTime = this.date_filter[0] ? formatDate(this.date_filter[0], 'dd/MM/yyyy', 'en-US') : '';
            const endTime = this.date_filter[1] ? formatDate(this.date_filter[1], 'dd/MM/yyyy', 'en-US') : '';
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(selectedVehicleRegNo);
            const sentence1Row = ['Vehicle: ', selectedVehicleRegNo];
            const sentence3Row = ['Period: ', startTime + ' - ' + endTime ];
            const headers = ['Start', 'Start Address', 'Odometer', 'End', 'Duration', 'Engine Hours' ]; 
            exportData.push(titleRow, [], sentence1Row, sentence2Row, sentence3Row, [], headers, noDataMessage);

            worksheet.addRows(exportData);

            worksheet.columns.forEach((column) => {
              column.width = 15; // width in characters
            });

            const headerRow = worksheet.getRow(7); // header row

            headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00BDEC' }, // background color
            };
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' }, // font color
            };
            cell.alignment = {
                vertical: 'middle', // vertical alignment to middle
            };
            });

            workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
              const excelBlob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
        
              saveAs(excelBlob, 'Report_Stop_' + selectedVehicleRegNo);
            });
        

          } else {
        
            const deviceName = data.length > 0 ? data[0].deviceName : '';
            const startTime = this.date_filter[0] ? formatDate(this.date_filter[0], 'dd/MM/yyyy', 'en-US') : '';
            const endTime = this.date_filter[1] ? formatDate(this.date_filter[1], 'dd/MM/yyyy', 'en-US') : '';
            const sentence1Row = ['Vehicle: ', deviceName];
            const sentence3Row = ['Period: ', startTime + ' - ' + endTime ];
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(deviceName);
            exportData.push(titleRow, [], sentence1Row, sentence2Row, sentence3Row, []);

            const headers = ['Start', 'Start Address', 'Odometer', 'End', 'Duration', 'Engine Hours' ]; 
            exportData.push(headers);
            
            data.forEach((datas: any) => {
              const formattedEndOdometer = (datas.endOdometer / 1000).toFixed(2);
              const formattedEndOdometerWithUnit = `${formattedEndOdometer} km`;
              const formattedspentFuel = (datas.spentFuel.toFixed(1));
              const formattedspentFuelWithUnit = `${formattedspentFuel} |`;

              const durationAfterDevide = (datas.duration/1000)
              const hours = Math.floor(durationAfterDevide / 3600);
              const minutes = Math.floor((durationAfterDevide % 3600) / 60);
              const Formattedduration = `${hours}h ${minutes}m`;

              const engineHoursAfterDevide = (datas.engineHours/1000)
              const hoursEngineHours = Math.floor(engineHoursAfterDevide / 3600);
              const minutesEngineHours = Math.floor((engineHoursAfterDevide % 3600) / 60);
              const FormattedEngineHours = `${hoursEngineHours}h ${minutesEngineHours}m`;

              const row = [formatDate(datas.startTime,  'dd/MM/yyyy hh:mm a', 'en-US'), datas.address, formattedEndOdometerWithUnit , formatDate(datas.endTime,  'dd/MM/yyyy hh:mm a', 'en-US'), Formattedduration, FormattedEngineHours];
              exportData.push(row);
            });
        
            worksheet.addRows(exportData);

            worksheet.columns.forEach((column) => {
                column.width = 15; // width in characters
            });

            const headerRow = worksheet.getRow(7); // header row

            headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00BDEC' }, // background color
            };
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' }, // font color
            };
            cell.alignment = {
                vertical: 'middle', // vertical alignment to middle
            };
            });
  
            workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
              const excelBlob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              const deviceName = data.length > 0 ? data[0].deviceName : '';
              saveAs(excelBlob, 'Report_Stop_' + deviceName  );
            });
          }
        });
    }
    

    fetchDataFromDatabase(): Promise<any[]> {

        return new Promise<any[]>((resolve, reject) => {

            const data = this.datas;
            resolve(data);
        });
    }

    getAllVehicle(){
      this.showVehicleLoader = true;
      this.commonService.vehicleDetails().subscribe(
        ((resp: any) => {
          if (resp.length > 0) {
            const result = resp.filter((obj: any) => {
              if(obj.disabled === false || obj.vehicleRegNo.includes('_MB_'))
              {
                if(obj.vehicleRegNo.includes('_MB_'))
                {
          
                  let vehicle = obj.vehicleRegNo;
                  obj.vehicleRegNo = vehicle.substring(0,obj.vehicleRegNo.indexOf('_MB_'));
                  return obj;
                }
                else
                {
                  return obj;
                }
              }
              //return obj.disabled === false;
            });
  
            if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
              this.dataAllVehicles = result;
              this.vehicleList = this.dataAllVehicles;
              this.showVehicleLoader = false;
            }
            else
            {
              this.dataAllVehicles = result;
              this.getAllUserVehicles();
              
              //this.showVehicleLoader = false;
            }
            
            
          }
        }));
    }
  
    getAllUserVehicles(){
      this.commonService.getUserVehicle().subscribe(
        ((resp: any) => {
  
          var uniqueVehicle:any = [];
         
          if (resp.Result.length != 0) {
  
            var userVehicles : any[] =[];
  
            var arrayUniqueByKey = resp.Result.forEach((element: any) => {
  
              var objIndex = this.dataAllVehicles.findIndex((obj => obj.vehicleRegNo.replace(/\s/g, '') == element.VehicleRegNo.replace(/\s/g, '')));
        
              var objVehicle = this.dataAllVehicles[objIndex];
  
              if(objIndex >= 0)
              {
                userVehicles.push(objVehicle);
              }
  
            });
  
  
            this.contractorVehicles = userVehicles;
            this.vehicleList = userVehicles;          
            this.showVehicleLoader = false;
          }
          else {
            this.vehicleList = [];
            this.showLoader = false;
          }
        }),
        (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
          this.showLoader = false;
        }
      );
    }



}

@Pipe({
    name: 'duration'
  })

  export class DurationPipe implements PipeTransform {
    transform(seconds: number): string {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
  
      return `${hours}h ${minutes}m`;
    }
  }
