import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { BackupVehicle } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-backup-vehicles',
  templateUrl: './backup-vehicles.component.html',
  styleUrls: ['./backup-vehicles.component.scss']
})
export class BackupVehiclesComponent implements OnInit {

    dataDialog: boolean = false;
    datas: BackupVehicle[]= [];
    data: BackupVehicle = { ModuleBackupVehicle: {}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    schdlDate = new Date();
    backup_vehicle: any[] = [];
    unassignVehicle: any[]= [];
    backupVehicleList: any[]= [];
    selectedunassignVehicle: any;
    currentDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    assignedBackup : boolean = false;
    currentUser: any = JSON.parse(localStorage.getItem('currentData') || '{}');
    showLoader: boolean = false;
    showVehicleLoader: boolean = false;
    isValidCurrentDate : boolean = false;
  

    // backupVehicleFormControl = new FormControl('', [
    //     Validators.required,
    // ]);

    assignBackupVehicle = this.formBuilder.group({
        vehicle_reg_no: this.data.vehicle_reg_no,
        route: this.data.route,
        subRoute: this.data.subRoute,
        date: this.data.backup_date,
        backup_vehicle_no: this.data.select_backup_vehicle_no,   
        createdBy: this.currentUser,
        // created: null,
    });

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder, public datepipe: DatePipe) { }

    ngOnInit(): void {
        // let currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd');
        // let currentDateTime = '2022-10-01';
        if(this.currentDate == formatDate(new Date(), 'yyyy-MM-dd', 'en-US'))
            this.isValidCurrentDate = true;
        else
            this.isValidCurrentDate = false;
            
        this.getDatas(this.currentDate);
        this.menuitems = GlobalComponent.submenus_backup;

        this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');
      

        this.items = [
            {label: 'Assign', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Detail', icon: 'pi pi-trash', command: () => {
                // this.deleteData(this.data);
            }}
        ];
    }

    parseDate(e: Date) {
        this.currentDate = formatDate(this.schdlDate, 'yyyy-MM-dd', 'en-US');

        if(this.currentDate == formatDate(new Date(), 'yyyy-MM-dd', 'en-US'))
            this.isValidCurrentDate = true;
        else
            this.isValidCurrentDate = false;
            
        this.getDatas(this.currentDate);
    }

    getDatas(currentDateTime: any){
        this.commonService.getAllBackupVehicleByDate(currentDateTime).subscribe((resp: any) => {
            if (resp.length != 0) {
                this.datas = resp.Result;
                
                this.loading = false;
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getUnassignVehicle(data: any){
        this.commonService.getUnassignVehicle(data).subscribe((resp: any) => {
            var distictCompany:any = [];
            if (resp.Total != 0) {
              this.unassignVehicle = resp.Result;
 
            } 
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }

    getBackupVehicleList(data: any){
        this.commonService.getBackupVehicleList(data).subscribe((resp: any) => {
            var distictCompany:any = [];
            if (resp.Total != 0) {
              this.backupVehicleList = resp.Result;
            } else {
              this.backupVehicleList = [];
            }
            this.showVehicleLoader = false;
        }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong with company schedule!");
        }
    }
    onSubmit(data: any): void {

        this.submitted = true;
     
        data.backup_vehicle_no = this.assignBackupVehicle.value.backup_vehicle_no;
        this.showVehicleLoader = true;
        var dataVehicle = {
            backup_vehicle_no: this.selectedunassignVehicle.VehicleRegNo,
            sub_route: this.data.subRoute,
            backup_date: this.data.backup_date,
            created_by: this.currentUser.Id,
            schedule_vehicle_no: this.data.vehicle_reg_no
          }

        // this.dataDialog = false;
        this.showLoader = true;
        this.assignBackup(dataVehicle);
    }

    selectData(data: any){
        this.data = data;
        console.log(this.data);
    }

    clearselectData(){
        this.selectedDatas = [];
        this.currentDate  = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
        this.getDatas(this.currentDate);
    }

    openNew() {
        this.data= { ModuleBackupVehicle: {}};

        this.submitted = false;
        this.dataDialog = true;
    }

    hideDialog() {
        this.dataDialog = false;
        this.submitted = false;
    }

    editData(data: any) {
        this.data = {...data};
        this.dataDialog = true;
        this.isCreateNew = false;
        this.backupVehicleList = [];
        this.Id = data.Id;
        this.showVehicleLoader = true;
        this.getUnassignVehicle(this.data);
        this.getBackupVehicleList(this.data);        
    }

    onChangeVehicleFilter(event: any) {

        this.data.select_backup_vehicle_no  = event.value;
   
        this.selectedunassignVehicle = event.value; 
      }

    assignBackup(data: any){
        this.commonService.assignBackupVehicle(data).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                
                this.getDatas(this.currentDate);
                this.getBackupVehicleList(this.data);
                this.showLoader = false;

             } else {
                this.showLoader = false;

                 this._appSharedService.showError('Error', "Something went wrong!");
             }

             this.showVehicleLoader = false;

         }), (error: any) => {

           this._appSharedService.showError('Error', "Something went wrong!");
         }
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.datas);
            worksheet['!cols'] = [];
            worksheet['!cols'][0] = { hidden: true };
            worksheet['!cols'][4] = { hidden: true };
            
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            /* save to file */
            XLSX.writeFile(workbook, 'ConfigRole.xlsx');
        
        });
    }

    unassign(data: any){
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.backup_vehicle_no + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.showLoader = true;
                this.showVehicleLoader = true;

                this.commonService.deleteBackupVehicle(data).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg',severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        //this.dataDialog = false;
                        this.getBackupVehicleList(this.data);
                        this.getDatas(this.currentDate);
                        this.showLoader = false;
                    } else {
                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.showLoader = false;
                    }

                    this.showVehicleLoader = false;

                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                }
            }
        });
    }

}
