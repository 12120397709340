import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AppSharedService } from '../../../app.SharedService';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

export interface vihelcelTableName {
  collectedId?: string;
  binID?: string;
  binType?: string;
  binstatus?: string;
  coordinate?: string;
  collectedTime?: string;
  collectedBy?: string;
}

@Component({
  selector: 'app-bin-details',
  templateUrl: './bin-details.component.html',
  styleUrls: ['./bin-details.component.scss']
})

export class BinDetailsComponent implements OnInit {
  private unsubscribe = new Subject<void>();
  binTableDetails: any;
  selectedBinTableDetails!: vihelcelTableName[];
  allVehicleDetails: any;
  datatemp: any;
  id: any;
  decrypted: any;

  constructor(private _appSharedService: AppSharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }


  async ngOnInit() {
    await this.getData();
    this.id = this.activatedRoute.snapshot.params['id'];
    this.decrypted = atob(this.id);
    this.binTableDetails = this.allVehicleDetails.filter((x: { collectionId: any; }) => x.collectionId == this.decrypted);
  }

  getData() {
    return new Promise<void>((resolve, reject) => {
      this._appSharedService.liveTrackDetails().subscribe(
        async data => {
          this.allVehicleDetails = await data.collectionSummary;
          resolve();
        },
        err => {
          console.error(err),
            reject();
        }
      );
    });
  }
}
