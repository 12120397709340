//import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
//import {FileUploadModule} from 'primeng/fileupload';
//import {HttpClientModule} from '@angular/common/http';
import {MenuItem} from 'primeng/api';
//import { User } from 'src/app/app.models';
//import * as XLSX from 'xlsx';
import { ConfigCategoryType } from 'src/app/app.models';
import { FormBuilder } from '@angular/forms';
import { GlobalComponent } from 'src/app/global-component';

@Component({
  selector: 'app-config-category-type',
  templateUrl: './config-category-type.component.html',
  styleUrls: ['./config-category-type.component.scss']
})
export class ConfigCategoryTypeComponent implements OnInit {

  @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
  @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;

    datas: ConfigCategoryType[]= [];

    data: ConfigCategoryType[]= [];

    selectedDatas: any[] = [];

    submitted: boolean = false;

    statuses: any[] = ['Active', 'Inactive'];

    items: MenuItem[] = [];

    loading: boolean = true;

    menuitems: MenuItem[]=[];

    isCreateNew = true;
    
    Id: any[] = [];

    configCategoryTypeParam =  {id: null, isactiveonly: true};

   

    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }


    ngOnInit(): void {
      this.getDatas();
  
  
      this.menuitems = GlobalComponent.submenus;
    }
  
    getDatas(){
      this.commonService.getAllConfigCategoryType(this.configCategoryTypeParam).subscribe((resp: any) => {
  
          if (resp.length != 0) {
              this.datas = resp.Result;
              this.loading = false;            
          } else {
            
          }
      }), (error: any) => {
          this._appSharedService.showError('Error', "Something went wrong!");
      }
    
  }
  
  // editData(data: any) {
  //   this.data = {...data};
  //   this.dataDialog = true;
  //   this.isCreateNew = false;
  //   this.Id = data.Id;
  // }
  
  }
