import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { AppSharedService } from 'src/app/app.SharedService';
import { MessageService } from 'primeng/api';
import { ConfirmationService }  from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import {MenuItem} from 'primeng/api';
import * as XLSX from 'xlsx';
import { ConfigCity } from 'src/app/app.models';
import { ModuleConfigState } from 'src/app/app.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ GlobalComponent } from 'src/app/global-component';


@Component({
    selector: 'app-config-city',
    templateUrl: './config-city.component.html',
    styleUrls: ['./config-city.component.scss']
})
export class ConfigCityComponent implements OnInit {

    @ViewChild('dtMain', { static: true }) tableMain!: ElementRef;
    @ViewChild('dt', { static: true }) table!: ElementRef;

    dataDialog: boolean = false;
    datas: ConfigCity[]= [];
    data: ConfigCity = { ConfigState: {}};
    selectedDatas: any[] = [];
    submitted: boolean = false;
    statuses: any[] = ['Active', 'Inactive'];
    items: MenuItem[] = [];
    loading: boolean = true;
    menuitems: MenuItem[]=[];
    isCreateNew = true;
    Id: any[] = [];
    configCityParam =  { id: null, isactiveonly: true };
    configStateParam =  {id: null, isactiveonly: true};
    selectedState: ModuleConfigState[] = [];
    state: any[] = [];
    isLoading: boolean = true;

    nameFormControl = new FormControl('', [
        Validators.required,
    ]);
    stateFormControl = new FormControl('', [
        Validators.required,
    ]);
    descriptionFormControl = new FormControl('', [
        Validators.required,
    ]);

    configCityForm = this.formBuilder.group({
        name: this.nameFormControl,
        description: this.descriptionFormControl,
        active: true,
        createdBy: localStorage.getItem('username'),
        StateId: this.stateFormControl,
    });

    // constructor() { }
    constructor(private commonService: CommonService, private _appSharedService: AppSharedService, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.getDatas();

        this.items = [
            {label: 'Edit', icon: 'pi pi-pencil', command: () => {
                this.editData(this.data);
            }},
            {label: 'Delete', icon: 'pi pi-trash', command: () => {
                this.deleteData(this.data);
            }}
        ];

        this.menuitems = GlobalComponent.submenus;

        this.commonService.getAllConfigState(this.configStateParam).subscribe((resp: any) => {

            if (resp.length != 0) {
                this.state = resp.Result;
                this.loading = false;              
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
        }
    }

    getDatas(){
        this.isLoading = true;
        this.commonService.getAllConfigCity(this.configCityParam).subscribe((resp: any) => {
            console.log(resp);
            if (resp.length != 0) {
                this.datas = resp.Result;
                this.isLoading = false;             
            } else {
              
            }
        }), (error: any) => {
            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    onSubmit(): void {
        this.submitted = true;
        if (this.configCityForm.valid) {
            if (this.isCreateNew == true) {
                this.CreateConfigCity();
            } else {
                this.UpdateConfigCity();
            }
        }
    }

    selectData(data: any){
        this.data = data;
    }

    clearselectData(){
        this.selectedDatas = [];
    }

    openNew() {
        this.data= { ConfigState: {}};
        this.submitted = false;
        this.dataDialog = true;
        this.isCreateNew = true;
    }

    hideDialog() {
        this.dataDialog = false;
        this.submitted = false;
    }

    editData(data: any) {
        this.data = {...data};
        this.dataDialog = true;
        this.isCreateNew = false;
        this.Id = data.Id;
    }

    CreateConfigCity(){
        this.isLoading = true;
        this.commonService.CreateConfigCity(this.configCityForm.value).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Created', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {

                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    UpdateConfigCity(){
        this.isLoading = true;
        this.configCityForm.value.id = this.Id;

        this.commonService.UpdateConfigCity(this.configCityForm.value).subscribe((resp: any) => {

            if (resp.Success == true) {
                this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Updated', life: 3000});
                this.isLoading = false;
                this.dataDialog = false;
                this.getDatas();
              
            } else {
                this._appSharedService.showError('Error', "Something went wrong!");
                this.isLoading = false;
            }
        }), (error: any) => {

            this._appSharedService.showError('Error', "Something went wrong!");
            this.isLoading = false;
        }
    }

    deleteData(data: any) {
        this.isLoading = true;
      data.ModifiedBy = localStorage.getItem('username');

        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + data.Name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.commonService.deleteConfigCity(data).subscribe((resp: any) => {
                    if (resp.Success == true) {
                        this.messageService.add({key: 'alertMsg', severity:'success', summary: 'Successful', detail: 'Data Deleted', life: 3000});
                        this.isLoading = false;
                        this.dataDialog = false;
                        this.getDatas();
                      
                    } else {
              
                        this._appSharedService.showError('Error', "Something went wrong!");
                        this.isLoading = false;
                    }
                }), (error: any) => {
            
                    this._appSharedService.showError('Error', "Something went wrong!");
                    this.isLoading = false;
                }
            }
        });
    }

    findIndexById(Id: string): number {
        let index = -1;
        for (let i = 0; i < this.datas.length; i++) {
            if (this.datas[i].Id === Id) {
                index = i;
                break;
            }
        }

        return index;
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.datas);
            worksheet['!cols'] = [];
            worksheet['!cols'][0] = { hidden: true };
            worksheet['!cols'][4] = { hidden: true };
            
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            /* save to file */
            XLSX.writeFile(workbook, 'ConfigCity.xlsx');
        
        });
    }

}
