<p-toast></p-toast>

<p-card styleClass="p-card-content-0">
    <p-menubar  [model]="menuitems">   
    </p-menubar>
    <h5 style="margin-top: 2.0rem; margin-left:1rem; margin-bottom:-0.3rem">Config Premise Type</h5>
    
    <p>&nbsp;</p>

    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
         
          
          <div class="result-found-list found-fonts fs-10 lh-12">
            <div class="w-100 tableFixHead">
              <!--Route schedule block-->
              <div class="ptable-css ptable-scrollable">
               
                  <div class="w-100 d-flex justify-content-between my-4">
                    <div class="vehicle-fillter">
                    
                        <ul class="nav">

                          <li class="d-flex align-items-center nav-item w-auto">                           
                            <!-- <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus" class="p-button-info rounded cursor-pointer" (click)="openNew()"></button> -->
                          </li>
                          <!-- <li class="d-flex align-items-center nav-item w-auto">
                           
                            <button style="width: 5rem;"  pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger  rounded cursor-pointer" (click)="deleteSelectedDatas()" [disabled]="!selectedDatas || !selectedDatas.length"></button>
                          </li> -->
                          <li class="d-flex align-items-center nav-item w-auto">
                           
                            <!-- <button style="width: 5rem;"  pButton pRipple label="Clear" icon="pi pi-filter-slash" class="p-button-info  rounded cursor-pointer" (click)="clearselectData()" [disabled]="!selectedDatas || !selectedDatas.length"></button> -->
                          </li>

                        </ul>
                      </div>
    
                      <div class="vehicle-fillter">
                        <ul class="nav">
                          <li class="nav-item">
                            <div class="range-calendar">
                              <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <!--input type="text" [(ngModel)]="selectedDatas" pInputText placeholder="Search"-->
                                <!--input pInputText type="text" (input)="dt1.ElementRef.filterGlobal($event.target.value, 'contains')" placeholder="Search..." /-->
                                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                              </span>
                            </div>
                          </li>
                          <!-- <li class="d-flex align-items-center nav-item w-auto pr-0 dropdown down-drop-btn-bk">
                            <button class="border px-3 py-2 filter-btn-btm bg-transparent d-flex align-items-center h-100  d-flex dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <div>Export</div>
                              <div class="pl-3">
                                <img src="./assets/images/web-112021/icons/export.png" />
                              </div>
                            </button>
                            <ul class="dropdown-menu border-0 p-0 mt-0 w-100 bg-white" aria-labelledby="navbarDropdown"> -->
                              <!-- <li>
                                <a class="dropdown-item fs-12 txt-black" style="cursor:pointer" (click)="exportExcel()">Download all</a>
                              </li> -->
                              <!-- <li>
                                <a class="dropdown-item fs-12 txt-black" style="cursor:pointer">Download selected</a>
                              </li>
                            </ul>
                          </li> -->
                        </ul>
                      </div>
                  </div>
                <div #dtMain>
                  <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['Name','Code','Description','Active']" 
                  [(selection)]="selectedDatas"  [rowHover]="true" dataKey="id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="loading"
                   responsiveLayout="scroll" [rowsPerPageOptions]="[10,25,50]"
                  [scrollable]="true" scrollHeight="60vh" [tableStyle]="{'max-width': '100vw'}"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                  <ng-template pTemplate="caption">
                      <div class="flex align-items-center justify-content-between" style="margin-top: -1rem; margin-left: -0.7rem;">
                        <div class="flex align-items-center justify-content-between summary-message">
                          {{datas ? datas.length : 0 }} result(s) found.
                      </div>
                      </div>
                  </ng-template>
                  
                  <ng-template pTemplate="header">
                      <tr>
                        <!-- <th style="width:3vw;max-width:3vw;" class="bg-white text-center fillet-boxs-css">
                              <p-tableHeaderCheckbox class="mx-auto"></p-tableHeaderCheckbox>
                          </th> -->
                        <th pSortableColumn="Name" style="width:150px">Premise Name <p-sortIcon field="Name"></p-sortIcon></th>
                        <th pSortableColumn="Code" style="width:150px">Code <p-sortIcon field="Code"></p-sortIcon></th> 
                        <th pSortableColumn="Description" style="width:150px">Description <p-sortIcon field="Description"></p-sortIcon></th>
                        <th pSortableColumn="Active" style="width:150px">Status <p-sortIcon field="Active"></p-sortIcon></th>
                        <th style="width:150px"></th>
        
                         
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data>
                      <tr class="p-selectable-row">
                          <!-- <td style="width:3vw;max-width:3vw;" class="fillet-boxs-css">
                              <p-tableCheckbox [value]="data" class="mx-auto"></p-tableCheckbox>
                          </td> -->
                          <td style="width:150px">{{data.Name}}</td>
                          <td style="width:150px">{{data.Code}}</td>
                          <td style="width:150px">{{data.Description}}</td>
                          <td style="width:150px"><span style="display: none;">{{data.Active}}</span>
                            <i *ngIf="data.Active" class="pi pi-check" style="font-size: 1rem; color:green"></i>                                                        
                            <i *ngIf="!data.Active"  class="pi pi-times" style="font-size: 1rem; color:red"></i>                           
                           </td>
                          <td style="width:150px">
                              <!-- <p-splitButton label="Action" [model]="this.items" styleClass="p-button-outlined p-button-info" class="border-0 rounded cursor-pointer" (click)="selectData(data)"></p-splitButton> -->
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="5" class="empty-message">No data found.</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between summary-message">
                        In total there are {{datas ? datas.length : 0 }} record(s).
                    </div>
                </ng-template>
        
              </p-table>
                </div>
      
                
              </div>
              
            </div>
          </div>
        </div>
    </div>
    
</p-card>
<!-- <form [formGroup]="configRoleForm" (ngSubmit)="onSubmit()">

    <p-dialog [(visible)]="dataDialog" [style]="{width: '450px'}" header="Details" [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="field">
                <label for="UserName">Name</label>
                <input type="text" formControlName="name" pInputText id="name" [(ngModel)]="data.Name" required autofocus />
                <small class="p-error" *ngIf="submitted && !data.Name">Name is required.</small>
            </div>
            <div class="field">
                <label for="description">Abbreviation</label>
                
                <input type="text" formControlName="abbreviation" pInputText id="abbreviation" [(ngModel)]="data.Abbreviation" required autofocus />
                <small class="p-error" *ngIf="submitted && !data.Abbreviation">Abbreviation Name is required.</small>
            </div>
            <div class="field">
                <label for="description">Description</label>
                <input type="text" formControlName="description" pInputText id="description" [(ngModel)]="data.Description" required autofocus />
                <small class="p-error" *ngIf="submitted && !data.Description">Description is required.</small>
            </div>


        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="submit"></button>
        </ng-template>
    </p-dialog>
</form> -->

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
