import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { CacheService } from "./cache";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  cache: CacheService;

  constructor() {
    this.cache = new CacheService();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:one-variable-per-declaration
    const cachedResponse = this.cache.get(request.url),
      cacheHeader = request.headers.get(CacheService.CACHE_HEADER),
      cacheConfig =
        cacheHeader === "true"
          ? 60
          : !isNaN(Number(cacheHeader))
            ? Number(cacheHeader)
            : false;

    if (cacheConfig && !!cachedResponse) {
      return of(cachedResponse);
    }
    return this.sendRequest(request, next, cacheConfig);
  }

  sendRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    ttlInSeconds: number | boolean = 60
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse && ttlInSeconds) {
          this.cache.set(request.url, response, ttlInSeconds);
        }
      })
    );
  }
}
