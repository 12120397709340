
<div class="w-100">
  <div class="bg-white  rounded p-3">
    <div class="lodar_ele" *ngIf="showLoader">
      <div class="cardloader">
        <span class="circles-loader">Loading…</span>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <h4 class="txt-gry font-weight-bold">Vehicle</h4>
            <button class="btn btn-primary" (click)="addShowDialog()">Add Vehicle</button>
          </div>
          <hr class="my-3" />
        </div>
        <div class="col-md-12">
          <div class="device_data mt-3">
            <div class="card" *ngIf="dataPresent == true;else noVehicles">
              <p-table [value]="vehicles" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Vehicle Id</th>
                    <th>Company Id</th>
                    <th>Device Id</th>
                    <th>Vehicle Registration No</th>
                    <th>Vehicle Type Id</th>
                    <th>Description</th>
                    <th>Vehicle Status</th>
                    <th style="width:75px;">Edit</th>
                    <th style="width:75px;">Delete</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                  <tr>
                    <td>{{customer.vehicleId}}</td>
                    <td>{{customer.companyId}}</td>
                    <td>{{customer.deviceId}}</td>
                    <td>{{customer.vehicleRegNo}}</td>
                    <td>{{customer.vehicleTypeId}}</td>
                    <td>{{customer.description}}</td>
                    <td>{{customer.vehicleStatus}}</td>
                    <td><button class="btn btn-warning rounded-circle fs-12 lh-23" (click)="editShowDialog(customer.vehicle_id)"><i class="fa fa-pencil"></i></button></td>
                    <td><button class="btn btn-danger rounded-circle fs-12 lh-23" (click)="deleteVehicle(customer.vehicle_id)"><i class="fa fa-trash"></i></button></td>
                  </tr>
                </ng-template>
                <!--  <ng-template pTemplate="paginatorleft">
                <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
              </ng-template>
              <ng-template pTemplate="paginatorright">
                <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
              </ng-template>-->
              </p-table>
            </div>
            <ng-template #noVehicles>
              <span>
                No Vehicles to Display
              </span>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="w-100">
        <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" key="myDialog"></p-confirmDialog>
      </div>
      <div class="w-100">
        <p-dialog [(visible)]="addDisplay" [style]="{width: '50vw'}" [modal]="true" [baseZIndex]="10000">
          <ng-template pTemplate="header">
            <h5 class="txt-dgry p-3 border-bottom w-100">Add Vehicle</h5>
          </ng-template>
          <div class="forms_group overflow-hidden">
            <form [formGroup]="vehicleForm" (ngSubmit)="addVehicle()">
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Company Id</label><br />
                <p-dropdown [style]="{'width':'100%'}" [options]="companies" placeholder="Company ID" formControlName="companyId"
                            [showClear]="true"></p-dropdown>
              </div>
              <div class="forms_groupmy-3 mx-3">
                <label class="txt-gry">Device Id</label><br />
                <p-dropdown [style]="{'width':'100%'}" [options]="devices" placeholder="Device ID" formControlName="deviceId"
                            [showClear]="true"></p-dropdown>
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry">Vehicle Registration No</label>
                <input type="text" class="form-control" placeholder="Vehicle Registration No" formControlName="vehicleRegNo" />
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry">Vehicle Type Id</label><br />
                <p-dropdown [style]="{'width':'100%'}" [options]="deviceTypeIds" placeholder="Vehicle Type ID" formControlName="vehicleTypeId"
                            [showClear]="true"></p-dropdown>
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry">Description</label>
                <input type="text" class="form-control" placeholder="Description" formControlName="description" />
              </div>
              <div class="forms_group toggle_switch my-2 mx-3" *ngIf="formActionType == 'Edit'">
                <div class=" align-items-center">
                  <div>Status</div>
                  <div>
                    <input type="checkbox" id="switch" formControlName="active" /><label for="switch">Toggle</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <ng-template pTemplate="footer">
            <button class="btn btn-light w-25" (click)="addCancLBtn()">Cancel</button>
            <button class="btn btn-success w-25" type="submit">Save</button>
          </ng-template>
        </p-dialog>
      </div>

      <div class="w-100">
        <p-dialog [(visible)]="editDisplay" [style]="{width: '50vw'}" [modal]="true" [baseZIndex]="10000">
          <ng-template pTemplate="header">
            <h5 class="txt-dgry p-3 border-bottom w-100">Edit Vehicle</h5>
          </ng-template>
          <div class="forms_group overflow-hidden">
            <form [formGroup]="vehicleForm" (ngSubmit)="editVehicle()">
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry"> Company Id</label>
                <input type="text" class="form-control" placeholder=" Company Id" formControlName="companyId" />
              </div>
              <div class="forms_groupmy-3 mx-3">
                <label class="txt-gry">Device Id</label>
                <input type="text" class="form-control" placeholder="Device Id" formControlName="deviceId" />
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry">Vehicle Registration No</label>
                <input type="text" class="form-control" placeholder="Vehicle Registration No" formControlName="vehicleRegNo" />
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry">Vehicle Type Id</label>
                <input type="text" class="form-control" placeholder="Vehicle Type Id" formControlName="vehicleTypeId" />
              </div>
              <div class="forms_group my-3 mx-3">
                <label class="txt-gry">Description</label>
                <input type="text" class="form-control" placeholder="Description" formControlName="description" />
              </div>
              <div class="forms_group toggle_switch my-2 mx-3">
                <div class=" align-items-center">
                  <div>Status</div>
                  <div>
                    <input type="checkbox" id="switch" formControlName="status" /><label for="switch">Toggle</label>
                  </div>
                </div>
              </div>

            </form>
          </div>
          <ng-template pTemplate="footer">
            <button class="btn btn-light w-25" (click)="editCancLBtn()">Cancel</button>
            <button class="btn btn-success w-25" type="submit">Save</button>
          </ng-template>
        </p-dialog>
      </div>
    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>

