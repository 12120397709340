import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../_helpers/auth.service';
import * as forge from 'node-forge';
import { AppSharedService } from '../../app.SharedService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  logInForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    rememberMe: new FormControl(),
  });
  publicKey: string = '';
  pwdEye: boolean = false;
  restPasswordBox: boolean = false;
  formError: string = "";
  errorMsgBox: boolean = false;
  successMsgBox: boolean = false;
  userName: any;
  loader: boolean = false;
  constructor(private fb: FormBuilder,
    private router: Router,
    private _appSharedService: AppSharedService,
    private commonService: CommonService,
    private authService: AuthService) { }

  ngOnInit(): void {
  
    if (AuthService.isAuthenticated() && AuthService.isAutherized('user')) {

      this.router.navigate(["/home/live-tracking"]);
    }
    else if (AuthService.getCurrentRole() == 'admin')
    {
      this.router.navigate(["/admin"]);
    }
    else {
 
      this.logInForm = this.fb.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(4)]],
        // password: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
        rememberMe: [false]
      });
      fetch('/assets/security/public_key.txt').then(res => res.text()).then(data => this.publicKey = data);
    }
    var cookie = this.authService.getUserCookie('clu'); // credentials to login user (clu)
    var cookiee = this.authService.getUserCookie('cpu'); // credentials to password user (cpu)
    if ((cookie && cookiee) != null) {
      this.logInForm.controls['email'].setValue(cookie);
      this.logInForm.controls['password'].setValue(cookiee);
      this.logInForm.controls['rememberMe'].setValue(true);
    }
  }

  loginFormSubmit(log: any) {
    this.loader = true;
    this.userName = this.logInForm.value.email.substring(0, log.email.lastIndexOf("@"));

    const loginObj = {
      email: log.email,
      password: log.password
    };

    if (this.logInForm.value.rememberMe) {
      this.authService.setUserCookie('clu', log.email);
      this.authService.setUserCookie('cpu', log.password);
    }
    else {
      this.authService.removeUserCookie('clu');
      this.authService.removeUserCookie('cpu');
    }
    this.authService.login(loginObj, false).subscribe(
      (res: any) => {
        if (res.Success == true) {
          
          this.loader = true;
          this._appSharedService.showSuccess("Login Successfully", this.userName);
          this._appSharedService.logout(false);

         

          localStorage.setItem('username', res.Result.Username);

          this.getTraccarAccess(res.Result.Roles[0]);
          

          
        }
        else
          this._appSharedService.showError("Something went wrong", this.formError = res.Message);
        this.loader = false;

      }), (error: any) => {
        this.formError = "Something went wrong!";
      }
  }

  routeToMainPage(role: any){
    let interval = setInterval(() => {
      if(role == 'Driver')
      {
        this.router.navigate(["/home/backup-vehicles"]);
      }
      else if(role == 'SAM')
      {
        this.router.navigate(["/home/backup-vehicles"]);
      }
      else if(role == 'SM' || role == 'EXCO'|| role == 'Contractor')
      {
        this.router.navigate(["/home/backup-vehicles"]);
      }
      else
      {
        this.router.navigate(["/home/live-tracking"]);
      }
      
      clearInterval(interval);
    }, 500)
  }

  forgotPassword() {
    this.restPasswordBox = true;
  }

  alertBoxClose() {
    this.errorMsgBox = false;
    this.successMsgBox = false;
  }

  getTraccarAccess(role: any){

    // var data = { 
    //   key: "TraccarAccess"       
    // }
    var key = "TraccarAccess";

    this.commonService.getTraccarAccess(key).subscribe((resp: any) => {
    
        if (resp.Success == true) {
          localStorage.setItem('TraccarAccess', btoa(resp.Result[0].Value));
          this.routeToMainPage(role);
        } else {      
            //this._appSharedService.showError('Error', "Something went wrong!");
        }
    }), (error: any) => {
        //this._appSharedService.showError('Error', "Something went wrong!");
    }
  }
}
