<p-card styleClass="p-card-content-0">
    <p-menubar [model]="menuitems">   
    </p-menubar>
    <h5 style="margin-top: 0.5rem; margin-left:1rem">Config Permission</h5>
    
    <p>&nbsp;</p>

    <div class="all-vehicle-details custom-scrollbar">
        <div class="w-100 result-found-blocks">
            <div class="result-found-list found-fonts fs-10 lh-12">
                <div class="w-100 tableFixHead">
                    <div class="ptable-css ptable-scrollable">
                        <div class="w-100 d-flex justify-content-between my-4">
                          <div class="vehicle-fillter">
                              <ul class="nav">
                                  <li class="d-flex align-items-center nav-item w-auto">                           
                                      <button style="width: 5rem;" pButton pRipple label="New" icon="pi pi-plus" class="p-button-info rounded cursor-pointer" (click)="openNew()"></button>
                                  </li>
                              </ul>
                            </div>
                            <div class="vehicle-fillter">
                                <ul class="nav">
                                    <li class="nav-item">
                                        <div class="range-calendar">
                                            <span class="p-input-icon-right">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Search..." />
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div #dtMain>
                            <p-table #dt [value]="datas" [rows]="10" [paginator]="true" [globalFilterFields]="['Name','Description','Abbreviation','Active']" 
                            [(selection)]="selectedDatas"  [rowHover]="true" dataKey="Id" styleClass="p-datatable-striped p-datatable-sm"  [loading]="isLoading"
                            responsiveLayout="scroll" [rowsPerPageOptions]="[10,25,50]"
                            [scrollable]="true" scrollHeight="60vh" [tableStyle]="{'max-width': '100vw'}"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                                <ng-template pTemplate="caption">
                                    <div class="flex align-items-center justify-content-between" style="margin-top: -1rem; margin-left: -0.7rem;">
                                        <div class="flex align-items-center justify-content-between summary-message">
                                            {{datas ? datas.length : 0 }} result(s) found.
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>                          
                                        <th pSortableColumn="Name" style="width:150px">Name <p-sortIcon field="Name"></p-sortIcon></th>            
                                        <th pSortableColumn="Description" style="width:150px">Description <p-sortIcon field="Description"></p-sortIcon></th>
                                        <th pSortableColumn="Abbreviation" style="width:150px">Abbreviation <p-sortIcon field="Abbreviation"></p-sortIcon></th>
                                        <th pSortableColumn="Active" style="width:150px">Status <p-sortIcon field="Active"></p-sortIcon></th>
                                        <th style="width:150px"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr class="p-selectable-row">
                                        <td style="width:150px">{{data.Name}}</td>
                                        <td style="width:150px">{{data.Description}}</td>
                                        <td style="width:150px">{{data.Abbreviation}}</td>
                                        <td style="width:150px"><span style="display: none;">{{data.Active}}</span>
                                            <i *ngIf="data.Active" class="pi pi-check" style="font-size: 1rem; color:green"></i>                                                        
                                            <i *ngIf="!data.Active"  class="pi pi-times" style="font-size: 1rem; color:red"></i>                           
                                        </td>
                                        <td style="width:150px">
                                            <p-splitButton label="Action" [model]="this.items" styleClass="p-button-outlined p-button-info" class="border-0 rounded cursor-pointer" (click)="selectData(data)"></p-splitButton>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="6" class="empty-message">No data found.</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between summary-message">
                                        In total there are {{datas ? datas.length : 0 }} record(s).
                                    </div>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card>
<form [formGroup]="configPermissionForm" (ngSubmit)="onSubmit()">
    <p-dialog [(visible)]="dataDialog" [style]="{width: '450px'}" header="Details" [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content" class="custom-pad-content">
            <div class="field custom-pad-bot">
                <label for="name">Name</label>
                <input type="text" formControlName="name" pInputText id="name" name="name" [(ngModel)]="data.Name" autofocus />
                <small class="p-error" *ngIf="submitted && !data.Name">Name is required.</small>
            </div>
            <div class="field custom-pad-bot">
                <label for="description">Description</label>
                <input type="text" formControlName="description" pInputText id="description" name="description" [(ngModel)]="data.Description" autofocus />
                <small class="p-error" *ngIf="submitted && !data.Description">Description is required.</small>
            </div>
            <div class="field">
                <label for="abbreviation">Abbreviation</label>
                <input type="text" formControlName="abbreviation" pInputText id="abbreviation" [(ngModel)]="data.Abbreviation" autofocus />
                <small class="p-error" *ngIf="submitted && !data.Abbreviation">Abbreviation is required.</small>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()" type="button" [disabled]="isLoading"></button>
            <button pButton pRipple class="p-button-text" type="submit" [disabled]="isLoading">
                <span *ngIf="!isLoading" style="font-weight: 700"> <i class="pi pi-check"></i> Save</span>
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
            </button>
        </ng-template>
    </p-dialog>
</form>
<!------------------------------- MODAL ASSIGN VEHICLE ---------------------------------->
<div id="formSubmit">
    <p-dialog [(visible)]="modalAssignRole" [maximizable]="true" [breakpoints]="{'960px': '70vw'}" [style]="{width: '50vw'}" [modal]="true" styleClass="p-fluid dialog-overflow-visible">
      <ng-template pTemplate="header">
          <div class="w-100 d-flex justify-content-between align-items-center h-100">
            <div class="title-fiters">Assign Role</div>
          </div>
      </ng-template>
      <div class="row">
          <div class="col-sm-6 col-md-12">
            <div class="w-100 fillet-boxs-css mb-4 position-relative">
            </div>
          </div>
      </div>
      <ng-template pTemplate="content" class="custom-pad-content" [baseZIndex]="0000">
          <table class="mb-3">
              <tr>
                  <td style="width:30vw;font-size: 90%;"><b>Permission Name</b></td>
                  <td style="width:10vw;font-size: 90%;">:</td>
                  <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.Name}}</span></td>
              </tr>
              <tr>
                  <td style="width:30vw;font-size: 90%"><b>Description</b></td>
                  <td style="width:10vw;font-size: 90%">:</td>
                  <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.Description}}</span></td>
              </tr>
              <tr>
                  <td style="width:30vw;font-size: 90%"><b>Abbreviation</b></td>
                  <td style="width:10vw;font-size: 90%">:</td>
                  <td style="width:40vw;"><span class=" fs-12 text-capitalize">{{data.Abbreviation}}</span></td>
              </tr>
              <tr>
                  <td  style="width:30vw;font-size: 90%"><b> <div class="w-100 fillet-boxs-css mb-4 position-relative">
                      Assign Role
                  </div></b></td>
                  <td><div class="w-100 fillet-boxs-css mb-4 position-relative">
                      :
                  </div></td>
                  <td>
                    <span>
                      <div class="w-100 fillet-boxs-css mb-4 position-relative">
                        <p-dropdown [options]="allRole" [(ngModel)]="selectedRole" [filter]="true" [ngModelOptions]="{standalone: true}"
                                      [showClear]="true" placeholder="Select Role" (onChange)="onChangeFilter($event)"
                                      optionLabel="NormalizedName">
                            <!-- <ng-template pTemplate="selectedItem">
                              <div class="filler-h-item" *ngIf="allRole">
                                <div>{{allRole.NormalizedName}}</div>
                              </div>
                            </ng-template> -->
                        </p-dropdown>
                      </div>
                    </span>
                  </td>
              </tr>
          </table>
          <div class="w-100 fillet-boxs-css mb-4 position-relative">                
            <button pButton label="Assign" class="p-button-info" (click)="assignRoleForm(data)"></button>
            <!-- <button pButton label="Assign" class="p-button-info" (click)="assignVehicleForm(dataModal)" [disabled]="!selectedVehicle"></button> -->
          </div>
          <div *ngIf="showLoader" class="align-items-center justify-content-center" style="text-align: center; display: flex;">
              <div class="loader"></div>
          </div>
          <div class="scrollable-table">
            <table class="table mt-3 outside-border" *ngIf="!showLoader">
                <thead class="thead-light" style="background-color: #e9ecef;">
                    <tr>
                        <td style="padding: 0.3rem;font-size: 90%; padding-left: 10px;" colspan="2">Assigned Role</td>
                    </tr>
                </thead>
                <tbody> 
                    <ng-template [ngIf]="assignedRoleList.length!=0" [ngIfElse]="noData">
                        <tr *ngFor="let assignedRole of assignedRoleList">
                            <td style="padding: 0.3rem;font-size: 90%;padding-left: 10px;">{{assignedRole.RoleName}}</td>
                            <td style="padding: 0.3rem;font-size: 90%;text-align: right; padding-right: 10px;"><i class="pi pi-trash" style="font-size: 1rem; color:red; cursor: pointer;" pTooltip="Unassign" tooltipPosition="right" (click)="unassignVehicle(assignedRole)"></i></td>
                        </tr>
                    </ng-template>
                    <ng-template #noData>
                        <tr>
                            <td style="padding: 0.3rem;" class="font-italic empty-message">No data</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
          </div>
      </ng-template>
      <ng-template pTemplate="footer" style="padding: 0.5rem !important;">
          <button pButton label="Close" icon="pi pi-times" class="p-button-outlined p-button-info p-button p-component" (click)="hideDialog()" type="button"></button>
      </ng-template>
      <ng-template #loaderProgress>
          <div class="Loading"></div>
      </ng-template>
    </p-dialog>
  
  </div>
<p-toast key="alertMsg" position="top-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
