import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { MenuItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { ReportRoute } from 'src/app/app.models';
import { Component, OnInit } from '@angular/core';
import { GlobalComponent } from 'src/app/global-component';
import { AppSharedService } from 'src/app/app.SharedService';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-report-route',
  templateUrl: './report-trips.component.html',
  styleUrls: ['./report-trips.component.scss']
})
export class ReportTripsComponent implements OnInit {

  menuitems: MenuItem[]=[];
  range_date = new Date();
  dateTime = new Date();
  vehicleList: any[] = [];
  selectedVehicle: any;
  datas: ReportRoute[]= [];
  selectedDatas: any[] = [];
  loading: boolean = true;
  month_preference = 0;
  date_filter: any[] = [];
  vehicle_filter= "";
  param_preference = { key: "Month"};
  min_date = new Date();
  showVehicleLoader: boolean = false;
  selectedVehicleRegNo: string;
  dataAllVehicles: any[] =[];
  contractorVehicles : any[] =[];
  dataVehicles: any[] =[];
  showLoader: boolean = false;
  currentUser: any;

  constructor(private commonService: CommonService, private _appSharedService: AppSharedService,) { this.selectedVehicleRegNo = ''; } 

  ngOnInit(): void {

    this.getAllVehicle();
    this.currentUser = JSON.parse(localStorage.getItem('currentData') || '{}');
    this.menuitems = GlobalComponent.report_menus;
    this.loading =false;
  }

  getAllVehicle(){
    this.showVehicleLoader = true;
    this.commonService.vehicleDetails().subscribe(
      ((resp: any) => {
        if (resp.length > 0) {
          const result = resp.filter((obj: any) => {
            if(obj.disabled === false || obj.vehicleRegNo.includes('_MB_'))
            {
              if(obj.vehicleRegNo.includes('_MB_'))
              {
        
                let vehicle = obj.vehicleRegNo;
                obj.vehicleRegNo = vehicle.substring(0,obj.vehicleRegNo.indexOf('_MB_'));
                return obj;
              }
              else
              {
                return obj;
              }
            }
            //return obj.disabled === false;
          });

          if (this.currentUser.Roles[0].Name == 'Administrator' || this.currentUser.Roles[0].Name == 'Monitoring') {
            this.dataAllVehicles = result;
            this.vehicleList = this.dataAllVehicles;
            this.showVehicleLoader = false;
          }
          else
          {
            this.dataAllVehicles = result;
            this.getAllUserVehicles();
            
            //this.showVehicleLoader = false;
          }
          
          
        }
      }));
  }

  getAllUserVehicles(){
    this.commonService.getUserVehicle().subscribe(
      ((resp: any) => {

        var uniqueVehicle:any = [];
       
        if (resp.Result.length != 0) {

          var userVehicles : any[] =[];

          var arrayUniqueByKey = resp.Result.forEach((element: any) => {

            var objIndex = this.dataAllVehicles.findIndex((obj => obj.vehicleRegNo.replace(/\s/g, '') == element.VehicleRegNo.replace(/\s/g, '')));
      
            var objVehicle = this.dataAllVehicles[objIndex];

            if(objIndex >= 0)
            {
              userVehicles.push(objVehicle);
            }

          });


          this.contractorVehicles = userVehicles;
          this.vehicleList = userVehicles;          
          this.showVehicleLoader = false;
        }
        else {
          this.vehicleList = [];
          this.showLoader = false;
        }
      }),
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
        this.showLoader = false;
      }
    );
  }


  getDatas(range_date_filter : any){

    this.loading = true;

    this.commonService.getReportTrips(range_date_filter).subscribe(
      (resp: any) => {
        console.log(resp);
        if (resp.length > 0) {
          
            this.datas = resp;
            this.loading = false;
        } else {
            // this.clearFilterData();
            this.datas = resp;
            this.loading = false;
        }
      },
      (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
      }
    );
  }

  parseDate(e: any) {
  
    if (e[1] != null) {
      this.date_filter = e;
    }
  
    if (e.value != null) {
      this.vehicle_filter = e.value.deviceId;
    }
  
    if (this.vehicle_filter && this.date_filter) {
      var dateStart = new Date (new Date(new Date(this.date_filter[0]).getFullYear(),new Date(this.date_filter[0]).getMonth(), new Date(this.date_filter[0]).getDate(), 0, 0, 0));
      var dateEnd = new Date (new Date(new Date(this.date_filter[1]).getFullYear(),new Date(this.date_filter[1]).getMonth(), new Date(this.date_filter[1]).getDate(), 23, 59, 0));  
      var range_date_filter = {
          // start_date: formatDate(new Date(this.date_filter[0]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        // end_date: formatDate(new Date(this.date_filter[1]), "yyyy-MM-dd'T'HH%'3A'mm%'3A'ss.SSS'Z'", 'en-US'),
        start_date: dateStart.toISOString(),
        end_date: dateEnd.toISOString(),
        deviceId: this.vehicle_filter
      };
      // console.log(range_date_filter);
      this.getDatas(range_date_filter);
    }
    
  }

  getDatasPreference(){
    this.commonService.getConfigPreferenceSettingsByKey(this.param_preference).subscribe((resp: any) => {
        if (resp.length != 0) {

            this.month_preference = resp.Result[0].Value;
            
            this.min_date = new Date(
                new Date().getFullYear(),
                new Date().getMonth() - resp.Result[0].Value, 
                new Date().getDate());
        } else {
          
        }
    }), (error: any) => {
        this._appSharedService.showError('Error', "Something went wrong!");
    }

  }

  clearFilterData(){
    window.location.reload();

    var range_date_filter = {
        start_date: null,
        end_date: null,
        module_person_id: null
    }
  }

  exportToExcel(selectedVehicleRegNo: string): void {
    const exportData: any[][] = [];

    const titleRow = ['Report Type: ', 'Trips']; 
    const sentence2Row = ['Group: '];

    this.fetchDataFromDatabase().then((data) => {
      if (data.length === 0) {
        
        const noDataMessage = ['No data found'];
        const startTime = this.date_filter[0] ? formatDate(this.date_filter[0], 'dd/MM/yyyy', 'en-US') : '';
        const endTime = this.date_filter[1] ? formatDate(this.date_filter[1], 'dd/MM/yyyy', 'en-US') : '';
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(selectedVehicleRegNo);
        const sentence1Row = ['Vehicle: ', selectedVehicleRegNo];
        const sentence3Row = ['Period: ', startTime + ' - ' + endTime ];
        const headers = ['Start', 'Start Address', 'Start Odometer', 'End', 'End Address', 'End Odometer', 'Duration', 'Length', 'Top Speed', 'Average Speed']; 
        exportData.push(titleRow, [], sentence1Row, sentence2Row, sentence3Row, [], headers, noDataMessage);

        worksheet.addRows(exportData);

        worksheet.columns.forEach((column) => {
          column.width = 15; // width in characters
        });

        const headerRow = worksheet.getRow(7); // header row

        headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00BDEC' }, // background color
        };
        cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' }, // font color
        };
        cell.alignment = {
            vertical: 'middle', // vertical alignment to middle
        };
        });

        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
          const excelBlob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
    
          saveAs(excelBlob, 'Report_Trips_' + selectedVehicleRegNo);
        });
        
      } else {
    
        const deviceName = data.length > 0 ? data[0].deviceName : '';
        const startTime = this.date_filter[0] ? formatDate(this.date_filter[0], 'dd/MM/yyyy', 'en-US') : '';
        const endTime = this.date_filter[1] ? formatDate(this.date_filter[1], 'dd/MM/yyyy', 'en-US') : '';
        const sentence1Row = ['Vehicle: ', deviceName];
        const sentence3Row = ['Period: ', startTime + ' - ' + endTime ];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(deviceName);
        exportData.push(titleRow, [], sentence1Row, sentence2Row, sentence3Row, []);

        const headers = ['Start', 'Start Address', 'Start Odometer', 'End', 'End Address', 'End Odometer', 'Duration', 'Length', 'Top Speed', 'Average Speed']; 
        exportData.push(headers);
        
        data.forEach((datas: any) => {
          const FormattedStartOdometer = (datas.startOdometer/1000000).toFixed(2);
          const FormattedStartOdometerWithUnit = `${FormattedStartOdometer} km`;
          const FormattedendOdometer = (datas.endOdometer/1000000).toFixed(2);
          const FormattedendOdometerWithUnit = `${FormattedendOdometer} km`;
          const FormattedaverageSpeed = (datas.averageSpeed).toFixed(1);
          const FormattedaverageSpeedWithUnit = `${FormattedaverageSpeed} kn`;
          const FormattedSpentFuel = datas.spentFuel.toFixed(1);
          const FormattedSpentFuelWithUnit = `${FormattedSpentFuel} |`;
          const FormattedMaxSpeed = datas.maxSpeed.toFixed(1);
          const FormattedMaxSpeedWithUnit = `${FormattedMaxSpeed} kn`;

          const durationAfterDevide = (datas.duration/1000)
          const hours = Math.floor(durationAfterDevide / 3600);
          const minutes = Math.floor((durationAfterDevide % 3600) / 60);
          const Formattedduration = `${hours}h ${minutes}m`;

          const row = [
            formatDate(datas.startTime,  'dd/MM/yyyy hh:mm a', 'en-US'), 
            datas.startAddress, 
            FormattedStartOdometerWithUnit, 
            formatDate(datas.endTime,  'dd/MM/yyyy hh:mm a', 'en-US'), 
            datas.endAddress, 
            FormattedendOdometerWithUnit, 
            Formattedduration, 
            datas.distance,
            FormattedMaxSpeedWithUnit, 
            FormattedaverageSpeedWithUnit, 
            FormattedSpentFuelWithUnit 
            //datas.driverName
        ];
          exportData.push(row);
        });

        worksheet.addRows(exportData);

        worksheet.columns.forEach((column) => {
            column.width = 15; // width in characters
        });

        const headerRow = worksheet.getRow(7); // header row

        headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00BDEC' }, // background color
        };
        cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' }, // font color
        };
        cell.alignment = {
            vertical: 'middle', // vertical alignment to middle
        };
        });

        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
          const excelBlob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
    
          saveAs(excelBlob, 'Report_Trips_' + deviceName);
        });
      }
    });
  }

  fetchDataFromDatabase(): Promise<any[]> {

    return new Promise<any[]>((resolve, reject) => {

        const data = this.datas;
        resolve(data);
    });
}

}
