import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdib',
  templateUrl: './pdib.component.html',
  styleUrls: ['./pdib.component.scss']
})
export class PdibComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
