// import { Component } from '@angular/core';
import { Router } from '@angular/router';

// @Component({
//     selector: 'app-component-overview',
//     templateUrl: './component-overview.component.html',
//     styleUrls: ['./component-overview.component.css']
//   })

  export class GlobalComponent {

    constructor(private router: Router) { }

    public static submenus: any[] = [
        
        {
            label: 'User',
            icon: 'pi pi-user',
            items: [
                {label: 'Manage User', icon: 'pi pi-users', "routerLink": "/home/settings/users"},
                {label: 'Manage User Role', icon: 'pi pi-users', "routerLink": "['/home']"},
                {label: 'Manage User Log', icon: 'pi pi-users', "routerLink": "/home/settings/config-module-action-log"},
        
            ]
        },
        {
            label: 'Configuration',
            icon: 'pi pi-cog',
            items: [
                
                {label: 'Config Activity Type', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-activity-type"},
                {label: 'Config BinType', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-bintype"},
                {label: 'Config Category Type', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-category-type"},
                {label: 'Config City', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-city"},
                {label: 'Config Groups', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-groups"},
                {label: 'Config Menu', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-menu"},
                {label: 'Config MenuType', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-menutype"},
                {label: 'Config Module Company', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-modulecompany"},
                {label: 'Config Module Vehicles', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-modulevehicle"},
                {label: 'Config Park', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-park"},
                {label: 'Config Status', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-status"},
                {label: 'Config Pbt', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-pbt"},
                {label: 'Config Premise Type', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-premise-type"},
                {label: 'Config Road', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-road"},
                {label: 'Config Role', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-role"},
                {label: 'Config Route', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-route"},
                {label: 'Config Scheme', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-scheme"},
                {label: 'Config Service Area', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-service-area"},
                {label: 'Config State', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-state"},
                {label: 'Config SubRoute', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-sub-route"},
                {label: 'Config Vehicle Type', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-vehicletype"},
                {label: 'Config Preference Setting', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-preference-setting"},
                {label: 'Config Permission', icon: 'pi pi-th-large', "routerLink": "/home/settings/config-permission"},
                
            ]
        },
        {
            label: 'Permission',
            icon: 'pi pi-wrench',
            items: [
                {label: 'Menu Permission', icon: 'pi pi-th-large'},
                {label: 'Role Permission', icon: 'pi pi-th-large'},
                
            ]
        },
        {
            label: 'Module',
            icon: 'pi pi-table',
            items: [
                {label: 'Schedule', icon: 'pi pi-fw pi-trash'},
                {label: 'Point Of Collection', icon: 'pi pi-fw pi-refresh'},
                {label: 'PDIB', icon: 'pi pi-fw pi-refresh'},
                
            ]
        }
    ];

    public static submenus_backup: any[] = [
        
        {
            label: 'Day',
            icon: 'pi pi-sun',
            "routerLink": "/home/backup-vehicles"
            
        },{
            label: 'Night',
            icon: 'pi pi-moon',
            "routerLink": "/home/vehicle-shift"
            
        },
    ];

    public static report_menus: any[] = [
        
        {
            label: 'Report Type',
            icon: 'pi pi-book',
            items: [
                {label: 'Route', icon: 'pi pi-map', "routerLink": "/home/report-route"},
                {label: 'Trips', icon: 'pi pi-car' , "routerLink": "/home/report-trips"},
                {label: 'Stops', icon: 'pi pi-stop-circle', "routerLink": "/home/report-stop"},
                {label: 'Summary', icon: 'pi pi-list' , "routerLink": "/home/report-summary"},
                {label: 'Daily Summary', icon: 'pi pi-calendar-minus' , "routerLink": "/home/report-dailysummary"}
            ]
        }
    ];

}